import { types as t, Instance } from 'mobx-state-tree'
import { DynamicAttribute } from '../models'

export const DynamicAttributesRepository = t.model({
    all: t.map(DynamicAttribute),
    byEntity: t.map(t.model({
        entityName: t.identifier,
        attributes: t.array(t.reference(DynamicAttribute)),
    }))
})
    .views(self => ({
        ofEntity(entityName: string): DynamicAttribute[] {
            const c = self.byEntity.get(entityName.toLowerCase())
            return c ? [...c.attributes.values()] : []
        },
    }))
    .actions(self => ({
        set(entityName: string, values: DynamicAttribute[]) {
            values.length > 0 ? self.all.merge(values
                .map(v => ({ [v.id]: v }))
                .reduce((a, c) => ({ ...a, ...c }))
            ) : self.all.merge([])
            self.byEntity.put({ entityName: entityName.toLowerCase(), attributes: values.map(v => v.id) })
        },
    }))

export type DynamicAttributesRepository = Instance<typeof DynamicAttributesRepository>
