import { normalize, schema, Schema } from 'normalizr'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ISortOptions, removeEmpty } from '../api-client'

import {
    Program, TimeBlock, PlayFileCommand,
    PlayAdvCommand, ProgramCommandPlaylist, PlayPpCommand,
    PlayCoverCommand, PlayMusicCommand, Device, emptyMusicProgram,
    ProgramSchema, DeviceSchema, IFilterValues, storeProgram, storeDevice
} from '../models'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'
import { IDomainService } from '.'
import { FormatToBackendDate } from './utils'
import { toJS } from 'mobx'

const entityName = 'program'

export interface IProgramFetchOptions {
    page?: number,
    perPage?: number,
    sort?: ISortOptions,
    filters?: IFilterValues,
}

export interface IProgramService {
    fetch(options?: IProgramFetchOptions): Promise<ICollectionMeta>
    create(program: Program): Promise<Program>
    getMss(program: Program): Promise<string>
}

const ToJSOptions = {
    detectCycles: true,
    exportMapsAsObjects: true,
    recurseEverything: true
}
@injectable()
export class ProgramService implements IDomainService<Program> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        let norm = normalize(data, entitySchema)
        storeProgram(this.dataContext, norm)
        return norm.result
    }

    async fetch(options?: IProgramFetchOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(ProgramSchema))
        if (!!order && order.length > 0) {
            this.dataContext.programs.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async createOrUpdate(program: Program): Promise<Program> {
        if (program.id > 0) {
            return await this.update(program)
        } else {
            return await this.create(program)
        }
    }

    async update(program: Program): Promise<Program> {
        const data = await this.apiClient.patchObject(this.serialize(program), entityName)
        //TODO: check json later
        this.storeContext(data, ProgramSchema)
        let res = this.dataContext.programs.all.get(data.id)!
        //unprotect(res);
        return toJS(res, ToJSOptions) as Program
    }

    async create(program: Program): Promise<Program> {
        const data = await this.apiClient.postObject(this.serialize(program), entityName)
        //TODO: check json later
        this.storeContext(data, ProgramSchema)
        let res = this.dataContext.programs.all.get(data.id)!
        //unprotect(res);
        return toJS(res, ToJSOptions) as Program
    }

    async getMss(program: Program): Promise<string> {
        const data = this.serialize(program)

        try {
            const mssObj = await this.apiClient.postByResource(data, 'programs/mss')
            return mssObj && mssObj.mss ? mssObj.mss : ''
        } catch (e) {
            console.log('Failed to generate MSS: ', e)
            return ''
        }
    }

    async get(id: number): Promise<Program> {
        try {
            const data = await this.apiClient.getObject(id, entityName)
            this.storeContext(data, ProgramSchema)
            let res = this.dataContext.programs.all.get(data.id)!
            //unprotect(res);
            return toJS(res, ToJSOptions) as Program
        } catch (e) {
            console.error(e)
            return Promise.reject()
        }
    }

    async assignProgram(prog: Program, devices: Device[]): Promise<any> {
        await this.apiClient.putByUrl({ program: { devices: devices.map(d => d.id) } }, `programs/${prog.id}/assignments`)
    }

    async getAssignments(prog: Program): Promise<any> {
        let data = await this.apiClient.getByUrl(`programs/${prog.id}/assignments`)
        data = data.devices.map((d: any) => removeEmpty(d))
        const norm = normalize(data, new schema.Array(DeviceSchema))
        storeDevice(this.dataContext, norm)
        return norm.result
    }

    async approve(prog: Program) {
        try {
            await this.apiClient.putByUrl({ program: { state: 'approved' } }, `programs/${prog.id}/state`)
        } catch (e) {
            console.error(e)
        }
    }

    clear() {
        this.dataContext.programs.ordered.clear()
    }

    private serialize(program: Program) {
        return {
            id: program.id !== 0 ? program.id : undefined,
            name: program.name,
            is_advertisement: program.isAdvertisement,
            clientId: program.client!.id,
            startTime: program.startTime,
            stopTime: program.stopTime,
            startDate: FormatToBackendDate(program.startDate!),
            endDate: FormatToBackendDate(program.endDate!),
            timeBlocksAttributes: program.timeBlocks && program.timeBlocks.map(tb => {
                return {
                    id: tb.id !== 0 ? tb.id : undefined,
                    index: tb.index,
                    name: tb.name,
                    startTime: tb.startTime,
                    programCommandsAttributes: this.serializeCommands(tb)
                }
            })
        }
    }

    private serializeCommands(tb: TimeBlock) {
        return this.serializePlayFileCommands(tb.playFileCommands)
            .concat(this.serializePlayAdvCommands(tb.playAdvCommands, tb))
            .concat(this.serializePlayPpCommands(tb.playPpCommands, tb))
            .concat(this.serializePlayCoverCommands(tb.playCoverCommands, tb))
            .concat(this.serializePlayMusicCommands(tb.playMusicCommands, tb))
    }

    private serializePlayFileCommands(playFileCommands: PlayFileCommand[]): any {
        return playFileCommands.map(pf => {
            return {
                id: pf.id !== 0 ? pf.id : undefined,
                index: pf.index,
                type: pf.type,
                startDate: FormatToBackendDate(pf.startDate!),
                endDate: FormatToBackendDate(pf.endDate!),
                startTime: pf.startTime,
                pref: pf.pref ? 1 : 0,
                contentId: pf.content!.id
            }
        })
    }

    private serializePlayAdvCommands(playAdvCommands: PlayAdvCommand[], tb: TimeBlock): any {
        return playAdvCommands.map(pa => {
            return {
                id: pa.id > 0 ? pa.id : undefined,
                index: pa.index,
                type: pa.type,
                startTime: tb.startTime,
                startDate: FormatToBackendDate(pa.startDate!),
                endDate: FormatToBackendDate(pa.endDate!),
                repetition_per_hour: pa.repetitionPerHour,
                songs_count_in_block: pa.songsCountInBlock,
                programCommandPlaylistsAttributes: this.serializePlaylists(pa.programCommandPlaylists)
            }
        })
    }

    private serializePlayPpCommands(playPpCommands: PlayPpCommand[], tb: TimeBlock): any {
        return playPpCommands.map(pp => {
            return {
                id: pp.id > 0 ? pp.id : undefined,
                index: pp.index,
                type: pp.type,
                startTime: tb.startTime,
                startDate: FormatToBackendDate(pp.startDate!),
                endDate: FormatToBackendDate(pp.endDate!),
                after_songs_count: pp.afterSongsCount,
                play_count: pp.playCount,
                programCommandPlaylistsAttributes: this.serializePlaylists(pp.programCommandPlaylists)
            }
        })
    }

    private serializePlayCoverCommands(playCoverCommands: PlayCoverCommand[], tb: TimeBlock): any {
        return playCoverCommands.map(pp => {
            return {
                id: pp.id > 0 ? pp.id : undefined,
                index: pp.index,
                type: pp.type,
                startTime: tb.startTime,
                startDate: FormatToBackendDate(pp.startDate!),
                endDate: FormatToBackendDate(pp.endDate!),
                repetition_per_hour: pp.repetitionPerHour,
                songs_count_in_block: pp.songsCountInBlock,
                programCommandPlaylistsAttributes: this.serializePlaylists(pp.programCommandPlaylists)
            }
        })
    }

    private serializePlayMusicCommands(playMusicCommands: PlayMusicCommand[], tb: TimeBlock): any {
        return playMusicCommands.map(pp => {
            return {
                id: pp.id > 0 ? pp.id : undefined,
                index: pp.index,
                type: pp.type,
                startTime: tb.startTime,
                startDate: FormatToBackendDate(pp.startDate!),
                endDate: FormatToBackendDate(pp.endDate!),
                programCommandPlaylistsAttributes: this.serializePlaylists(pp.programCommandPlaylists)
            }
        })
    }

    private serializePlaylists(pls: ProgramCommandPlaylist[]) {
        return pls.map(pl => {
            return {
                id: pl.id > 0 ? pl.id : undefined,
                index: pl.index,
                repetition_count: pl.repetitionCount,
                shuffle: pl.shuffle,
                playlist_id: pl.playlist!.id,

            }
        })
    }
}
