import React from 'react'
import {
  Dialog, DialogTitle, DialogContent,
  DialogActions, Button
} from '@material-ui/core'
import { confirmAlert } from 'react-confirm-alert'

interface IProps {
  handleClose: () => void
  onConfirm?: () => void
  title: string
  message?: string
}

const ConfirmDialog: React.SFC<IProps> = ({ handleClose, onConfirm, title, message }) => (
  <Dialog
    open
    onClose={handleClose}
    aria-labelledby="confirm-dialog"
    maxWidth="md"
  >
    <DialogTitle id="confirm-dialog-title">
      {title}
    </DialogTitle>
    {!!message ?
      <DialogContent>
        {message}
      </DialogContent> : []
    }
    <DialogActions>
      <Button
        color="default"
        variant="outlined"
        onClick={() => {
          handleClose()
        }}
      >
        No
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          if (onConfirm) {
            onConfirm()
          }
          handleClose()
        }}
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
)

export default function ConfirmationDialog(title: string, message: string, onConfirm: () => void) {
  return confirmAlert({
    title: title,
    message: message,
    customUI: ({ title, message, onClose }) => {
      return (
        <ConfirmDialog
          handleClose={onClose}
          title={title}
          message={message}
          onConfirm={onConfirm}
        />)
    }
  })
}