import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { GenericValue } from './generic'
import { Client, ClientSchema, storeClient } from './client'
import validate from 'validate.js'
import { Content, ContentSchema, storeContent } from '.'
import moment from 'moment'
import { Playlist, PlaylistSchema, storePlaylist } from './playlist'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend, FixTimesFromBackend, FixDatesFromBackend } from '../services/utils'

const isEmpty = (obj: any): boolean => {
    return Object.entries(obj).length === 0 && obj.constructor === Object
}

export const ProgramCommandPlaylist = t.model({
    id: t.identifierNumber,
    index: t.optional(t.number, 0),
    repetitionCount: t.optional(t.number, 1),
    shuffle: t.optional(t.boolean, false),
    playlist: t.maybe(t.reference(Playlist)),
})

export type ProgramCommandPlaylist = Instance<typeof ProgramCommandPlaylist>

export const playlistSelectionConstraints = {
    repetitionCount: {
        presence: { allowEmpty: false }, numericality: {
            onlyInteger: true,
            greaterThan: 0
        }
    },
    playlist: { presence: { allowEmpty: false } },
}

export const emptyProgramCommandPlaylist = {
    id: 0,
    index: 0,
    repetitionCount: 1,
    shuffle: false,
}

export const defaultPlayListArray = (): ProgramCommandPlaylist[] => {
    return [emptyProgramCommandPlaylist as any]
}

export const PlayMusicCommand = t.model({
    id: t.identifierNumber,
    index: t.number,
    type: t.literal('PlayMusicCommand'),
    startDate: t.maybe(t.Date),
    endDate: t.maybe(t.Date),
    programCommandPlaylists: t.optional(t.array(t.reference(ProgramCommandPlaylist)), []),
})

export type PlayMusicCommand = Instance<typeof PlayMusicCommand>

export const playMusicConstraints = {
    startDate: { presence: { allowEmpty: false }, datetime: false },
    endDate: { presence: { allowEmpty: false }, datetime: false },
}

export const PlayCoverCommand = t.model({
    id: t.identifierNumber,
    index: t.number,
    type: t.literal('PlayCoverCommand'),
    startDate: t.maybe(t.Date),
    endDate: t.maybe(t.Date),
    repetitionPerHour: t.number,
    songsCountInBlock: t.number,
    programCommandPlaylists: t.optional(t.array(t.reference(ProgramCommandPlaylist)), []),
})

export type PlayCoverCommand = Instance<typeof PlayCoverCommand>

export const playCoverConstraints = {
    startDate: { presence: { allowEmpty: false }, datetime: false },
    endDate: { presence: { allowEmpty: false }, datetime: false },
    repetitionPerHour: {
        presence: { allowEmpty: false }, numericality: {
            onlyInteger: true,
            greaterThan: 0
        }
    },
    songsCountInBlock: {
        presence: { allowEmpty: false }, numericality: {
            onlyInteger: true,
            greaterThan: 0
        }
    },
}

export const PlayPpCommand = t.model({
    id: t.identifierNumber,
    index: t.number,
    type: t.literal('PlayPpCommand'),
    startDate: t.maybe(t.Date),
    endDate: t.maybe(t.Date),
    afterSongsCount: t.number,
    playCount: t.number,
    programCommandPlaylists: t.optional(t.array(t.reference(ProgramCommandPlaylist)), []),
})

export type PlayPpCommand = Instance<typeof PlayPpCommand>

export const playPpConstraints = {
    startDate: { presence: { allowEmpty: false }, datetime: false },
    endDate: { presence: { allowEmpty: false }, datetime: false },
    afterSongsCount: {
        presence: { allowEmpty: false }, numericality: {
            onlyInteger: true,
            greaterThan: 0
        }
    },
    playCount: {
        presence: { allowEmpty: false }, numericality: {
            onlyInteger: true,
            greaterThan: 0
        }
    },
}

export const PlayAdvCommand = t.model({
    id: t.identifierNumber,
    index: t.number,
    type: t.literal('PlayAdvertisingCommand'),
    startDate: t.maybe(t.Date),
    endDate: t.maybe(t.Date),
    repetitionPerHour: t.number,
    songsCountInBlock: t.number,
    programCommandPlaylists: t.optional(t.array(t.reference(ProgramCommandPlaylist)), []),
})

export type PlayAdvCommand = Instance<typeof PlayAdvCommand>

export const playAdvConstraints = {
    startDate: { presence: { allowEmpty: false }, datetime: false },
    endDate: { presence: { allowEmpty: false }, datetime: false },
    repetitionPerHour: {
        presence: { allowEmpty: false }, numericality: {
            onlyInteger: true,
            greaterThan: 0
        }
    },
    songsCountInBlock: {
        presence: { allowEmpty: false }, numericality: {
            onlyInteger: true,
            greaterThan: 0
        }
    },
}

export const PlayFileCommand = t.model({
    id: t.identifierNumber,
    index: t.number,
    type: t.literal('PlayFileCommand'),
    startDate: t.maybe(t.Date),
    endDate: t.maybe(t.Date),
    startTime: t.string,
    pref: t.boolean,
    content: t.maybe(t.reference(Content)),
})

export type PlayFileCommand = Instance<typeof PlayFileCommand>

export const playFileConstraints = {
    startDate: { presence: { allowEmpty: false }, datetime: false },
    endDate: { presence: { allowEmpty: false }, datetime: false },
    startTime: { presence: { allowEmpty: false } },
    content: { presence: { allowEmpty: false } },
}

export const PlayPlaylistCommand = t.model({
    index: t.number,
    type: t.literal('play_playlist')
})
export type PlayPlaylistCommand = Instance<typeof PlayPlaylistCommand>

export const Command = t.union(PlayFileCommand, PlayPlaylistCommand,
    PlayAdvCommand, PlayPpCommand,
    PlayCoverCommand, PlayMusicCommand)
export type Command = Instance<typeof Command>

export const TimeBlock = t.model({
    id: t.identifierNumber,
    index: t.optional(t.number, 0),
    name: t.string,
    startTime: t.string,
    //stopTime: t.string,
    playFileCommands: t.optional(t.array(t.reference(PlayFileCommand)), []),
    playAdvCommands: t.optional(t.array(t.reference(PlayAdvCommand)), []),
    playPpCommands: t.optional(t.array(t.reference(PlayPpCommand)), []),
    playCoverCommands: t.optional(t.array(t.reference(PlayCoverCommand)), []),
    playMusicCommands: t.optional(t.array(t.reference(PlayMusicCommand)), []),
})
export type TimeBlock = Instance<typeof TimeBlock>

export const timeBlockConstraints = {
    name: { presence: { allowEmpty: false } },
    startTime: { presence: { allowEmpty: false } },
    //stopTime: { presence: { allowEmpty: false } },
}

export const Program = t
    .model({
        id: t.identifierNumber,
        name: t.string,
        isAdvertisement: t.boolean,
        startTime: t.string,
        stopTime: t.string,
        startDate: t.maybe(t.Date),
        endDate: t.maybe(t.Date),
        timeBlocks: t.optional(t.array(t.reference(TimeBlock)), []),
        client: t.maybe(t.reference(Client)),
        mss: t.optional(t.string, ''),
        state: t.string, //t.union(t.literal('created'), t.literal('approved'), t.literal('disabled')),
        createdAt: t.optional(t.Date, new Date()),
        updatedAt: t.optional(t.Date, new Date()),
        //updatedAt: t.optional(t.Date, new Date()),
    })
    .views(self => ({
        get isApproved(): boolean {
            return self.state === 'approved'
        },
        get isCreated(): boolean {
            return self.state === 'created'
        },
        isReadOnly(): boolean {
            return self.state === 'approved' || self.state === 'disabled'
        }
    }))
export type Program = Instance<typeof Program>

export const ProgramFields = t.enumeration([
    'id',
    'name',
    'startTime',
    'stopTime',
    'startDate',
    'endDate',
    'createdAt',
    'updatedAt'
])

export type ProgramFields = Instance<typeof ProgramFields>

export const emptyMusicProgram = {
    id: 0,
    isAdvertisement: false,
    name: '', //Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) ,
    startTime: '10:00',
    stopTime: '20:00',
    startDate: new Date(),
    endDate: moment(new Date()).add(1, 'days').toDate(),
    timeBlocks: [],
    mss: '',
    state: 'created',
    createdAt: new Date(),
    updatedAt: new Date()
}

export const emptyAdsProgram = {
    id: 0,
    isAdvertisement: true,
    name: '', //Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) ,
    startTime: '10:00',
    stopTime: '20:00',
    startDate: new Date(),
    endDate: moment(new Date()).add(1, 'days').toDate(),
    timeBlocks: [],
    mss: '',
    state: 'created',
    createdAt: new Date(),
    updatedAt: new Date()
}

export const ProgramFilters = t
    .model({
        name: t.string,
    })

export type ProgramFilters = Map<ProgramFields, GenericValue>

export const programConstraints = {
    name: { presence: { allowEmpty: false } },
    startTime: { presence: { allowEmpty: false } },
    stopTime: { presence: { allowEmpty: false } },
    startDate: { presence: { allowEmpty: false }, datetime: false },
    endDate: { presence: { allowEmpty: false }, datetime: false },
    client: { presence: { allowEmpty: false } },
}

export class TimeBlockErrors {
    errors: { [field: string]: string[] } = {}
    playFileErrors: { [field: string]: string[] }[] = []
    playAdvErrors: CommandWithPlValidationError[] = []
    playPpErrors: CommandWithPlValidationError[] = []
    playCoverErrors: CommandWithPlValidationError[] = []
    playMusicErrors: CommandWithPlValidationError[] = []
    constructor(timeBlock: TimeBlock, i: number, program: Program) {
        /*if(!this.errors["startTime"] && !this.errors["stopTime"] && timeBlock.startTime >= timeBlock.stopTime) {
        const errText =  "Start time can't be grater then or equals to  Stop time";
            this.errors = {"startTime": [errText],...this.e rrors};
        }  */

        this.playFileErrors = timeBlock.playFileCommands ? timeBlock.playFileCommands.map((pf, i) => {
            return ValidatePlayFileCommand(pf)
        }) : []

        this.playAdvErrors = timeBlock.playAdvCommands ? timeBlock.playAdvCommands.map((adv, i) => {
            return ValidatePlayAdvCommand(adv)
        }) : []

        this.playPpErrors = timeBlock.playPpCommands ? timeBlock.playPpCommands.map((pp, i) => {
            return ValidatePlayPpCommand(pp)
        }) : []

        this.playCoverErrors = timeBlock.playCoverCommands ? timeBlock.playCoverCommands.map((pc, i) => {
            return ValidatePlayCoverCommand(pc)
        }) : []

        this.playMusicErrors = timeBlock.playMusicCommands ? timeBlock.playMusicCommands.map((pm, i) => {
            return ValidatePlayMusicCommand(pm)
        }) : []

        if (i === 0 && !this.errors.startTime && program.startTime !== timeBlock.startTime) {
            this.errors = { 'startTime': ['Start time for the first block should match start time for program'], ...this.errors }
        }

        const isOverMidnightProgram = program.stopTime < program.startTime

        const checkAddDay = (time: string): string => {
            return (isOverMidnightProgram && time < program.startTime && time < program.stopTime) ?
                '1:' + time :
                '0:' + time
        }

        //TimeBlocks should be ordered by startTime
        if (i > 0) {
            const currStartTime = checkAddDay(timeBlock.startTime)

            const prevStartTime = checkAddDay(program.timeBlocks[i - 1].startTime)

            if (currStartTime <= prevStartTime) {
                this.errors = { 'startTime': ['Start time for the this block should be greater then for the preveous one'], ...this.errors }
            }
        }

        if (!program.isAdvertisement && timeBlock.playMusicCommands.length == 0) {
            this.errors = { 'overall': ['Time block should containt at least one music command'] }
        }

        // match stop time for program and for last block
        // if(i == (program.timeBlocks.length - 1) && !this.errors["stopTime"] && program.stopTime != timeBlock.stopTime) {
        //     this.errors = {"stopTime": ["Stop time for the last block should match stop time for program"],...this.errors};
        // }
    }

    get cmdsErrors() {
        return [this.playAdvErrors, this.playPpErrors, this.playCoverErrors, this.playMusicErrors]
    }

    get isValid() {
        return isEmpty(this.errors) && this.playFileErrors.reduce((valid, v) => { return valid && isEmpty(v) }, true) && this.isCmdsValid
    }

    private get isCmdsValid() {
        return this.cmdsErrors.reduce((valid, v) => {
            const res = valid && v.reduce((valid, v2) => { return valid && v2.isValid }, true)
            return res
        }, true)
    }
}
export class ProgramValidationError {
    errors: { [field: string]: string[] }
    timeBlocksErrors: TimeBlockErrors[] = []
    constructor(program?: Program) {
        if (!program) {
            return
        }

        this.errors = validate(program, programConstraints) || {} as { [field: string]: string[] }

        if (this.errors.startDate || this.errors.endDate) {
            this.errors.dateRange = [...this.errors.startDate, ...this.errors.endDate]
        }

        //if(!this.errors["startTime"] && !this.errors["stopTime"] && program.startTime >= program.stopTime) {
        //  const errText =  "Start time can't be grater then or equals to  End time";
        //    this.errors = {"startTime": [errText], ...this.errors};
        //}

        if (program.timeBlocks && program.timeBlocks.length == 0) {
            //this.errors["timeBlocks"] = ["There should be at least one timeblock for program"];
        } else {
            this.timeBlocksErrors = program.timeBlocks ? program.timeBlocks.map((tb, i) => new TimeBlockErrors(tb, i, program)) : []
        }
    }
    get isValid() {
        return isEmpty(this.errors) && this.timeBlocksErrors.reduce((valid, v) => { return valid && v.isValid }, true)
    }
}

export class CommandWithPlValidationError {
    errors: { [field: string]: string[] } = {}
    playlistErrors: { [field: string]: string[] }[] = []
    get isValid() {
        return isEmpty(this.errors) && this.playlistErrors.reduce((valid, v) => { return valid && isEmpty(v) }, true)
    }
}

export const ValidateProgram = (program: Program): ProgramValidationError | null => {
    let err = new ProgramValidationError(program)

    if (err.isValid) {
        return null
    } else {
        return err
    }
}

const ValidatePlayFileCommand = (pf: PlayFileCommand): { [field: string]: string[] } => {
    let err = validate(pf, playFileConstraints) || {} as { [field: string]: string[] }

    if (err.startDate || err.endDate) {
        err.dateRange = [...err.startDate, ...err.endDate]
    }

    return err
}

const ValidatePlayAdvCommand = (pf: PlayAdvCommand): CommandWithPlValidationError => {
    let err = new CommandWithPlValidationError()
    err.errors = validate(pf, playAdvConstraints) || {} as { [field: string]: string[] }

    if (err.errors.startDate || err.errors.endDate) {
        err.errors.dateRange = [...err.errors.startDate, ...err.errors.endDate]
    }

    err.playlistErrors = pf.programCommandPlaylists.map(pl => validate(pl, playlistSelectionConstraints) || {} as { [field: string]: string[] })

    return err
}

const ValidatePlayPpCommand = (pp: PlayPpCommand): CommandWithPlValidationError => {
    let err = new CommandWithPlValidationError()
    err.errors = validate(pp, playPpConstraints) || {} as { [field: string]: string[] }

    if (err.errors.startDate || err.errors.endDate) {
        err.errors.dateRange = [...err.errors.startDate, ...err.errors.endDate]
    }

    err.playlistErrors = pp.programCommandPlaylists.map(pl => validate(pl, playlistSelectionConstraints) || {} as { [field: string]: string[] })

    return err
}

const ValidatePlayCoverCommand = (pc: PlayCoverCommand): CommandWithPlValidationError => {
    let err = new CommandWithPlValidationError()
    err.errors = validate(pc, playCoverConstraints) || {} as { [field: string]: string[] }

    if (err.errors.startDate || err.errors.endDate) {
        err.errors.dateRange = [...err.errors.startDate, ...err.errors.endDate]
    }

    err.playlistErrors = pc.programCommandPlaylists.map(pl => validate(pl, playlistSelectionConstraints) || {} as { [field: string]: string[] })

    return err
}

const ValidatePlayMusicCommand = (pm: PlayMusicCommand): CommandWithPlValidationError => {
    let err = new CommandWithPlValidationError()
    err.errors = validate(pm, playMusicConstraints) || {} as { [field: string]: string[] }

    if (err.errors.startDate || err.errors.endDate) {
        err.errors.dateRange = [...err.errors.startDate, ...err.errors.endDate]
    }

    err.playlistErrors = pm.programCommandPlaylists.map(pl => validate(pl, playlistSelectionConstraints) || {} as { [field: string]: string[] })

    return err
}

//=== Schema ===
export const programCommandPlaylistsSchema = new schema.Entity('program_command_playlists', {
    playlist: PlaylistSchema
})
export const PlayFileCommandSchema = new schema.Entity('program_commands', {
    content: ContentSchema,
})
export const CommandSchema = new schema.Entity('program_commands', {
    content: ContentSchema,
    programCommandPlaylists: [programCommandPlaylistsSchema]
})

export const TimeBlockSchema = new schema.Entity('time_blocks', {
    programCommands: [CommandSchema],
    playFileCommands: [PlayFileCommandSchema]
})

export const ProgramSchema = new schema.Entity('programs', {
    client: ClientSchema,
    timeBlocks: [TimeBlockSchema]
})

//=== store ===
export function storeProgram(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const programs = norm.entities.programs
    if (!!programs && Object.keys(programs).length > 0) {
        storeClient(dataContext, norm)
        storeTimeBlock(dataContext, norm)
        FixNullsToUndefinedFromBackend(programs)
        FixDatesFromBackend(programs, ['startDate', 'endDate', 'createdAt', 'updatedAt'])
        FixTimesFromBackend(programs, ['startTime', 'stopTime'])
        dataContext.programs.all.merge(programs)
        norm.entities.programs = undefined
    }
}

export function storeCommandPlaylist(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const programCommandPlaylists = norm.entities.program_command_playlists
    if (!!programCommandPlaylists && Object.keys(programCommandPlaylists).length > 0) {
        storePlaylist(dataContext, norm)
        FixNullsToUndefinedFromBackend(programCommandPlaylists)
        dataContext.programCommandPlaylists.all.merge(programCommandPlaylists)
        norm.entities.program_command_playlists = undefined
    }
}

const toObj = (acc: any, cur: any) => {
    acc[cur.id] = cur
    return acc
}

export function storeTimeBlock(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    let playFileCommands: any = undefined
    let playAdvCommands: any = undefined
    let playPpCommands: any = undefined
    let playCoverCommands: any = undefined
    let playMusicCommands: any = undefined

    const commands = norm.entities.program_commands
    if (!!commands && Object.keys(commands).length > 0) {
        storeContent(dataContext, norm)
        storeCommandPlaylist(dataContext, norm)
        FixTimesFromBackend(commands, ['startTime'])
        FixDatesFromBackend(commands, ['startDate', 'endDate'])
        FixNullsToUndefinedFromBackend(commands)

        playFileCommands = Object.values(commands)
            .filter((c: any) => c.type === 'PlayFileCommand')
        playAdvCommands = Object.values(commands)
            .filter((c: any) => c.type === 'PlayAdvertisingCommand')
        playPpCommands = Object.values(commands)
            .filter((c: any) => c.type === 'PlayPpCommand')
        playCoverCommands = Object.values(commands)
            .filter((c: any) => c.type === 'PlayCoverCommand')
        playMusicCommands = Object.values(commands)
            .filter((c: any) => c.type === 'PlayMusicCommand')

        !!playFileCommands && dataContext.playFileCommands.all.merge(playFileCommands.reduce(toObj, {}))
        !!playAdvCommands && dataContext.playAdvCommands.all.merge(playAdvCommands.reduce(toObj, {}))
        !!playPpCommands && dataContext.playPpCommands.all.merge(playPpCommands.reduce(toObj, {}))
        !!playCoverCommands && dataContext.playCoverCommands.all.merge(playCoverCommands.reduce(toObj, {}))
        !!playMusicCommands && dataContext.playMusicCommands.all.merge(playMusicCommands.reduce(toObj, {}))
        norm.entities.program_commands = undefined
    }
    const timeBlocks = norm.entities.time_blocks
    if (!!timeBlocks && Object.keys(timeBlocks).length > 0) {
        FixTimesFromBackend(timeBlocks, ['startTime'])
        Object.values(timeBlocks).forEach((v: any) => {
            if (v.programCommands) {
                !!playFileCommands && (v.playFileCommands = v.programCommands.filter((id: number) => playFileCommands.map((pf: any) => pf.id).includes(id)))
                !!playAdvCommands && (v.playAdvCommands = v.programCommands.filter((id: number) => playAdvCommands.map((pf: any) => pf.id).includes(id)))
                !!playPpCommands && (v.playPpCommands = v.programCommands.filter((id: number) => playPpCommands.map((pf: any) => pf.id).includes(id)))
                !!playCoverCommands && (v.playCoverCommands = v.programCommands.filter((id: number) => playCoverCommands.map((pf: any) => pf.id).includes(id)))
                !!playMusicCommands && (v.playMusicCommands = v.programCommands.filter((id: number) => playMusicCommands.map((pf: any) => pf.id).includes(id)))
            }
        })
        dataContext.timeBlocks.all.merge(timeBlocks)
        norm.entities.time_blocks = undefined
    }
}
