import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ICollectionOptions } from '../api-client'
import { Store, StoreSchema, storeStore } from '../models'
import { IDomainService } from '.'
import { toJS } from 'mobx'

const entityName = 'store'

@injectable()
export class StoresService implements IDomainService<Store> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)
        storeStore(this.dataContext, norm)
        return norm.result
    }

    async fetch(options?: ICollectionOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(StoreSchema))
        if (!!order && order.length > 0) {
            this.dataContext.stores.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async get(id: number): Promise<Store> {
        let result: any
        if (id > 0) {
            result = await this.apiClient.getObject(id, entityName)
        } else {
            return Promise.reject()
        }
        this.storeContext(result, StoreSchema)
        return this.dataContext.stores.all.get(result.id)!
    }

    async createOrUpdate(store: Store, attachment?: File): Promise<Store> {
        const clone = {
            ...store,
            photo: !!store.photoUrl ? null : 'nil',
            clientId: store.client!.id,
            client: undefined,
            openDate: store.openDate.toDateString(),
            square: store.square && !isNaN(store.square) ? store.square : '',
            // groupStoreId: store.groupStore ? store.groupStore.id : undefined,
            // groupStore: undefined,
            locationId: store.location!.id,
            location: undefined,
            dynamicAttributes: toJS(store.dynamicAttributes),
        }

        const attachments = attachment ? { 'photo': attachment } : undefined
        let result: any
        if (store.id > 0) {
            result = await this.apiClient.patchObjectWithAttachments(clone, entityName, attachments)
        } else {
            result = await this.apiClient.postObjectWithAttachments(clone, entityName, attachments)
        }
        this.storeContext(result, StoreSchema)
        return this.dataContext.stores.all.get(result.id)!
    }

    clear() {
        this.dataContext.stores.ordered.clear()
    }
}
