import React from 'react'
import moment from 'moment'
import { IAttribute, Program, DataContext } from '@shared/common'
import ClientInfo from '../clients/ClientInfo'
import ClientAttributes from '../clients/ClientAttributes'

const ProgramAttributes = (dataCtx: DataContext) => {
    let attributes: IAttribute<Program>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            value: (d: Program) => d.id,
            display: (d: Program) => d.id || '',
        },
        {
            name: 'name',
            label: 'Name',
            show: true,
            display: (d: Program) => d.name || '',
            value: (d: Program) => d.name,
        },
        {
            name: 'type',
            label: 'Type',
            show: true,
            display: (d: Program) => d.isAdvertisement ? 'Adv' : 'Music',
            value: (d: Program) => d.isAdvertisement,
        },
        {
            name: 'Client',
            label: 'Client',
            show: true,
            nested: (p: Program) => (
                p.client ?
                    <ClientInfo
                        attributes={ClientAttributes(dataCtx)}
                        entity={p.client}
                    /> : undefined
            ),
            display: (d: Program) => d.client ? d.client.name : '',
            value: (d: Program) => d.client,
        },
        {
            name: 'state',
            label: 'State',
            show: true,
            display: (d: Program) => d.state || '',
            value: (d: Program) => d.state,
        },
        {
            name: 'startDate',
            label: 'Start Date',
            show: true,
            display: (d: Program) => d.startDate ? moment(d.startDate).format('DD.MM.YYYY') : '',
            value: (d: Program) => d.startDate,
        },
        {
            name: 'endDate',
            label: 'End Date',
            show: true,
            display: (d: Program) => d.endDate ? moment(d.endDate).format('DD.MM.YYYY') : '',
            value: (d: Program) => d.endDate,
        },
        {
            name: 'startTime',
            label: 'Start Time',
            show: true,
            display: (d: Program) => d.startTime ? d.startTime : '',
            value: (d: Program) => d.startTime,
        },
        {
            name: 'stopTime',
            label: 'Stop Time',
            show: true,
            display: (d: Program) => d.stopTime ? d.stopTime : '',
            value: (d: Program) => d.stopTime,
        },
        {
            name: 'createdAt',
            label: 'Created',
            show: true,
            display: (d: Program) => d.createdAt ? moment(d.createdAt).format('DD.MM.YYYY') : '',
            value: (d: Program) => d.createdAt,
        },
        {
            name: 'updatedAt',
            label: 'Updated',
            show: true,
            display: (d: Program) => d.updatedAt ? moment(d.updatedAt).format('DD.MM.YYYY') : '',
            value: (d: Program) => d.updatedAt,
        },
    ]

    return attributes
}

export default ProgramAttributes