import React from 'react'
import { IAttribute, ContentRecord } from '@shared/common'
import { InfoCardContent, AttributesList, PlayerControl } from '@shared/ui'

interface IProps {
    attributes: IAttribute<ContentRecord>[]
    entity: ContentRecord
}

const ContentRecordInfo: React.FunctionComponent<IProps> = ({ entity, attributes }) => (
    <React.Fragment>
        <InfoCardContent
            header="Content Record"
            subHeader={`ID = ${entity.id}`}
            actionsHeader={[
                <PlayerControl
                    key={entity.id + '-header-info-play'}
                    audio={{
                        name: `Content Record ${entity.id}`,
                        fileUrl: entity.fileUrl,
                        meta: {
                            genre: undefined,
                            bitrate: entity.bitrate,
                            sampleRate: entity.sampleRate,
                            durationMs: entity.durationMs
                        }
                    }}
                />
            ]}
            content={
                <AttributesList
                    entity={entity}
                    attrs={attributes.filter(a => a.show)}
                />
            }
        />
    </React.Fragment>
)

export default ContentRecordInfo
