import React from 'react'
import { observer } from 'mobx-react'
import { computed, IReactionDisposer, reaction, toJS, comparer } from 'mobx'
import PlaylistAttributes from './PlaylistAttributes'
import { injectable } from 'inversify'
import { Grid, Tooltip, IconButton } from '@material-ui/core'
import { Edit, Add } from '@material-ui/icons'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  RouteParams, ISelectConfig,
  IFilterPreset, DataContext,
  DiC, PageService, Playlist,
  entities, PageContext,
  presetClient
} from '@shared/common'
import {
  IFilters, dynamicAttrFilters,
  DataTable, DeleteEntity, FAB
} from '@shared/ui'

interface IProps extends RouteComponentProps<RouteParams> {
  selectConfig?: ISelectConfig
  presetFilters?: IFilterPreset
}

export const playlistFilters = (dataCtx: DataContext): IFilters => ({
  name: { label: 'Name', type: 'string' },
  playlistTypeId: {
    label: 'Playlist Type',
    type: 'select',
    selectParams: {
      multiple: true,
      options: [...dataCtx.playlistTypes.all.values()].map(v => ({ value: v.id.toString(), label: v.name }))
    }
  },
})

@injectable()
@observer
class PlaylistsPage extends React.Component<IProps> {
  reactionDisposers: IReactionDisposer[] = []

  get pageService() {
    return DiC.get<PageService<Playlist>>(entities.PlaylistSrv)
  }

  get ctx() {
    return DiC.get<PageContext>(entities.PlaylistCtx)
  }

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  @computed get dynamicAttributes() {
    return this.dataCtx.dynamicAttributes.ofEntity('playlists')
  }

  @computed get allColumns() {
    return PlaylistAttributes()
  }

  @computed get canUpdate() {
    return this.pageService.getAbility('update')
  }

  @computed get canRead() {
    return this.pageService.getAbility('read')
  }

  @computed get filterFields() {
    let dynamic = this.dynamicAttributes ? dynamicAttrFilters(this.dynamicAttributes) : {}
    return { ...playlistFilters(this.dataCtx), ...dynamic }
  }

  @computed get canDelete() {
    return this.pageService.getAbility('delete')
  }

  @computed get presetFilters() {
    let preset: IFilterPreset = !!this.props.presetFilters ? this.props.presetFilters : {}
    if (this.dataCtx.global.hasSelectedClient) {
      presetClient(preset, this.dataCtx.global.selectedClient!.id)
    }
    return preset.playlist
  }

  handleInitInfo = () => {
    this.pageService.filtersState.handlePresetFilters(this.presetFilters)
    // if (this.id) {
    //   this.pageService.init(this.id)
    //     .catch(_ => this.props.enqueueSnackbar("Could not find requested client", { variant: 'error' }));
    // } else {
    this.pageService.init()
    //}
  }

  componentDidMount() {
    this.handleInitInfo()
    this.reactionDisposers.push(reaction(
      () => toJS(this.presetFilters),
      () => {
        //if (!!!this.id) {
        this.pageService.filtersState.handlePresetFilters(this.presetFilters)
        this.pageService.filtersState.applyFilters()
        //}
      },
      {
        equals: comparer.structural
      }
    ))
    window.addEventListener('popstate', this.handleInitInfo)
  }

  componentWillUnmount() {
    this.reactionDisposers.forEach(d => d())
    window.removeEventListener('popstate', this.handleInitInfo)
  }

  render() {
    if (!this.canRead) {
      return (<React.Fragment />)
    }
    return (
      <React.Fragment>
        <FAB
          ariaLabel="Add Playlist"
          onClick={() => this.props.history.push('/contents')}
          disabled={this.ctx.isLoading}
        >
          <Add />
          Add Playlist
        </FAB>
        <DataTable
          repository={this.dataCtx.playlists}
          attributes={this.allColumns}
          ctx={this.ctx}
          service={this.pageService}
          selectConfig={this.props.selectConfig}
          filterFields={this.filterFields}
          actions={this.canUpdate ? (playlist: Playlist) => [
            <Grid
              item
              key={playlist.id + '-edit'}
            >
              <Tooltip
                title="Edit this playlist"
                placement="right"
              >
                <IconButton
                  aria-label="Add"
                  color="default"
                  onClick={() => {
                    this.props.history.push(`/playlists/${playlist.id}`)
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            </Grid>,
            <Grid item key={playlist.id + '-delete'}>
              <DeleteEntity can={this.canDelete} listProvider={this.pageService.paginatedList} id={playlist.id} />
            </Grid>
          ] : undefined}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(PlaylistsPage)