import React from 'react'

import {
    Location, DynamicAttribute,
    IAttribute, generateDynAttrs,
    DataContext
} from '@shared/common'
import AddressInfo from '../addresses/AddressInfo'
import AddressAttributes from '../addresses/AddressAttrbiutes'
import moment from 'moment'

const LocationAttributes = (dataCtx: DataContext) => {
    let attributes: IAttribute<Location>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            value: (l: Location) => l.id,
            display: (l: Location) => l.id || '',
        },
        {
            name: 'name',
            label: 'Name',
            show: true,
            display: (l: Location) => l.name || '',
            value: (l: Location) => l.name,
        },
        {
            name: 'room',
            label: 'Room',
            show: true,
            display: (l: Location) => l.room || '',
            value: (l: Location) => l.room,
        },
        {
            name: 'address',
            label: 'Address',
            show: true,
            nested: (l: Location) => (
                l.address ?
                    <AddressInfo
                        attributes={AddressAttributes(dataCtx)}
                        entity={l.address}
                    /> : undefined
            ),
            display: (l: Location) => l.address ? l.address.name : '',
            value: (l: Location) => l.address,
        },
        {
            name: 'created_at',
            label: 'Created',
            show: true,
            display: d => d.createdAt ? moment(d.createdAt).format('DD.MM.YYYY') : '',
            value: d => d.createdAt,
        },
        {
            name: 'updated_at',
            label: 'Updated',
            show: true,
            display: d => d.updatedAt ? moment(d.updatedAt).format('DD.MM.YYYY') : '',
            value: d => d.updatedAt,
        },
    ]

    let locationDynamic: DynamicAttribute[] = dataCtx.dynamicAttributes.ofEntity('location')
    const dyn: IAttribute<Location>[] = generateDynAttrs(locationDynamic)

    return attributes.concat(dyn)
}

export default LocationAttributes