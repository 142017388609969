import React from 'react'
import { IAttribute, ContentRecord } from '@shared/common'
import moment from 'moment'
import { BooleanIndicator } from '@shared/ui'

const ContentRecordAttributes = () => {
    let attributes: IAttribute<ContentRecord>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            display: (c: ContentRecord) => c.id || '',
            value: (c: ContentRecord) => c.id,
        },
        {
            name: 'forPlay',
            label: 'For Play',
            show: true,
            display: (c: ContentRecord) => <BooleanIndicator value={c.forPlay} />,
            value: (c: ContentRecord) => c.forPlay,
        },
        {
            name: 'bitrate',
            label: 'Bitrate',
            show: true,
            display: (c: ContentRecord) => c.bitrate || '',
            value: (c: ContentRecord) => c.bitrate,
        },
        {
            name: 'sampleRate',
            label: 'Sample rate',
            show: true,
            display: (c: ContentRecord) => c.sampleRate || '',
            value: (c: ContentRecord) => c.sampleRate,
        },
        {
            name: 'durationMs',
            label: 'Duration',
            show: true,
            display: (c: ContentRecord) => moment.utc(c.durationMs).format('HH:mm:ss'),
            value: (c: ContentRecord) => moment.duration(c.durationMs).milliseconds(),
        },
        {
            name: 'comment',
            label: 'Comment',
            show: true,
            display: (c: ContentRecord) => c.comment || '',
            value: (c: ContentRecord) => c.comment,
        },
    ]

    //let contentDynamic: DynamicAttribute[] = dataCtx.dynamicAttributes.ofEntity(relatedModel)
    //const dyn: IAttribute<ContentRecord>[] = generateDynAttrs(contentDynamic)

    return attributes//.concat(dyn);
}

export default ContentRecordAttributes