import { types as t, Instance } from 'mobx-state-tree'
import { IAttribute } from '..'

export const ZRequest = t
    .model('zrequest', {
        id: t.identifierNumber,
        date: t.string,
        theme: t.string,
        stores: t.string,
        status: t.string,
    })

export type ZRequest = Instance<typeof ZRequest>

export const ZRequestsRepository = t.model({
    all: t.map(ZRequest),
    ordered: t.array(t.reference(ZRequest)),
    selected: t.array(t.reference(ZRequest)),
    filterSelected: t.array(t.reference(ZRequest)),
})

export type ZRequestsRepository = Instance<typeof ZRequestsRepository>

export const ZRequestAttributes = () => {
    let attributes: IAttribute<ZRequest>[] = [
        {
            name: 'id',
            label: '#',
            show: true,
            display: a => a.id || '',
            value: a => a.id
        },
        {
            name: 'date',
            label: 'Дата',
            show: true,
            display: a => a.date || '',
            value: a => a.date
        },
        {
            name: 'theme',
            label: 'Тема',
            show: true,
            display: a => a.theme || '',
            value: a => a.theme
        },
        {
            name: 'stores',
            label: 'Магазины',
            show: true,
            display: a => a.stores || '',
            value: a => a.stores
        },
        {
            name: 'status',
            label: 'Статус',
            show: true,
            display: a => a.status || '',
            value: a => a.status
        },
    ]

    return attributes
}