import React from 'react'
import ClientsPage from '../clients/ClientsPage'
import { DataContext, IFilterPreset, ContentTypes, ISelectType } from '@shared/common'
import { IFilters } from '@shared/ui'

export const contentFilters = (dataCtx: DataContext, preset?: IFilterPreset): IFilters => ({
    name: { label: 'Name', type: 'string' },
    type: {
        label: 'Type',
        type: 'select',
        readonly: true,
        selectParams: {
            multiple: false,
            options: ContentTypes
        }
    },
    boNumber: {
        label: 'БО№',
        type: 'numeric',
        isRange: false
    },
    comment: { label: 'Comment', type: 'string' },
    clientId: {
        label: 'Client',
        type: 'dialog',
        dialogParams: {
            children: (selectType?: ISelectType) => {
                return <ClientsPage
                    selectConfig={
                        selectType ? {
                            type: selectType,
                            usage: 'filter'
                        } : undefined
                    }
                    presetFilters={preset}
                />
            },
            selectByIds: (ids: string[]) => {
                dataCtx.clients.filterSelected.clear()
                ids.forEach(id => {
                    if (dataCtx.clients.all.has(id)) {
                        dataCtx.clients.filterSelected.push(dataCtx.clients.all.get(id)!)
                    }
                })
            },
            onSelected: () => {
                if (!!dataCtx.clients.filterSelected && dataCtx.clients.filterSelected.length > 0) {
                    return dataCtx.clients.filterSelected.map(v => ({ value: v.id.toString(), label: v.name }))
                }
                return undefined
            },
            onClear: () => {
                dataCtx.clients.filterSelected.clear()
            },
            multiple: true
        }
    },
    // createdAt: {
    //     label: 'Creation date',
    //     type: 'date',
    //     isRange: true
    // },
})