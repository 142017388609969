import React from 'react'
import { observer } from 'mobx-react'
import { computed, observable, toJS, IReactionDisposer, reaction, comparer } from 'mobx'
import ContentForm from './ContentForm'
import ContentInfo from './ContentInfo'
import { contentFilters } from './ContentFilters'
import ContentAttrbiutes from './ContentAttrbiutes'
import { injectable } from 'inversify'
import Player from './Player'
import { Grid, Tooltip, IconButton } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { PlaylistAdd } from '@material-ui/icons'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import {
    RouteParams, ISelectConfig,
    IFilterPreset, Content, DiC,
    PageService, entities,
    PageContext, DataContext,
    ContentTypes, emptyContent,
    IFilterValues, presetClient,
    playableContentRecord
} from '@shared/common'
import {
    dynamicAttrFilters, InfoPage,
    DataTable, PlayerControl,
    InfoModal, DeleteEntity,
    DataTabs
} from '@shared/ui'

interface IProps extends RouteComponentProps<RouteParams>, WithSnackbarProps {
    selectConfig?: ISelectConfig
    presetFilters?: IFilterPreset
    handleAddToPlaylist?: (content: Content) => void
}

@injectable()
@observer
class ContentsPage extends React.Component<IProps> {
    reactionDisposers: IReactionDisposer[] = []
    @observable typeIdx: number = 0

    get pageService() {
        return DiC.get<PageService<Content>>(entities.ContentSrv)
    }

    get ctx() {
        return DiC.get<PageContext>(entities.ContentCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    @computed get dynamicAttributes() {
        return this.dataCtx.dynamicAttributes.ofEntity(this.contentTypeValue)
    }

    @computed get allColumns() {
        return ContentAttrbiutes(this.dataCtx, this.contentTypeValue)
    }

    @computed get filterFields() {
        let dynamic = this.dynamicAttributes ? dynamicAttrFilters(this.dynamicAttributes) : {}
        return { ...contentFilters(this.dataCtx, this.props.presetFilters), ...dynamic }
    }

    @computed get contentTypeValue() {
        return ContentTypes[this.typeIdx].value
    }

    @computed get id() {
        return this.props.match.path === '/contents/:id' && !!this.props.match.params.id ?
            parseInt(this.props.match.params.id) : undefined
    }

    @computed get content() {
        return !!this.id && this.dataCtx.contents.all.has(this.id.toString()) ?
            this.dataCtx.contents.all.get(this.id.toString())! :
            emptyContent as any
    }

    @computed get presetFilters(): IFilterValues | undefined {
        let preset: IFilterPreset = !!this.props.presetFilters ? this.props.presetFilters :
            {
                content: new Map([['type', this.contentTypeValue]])
            }
        if (this.dataCtx.global.hasSelectedClient) {
            presetClient(preset, this.dataCtx.global.selectedClient!.id)
        }
        if (!!preset.content && !preset.content.has('type')) {
            preset.content.set('type', this.contentTypeValue)
        }
        return preset.content
    }

    @computed get canRead() {
        return this.pageService.getAbility('read')
    }

    @computed get canDelete() {
        return this.pageService.getAbility('delete')
    }

    handleTabChange = (idx: number) => {
        if (idx >= 0 && idx < ContentTypes.length) {
            this.typeIdx = idx
            this.pageService.filtersState.resetDynamicFilters()
            this.ctx.filters.set('type', this.contentTypeValue)
            this.ctx.sort = undefined
            //this.pageService.filtersState.applyFilters()
        }
    }

    handleInitInfo = () => {
        this.pageService.filtersState.handlePresetFilters(this.presetFilters)
        if (this.id) {
            this.pageService.init(this.id)
                .catch(_ => this.props.enqueueSnackbar('Could not find requested content', { variant: 'error' }))
        } else {
            this.pageService.init()
        }
    }

    componentDidMount() {
        this.pageService.dynamicAttributesService.fetch('performer_role')
        this.pageService.dynamicAttributesService.fetch('performer')
        this.handleInitInfo()
        this.reactionDisposers.push(reaction(
            () => toJS(this.presetFilters),
            () => {
                if (!!!this.id) {
                    this.pageService.filtersState.handlePresetFilters(this.presetFilters)
                    this.pageService.filtersState.applyFilters()
                }
            },
            {
                equals: comparer.structural
            }
        ))
        window.addEventListener('popstate', this.handleInitInfo)
    }

    componentWillUnmount() {
        this.reactionDisposers.forEach(d => d())
        window.removeEventListener('popstate', this.handleInitInfo)
    }

    render() {
        if (!this.canRead) {
            return (<React.Fragment />)
        }
        return (
            <React.Fragment>
                <Player />
                {!!this.id ?
                    <InfoPage>
                        <ContentInfo
                            entity={this.content}
                            attributes={this.allColumns}
                        />
                    </InfoPage> :
                    <React.Fragment>
                        <ContentForm
                            relatedModel={this.contentTypeValue}
                        />
                        <DataTable
                            repository={this.dataCtx.contents}
                            attributes={this.allColumns}
                            ctx={this.ctx}
                            service={this.pageService}
                            selectConfig={this.props.selectConfig}
                            dynamic={this.dynamicAttributes}
                            filterFields={this.filterFields}
                            toolbarPaddingRight={!!this.props.handleAddToPlaylist}
                            actions={(content: Content) => {
                                let playable = playableContentRecord(content)
                                let contentActions: JSX.Element[] = [
                                    <Grid
                                        item
                                        key={content.id + '-play'}
                                    >
                                        <PlayerControl
                                            audio={{
                                                name: content.name,
                                                fileUrl: !!playable ? playable.fileUrl : '',
                                                meta: !!playable ? {
                                                    genre: undefined,
                                                    bitrate: playable.bitrate,
                                                    sampleRate: playable.sampleRate,
                                                    durationMs: playable.durationMs
                                                } : undefined
                                            }}
                                        />
                                    </Grid>,
                                    <Grid item key={`${content.id}-info`}>
                                        <InfoModal
                                            key={`${content.id}`}
                                            id={content.id}
                                            path="contents"
                                        >
                                            <ContentInfo
                                                entity={content}
                                                attributes={this.allColumns}
                                            />
                                        </InfoModal>
                                    </Grid>,
                                    <Grid item key={`${content.id}-edit`}>
                                        <ContentForm
                                            key={content.id}
                                            content={content}
                                            relatedModel={this.contentTypeValue}
                                        />
                                    </Grid>,
                                    <Grid item key={`${content.id}-delete`}>
                                        <DeleteEntity can={this.canDelete} listProvider={this.pageService.paginatedList} id={content.id} />
                                    </Grid>
                                ]
                                return !!this.props.handleAddToPlaylist ? [
                                    <Grid
                                        item
                                        key={content.id + '-add'}
                                    >
                                        <Tooltip
                                            title={!!playable ? 'Add this track to playlist' : 'Content doesn\'t have records for play'}
                                            placement="right"
                                        >
                                            <div>
                                                <IconButton
                                                    aria-label="Add"
                                                    disabled={!!!playable}
                                                    color="primary"
                                                    onClick={() => this.props.handleAddToPlaylist!(content)}
                                                >
                                                    <PlaylistAdd />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                ].concat(contentActions) : contentActions
                            }}
                            relatedModel={this.contentTypeValue}
                            tabsComponent={
                                <DataTabs
                                    labels={ContentTypes.map(t => t.label)}
                                    onTabChange={this.handleTabChange}
                                />
                            }
                        />
                    </React.Fragment >
                }
            </React.Fragment >
        )
    }
}

export default withRouter(withSnackbar(ContentsPage))
