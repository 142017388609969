import React from 'react'
import {
  List, ListItem, ListItemText,
  Popover, Grid, IconButton
} from '@material-ui/core'
import { IAttribute, chunkify } from '@shared/common'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { ExpandMore } from '@material-ui/icons'
import { InfoCard } from '.'

interface IProps<A> {
  attrs: IAttribute<A>[]
  entity: A
  maxRows?: number
}

@observer
class AttributesList<A> extends React.Component<IProps<A>>  {
  @observable nestedCollapse: Map<string, boolean> = new Map()
  @observable listItemAnchor?: EventTarget & HTMLButtonElement

  handleSwitchCollapse = (name: string) => {
    this.nestedCollapse.set(name, this.nestedCollapse.has(name) ? !(this.nestedCollapse.get(name)!) : true)
  }

  collapseOpened = (name: string): boolean => {
    return this.nestedCollapse.has(name) ? this.nestedCollapse.get(name)! : false
  }

  render() {
    let maxRows = !!this.props.maxRows ? this.props.maxRows : 120

    let colCount = this.props.attrs.length / maxRows >> 0
    if (this.props.attrs.length % maxRows !== 0) {
      colCount += 1
    }

    let attrs: IAttribute<A>[][] = []
    for (let i = 0; i < colCount; i++) {
      let a: IAttribute<A>[] = []
      attrs.push(a)
    }

    attrs = chunkify(this.props.attrs, colCount)

    return (
      <Grid container direction="row" wrap="nowrap" spacing={5}>
        {attrs.map((col, idx) =>
          <Grid
            item
            key={`${idx}-info-column`}
            style={{
              width: '100%',
              minWidth: '240px'
            }}
          >
            <List
              disablePadding
              dense
            >
              {col.map((attr, attrIdx) => {
                const haveNested: boolean = !!attr.nested && !!attr.nested(this.props.entity) && !!attr.value(this.props.entity)
                return (
                  <React.Fragment
                    key={`${attrIdx}-${attr.name}-li`}
                  >
                    <ListItem
                      style={attrIdx % 2 === 0 ? { backgroundColor: 'rgba(0, 0, 0, 0.07)' } : {}}
                    >
                      <ListItemText
                        style={{
                          flex: '1 1 auto',
                          textAlign: 'left',
                          wordBreak: 'break-word',
                          maxWidth: '50%'
                        }}
                        primaryTypographyProps={{
                          variant: 'subtitle1',
                        }}
                        primary={
                          `${attr.label !== '' ? attr.label : attr.name}:`
                        }
                      />
                      <div
                        style={{
                          minWidth: '24px',
                          flex: '1 1 auto',
                        }}
                      />
                      {haveNested ?
                        <IconButton
                          onClick={(e) => {
                            this.handleSwitchCollapse(attr.name)
                            this.listItemAnchor = e.currentTarget
                          }}
                          color="primary"
                        >
                          <ExpandMore
                            fontSize="small"
                            style={Object.assign(
                              {
                                transform: 'rotate(0deg)',
                                transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                alignSelf: 'center'
                              },
                              this.collapseOpened(attr.name) && { transform: 'rotate(180deg)' }
                            )}
                          />
                        </IconButton>
                        : []
                      }
                      <ListItemText
                        style={Object.assign({
                          flex: '1 1 auto',
                          textAlign: 'right',
                          wordBreak: 'break-word',
                          maxWidth: '50%'
                        },
                          haveNested && {
                            flex: '0 1 auto',
                            maxWidth: '300px'
                            // textOverflow: 'ellipsis',
                            // overflow: 'hidden',
                            // maxWidth: '50%',
                            // whiteSpace: 'nowrap'
                          }
                        )}
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                        primary={attr.display(this.props.entity)}
                      />
                    </ListItem>
                    {haveNested ?
                      <Popover
                        PaperProps={{
                          style: {
                            width: 'fit-content',
                            minWidth: '320px',
                            maxWidth: '640px',
                            overflow: 'auto',
                            padding: '8px'
                          }
                        }}
                        anchorEl={this.listItemAnchor}
                        open={this.collapseOpened(attr.name)}
                        onClose={() => {
                          this.handleSwitchCollapse(attr.name)
                          this.listItemAnchor = undefined
                        }}
                        // open={!!popoverAnchor}
                        // onClose={() => { popoverAnchor = undefined }}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                      // timeout="auto"
                      >
                        {attr.nested!(this.props.entity)}
                      </Popover> : []
                    }
                  </React.Fragment>
                )
              })}
            </List>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default AttributesList