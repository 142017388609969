import React from 'react'
import moment from 'moment'
import DemoRecordingInfo from './DemoRecordingInfo'
import { DataContext, IAttribute, Demo, DemoRecording } from '@shared/common'
import { InfoTabs } from '@shared/ui'

const DemoAttributes = (dataCtx: DataContext) => {
    let attributes: IAttribute<Demo>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            value: (d: Demo) => d.id,
            display: (d: Demo) => d.id || '',
        },
        {
            name: 'comment',
            label: 'Comment',
            show: true,
            display: (d: Demo) => d.comment || '',
            value: (d: Demo) => d.comment,
        },
        {
            name: 'recordingDate',
            label: 'Recording Date',
            show: true,
            display: (d: Demo) => d.recordingDate ? moment(d.recordingDate).format('DD.MM.YYYY') : '',
            value: (d: Demo) => d.recordingDate,
        },
        {
            name: 'demoRecordings',
            label: 'Recordings',
            show: true,
            nested: (d: Demo) => {
                return d.demoRecordings.length > 0 ?
                    <React.Fragment>
                        <InfoTabs
                            tabs={d.demoRecordings.map((dr, idx) => {
                                return {
                                    label: `Record #${idx + 1}`,
                                    content: <DemoRecordingInfo
                                        attributes={DemoRecordingAttributes(dataCtx)}
                                        entity={dr}
                                    />
                                }
                            })}
                        />
                    </React.Fragment> : undefined
            },
            display: (d: Demo) => `${d.demoRecordings.length} records`,
            value: (d: Demo) => d.demoRecordings,
        }
    ]

    return attributes
}

const DemoRecordingAttributes = (dataCtx: DataContext) => {
    let attributes: IAttribute<DemoRecording>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            value: (l: DemoRecording) => l.id,
            display: (l: DemoRecording) => l.id || '',
        },
        {
            name: 'comment',
            label: 'Comment',
            show: true,
            display: (l: DemoRecording) => l.comment || '',
            value: (l: DemoRecording) => l.comment,
        }
    ]

    return attributes
}

export default DemoAttributes