import { Container } from 'inversify'

let DiC = new Container
export { DiC }

export const entities = {
    IApiClient: 'IApiClient',
    GlobalContext: 'GlobalContext',
    DataContext: 'DataContext',

    DynamicAttribute: 'DynamicAttributes',

    Address: 'Address',
    AddressCtx: 'AddressCtx',
    AddressSrv: 'AddressSrv',

    Location: 'Location',
    LocationCtx: 'LocationCtx',
    LocationSrv: 'LocationSrv',

    Client: 'Client',
    ClientCtx: 'ClientCtx',
    ClientSrv: 'ClientSrv',

    Device: 'Device',
    DeviceCtx: 'DeviceCtx',
    DeviceSrv: 'DeviceSrv',

    Content: 'Content',
    ContentCtx: 'ContentCtx',
    ContentSrv: 'ContentSrv',

    Playlist: 'Playlist',
    PlaylistCtx: 'PlaylistCtx',
    PlaylistSrv: 'PlaylistSrv',

    PlaylistType: 'PlaylistType',
    PlaylistTypeCtx: 'PlaylistTypeCtx',
    PlaylistTypeSrv: 'PlaylistTypeSrv',

    GroupStore: 'GroupStore',
    GroupStoreCtx: 'GroupStoreCtx',
    GroupStoreSrv: 'GroupStoreSrv',

    Store: 'Store',
    StoreCtx: 'StoreCtx',
    StoreSrv: 'StoreSrv',

    Audio: 'Audio',

    Program: 'Program',
    ProgramCtx: 'ProgramCtx',
    ProgramSrv: 'ProgramSrv',

    Mediaplan: 'Mediaplan',
    MediaplanCtx: 'MediaplanCtx',
    MediaplanSrv: 'MediaplanSrv',

    Performer: 'Performer',
    PerformerCtx: 'PerformerCtx',
    PerformerSrv: 'PerformerSrv',

    Gender: 'Gender',
    GenderCtx: 'GenderCtx',
    GenderSrv: 'GenderSrv',

    Relevance: 'Relevance',
    RelevanceCtx: 'RelevanceCtx',
    RelevanceSrv: 'RelevanceSrv',

    PerformerRole: 'PerformerRole',
    PerformerRoleCtx: 'PerformerRoleCtx',
    PerformerRoleSrv: 'PerformerRoleSrv',
}