import { types as t, Instance } from 'mobx-state-tree'
import { IAttribute } from '..'

export const ZStore = t
    .model('zstore', {
        id: t.identifierNumber,
        address: t.string,
        conceptName: t.string,
        dateOpen: t.string,
        timeOpen: t.string,
        timeClose: t.string,
    })

export type ZStore = Instance<typeof ZStore>

export const ZStoresRepository = t.model({
    all: t.map(ZStore),
    ordered: t.array(t.reference(ZStore)),
    selected: t.array(t.reference(ZStore)),
    filterSelected: t.array(t.reference(ZStore)),
})

export type ZStoresRepository = Instance<typeof ZStoresRepository>

export const ZStoreAttributes = () => {
    let attributes: IAttribute<ZStore>[] = [
        {
            name: 'id',
            label: '#',
            show: true,
            display: a => a.id || '',
            value: a => a.id
        },
        {
            name: 'address',
            label: 'Адрес',
            show: true,
            display: a => a.address || '',
            value: a => a.address
        },
        {
            name: 'conceptName',
            label: 'Концепция',
            show: true,
            display: a => a.conceptName || '',
            value: a => a.conceptName
        },
        {
            name: 'openDate',
            label: 'Дата открытия',
            show: true,
            display: a => a.dateOpen || '',
            value: a => a.dateOpen
        },
        {
            name: 'timeOpen',
            label: 'Время открытия',
            show: true,
            display: a => a.timeOpen || '',
            value: a => a.timeOpen
        },
        {
            name: 'timeClose',
            label: 'Время закрытия',
            show: true,
            display: a => a.timeClose || '',
            value: a => a.timeClose
        },
    ]

    return attributes
}