import React from 'react'
import {
    Performer, DynamicAttribute,
    IAttribute, PerformerRoleTypes,
    generateDynAttrs, DataContext
} from '@shared/common'
import moment from 'moment'
import PerformerRoleInfo from './PerformerRoleInfo'
import DemoInfo from './DemoInfo'
import DemoAttributes from './DemoAttributes'
import { InfoTabs, IFilters } from '@shared/ui'

const PerformerAttributes = (dataCtx: DataContext) => {
    let attributes: IAttribute<Performer>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            value: (p: Performer) => p.id,
            display: (p: Performer) => p.id || '',
        },
        {
            name: 'firstName',
            label: 'First Name',
            show: true,
            display: (p: Performer) => p.firstName || '',
            value: (p: Performer) => p.firstName,
        },
        {
            name: 'lastName',
            label: 'Last Name',
            show: true,
            display: (p: Performer) => p.lastName || '',
            value: (p: Performer) => p.lastName,
        },
        {
            name: 'patronymic',
            label: 'Patronymic',
            show: true,
            display: (p: Performer) => p.patronymic || '',
            value: (p: Performer) => p.patronymic,
        },
        {
            name: 'gender',
            label: 'Gender',
            show: true,
            display: (p: Performer) => p.gender ? p.gender.name : '',
            value: (p: Performer) => p.gender ? p.gender.name : '',
        },
        {
            name: 'roles',
            label: 'Roles',
            show: true,
            nested: (p: Performer) => {
                return p.performerRoles.length > 0 ?
                    <React.Fragment>
                        <InfoTabs
                            tabs={p.performerRoles.map(r => {
                                let roleDynamic: DynamicAttribute[] = dataCtx.dynamicAttributes.ofEntity(r.type)
                                return {
                                    label: PerformerRoleTypes.filter(v => v.value === r.type)[0].label,
                                    content: <PerformerRoleInfo
                                        attributes={generateDynAttrs(roleDynamic)}
                                        entity={r}
                                    />
                                }
                            })
                            }
                        />
                    </React.Fragment> : undefined
            },
            display: (p: Performer) => p.performerRoles.map(pr => PerformerRoleTypes.filter(v => v.value === pr.type)[0].label).join(', ') || '',
            value: (p: Performer) => p.performerRoles,
        },
        {
            name: 'demos',
            label: 'Demos',
            show: true,
            nested: (p: Performer) => {
                return p.demos.length > 0 ?
                    <React.Fragment>
                        <InfoTabs
                            tabs={p.demos.map((d, idx) => {
                                return {
                                    label: `Demo #${idx + 1}`,
                                    content: <DemoInfo
                                        attributes={DemoAttributes(dataCtx)}
                                        entity={d}
                                    />
                                }
                            })}
                        />

                    </React.Fragment> : undefined
            },
            display: (p: Performer) => `${p.demos.length} Demos`,
            value: (p: Performer) => p.demos,
        },
        {
            name: 'birthDate',
            label: 'Birth Date',
            show: true,
            display: (p: Performer) => p.birthDate ? moment(p.birthDate).format('DD.MM.YYYY') : '',
            value: (p: Performer) => p.birthDate,
        },
        {
            name: 'email',
            label: 'EMail',
            show: true,
            display: (p: Performer) => p.email || '',
            value: (p: Performer) => p.email,
        },
        {
            name: 'homePhoneNumber',
            label: 'Home Phone Number',
            show: true,
            display: (p: Performer) => p.homePhoneNumber || '',
            value: (p: Performer) => p.homePhoneNumber,
        },
        {
            name: 'mobilePhoneNumber',
            label: 'Mobile Phone Number',
            show: true,
            display: (p: Performer) => p.mobilePhoneNumber || '',
            value: (p: Performer) => p.mobilePhoneNumber,
        },
        {
            name: 'comment',
            label: 'Comment',
            show: true,
            display: (p: Performer) => p.comment || '',
            value: (p: Performer) => p.comment,
        },
        {
            name: 'socialNetwork',
            label: 'Social Network',
            show: true,
            display: (p: Performer) => p.socialNetwork || '',
            value: (p: Performer) => p.socialNetwork,
        },
        {
            name: 'relevance',
            label: 'Relevance',
            show: true,
            display: (p: Performer) => p.relevance ? p.relevance.name : '',
            value: (p: Performer) => p.relevance,
        },
        {
            name: 'skype',
            label: 'Skype',
            show: true,
            display: (p: Performer) => p.skype || '',
            value: (p: Performer) => p.skype,
        },
        {
            name: 'yandexMoneyWallet',
            label: 'Yandex Money Wallet',
            show: true,
            display: (p: Performer) => p.yandexMoneyWallet || '',
            value: (p: Performer) => p.yandexMoneyWallet,
        },
        {
            name: 'creditCardNumber',
            label: 'Credit Card Number',
            show: true,
            display: (p: Performer) => p.creditCardNumber || '',
            value: (p: Performer) => p.creditCardNumber,
        },
        {
            name: 'bank',
            label: 'Bank',
            show: true,
            display: (p: Performer) => p.bank || '',
            value: (p: Performer) => p.bank,
        },
        {
            name: 'address',
            label: 'Address',
            show: true,
            display: (p: Performer) => p.address || '',
            value: (p: Performer) => p.address,
        },
        {
            name: 'passportNumber',
            label: 'Passport Number',
            show: true,
            display: (p: Performer) => p.passportNumber || '',
            value: (p: Performer) => p.passportNumber,
        },
        {
            name: 'passportDateOfIssue',
            label: 'Passport Date Of Issue',
            show: true,
            display: (p: Performer) => (p.passportDateOfIssue ? moment(p.passportDateOfIssue).format('DD.MM.YYYY') : ''),
            value: (p: Performer) => p.passportDateOfIssue,
        },
        {
            name: 'passportUnitCode',
            label: 'Passport Unit Code',
            show: true,
            display: (p: Performer) => p.passportUnitCode || '',
            value: (p: Performer) => p.passportUnitCode,
        },
        {
            name: 'registrationAddress',
            label: 'Registration Address',
            show: true,
            display: (p: Performer) => p.registrationAddress || '',
            value: (p: Performer) => p.registrationAddress,
        },
        {
            name: 'recommendation',
            label: 'Recommendation',
            show: true,
            display: (p: Performer) => p.recommendation || '',
            value: (p: Performer) => p.recommendation,
        },
        {
            name: 'created_at',
            label: 'Created',
            show: true,
            display: (d: Performer) => d.createdAt ? moment(d.createdAt).format('DD.MM.YYYY') : '',
            value: (d: Performer) => d.createdAt,
        },
        {
            name: 'updated_at',
            label: 'Updated',
            show: true,
            display: (d: Performer) => d.updatedAt ? moment(d.updatedAt).format('DD.MM.YYYY') : '',
            value: (d: Performer) => d.updatedAt,
        },
    ]

    let performerDynamic: DynamicAttribute[] = dataCtx.dynamicAttributes.ofEntity('performer')
    const dyn: IAttribute<Performer>[] = generateDynAttrs(performerDynamic)

    return attributes.concat(dyn)
}

export const performerFilters = (dataCtx: DataContext): IFilters => ({
    id: { label: 'Id', type: 'numeric' },
    firstName: { label: 'First Name', type: 'string' },
    lastName: { label: 'Last Name', type: 'string' },
    patronymic: { label: 'Patronymic', type: 'string' },
    birthDate: { label: 'Birth Date', type: 'date', isRange: true },
    performer_roles: {
        label: 'Roles',
        type: 'select',
        selectParams: {
            multiple: true,
            options: PerformerRoleTypes
        }
    },
    gender: {
        label: 'Gender',
        type: 'select',
        selectParams: {
            multiple: true,
            options: [...dataCtx.genders.all.values()].map(v => ({ value: v.id.toString(), label: v.name }))
        }
    },
    relevance: {
        label: 'Relevance',
        type: 'select',
        selectParams: {
            multiple: true,
            options: [...dataCtx.relevances.all.values()].map(v => ({ value: v.id.toString(), label: v.name }))
        }
    },
    homePhoneNumber: { label: 'Home Phone Number', type: 'string' },
    mobilePhoneNumber: { label: 'Mobile Phone Number', type: 'string' },
    socialNetwork: { label: 'Social Network', type: 'string' },
    email: { label: 'EMail', type: 'string' },
    skype: { label: 'Skype', type: 'string' },
    yandexMoneyWallet: { label: 'Yandex Money Wallet', type: 'string' },
    creditCardNumber: { label: 'Credit Card Number', type: 'string' },
    bank: { label: 'Bank', type: 'string' },
    address: { label: 'Address', type: 'string' },
    passportNumber: { label: 'Passport Number', type: 'string' },
    passportUnitCode: { label: 'Passport Unit Code', type: 'string' },
    registrationAddress: { label: 'Registration Address', type: 'string' },
    retirementCertificate: { label: 'Retirement Certificate', type: 'string' },
    recommendation: { label: 'Recommendation', type: 'string' },
    comment: { label: 'Comment', type: 'string' },
})

export default PerformerAttributes