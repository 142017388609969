import React from 'react'
import { observer } from 'mobx-react'

import { IconButton, Tooltip } from '@material-ui/core'

import { PlayArrow, Pause } from '@material-ui/icons'
import {
    DataContext, DiC, entities,
    PlayerAudio
} from '@shared/common'
import { computed, toJS } from 'mobx'

interface IProps {
    audio: PlayerAudio
}

@observer class PlayerControl extends React.Component<IProps> {

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    get audio() {
        return DiC.get<HTMLAudioElement>(entities.Audio)
    }

    handlePlayPause = () => {
        this.dataCtx.player.state.playerOpen = true
        if (!!this.audio.error) {
            this.dataCtx.player.state.state = 'stopped'
        } else {
            if (this.dataCtx.player.state.state === 'played') {
                this.dataCtx.player.state.state = 'paused'
                if (this.audio.src !== '' && !this.audio.paused) {
                    this.audio.pause()
                }
            } else {
                this.dataCtx.player.state.state = 'played'
                if (this.audio.src !== '' && this.audio.paused) {
                    this.audio.play()
                }
            }
        }
    }

    @computed get audioMatch() {
        return !!this.dataCtx.player.played && this.dataCtx.player.played.fileUrl === this.props.audio.fileUrl
    }

    @computed get audioEmpty() {
        return this.props.audio.fileUrl === ''
    }

    render() {
        return (
            <React.Fragment>
                <Tooltip
                    title={this.audioEmpty ? 'No file for play' : (!this.audioMatch ? 'Play' :
                        (this.dataCtx.player.state.state !== 'played' ?
                            'Play' : 'Pause'))
                    }
                    placement="right"
                >
                    <div>
                        <IconButton
                            aria-label={!this.audioMatch ? 'Play' :
                                this.dataCtx.player.state.state !== 'played' ?
                                    'Play' : 'Pause'
                            }
                            disabled={this.audioEmpty}
                            onClick={() => {
                                if (!this.audioMatch) {
                                    let meta = !!this.props.audio.meta ? {
                                        genre: this.props.audio.meta.genre,
                                        bitrate: !!this.props.audio.meta.bitrate && this.props.audio.meta.bitrate > 0 ? this.props.audio.meta.bitrate : undefined,
                                        sampleRate: !!this.props.audio.meta.sampleRate && this.props.audio.meta.sampleRate > 0 ? this.props.audio.meta.sampleRate : undefined,
                                        durationMs: !!this.props.audio.meta.durationMs && this.props.audio.meta.durationMs > 0 ? this.props.audio.meta.durationMs : undefined,
                                    } : undefined
                                    this.dataCtx.player.played = this.props.audio
                                    this.dataCtx.player.played.meta = meta
                                }
                                if (!!this.dataCtx.player.played) {
                                    this.handlePlayPause()
                                }
                            }}
                            onMouseLeave={() => {
                                if (this.audioMatch) {
                                    this.dataCtx.player.state.timerIds.push(window.setTimeout(() => {
                                        this.dataCtx.player.state.playerOpen = false
                                        this.dataCtx.player.state.timerIds.clear()
                                    }, 3000))
                                }
                            }}
                            onMouseEnter={() => {
                                if (this.audioMatch) {
                                    if (this.dataCtx.player.state.timerIds.length > 0) {
                                        this.dataCtx.player.state.timerIds.forEach(tid => {
                                            window.clearTimeout(tid)
                                        })
                                        this.dataCtx.player.state.timerIds.clear()
                                    }
                                    this.dataCtx.player.state.playerOpen = true
                                }
                            }}
                        >
                            {!this.audioMatch ? <PlayArrow /> :
                                this.dataCtx.player.state.state !== 'played' ?
                                    <PlayArrow
                                        color="primary"
                                    /> :
                                    <Pause
                                        color="primary"
                                    />
                            }
                        </IconButton>
                    </div>
                </Tooltip>
            </React.Fragment >
        )
    }
}

export default PlayerControl
