import React from 'react'
import { IAttribute, Content, playableContentRecord } from '@shared/common'
import { InfoCardContent, AttributesList, PlayerControl } from '@shared/ui'
import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

interface IProps {
    attributes: IAttribute<Content>[]
    entity: Content
}

const ContentInfo: React.FunctionComponent<IProps> = ({ entity, attributes }) => {
    let playable = playableContentRecord(entity)
    return (
        <React.Fragment>
            <InfoCardContent
                header="Content"
                //subHeader={`ID = ${entity.id}`}
                subHeader={entity.id > 0 ?
                    <Link component={RouterLink} to={`/contents/${entity.id}`} target="_blank">
                        {`${window.location.origin}/contents/${entity.id}`}
                    </Link> : undefined
                }
                actionsHeader={[
                    <PlayerControl
                        key={entity.id + '-header-info-play'}
                        audio={{
                            name: entity.name,
                            fileUrl: !!playable ? playable!.fileUrl : '',
                            meta: !!playable ? {
                                genre: undefined,
                                bitrate: playable.bitrate,
                                sampleRate: playable.sampleRate,
                                durationMs: playable.durationMs
                            } : undefined
                        }}
                    />
                ]}
                content={
                    <AttributesList
                        entity={entity}
                        attrs={attributes.filter(a => a.show)}
                    />
                }
            />
        </React.Fragment>
    )
}

export default ContentInfo
