import React from 'react'

import { IAttribute, Performance } from '@shared/common'
import { InfoCardContent, AttributesList } from '@shared/ui'

interface IProps {
    attributes: IAttribute<Performance>[]
    entity: Performance
}

const PerformanceInfo: React.FunctionComponent<IProps> = ({ entity, attributes }) => (
    <React.Fragment>
        <InfoCardContent
            header="Perfomance"
            subHeader={`ID = ${entity.id}`}
            content={
                <AttributesList
                    entity={entity}
                    attrs={attributes.filter(a => a.show)}
                />
            }
        />
    </React.Fragment>

)

export default PerformanceInfo
