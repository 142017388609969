import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { Performer, PerformerSchema, storePerformer } from './performer'
import { PerformerRole, PerformerRoleSchema, storePerformerRole } from './performer_role'
import validate, { isEmpty } from 'validate.js'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend } from '../services/utils'

export const Performance = t.model('performance', {
    id: t.identifierNumber,
    comment: t.maybe(t.string),
    performer: t.maybe(t.reference(Performer)),
    performerRole: t.maybe(t.reference(PerformerRole)),
    selectedRole: t.maybe(t.string),
})

export type Performance = Instance<typeof Performance>

export const emptyPerformance = {
    id: 0,
    comment: '',
    performer: undefined,
    performerRole: undefined,
    selectedRole: undefined
}

export const performanceConstraints = {
    performer: { presence: { allowEmpty: false } },
    performerRole: { presence: { allowEmpty: false } },
}

export const PerformanceSchema = new schema.Entity('performances', {
    performer: PerformerSchema,
    performerRole: PerformerRoleSchema
})

export function storePerformance(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const performances = norm.entities.performances
    if (!!performances && Object.keys(performances).length > 0) {
        storePerformer(dataContext, norm)
        storePerformerRole(dataContext, norm)
        FixNullsToUndefinedFromBackend(performances)
        dataContext.performances.all.merge(performances)
        norm.entities.performances = undefined
    }
}
