import React from 'react'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { Grid, Radio } from '@material-ui/core'
import { ContentRecord } from '@shared/common'
import { FileDialogWV, TextFieldWV } from '@shared/ui'

interface IProps {
    record: ContentRecord
    errors?: any
    idx?: number
    onPlayChange: () => void
}

@observer class ContentRecordItem extends React.Component<IProps> {
    @computed get currentFile() {
        return (this.getFile() as any)
    }

    setFile = (value: File) => {
        this.props.record.file = value as any
    }
    getFile = () => {
        return this.props.record.file as any
    }

    handleOnPlayChange = () => {
        this.props.onPlayChange()
        this.props.record.forPlay = true
    }

    render() {
        return (
            <Grid container direction="row" spacing={2}>
                <Grid
                    item
                    style={{
                        paddingTop: '20px',
                        paddingRight: 0,
                    }}
                >
                    <Radio
                        checked={this.props.record.forPlay}
                        onChange={this.handleOnPlayChange}
                        value=""
                        color="primary"
                        inputProps={{ 'aria-label': 'content-record-for-play' }}
                    />
                </Grid>
                <Grid item xs>
                    <FileDialogWV
                        key={`content-records-fd-${this.props.idx}`}
                        required
                        type="audio"
                        label="Audio File"
                        fileUrl={this.props.record.fileUrl}
                        fileName={!!this.currentFile ? this.currentFile.name : undefined}
                        errors={this.props.errors ? this.props.errors.fileUrl : undefined}
                        onChange={file => {
                            if (!!file) {
                                this.setFile(file)
                                this.props.record.fileUrl = URL.createObjectURL(file)
                                this.props.errors && (this.props.errors.fileUrl = '')
                            }
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <TextFieldWV
                        multiline
                        label="Comment"
                        value={this.props.record.comment}
                        onChange={value => {
                            this.props.record.comment = value
                        }}
                    />
                </Grid>
            </Grid>
        )
    }
}

export default ContentRecordItem