import { types as t, Instance } from 'mobx-state-tree'
import { Device } from '../models'

export const DeviceRepository = t.model({
    all: t.map(Device),
    ordered: t.array(t.reference(Device)),
    selected: t.array(t.reference(Device)),
    filterSelected: t.array(t.reference(Device)),
})

export type DeviceRepository = Instance<typeof DeviceRepository>
