import React from 'react'
import { Card } from '@material-ui/core'

interface IProps {
    children: React.ReactNode
    wide?: boolean
}

const InfoCard: React.SFC<IProps> = ({ children, wide }) => {
    return (
        <Card
            style={Object.assign({
                width: 'fit-content',
                minWidth: '320px',
                overflow: 'auto',
                padding: '8px', //for horizontal scrollbar
                maxHeight: 'calc(100% - 16px)',
                height: 'fit-content'
            },
                !!wide && wide ? {} : { maxWidth: '640px' }
            )}
        >
            {children}
        </Card >
    )
}

export default InfoCard