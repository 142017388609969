import React from 'react'
import { observable, toJS, computed, reaction } from 'mobx'
import { observer } from 'mobx-react'
import {
    Dialog, Grid,
    DialogContent, DialogActions,
    DialogTitle, IconButton
} from '@material-ui/core'

import { Add, Edit } from '@material-ui/icons'

import {
    Address, addressConstraints,
    emptyAddress, timeZones,
    DiC, entities, PageService, PageContext,
    FormState, DataContext,
    Undoer, UndoerState, emptyUndoerState
} from '@shared/common'
import {
    TextFieldWV, SelectWV, FAB,
    DynamicAttributeAdornment,
    UndoButtons, DialogButtons
} from '@shared/ui'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { closeUnsavedForm } from '../shared/UnsavedDialog'

interface IProps extends WithSnackbarProps {
    address?: Address
}

@observer class AddressForm extends React.Component<IProps> {
    @observable address: Address = emptyAddress as any
    @observable formState: FormState<Address> = this.pageService.createFormState()

    undoer: Undoer<Address> = new Undoer<Address>()
    debounceUndoer: () => void
    @observable undoerState: UndoerState = emptyUndoerState

    componentDidMount() {
        this.debounceUndoer = AwesomeDebouncePromise(() => { this.undoer.Push(toJS(this.address), this.undoerState) }, 500)
        reaction(
            () => toJS(this.address),
            () => {
                if (!this.undoerState.dontReact) {
                    this.undoerState.waiting = true
                    this.debounceUndoer()
                }
            },
        )
    }

    get pageService() {
        return DiC.get<PageService<Address>>(entities.AddressSrv)
    }

    get ctx() {
        return DiC.get<PageContext>(entities.AddressCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    @computed get dynamicAttributes() {
        return this.dataCtx.dynamicAttributes.ofEntity('address')
    }

    @computed get isEditMode() {
        return !!this.props.address
    }

    @computed get canCreate() {
        return this.pageService.getAbility('create')
    }

    @computed get canUpdate() {
        return this.pageService.getAbility('update')
    }

    handleClear = () => {
        this.formState.clearErrors()
        if (this.isEditMode) {
            this.handleFillAddress(toJS(this.props.address!))
        } else {
            this.handleFillAddress(emptyAddress as any)
        }
    }

    handleFillAddress = (newAddress: Address) => {
        this.address = newAddress
        this.address.dynamicAttributes = new Map(Object.entries(newAddress.dynamicAttributes)) as any
    }

    openForm = () => {
        if (this.isEditMode) {
            this.handleFillAddress(toJS(this.props.address!))
        } else {
            this.handleFillAddress(toJS(this.address))
        }
        this.formState.openForm()
    }

    closeForm = () => {
        closeUnsavedForm(this.isEditMode, () => this.formState.closeForm(), toJS(!!this.props.address ? this.props.address : emptyAddress), toJS(this.address))
    }

    handleSubmit = () => {
        this.formState.save(this.address, addressConstraints)
            .then(res => {
                if (res.isOk) {
                    this.formState.closeForm()
                    this.undoerState.dontReact = true
                    this.address = emptyAddress as any
                    this.undoerState.dontReact = false
                    this.undoer = new Undoer<Address>()
                    this.pageService.paginatedList.refresh()
                } else {
                    this.props.enqueueSnackbar('You have filled one or more fields incorrectly.', { variant: 'error' })
                }
            })
    }

    render() {
        if ((!this.isEditMode || !this.canUpdate) && (!this.canCreate || this.isEditMode)) {
            return (<React.Fragment />)
        }
        return (
            <React.Fragment>
                {this.isEditMode ?
                    <IconButton
                        aria-label="Edit"
                        onClick={this.openForm}
                    >
                        <Edit />
                    </IconButton>
                    :
                    <FAB
                        ariaLabel="Add"
                        onClick={this.openForm}
                        disabled={this.ctx.isLoading}
                    >
                        <Add />
                        Add Address
                    </FAB>
                }
                <Dialog
                    open={this.formState.formOpen}
                    onClose={this.closeForm}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title">
                        {this.isEditMode ? 'Edit' : 'Add'} Address
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="column">
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="Country"
                                    errors={this.formState.getErrorsForField('country')}
                                    value={this.address.country}
                                    //originalValue={this.isEditMode ? this.props.address!.country : undefined}
                                    onChange={value => {
                                        this.address.country = value
                                        this.formState.clearErrorsForField('country')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="City"
                                    errors={this.formState.getErrorsForField('city')}
                                    value={this.address.city}
                                    onChange={value => {
                                        this.address.city = value
                                        this.formState.clearErrorsForField('city')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    label="Region"
                                    errors={this.formState.getErrorsForField('region')}
                                    value={this.address.region}
                                    onChange={value => {
                                        this.address.region = value
                                        this.formState.clearErrorsForField('region')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="Street"
                                    errors={this.formState.getErrorsForField('street')}
                                    value={this.address.street}
                                    onChange={value => {
                                        this.address.street = value
                                        this.formState.clearErrorsForField('street')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="Home"
                                    errors={this.formState.getErrorsForField('home')}
                                    value={this.address.home}
                                    onChange={value => {
                                        this.address.home = value
                                        this.formState.clearErrorsForField('home')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    type="number"
                                    label="Zip Code"
                                    errors={this.formState.getErrorsForField('zipCode')}
                                    value={this.address.zipCode}
                                    onChange={value => {
                                        this.address.zipCode = parseInt(value)
                                        this.formState.clearErrorsForField('zipCode')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <SelectWV
                                    required
                                    label="Time Zone"
                                    errors={this.formState.getErrorsForField('timeZone')}
                                    selectOptions={timeZones.map(tz => ({ label: tz, value: tz }))}
                                    //multiple
                                    value={!!this.address.timeZone ? [{ label: this.address.timeZone, value: this.address.timeZone }] : undefined}
                                    onChange={value => {
                                        !!value && value.length > 0 ? this.address.timeZone = value[0].value : this.address.timeZone = ''
                                        this.formState.clearErrorsForField('timeZone')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    type="number"
                                    label="Latitude"
                                    errors={this.formState.getErrorsForField('latitude')}
                                    value={this.address.latitude || ''}
                                    onChange={value => {
                                        this.address.latitude = parseFloat(value)
                                        if (isNaN(this.address.latitude)) {
                                            this.address.latitude = null
                                        }
                                        this.formState.clearErrorsForField('latitude')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    type="number"
                                    label="Longitude"
                                    errors={this.formState.getErrorsForField('longitude')}
                                    value={this.address.longitude || ''}
                                    onChange={value => {
                                        this.address.longitude = parseFloat(value)
                                        if (isNaN(this.address.longitude)) {
                                            this.address.longitude = null
                                        }
                                        this.formState.clearErrorsForField('longitude')
                                    }}
                                />
                            </Grid>
                            {this.dynamicAttributes ?
                                <DynamicAttributeAdornment
                                    attributes={this.dynamicAttributes}
                                    values={this.address.dynamicAttributes}
                                    errors={this.formState.errors}
                                />
                                : <React.Fragment />
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogButtons
                            onConfirm={this.handleSubmit}
                            confirmText="Save"
                            onCancel={this.closeForm}
                            onReset={this.handleClear}
                            resetText={`${this.isEditMode ? 'Reset' : 'Clear'} Fields`}
                            additionalActions={
                                <UndoButtons
                                    onUndo={() => {
                                        this.undoer.Undo(this.handleFillAddress, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    onRedo={() => {
                                        this.undoer.Redo(this.handleFillAddress, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    state={this.undoerState}
                                />
                            }
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withSnackbar(AddressForm)
