import { IFilters, dynamicAttrFilters } from '@shared/ui'
import { DataContext } from '@shared/common'

export const clientFilters = (): IFilters => ({
    id: { label: 'Id', type: 'numeric' },
    name: { label: 'Name', type: 'string' },
    phone: { label: 'Phone', type: 'string' },
    corpEmail: { label: 'Corporate e-mail', type: 'string' },
    address: { label: 'Address', type: 'string' },
    domainName: { label: 'Domain name', type: 'string' },
})