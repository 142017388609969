import React from 'react'

import './program_creator.css'
import ProgramCreateBlock from './ProgramCreatorBlock'
import { observer } from 'mobx-react'
import { observable, action, computed, comparer, toJS, reaction } from 'mobx'
import {
  Button, Typography, Grid,
  Fab, ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails, Box,
  Paper, Container
} from '@material-ui/core'
import styled from 'styled-components'
import { ExpandMore, Delete, Add } from '@material-ui/icons'
import ClientsPage from '../clients/ClientsPage'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import camelcaseKeys from 'camelcase-keys'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {
  ProgramValidationError, emptyMusicProgram,
  Undoer, UndoerState, emptyUndoerState,
  DiC, DataContext, entities,
  ProgramService, emptyAdsProgram,
  TimeBlock, Program,
  IAutosaveState, emptyAutosaveState
} from '@shared/common'
import {
  TextFieldWV, SelectViaDialog,
  DateRangePickerWV, TimeFieldWV,
  AutosaveIndicator, UndoButtons,
  ConfirmationDialog
} from '@shared/ui'

const StyledBox = styled(({ color, ...otherProps }) => <Box {...otherProps} />)`
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: center;
`

type TParams = { id?: string | undefined; }

interface IProps extends WithSnackbarProps, RouteComponentProps<TParams> {
  createAsAdvertisment: boolean
}

@observer
class ProgramCreatorPage extends React.Component<IProps> {
  @observable errors: ProgramValidationError = new ProgramValidationError()
  @observable program: Program = emptyMusicProgram as any
  prevProgram: Program = emptyMusicProgram as any
  @observable readyToTimeblock: boolean = false

  @observable autosaveState: IAutosaveState = emptyAutosaveState
  debounceAutosave: () => void

  undoer: Undoer<Program> = new Undoer<Program>()
  debounceUndoer: () => void
  @observable undoerState: UndoerState = emptyUndoerState

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  get programsService() {
    return DiC.get<ProgramService>(entities.Program)
  }

  @computed get isEditMode() {
    return !!this.props.match.params.id && !isNaN(parseInt(this.props.match.params.id))
  }

  componentDidMount() {
    this.debounceAutosave = AwesomeDebouncePromise(this.handleSubmit, 2000)
    this.debounceUndoer = AwesomeDebouncePromise(() => { this.undoer.Push(toJS(this.program), this.undoerState) }, 500)
    reaction(
      () => toJS(this.program),
      () => {
        if (this.readyToTimeblock) {
          if (!this.autosaveState.dontReact) {
            this.autosaveState.needSave = true
            this.autosaveState.loading = true
            this.debounceAutosave()
          }
          if (!this.undoerState.dontReact) {
            this.undoerState.waiting = true
            this.debounceUndoer()
          }
        }
      },
      {
        equals: comparer.structural
      }
    )
    this.handleInit()
  }

  handleFillOnEdit = () => {
    this.autosaveState.status = 'success'
    this.programsService.get(parseInt(this.props.match.params.id!))
      .then(res => {
        this.readyToTimeblock = true
        this.autosaveState.dontReact = true
        this.handleFillProgram(toJS(res) as Program)
        this.autosaveState.dontReact = false
        this.prevProgram = toJS(this.program)
      }).catch(() => {
        this.autosaveState.status = 'error'
      })
  }

  handleFillProgram = (newProgram: Program) => {
    this.program = newProgram
    //this.program.dynamicAttributes = new Map(Object.entries(newProgram.dynamicAttributes)) as any
  }

  handleInit = () => {
    this.errors = new ProgramValidationError()
    if (this.isEditMode) {
      this.handleFillOnEdit()
    } else {
      this.handleFillProgram((this.props.createAsAdvertisment ? emptyAdsProgram : emptyMusicProgram) as any)
    }
  }

  handleSubmit = () => {
    this.autosaveState.needSave = false
    let savedItem: Program | undefined = undefined
    if (this.program.state === 'approved' || this.program.state === 'disabled') {
      this.props.enqueueSnackbar(`Program is in state: ${this.program.state} - You CANNOT change the program`, { variant: 'warning' })
      this.undoerState.dontReact = true
      this.autosaveState.dontReact = true
      this.handleFillProgram(this.prevProgram)
      this.autosaveState.dontReact = false
      this.undoerState.dontReact = false
      this.autosaveState.loading = false
      this.autosaveState.status = 'success'
    } else {
      let err = new ProgramValidationError(this.program)
      this.errors = err
      if (err.isValid) {
        this.programsService.createOrUpdate(this.program).then(result => {
          this.prevProgram = toJS(this.program)
          savedItem = toJS(result as Program)
          if (this.program.id !== savedItem.id) {
            this.autosaveState.dontReact = true
            this.program.id = savedItem.id
            this.autosaveState.dontReact = false
          }
          if (this.props.location.pathname.startsWith('/programs/new')) {
            this.props.history.replace(`/programs/${this.program.id}`)
          }
          this.props.enqueueSnackbar('Program has been saved', { variant: 'success' })
          this.readyToTimeblock = true
        }).catch((e) => {
          if (!!e.response && e.response.status === 400) {
            let response = camelcaseKeys(e.response.data, { deep: true }) as any
            if (!!response) {
              let r = new ProgramValidationError()
              r.errors = response.errors
              this.errors = r
            }
          }
          this.autosaveState.status = this.autosaveState.status !== 'error' ? 'warning' : 'error'
        }).finally(() => {
          if (!this.autosaveState.needSave) {
            if (!!savedItem) {
              this.undoer.ReplaceBy(savedItem)
              this.undoerState.dontReact = true
              this.autosaveState.dontReact = true
              this.handleFillProgram(savedItem)
              this.autosaveState.dontReact = false
              this.undoerState.dontReact = false
              this.autosaveState.status = 'success'
            }
            this.autosaveState.loading = false
          }
        })
      } else {
        this.props.enqueueSnackbar('You have filled one or more fields incorrectly.', { variant: 'error' })
        this.autosaveState.status = this.autosaveState.status !== 'error' ? 'warning' : 'error'
        if (!this.autosaveState.needSave) {
          this.autosaveState.loading = false
        }
      }
    }
  }

  @action handleApprove = () => {
    let err = new ProgramValidationError(this.program)
    if (err.isValid) {
      if (this.program.id > 0) {
        this.programsService.approve(this.program).then(_ => {
          this.program.state = 'approved'
          this.prevProgram = toJS(this.program)
        })
      }
    } else {
      this.errors = err!
    }
    //this.props.onSubmit(this.client, this.logoFile);
  }

  handleAddTimeBlock = () => {
    let maxIndex = 0
    if (!this.program.timeBlocks) {
      this.program.timeBlocks = ([] as any)
    }
    if (this.program.timeBlocks.length > 0) {
      maxIndex = Math.max(...this.program.timeBlocks.map(v => v.index))
    }

    const startTime = this.program.startTime !== '' ? this.program.startTime : '10:00'
    const endTime = this.program.stopTime !== '' ? this.program.stopTime : '10:00'
    let timeBlock: TimeBlock = {
      name: 'Block #' + String(maxIndex + 1),
      startTime: startTime,
      stopTime: endTime,
      playFileCommands: [],
      playAdvCommands: [],
      playPpCommands: [],
      playCoverCommands: [],
      playMusicCommands: [],
    } as any
    timeBlock.index = maxIndex + 1
    this.program.timeBlocks.push(timeBlock)
  }

  handelActivationDatesChange = (startDate: Date, endDate: Date) => {
    this.program.startDate = startDate
    this.program.endDate = endDate
  }

  handleActivationDatesReset = () => {
    this.program.startDate = undefined
    this.program.endDate = undefined
  }

  handleFillClientSelect = () => {
    this.dataCtx.clients.selected.clear()
    if (!!this.program.client && this.program.client.id > 0 && this.dataCtx.clients.all.has(this.program.client.id.toString())) {
      this.dataCtx.clients.selected[0] = this.dataCtx.clients.all.get(this.program.client.id.toString())!
    }
  }

  handleClientSelect = () => {
    if (this.dataCtx.clients.selected && this.dataCtx.clients.selected.length > 0) {
      this.program.client = this.dataCtx.clients.selected[0]
    }
    this.ClearErrorsForField('client')
  }

  handleClearClientSelect = () => {
    this.program.client = undefined
    this.dataCtx.clients.selected.clear()
    this.ClearErrorsForField('client')
  }

  @computed get savedSuccess() {
    return this.autosaveState.status === 'success' && !this.autosaveState.loading
  }

  @computed get canBeApproved() {
    return this.program.id > 0 && this.program.state === 'created'
  }

  @computed get canBeAssigned() {
    return this.program.id > 0 && this.program.state === 'approved'
  }

  render() {
    return (
      <Paper style={{ height: '100%', overflow: 'auto' }}>
        <Container maxWidth={false} style={{ padding: '32px' }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                  >
                    {this.program.isAdvertisement ? 'Advertisment' : 'Music'} program {this.program.id > 0 ? `#${this.program.id}` : `NEW`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldWV
                    required
                    readonly={this.readyToTimeblock}
                    label="Program name"
                    value={this.program.name}
                    onChange={value => {
                      this.program.name = value
                      this.ClearErrorsForField('name')
                    }}
                    errors={this.GetErrorsForField('name')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectViaDialog
                    required
                    readonly={this.readyToTimeblock}
                    label="Client"
                    placeholder="Select Client"
                    errors={this.GetErrorsForField('client')}
                    onOpen={(openModal) => {
                      this.handleFillClientSelect()
                      openModal()
                    }}
                    onSelected={this.handleClientSelect}
                    value={this.program.client ? [{ value: this.program.client.id.toString(), label: this.program.client.name }] : undefined}
                    onClear={this.handleClearClientSelect}
                  >
                    <ClientsPage
                      selectConfig={{
                        type: 'single',
                        usage: 'create-or-update'
                      }}
                    />
                  </SelectViaDialog>
                </Grid>
                <Grid item xs={8}>
                  <DateRangePickerWV
                    label="Activation dates"
                    errors={this.GetErrorsForField('dateRange')}
                    startDate={this.program.startDate}
                    endDate={this.program.endDate}
                    onChange={this.handelActivationDatesChange}
                    onDatesReset={this.handleActivationDatesReset}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TimeFieldWV
                    label="Start"
                    value={this.program.startTime}
                    errors={this.GetErrorsForField('startTime')}
                    onChange={value => this.program.startTime = value}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TimeFieldWV
                    label="End"
                    value={this.program.stopTime}
                    errors={this.GetErrorsForField('stopTime')}
                    onChange={value => this.program.stopTime = value}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                      <Typography>
                        Generated MSS
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <pre>{this.program.mss}</pre>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={2}>
                    {this.readyToTimeblock ?
                      <React.Fragment>
                        <Grid item>
                          <AutosaveIndicator
                            state={this.autosaveState}
                          />
                        </Grid>
                        <Grid
                          item
                          style={{
                            paddingLeft: 0,
                            marginTop: '-8px',
                            display: 'flex'
                          }}
                        >
                          <UndoButtons
                            onUndo={() => {
                              this.undoer.Undo(this.handleFillProgram, this.undoerState)
                              this.ClearAllErrors()
                            }}
                            onRedo={() => {
                              this.undoer.Redo(this.handleFillProgram, this.undoerState)
                              this.ClearAllErrors()
                            }}
                            state={this.undoerState}
                          />
                        </Grid>
                        <div style={{ flex: '1 1 auto' }} />
                      </React.Fragment> :
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={() => ConfirmationDialog('Start editing Time blocks?',
                            'You WON\'T be able to change program name and client!',
                            this.handleSubmit)}
                          color="primary"
                        >
                          {'Start edit time blocks'}
                        </Button>
                      </Grid>
                    }
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => ConfirmationDialog('Approve program?',
                          'You CANNOT edit this program after approve!',
                          this.handleApprove)}
                        disabled={!(this.canBeApproved && this.savedSuccess && this.readyToTimeblock)}
                      >
                        Approve
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.props.history.push(`/programs/assign/${this.program.id}`)}
                        disabled={!(this.canBeAssigned && this.savedSuccess && this.readyToTimeblock)}
                      >
                        Assign devices
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              {/* Time blocks container */}
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                  >
                    Time Blocks
                  </Typography>
                </Grid>
                {/* {fieldErrorMessage('timeBlocks', this.errors.errors)} */}
                <Grid item xs={12}>
                  {this.program.timeBlocks && this.program.timeBlocks.slice().sort((blk1: TimeBlock, blk2: TimeBlock) => blk1.index - blk2.index).map((value, index) => {
                    return <ExpansionPanel key={index} defaultExpanded>
                      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                        <StyledBox>
                          <Typography>{value.name}</Typography>
                          <Button onClick={() => this.program.timeBlocks.remove(value)} style={{ marginRight: '10px' }}>
                            <Delete />
                          </Button>
                        </StyledBox>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <ProgramCreateBlock
                          data={value}
                          errors={this.errors.timeBlocksErrors.length > 0 ? this.errors.timeBlocksErrors[index] : undefined}
                          onRemove={() => this.program.timeBlocks.remove(value)}
                          program={this.program}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Fab onClick={this.handleAddTimeBlock} disabled={!this.readyToTimeblock}>
                    <Add />
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    )
  }

  private GetErrorsForField = (key: string) => {
    return this.errors.errors && this.errors.errors[key] ? this.errors.errors[key] : undefined
  }

  private ClearErrorsForField = (key: string) => {
    if (!!this.errors.errors && !!this.errors.errors[key]) {
      delete this.errors.errors[key]
    }
  }

  private ClearAllErrors = () => {
    delete this.errors.errors
  }
}

export default withRouter(withSnackbar(ProgramCreatorPage))