import { types as t, Instance } from 'mobx-state-tree'
import {
    Program, TimeBlock, Command, PlayFileCommand,
    PlayAdvCommand, ProgramCommandPlaylist, PlayPpCommand,
    PlayCoverCommand, PlayMusicCommand
} from '../models'

export const PlayMusicCommandRepository = t.model({
    all: t.map(PlayMusicCommand),
})

export type PlayMusicCommandRepository = Instance<typeof PlayMusicCommandRepository>

export const PlayCoverCommandRepository = t.model({
    all: t.map(PlayCoverCommand),
})

export type PlayCoverCommandRepository = Instance<typeof PlayCoverCommandRepository>

export const PlayPpCommandRepository = t.model({
    all: t.map(PlayPpCommand),
})

export type PlayPpCommandRepository = Instance<typeof PlayPpCommandRepository>

export const ProgramCommandPlaylistRepository = t.model({
    all: t.map(ProgramCommandPlaylist),
})

export type ProgramCommandPlaylistRepository = Instance<typeof ProgramCommandPlaylistRepository>

export const PlayAdvCommandRepository = t.model({
    all: t.map(PlayAdvCommand),
})

export type PlayAdvCommandRepository = Instance<typeof PlayAdvCommandRepository>

export const PlayFileCommandRepository = t.model({
    all: t.map(PlayFileCommand),
})

export type PlayFileCommandRepository = Instance<typeof PlayFileCommandRepository>

export const TimeBlockRepository = t.model({
    all: t.map(TimeBlock),
})

export type TimeBlockRepository = Instance<typeof TimeBlockRepository>

export const ProgramsRepository = t.model({
    all: t.map(Program),
    ordered: t.array(t.reference(Program)),
    selected: t.array(t.reference(Program)),
    filterSelected: t.array(t.reference(Program)),
})

export type ProgramsRepository = Instance<typeof ProgramsRepository>