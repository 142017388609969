import React from 'react'
import { observer } from 'mobx-react'
import { computed, comparer, toJS, reaction, IReactionDisposer } from 'mobx'
import ClientForm from './ClientForm'
import ClientAttributes from './ClientAttributes'
import { clientFilters } from './ClientFilters'
import { injectable } from 'inversify'
import ClientInfo from './ClientInfo'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import {
  RouteParams, ISelectConfig,
  IFilterPreset, DiC, PageService,
  Client, entities, PageContext,
  DataContext, emptyClient, presetClient
} from '@shared/common'
import {
  dynamicAttrFilters, InfoPage,
  DataTable, InfoModal,
  DeleteEntity
} from '@shared/ui'

interface IProps extends RouteComponentProps<RouteParams>, WithSnackbarProps {
  selectConfig?: ISelectConfig
  presetFilters?: IFilterPreset
  showAll?: boolean
}

@injectable()
@observer
class ClientsPage extends React.Component<IProps> {
  reactionDisposers: IReactionDisposer[] = []

  get pageService() {
    return DiC.get<PageService<Client>>(entities.ClientSrv)
  }

  get ctx() {
    return DiC.get<PageContext>(entities.ClientCtx)
  }

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  @computed get dynamicAttributes() {
    return this.dataCtx.dynamicAttributes.ofEntity('client')
  }

  @computed get allColumns() {
    return ClientAttributes(this.dataCtx)
  }

  @computed get filterFields() {
    let dynamic = this.dynamicAttributes ? dynamicAttrFilters(this.dynamicAttributes) : {}
    return { ...clientFilters(), ...dynamic }
  }

  @computed get id() {
    return this.props.match.path === '/clients/:id?' && !!this.props.match.params.id ?
      parseInt(this.props.match.params.id) : undefined
  }

  @computed get client() {
    return !!this.id && this.dataCtx.clients.all.has(this.id.toString()) ?
      this.dataCtx.clients.all.get(this.id.toString())! :
      emptyClient as any
  }

  @computed get presetFilters() {
    let preset: IFilterPreset = !!this.props.presetFilters ? this.props.presetFilters : {}
    if (this.dataCtx.global.hasSelectedClient && !this.props.showAll) {
      presetClient(preset, this.dataCtx.global.selectedClient!.id)
    }
    return preset.client
  }

  @computed get canRead() {
    return this.pageService.getAbility('read')
  }

  @computed get canDelete() {
    return this.pageService.getAbility('delete')
  }

  handleInitInfo = () => {
    this.pageService.filtersState.handlePresetFilters(this.presetFilters)
    if (this.id) {
      this.pageService.init(this.id)
        .catch(_ => this.props.enqueueSnackbar('Could not find requested client', { variant: 'error' }))
    } else {
      this.pageService.init()
    }
  }

  componentDidMount() {
    this.handleInitInfo()
    //save disposer to stop reaction after unmount component
    this.reactionDisposers.push(reaction(
      () => toJS(this.presetFilters),
      () => {
        if (!!!this.id) {
          this.pageService.filtersState.handlePresetFilters(this.presetFilters)
          this.pageService.filtersState.applyFilters()
        }
      },
      {
        equals: comparer.structural
      }
    ))
    //listen history back and forward events
    window.addEventListener('popstate', this.handleInitInfo)
  }

  componentWillUnmount() {
    this.reactionDisposers.forEach(d => d())
    window.removeEventListener('popstate', this.handleInitInfo)
  }

  render() {
    if (!this.canRead) {
      return (<React.Fragment />)
    }
    return (
      <React.Fragment>
        {!!this.id ?
          <InfoPage>
            <ClientInfo
              entity={this.client}
              attributes={this.allColumns}
            />
          </InfoPage> :
          <React.Fragment>
            <ClientForm />
            <DataTable
              repository={this.dataCtx.clients}
              attributes={this.allColumns}
              dynamic={this.dynamicAttributes}
              ctx={this.ctx}
              service={this.pageService}
              selectConfig={this.props.selectConfig}
              filterFields={this.filterFields}
              actions={(c: Client) => [
                <InfoModal
                  key={`${c.id}-info`}
                  id={c.id}
                >
                  <ClientInfo
                    entity={c}
                    attributes={this.allColumns}
                  />
                </InfoModal>,
                <ClientForm key={`${c.id}-edit`} client={c} />,
                <DeleteEntity key={`${c.id}-delete`} can={this.canDelete} listProvider={this.pageService.paginatedList} id={c.id} />
              ]}
            />
          </React.Fragment >
        }
      </React.Fragment>
    )
  }
}

export default withRouter(withSnackbar(ClientsPage))
