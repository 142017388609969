import React from 'react'

import {
    IAttribute,
    PerformerRoleTypes, Performance,
    DataContext
} from '@shared/common'
import PerformerAttributes from './PerformerAttributes'
import PerformerInfo from './PerformerInfo'

const PerformanceAttributes = (dataCtx: DataContext) => {
    let attributes: IAttribute<Performance>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            value: (p: Performance) => p.id,
            display: (p: Performance) => p.id || '',
        },
        {
            name: 'performer',
            label: 'Performer',
            show: true,
            nested: (p: Performance) => (
                p.performer ?
                    <PerformerInfo
                        attributes={PerformerAttributes(dataCtx)}
                        entity={p.performer}
                    /> : undefined
            ),
            display: (p: Performance) => p.performer ? p.performer.displayName() : '',
            value: (p: Performance) => p.performer,
        },
        {
            name: 'performerRole',
            label: 'Role',
            show: true,
            display: (p: Performance) => p.performerRole ? PerformerRoleTypes.filter(v => v.value === p.performerRole!.type)[0].label : '',
            value: (p: Performance) => p.performerRole,
        },
        {
            name: 'comment',
            label: 'Comment',
            show: true,
            value: (p: Performance) => p.comment,
            display: (p: Performance) => p.comment || '',
        }
    ]

    return attributes
}

export default PerformanceAttributes