import {
    Content, IAttribute,
    DynamicAttribute, generateDynAttrs,
    PerformerRoleTypes, ContentTypes,
    Client, displayMore, DataContext
} from '@shared/common'
import moment from 'moment'
import PerformanceInfo from '../performers/PerformanceInfo'
import React from 'react'
import PerformanceAttributes from '../performers/PerformanceAttributes'
import { InfoTabs } from '@shared/ui'
import ClientInfo from '../clients/ClientInfo'
import ClientAttributes from '../clients/ClientAttributes'
import ContentRecordInfo from './ContentRecordInfo'
import ContentRecordAttributes from './ContentRecordAttrbiutes'

const ContentAttributes = (dataCtx: DataContext, relatedModel: string) => {
    let attributes: IAttribute<Content>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            display: (c: Content) => c.id || '',
            value: (c: Content) => c.id,
        },
        {
            name: 'name',
            label: 'Name',
            show: true,
            display: (c: Content) => c.name || '',
            value: (c: Content) => c.name,
        },
        {
            name: 'file',
            label: 'File',
            show: true,
            display: (c: Content) => (c.contentRecords.findIndex(cr => cr.forPlay) === -1) ? 'No file' : c.contentRecords[c.contentRecords.findIndex(cr => cr.forPlay)].fileName,
            value: (c: Content) => c.contentRecords,
        },
        {
            name: 'type',
            label: 'Type',
            show: true,
            display: (c: Content) => {
                let type = ContentTypes.filter(v => v.value === c.type)
                return type.length > 0 ? type[0].label : ''
            },
            value: (c: Content) => c.type,
        },
        {
            name: 'boNumber',
            label: 'БО№',
            show: true,
            display: (c: Content) => c.boNumber || '',
            value: (c: Content) => c.boNumber,
        },
        {
            name: 'clients',
            label: 'Clients',
            show: true,
            nested: (c: Content) => {
                return c.clients && c.clients.length > 0 ?
                    <React.Fragment>
                        <InfoTabs
                            tabs={c.clients.map(cl => {
                                return {
                                    label: cl.name,
                                    content: <ClientInfo
                                        attributes={ClientAttributes(dataCtx)}
                                        entity={cl}
                                    />
                                }
                            })}
                        />
                    </React.Fragment> : undefined
            },
            display: (c: Content) => displayMore(c.clients, (cl: Client) => { return cl.name }).join(', '),
            value: (c: Content) => c.clients,
        },
        {
            name: 'perfomances',
            label: 'Performances',
            show: true,
            nested: (c: Content) => {
                return c.performances.length > 0 ?
                    <React.Fragment>
                        <InfoTabs
                            tabs={c.performances.filter(p => !!p.performer && !!p.performerRole).map(p => {
                                return {
                                    label: PerformerRoleTypes.filter(v => v.value === p.performerRole!.type)[0].label,
                                    content: <PerformanceInfo
                                        attributes={PerformanceAttributes(dataCtx)}
                                        entity={p}
                                    />
                                }
                            })}
                        />
                    </React.Fragment> : undefined
            },
            display: (c: Content) => c.performances.length > 0 ? c.performances.filter(p => !!p.performer && !!p.performerRole).map(p =>
                `${PerformerRoleTypes.filter(v => v.value === p.performerRole!.type)[0].label}: ${p.performer!.displayName()}`
            ).join(',\n') : '',
            value: (c: Content) => c.performances,
        },
        {
            name: 'contentRecords',
            label: 'Records',
            show: true,
            nested: (c: Content) => {
                return c.contentRecords.length > 0 ?
                    <React.Fragment>
                        <InfoTabs
                            tabs={c.contentRecords.map(cr => {
                                return {
                                    label: `Record ${cr.id}`,
                                    content: <ContentRecordInfo
                                        attributes={ContentRecordAttributes()}
                                        entity={cr}
                                    />
                                }
                            })}
                        />
                    </React.Fragment> : undefined
            },
            display: (c: Content) => `${c.contentRecords.length} records`,
            value: (c: Content) => c.contentRecords,
        },
        {
            name: 'createdAt',
            label: 'Created',
            show: true,
            display: (c: Content) => c.createdAt ? moment(c.createdAt).format('DD.MM.YYYY') : '',
            value: (c: Content) => c.createdAt,
        },
        {
            name: 'updatedAt',
            label: 'Updated',
            show: true,
            display: (c: Content) => c.updatedAt ? moment(c.updatedAt).format('DD.MM.YYYY') : '',
            value: (c: Content) => c.updatedAt,
        },
        {
            name: 'comment',
            label: 'Comment',
            show: true,
            display: (c: Content) => c.comment || '',
            value: (c: Content) => c.comment,
        },
    ]

    let contentDynamic: DynamicAttribute[] = dataCtx.dynamicAttributes.ofEntity(relatedModel)
    const dyn: IAttribute<Content>[] = generateDynAttrs(contentDynamic)

    return attributes.concat(dyn)
}

export default ContentAttributes
