import React from 'react'
import { IAttribute, DemoRecording } from '@shared/common'
import { InfoCardContent, PlayerControl, AttributesList } from '@shared/ui'

interface IProps {
    attributes: IAttribute<DemoRecording>[]
    entity: DemoRecording
}

const DemoRecordingInfo: React.FunctionComponent<IProps> = ({ entity, attributes }) => (
    <React.Fragment>
        <InfoCardContent
            header="Demo Recording"
            subHeader={`ID = ${entity.id}`}
            actionsHeader={[
                <PlayerControl
                    key={`${entity.id}-play-demo-recording`}
                    audio={{
                        name: `Demo Recording ID = ${entity.id}`,
                        fileUrl: entity.fileUrl,
                        meta: {
                            genre: 'Performer demo',
                            bitrate: undefined,
                            durationMs: undefined,
                            sampleRate: undefined
                        }
                    }}
                />
            ]}
            content={
                <AttributesList
                    entity={entity}
                    attrs={attributes.filter(a => a.show)}
                />
            }
        />
    </React.Fragment>

)

export default DemoRecordingInfo
