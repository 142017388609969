import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend, FixDateTimesFromBackend } from '../services/utils'

export const MediaplanProgram = t.model('mediaplanProgram', {
    id: t.identifierNumber,
    name: t.string,
    isAds: t.boolean,
    startTime: t.optional(t.Date, new Date()),
    stopTime: t.optional(t.Date, new Date()),
    linkedAt: t.optional(t.Date, new Date()),
})

export type MediaplanProgram = Instance<typeof MediaplanProgram>

export const Mediaplan = t.model('mediaplan', {
    deviceId: t.identifierNumber,
    storeId: t.maybe(t.number),
    name: t.string,
    programs: t.optional(t.array(t.reference(MediaplanProgram)), []),
})

export type Mediaplan = Instance<typeof Mediaplan>

export const MediaplanProgramSchema = new schema.Entity('programs', {})

export const MediaplanSchema = new schema.Entity('mediaplans', {
    programs: [MediaplanProgramSchema],
}, {
    idAttribute: 'deviceId'
})

export function storeMediaplan(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const mediaplans = norm.entities.mediaplans
    if (!!mediaplans && Object.keys(mediaplans).length > 0) {
        storeMediaplanProgram(dataContext, norm)
        FixNullsToUndefinedFromBackend(mediaplans)
        dataContext.mediaplans.all.merge(mediaplans)
        norm.entities.mediaplans = undefined
    }
}

export function storeMediaplanProgram(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const programs = norm.entities.programs
    if (!!programs && Object.keys(programs).length > 0) {
        FixDateTimesFromBackend(programs, ['startTime', 'stopTime', 'linkedAt'])
        FixNullsToUndefinedFromBackend(programs)
        dataContext.mediaplanPrograms.all.merge(programs)
        norm.entities.programs = undefined
    }
}