import React from 'react'
import {
  Dialog, Grid, DialogActions,
  DialogContent, DialogTitle,
  IconButton, Tabs, Tab
} from '@material-ui/core'
import { observer } from 'mobx-react'
import { observable, computed, toJS, reaction } from 'mobx'
import { Edit, Add, Delete, Cancel, Clear, PlaylistAdd } from '@material-ui/icons'
import DemoForm from './DemoForm'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {
  Performer, emptyPerformer,
  FormState, Undoer, UndoerState,
  emptyUndoerState, DiC, PageService,
  entities, PageContext, DataContext,
  performerConstraints, ISelectOption,
  PerformerRole, GenericValue, Demo,
  emptyDemo, PerformerRoleTypes
} from '@shared/common'
import {
  FAB, UndoButtons,
  DynamicAttributeAdornment,
  MultilineAdd, SelectWV,
  TextFieldWV, DateRangePickerWV,
  DialogButtons
} from '@shared/ui'
import { closeUnsavedForm } from '../shared/UnsavedDialog'

interface IProps extends WithSnackbarProps {
  performer?: Performer
}

@observer
class PerformerForm extends React.Component<IProps> {
  @observable performer: Performer = emptyPerformer as any
  @observable formState: FormState<Performer> = this.pageService.createFormState()
  @observable selectedTab = 0

  undoer: Undoer<Performer> = new Undoer<Performer>()
  debounceUndoer: () => void
  @observable undoerState: UndoerState = emptyUndoerState

  componentDidMount() {
    this.debounceUndoer = AwesomeDebouncePromise(() => { this.undoer.Push(toJS(this.performer), this.undoerState) }, 500)
    reaction(
      () => toJS(this.performer),
      () => {
        if (!this.undoerState.dontReact) {
          this.undoerState.waiting = true
          this.debounceUndoer()
        }
      },
    )
  }

  get pageService() {
    return DiC.get<PageService<Performer>>(entities.PerformerSrv)
  }

  get ctx() {
    return DiC.get<PageContext>(entities.PerformerCtx)
  }

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  @computed get dynamicAttributes() {
    return this.dataCtx.dynamicAttributes.ofEntity('performer')
  }

  @computed get isEditMode() {
    return !!this.props.performer
  }

  @computed get canCreate() {
    return this.pageService.getAbility('create')
  }

  @computed get canUpdate() {
    return this.pageService.getAbility('update')
  }

  handleClear = () => {
    this.formState.clearErrors()
    if (this.isEditMode) {
      this.handleFillPerformer(toJS(this.props.performer!))
    } else {
      this.handleFillPerformer(emptyPerformer as any)
    }
  }

  handleFillPerformer = (newPerformer: Performer) => {
    this.performer = newPerformer
    this.performer.dynamicAttributes = new Map(Object.entries(newPerformer.dynamicAttributes)) as any
    this.performer.performerRoles.clear()
    newPerformer.performerRoles.forEach(pr => {
      this.performer.performerRoles.push({ ...pr, dynamicAttributes: new Map(Object.entries(pr.dynamicAttributes)) as any })
    })
  }

  openForm = () => {
    if (this.isEditMode) {
      this.handleFillPerformer(toJS(this.props.performer!))
    } else {
      this.handleFillPerformer(toJS(this.performer))
    }
    // Promise.all([
    //   this.genderPageService.domainService.fetch(),
    //   this.relevancePageService.domainService.fetch(),
    // ])
    // this.pageService.dynamicAttributesService.fetch('performer_role')
    this.formState.openForm()
  }

  closeForm = () => {
    closeUnsavedForm(this.isEditMode, () => this.formState.closeForm(), toJS(!!this.props.performer ? this.props.performer : emptyPerformer), toJS(this.performer))
  }

  handleSubmit = () => {
    this.formState.save(this.performer, performerConstraints)
      .then(res => {
        if (res.isOk) {
          this.selectedTab = 0
          this.formState.closeForm()
          this.undoerState.dontReact = true
          this.performer = emptyPerformer as any
          this.undoerState.dontReact = false
          this.undoer = new Undoer<Performer>()
          this.pageService.paginatedList.refresh()
        } else {
          this.props.enqueueSnackbar('You have filled one or more fields incorrectly.', { variant: 'error' })
        }
      })
  }

  handleTabChange = (e: object, value: number) => {
    this.selectedTab = value
  }

  handleRoleChange = (values: ISelectOption[]) => {
    const currentRoleTypes = this.performer.performerRoles.map(pr => pr.type)
    const freshTypes = values.filter(v => !currentRoleTypes.includes(v.value))
    freshTypes.forEach(t => {
      const role: PerformerRole = {
        id: 0,
        type: t.value,
        dynamicAttributes: new Map<string, GenericValue>(),
      } as any
      // if(this.performer.id > 0) {
      //   this.dataCtx.performerRoles.all.merge({...[{...role,dynamicAttributes: undefined}]})
      //   this.performer.performerRoles.push(role.id)
      // } else {
      this.performer.performerRoles.push(role)
      //      }
    })
    const toRemove = this.performer.performerRoles.filter(pr => !values.map(v => v.value).includes(pr.type))
    toRemove.forEach(pr => this.performer.performerRoles.remove(pr))
  }

  handleAddDemo = () => {
    let demo: Demo = emptyDemo as any
    this.performer.demos.push(demo)
  }

  handleRemoveDemo = (idx: number) => {
    this.performer.demos.splice(idx, 1)
  }

  @computed get currentRoles(): ISelectOption[] {
    return PerformerRoleTypes.filter(prt => this.performer.performerRoles.map(pr => pr.type).includes(prt.value))
  }

  @computed get selectedRole() {
    const i = this.selectedTab - 2
    return i < this.performer.performerRoles.length ? this.performer.performerRoles[i] : undefined
  }

  @computed get roleDynamicAttributes() {
    return this.selectedRole ? this.dataCtx.dynamicAttributes.ofEntity(this.selectedRole.type) : []
  }

  @computed get roleDynamicAttributesValues() {
    return this.selectedRole!!.dynamicAttributes
  }
  render() {
    if ((!this.isEditMode || !this.canUpdate) && (!this.canCreate || this.isEditMode)) {
      return (<React.Fragment />)
    }
    return (
      <React.Fragment>
        {this.isEditMode ?
          <IconButton
            aria-label="Edit"
            onClick={this.openForm}
          >
            <Edit />
          </IconButton>
          :
          <FAB
            ariaLabel="Add"
            onClick={this.openForm}
            disabled={this.ctx.isLoading}
          >
            <Add />
            Add Performer
          </FAB>
        }
        <Dialog
          PaperProps={{
            style: {
              height: 'calc(100% - 96px)'
            }
          }}
          open={this.formState.formOpen}
          onClose={this.closeForm}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">{this.isEditMode ? 'Edit' : 'Add'} performer</DialogTitle>
          <DialogContent>

            <Tabs
              value={this.selectedTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              onChange={this.handleTabChange}
            >
              <Tab label="Base info" />
              <Tab label="Demos" />
              {
                this.performer.performerRoles.map(pr => {
                  return <Tab key={pr.type} label={PerformerRoleTypes.filter(v => v.value === pr.type)[0].label} />
                })
              }

            </Tabs>
            {
              this.selectedTab === 0 && this.renderFields()
            }
            {
              this.selectedTab === 1 && this.renderDemos()
            }
            {
              this.selectedTab > 1 && this.renderRoleFields()
            }
          </DialogContent>
          <DialogActions>
            <DialogButtons
              onConfirm={this.handleSubmit}
              confirmText="Save"
              onCancel={this.closeForm}
              onReset={this.handleClear}
              resetText={`${this.isEditMode ? 'Reset' : 'Clear'} Fields`}
              additionalActions={
                <UndoButtons
                  onUndo={() => {
                    this.undoer.Undo(this.handleFillPerformer, this.undoerState)
                    this.formState.clearErrors()
                  }}
                  onRedo={() => {
                    this.undoer.Redo(this.handleFillPerformer, this.undoerState)
                    this.formState.clearErrors()
                  }}
                  state={this.undoerState}
                />
              }
            />
          </DialogActions>
        </Dialog>
      </React.Fragment >
    )
  }

  renderRoleFields() {
    return (
      <DynamicAttributeAdornment
        attributes={this.roleDynamicAttributes}
        values={this.roleDynamicAttributesValues}
      />
    )
  }

  renderDemos() {
    let demosError = this.formState.getErrorsForField('demos')
    return (
      <MultilineAdd<Demo>
        divideItems
        placeholder="No Demos. You can add them by clicking the button on the left."
        collection={this.performer.demos}
        renderItem={(entity, idx) => {
          return (
            <DemoForm
              demo={entity}
              errors={!!demosError && !!demosError[0] ? demosError[0][idx] : undefined}
            />
          )
        }}
        onAdd={this.handleAddDemo}
        onRemove={this.handleRemoveDemo}
      />
    )
  }

  renderFields() {
    return (
      <Grid container direction="column">
        <Grid item style={{ width: '100%' }} >
          <SelectWV
            multiple
            label="Roles"
            errors={this.formState.getErrorsForField('roles')}
            value={this.currentRoles}
            selectOptions={PerformerRoleTypes}
            onChange={this.handleRoleChange}
          // //multiple
          // value={!!this.address.timeZone ? [{ label: this.address.timeZone, value: this.address.timeZone }] : undefined}
          // onChange={value => {
          //     !!value && value.length > 0 ? this.address.timeZone = value[0].value : this.address.timeZone = ''
          //     this.formState.clearErrorsForField("roles")
          // }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="First Name"
            errors={this.formState.getErrorsForField('firstName')}
            value={this.performer.firstName}
            onChange={value => {
              this.performer.firstName = value
              this.formState.clearErrorsForField('firstName')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Last Name"
            errors={this.formState.getErrorsForField('lastName')}
            value={this.performer.lastName}
            onChange={value => {
              this.performer.lastName = value
              this.formState.clearErrorsForField('lastName')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Patronymic"
            errors={this.formState.getErrorsForField('patronymic')}
            value={this.performer.patronymic}
            onChange={value => {
              this.performer.patronymic = value
              this.formState.clearErrorsForField('patronymic')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Gender"
            selectOptions={[...this.dataCtx.genders.all.values()].map(a => ({ value: a.id.toString(), label: a.name }))}
            errors={this.formState.getErrorsForField('gender')}
            //When using as select - send id as value!
            value={!!this.performer.gender ? this.performer.gender.id : ''}
            onChange={value => {
              this.performer.gender = this.dataCtx.genders.all.get(value)
              this.formState.clearErrorsForField('gender')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Relevance"
            selectOptions={[...this.dataCtx.relevances.all.values()].map(a => ({ value: a.id.toString(), label: a.name }))}
            errors={this.formState.getErrorsForField('relevance')}
            //When using as select - send id as value!
            value={!!this.performer.relevance ? this.performer.relevance.id : ''}
            onChange={value => {
              this.performer.relevance = this.dataCtx.relevances.all.get(value)
              this.formState.clearErrorsForField('relevance')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <DateRangePickerWV
            mode="single"
            label="Birth Date"
            startDate={this.performer.birthDate || undefined}
            errors={this.formState.getErrorsForField('birthDate')}
            onChange={(begin, end) => {
              this.performer.birthDate = begin
              this.formState.clearErrorsForField('birthDate')
            }}
            onDatesReset={() => {
              this.performer.birthDate = new Date()
              this.formState.clearErrorsForField('birthDate')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Email"
            errors={this.formState.getErrorsForField('email')}
            value={this.performer.email}
            onChange={value => {
              this.performer.email = value
              this.formState.clearErrorsForField('email')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Home phone number"
            errors={this.formState.getErrorsForField('homePhoneNumber')}
            value={this.performer.homePhoneNumber}
            onChange={value => {
              this.performer.homePhoneNumber = value
              this.formState.clearErrorsForField('homePhoneNumber')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Mobile phone number"
            errors={this.formState.getErrorsForField('mobilePhoneNumber')}
            value={this.performer.mobilePhoneNumber}
            onChange={value => {
              this.performer.mobilePhoneNumber = value
              this.formState.clearErrorsForField('mobilePhoneNumber')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Social network"
            errors={this.formState.getErrorsForField('socialNetwork')}
            value={this.performer.socialNetwork}
            onChange={value => {
              this.performer.socialNetwork = value
              this.formState.clearErrorsForField('socialNetwork')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Passport number"
            errors={this.formState.getErrorsForField('passportNumber')}
            value={this.performer.passportNumber}
            onChange={value => {
              this.performer.passportNumber = value
              this.formState.clearErrorsForField('passportNumber')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <DateRangePickerWV
            mode="single"
            label="Passport date of issue"
            onChange={(begin, end) => {
              this.performer.passportDateOfIssue = begin
            }}
            onDatesReset={() => {
              this.performer.passportDateOfIssue = new Date()
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Passport unit code"
            errors={this.formState.getErrorsForField('passportUnitCode')}
            value={this.performer.passportUnitCode}
            onChange={value => {
              this.performer.passportUnitCode = value
              this.formState.clearErrorsForField('passportUnitCode')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Address"
            errors={this.formState.getErrorsForField('address')}
            value={this.performer.address}
            onChange={value => {
              this.performer.address = value
              this.formState.clearErrorsForField('address')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Registration address"
            errors={this.formState.getErrorsForField('registrationAddress')}
            value={this.performer.registrationAddress}
            onChange={value => {
              this.performer.registrationAddress = value
              this.formState.clearErrorsForField('registrationAddress')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Yandex money wallet"
            errors={this.formState.getErrorsForField('yandexMoneyWallet')}
            value={this.performer.yandexMoneyWallet}
            onChange={value => {
              this.performer.yandexMoneyWallet = value
              this.formState.clearErrorsForField('yandexMoneyWallet')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Credit card number"
            errors={this.formState.getErrorsForField('creditCardNumber')}
            value={this.performer.creditCardNumber}
            onChange={value => {
              this.performer.creditCardNumber = value
              this.formState.clearErrorsForField('creditCardNumber')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Bank"
            errors={this.formState.getErrorsForField('bank')}
            value={this.performer.bank}
            onChange={value => {
              this.performer.bank = value
              this.formState.clearErrorsForField('bank')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Retirement certificate"
            errors={this.formState.getErrorsForField('retirementCertificate')}
            value={this.performer.retirementCertificate}
            onChange={value => {
              this.performer.retirementCertificate = value
              this.formState.clearErrorsForField('retirementCertificate')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Recommendation"
            errors={this.formState.getErrorsForField('recommendation')}
            value={this.performer.recommendation}
            onChange={value => {
              this.performer.recommendation = value
              this.formState.clearErrorsForField('recommendation')
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} >
          <TextFieldWV
            label="Comment"
            errors={this.formState.getErrorsForField('comment')}
            value={this.performer.comment}
            onChange={value => {
              this.performer.comment = value
              this.formState.clearErrorsForField('comment')
            }}
          />
        </Grid>
        {this.dynamicAttributes &&
          <DynamicAttributeAdornment
            attributes={this.dynamicAttributes}
            values={this.performer.dynamicAttributes}
            errors={this.formState.errors}
          />
        }

      </Grid>
    )
  }
}

export default withSnackbar(PerformerForm)