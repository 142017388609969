import React from 'react'
import { computed, observable, toJS } from 'mobx'
import ProgramAttributes from '../programs/ProgramAttributes'
import {
    Grid, Typography,
    Button, IconButton,
    Link
} from '@material-ui/core'
import { Clear, Delete, OpenInNew } from '@material-ui/icons'
import { observer } from 'mobx-react'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'
import {
    IAttribute, Device,
    DiC, DataContext,
    entities, ProgramService,
    DevicesService, Program,
    IFilterValues
} from '@shared/common'
import {
    InfoCardContent, AttributesList,
    RawDataTable, ConfirmationDialog,
    InfoTabs, Timeline
} from '@shared/ui'

interface IProps extends WithSnackbarProps {
    attributes: IAttribute<Device>[]
    entity: Device
}

@observer
class DeviceInfo extends React.Component<IProps> {
    @observable mssAnchor?: EventTarget & HTMLButtonElement
    @observable programsTableAnchor?: EventTarget & HTMLButtonElement

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    get programsService() {
        return DiC.get<ProgramService>(entities.Program)
    }

    get devicesService() {
        return DiC.get<DevicesService>(entities.Device)
    }

    handleClearAllCurrentProgs = () => {
        this.devicesService.clearCurrentProgs(this.props.entity).then(_ => {
            this.props.enqueueSnackbar('All programs has been cleared.', { variant: 'success' })
            this.updateDevice()
            this.loadPrograms()
        })
            .catch(_ => {
                this.props.enqueueSnackbar('Failed clear assigned programs for the device', { variant: 'error' })
            })
    }

    removeDeviceFromAssignment = (prog: Program) => {
        this.devicesService.removeProgram(this.props.entity, prog).then(_ => {
            this.props.enqueueSnackbar('Program has been dis-assgined.', { variant: 'success' })
            this.updateDevice()
            this.loadPrograms()
        })
            .catch(_ => {
                this.props.enqueueSnackbar('Failed to change program assignment', { variant: 'error' })
            })
    }

    @computed get deviceIds() {
        if (this.props.entity.id > 0) {
            return [this.props.entity.id]
        } else {
            return []
        }
    }

    componentDidMount() {
        this.loadPrograms()
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.entity.id !== this.props.entity.id) {
            this.loadPrograms()
        }
    }

    private loadPrograms = async (committed?: boolean) => {
        let filterField = !!committed && committed ? 'committed_devices_id' : 'devices_id'
        if (this.props.entity.id !== 0) {
            let filters: IFilterValues = new Map([[filterField, this.props.entity.id]])
            await this.programsService.fetch({
                filters: toJS(filters)
            })
        }
    }

    private updateDevice = async () => {
        if (this.props.entity.id !== 0) {
            await this.devicesService.get(this.props.entity.id)
        }
    }

    renderBaseInfo() {
        return (
            <div style={{ maxWidth: '640px' }}>
                <InfoCardContent
                    header="Device"
                    subHeader={this.props.entity.id > 0 ?
                        <Link component={RouterLink} to={`/devices/${this.props.entity.id}`} target="_blank">
                            {`${window.location.origin}/devices/${this.props.entity.id}`}
                        </Link> : undefined
                    }
                    content={
                        <AttributesList
                            entity={this.props.entity}
                            attrs={this.props.attributes.filter(a => a.show)}
                            maxRows={this.props.attributes.length}
                        />
                    }
                />
            </div>
        )
    }

    renderMediaplan(committed?: boolean) {
        let mss = !!committed && committed ? this.props.entity.mssCommitted : this.props.entity.mssAssigned
        return (
            <Grid
                container
                direction="column"
                wrap="nowrap"
                spacing={2}
                style={{
                    padding: '16px'
                }}
            >
                <Grid item>
                    <Timeline deviceIds={this.deviceIds} committed={committed} />
                </Grid>
                {this.dataCtx.programs.ordered.length > 0 ?
                    <Grid item container direction="row" wrap="nowrap" spacing={2}>
                        <Grid item xs={3}>
                            <Typography>
                                MSS:
                            </Typography>
                            {mss !== '' ?
                                <pre>
                                    {mss}
                                </pre> :
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    At the moment device doesn't have mss
                                </Typography>
                            }
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            style={{
                                overflow: 'auto'
                            }}
                        >
                            <Typography>
                                {`${!!committed && committed ? 'Committed' : 'Assigned'} programs:`}
                            </Typography>
                            {this.renderProgramsTable(committed)}
                        </Grid>
                    </Grid> : []}
            </Grid>
        )
    }

    renderProgramsTable(committed?: boolean) {
        return (
            <Grid container direction="column" wrap="nowrap">
                <Grid item style={{ paddingBottom: '8px' }}>
                    <RawDataTable
                        values={this.props.entity.id !== 0 ? this.dataCtx.programs.ordered : []}
                        attributes={ProgramAttributes(this.dataCtx)}
                        actions={(p: Program) => {
                            let actions = [<Grid item key={`${p.id}-link`}>
                                <IconButton
                                    aria-label="Link"
                                    component={RouterLink}
                                    to={`/programs/${p.id}`}
                                    target="_blank"
                                    color="default"
                                    size="small"
                                >
                                    <OpenInNew />
                                </IconButton>
                            </Grid>]
                            return !!committed && committed ? actions : [
                                <Grid item key={`${p.id}-remove`}>
                                    <IconButton
                                        aria-label="Remove"
                                        color="secondary"
                                        size="small"
                                        onClick={() => ConfirmationDialog('De-assign program from the device?',
                                            'Program assignment will be changed',
                                            () => this.removeDeviceFromAssignment(p))}
                                    >
                                        <Clear />
                                    </IconButton>
                                </Grid>].concat(actions)
                        }}
                    />
                </Grid>
                {!!committed && committed ? [] : <Grid item>
                    <Button
                        color="secondary"
                        variant="outlined"
                        endIcon={<Delete />}
                        onClick={() =>
                            ConfirmationDialog('Clear ALL programs from device?',
                                'Program assignment will be changed',
                                this.handleClearAllCurrentProgs)
                        }
                    >
                        Clear all assigned programs
                    </Button>
                </Grid>}
            </Grid>
        )
    }

    render() {
        let mediaTabs = this.props.entity.isUpToDate ? {
            label: 'Programs',
            content: this.renderMediaplan(),
            onChange: (() => {
                this.loadPrograms()
            })
        } : [
                {
                    label: 'Assigned',
                    content: this.renderMediaplan(false),
                    onChange: (() => {
                        this.loadPrograms(false)
                    })
                },
                {
                    label: 'Committed',
                    content: this.renderMediaplan(true),
                    onChange: (() => {
                        this.loadPrograms(true)
                    })
                },
            ]
        return (
            <InfoTabs
                tabs={[
                    {
                        label: 'Info',
                        content: this.renderBaseInfo()
                    }].concat(mediaTabs)
                }
            />
        )
    }
}

export default withSnackbar(DeviceInfo)
