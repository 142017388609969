import React from 'react'
import { Container, Box } from '@material-ui/core'
import styled from 'styled-components'

export const StyledContainer = styled(({ color, ...otherProps }) => <Container {...otherProps} />)`
  && {
    display: flex;
    justify-content: flex-start;
    padding: 0 8px; 
  }
  >div>div>div {
    padding-right: 8px;
  }
`

export const SmallInputBox = styled(({ color, ...otherProps }) => <Box {...otherProps} />)`
  && {
    width: 40px;
  }
`
