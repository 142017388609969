import { types as t, Instance } from 'mobx-state-tree'
import { GenericValue } from '../../../models/generic'
import { IFilterValues } from '../../../models'
import { computed, toJS, action } from 'mobx'

export const FiltersUIContext = t.model({
    filtersOpen: t.optional(t.boolean, false),
    filters: t.optional(t.map(GenericValue), {}),
    searchQuery: t.optional(t.string, ''),
    filtersPreset: t.optional(t.map(GenericValue), {}),
    applyedFiltersCount: t.optional(t.number, 0),
})

export type FiltersUIContext = Instance<typeof FiltersUIContext>

interface IUIContext {
    filtersOpen: boolean
    filters: IFilterValues
    searchQuery: string
    filtersPreset: IFilterValues
    applyedFiltersCount: number
}

interface IPaginationListService {
    initPaginatedList(): Promise<void>
    mixedFilters: IFilterValues
}

export class FiltersState {
    constructor(
        readonly ctx: IUIContext,
        readonly listService: IPaginationListService,
    ) { }

    openFilters() {
        this.ctx.filtersOpen = true
    }

    closeFilters() {
        this.ctx.filtersOpen = false
    }

    resetFilters() {
        this.ctx.filters.clear()
    }

    resetFiltersPreset() {
        this.ctx.filtersPreset.clear()
    }

    @action handlePresetFilters(preset?: IFilterValues) {
        this.resetFiltersPreset()
        if (!!preset) {
            preset.forEach((v, k) => {
                this.ctx.filtersPreset.set(k, v)
            })
        }
    }

    resetDynamicFilters() {
        let keys = this.ctx.filters.keys()
        for (let key of keys) {
            if (key.includes('dynamicAttributes')) {
                this.ctx.filters.delete(key)
            }
        }
    }

    applyFilters() {
        this.closeFilters()
        this.listService.initPaginatedList()
    }
}