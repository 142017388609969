import React from 'react'

import AddressesPage from '../addresses/AddressPage'
import { DataContext, IFilterPreset, ISelectType } from '@shared/common'
import { IFilters } from '@shared/ui'

export const locationFilters = (dataCtx: DataContext, preset?: IFilterPreset): IFilters => ({
    name: { label: 'Name', type: 'string' },
    room: { label: 'Room', type: 'string' },
    addressId: {
        label: 'Address',
        type: 'dialog',
        dialogParams: {
            children: (selectType?: ISelectType) => {
                return <AddressesPage
                    selectConfig={
                        selectType ? {
                            type: selectType,
                            usage: 'filter'
                        } : undefined
                    }
                    presetFilters={preset}
                />
            },
            selectByIds: (ids: string[]) => {
                dataCtx.addresses.filterSelected.clear()
                ids.forEach(id => {
                    if (dataCtx.addresses.all.has(id)) {
                        dataCtx.addresses.filterSelected.push(dataCtx.addresses.all.get(id)!)
                    }
                })
            },
            onSelected: () => {
                if (!!dataCtx.addresses.filterSelected && dataCtx.addresses.filterSelected.length > 0) {
                    return dataCtx.addresses.filterSelected.map(v => ({ value: v.id.toString(), label: v.name }))
                }
                return undefined
            },
            onClear: () => {
                dataCtx.addresses.filterSelected.clear()
            },
            multiple: true
        }
    },
})