import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import { Undo, Redo } from '@material-ui/icons'
import { observer } from 'mobx-react'
import { UndoerState } from '@shared/common'

interface IProps {
    onUndo(): void
    onRedo(): void
    state: UndoerState
}

const UndoButtons: React.FunctionComponent<IProps> = observer(({ onUndo, onRedo, state }) => {
    return (
        <React.Fragment>
            <Grid item>
                <IconButton
                    onClick={onUndo}
                    disabled={!state.canUndo || state.waiting}
                    color="primary"
                >
                    <Undo />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton
                    onClick={onRedo}
                    disabled={!state.canRedo || state.waiting}
                    color="primary"
                >
                    <Redo />
                </IconButton>
            </Grid>
        </React.Fragment>
    )
})

export default UndoButtons