import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { GenericValue } from './generic'
import { FixNullsToUndefinedFromBackend, FixDatesFromBackend } from '../services/utils'
import { DataContext } from '../data-context'

export const Address = t
    .model('address', {
        id: t.identifierNumber,
        country: t.string,
        city: t.string,
        region: t.optional(t.string, ''),
        street: t.string,
        home: t.string,
        zipCode: t.number,
        timeZone: t.string,
        latitude: t.maybeNull(t.number),
        longitude: t.maybeNull(t.number),
        name: t.optional(t.string, ''),
        createdAt: t.optional(t.Date, new Date()),
        updatedAt: t.optional(t.Date, new Date()),
        dynamicAttributes: t.optional(t.map(GenericValue), {}),
    })

export type Address = Instance<typeof Address>

export const emptyAddress = {
    id: 0,
    country: '',
    city: '',
    region: '',
    street: '',
    home: '',
    zipCode: NaN,
    timeZone: '',
    latitude: null,
    longitude: null,
    name: '',
    createdAt: new Date(),
    udpatedAt: new Date(),
    dynamicAttributes: new Map<string, GenericValue>(),
}

export const addressConstraints = {
    country: { presence: { allowEmpty: false } },
    city: { presence: { allowEmpty: false } },
    home: { presence: { allowEmpty: false } },
    timeZone: { presence: { allowEmpty: false } },
    street: { presence: { allowEmpty: false } },
    zipCode: {
        presence: true, numericality: true
    },
    latitude: {
        numericality: {
            greaterThanOrEqualTo: -90,
            lessThanOrEqualTo: 90,
        }
    },
    longitude: {
        numericality: {
            greaterThanOrEqualTo: -180,
            lessThanOrEqualTo: 180,
        }
    },
}

export const AddressSchema = new schema.Entity('addresses')

export function storeAddress(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const addresses = norm.entities.addresses
    if (!!addresses && Object.keys(addresses).length > 0) {
        FixNullsToUndefinedFromBackend(addresses)
        FixDatesFromBackend(addresses, ['createdAt', 'updatedAt'])
        dataContext.addresses.all.merge(addresses)
        norm.entities.addresses = undefined
    }
}