import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { GenericValue } from './generic'
import { Performance, PerformanceSchema, performanceConstraints, storePerformance } from './performance'
import { Client, ClientSchema, storeClient } from './client'
import { ContentRecord, ContentRecordSchema, contentRecordConstraints, storeContentRecord } from './content-record'
import { ISelectOption } from '.'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend, FixDatesFromBackend } from '../services/utils'

export const ContentTypes: ISelectOption[] = [
    { label: 'Music', value: 'Music' },
    { label: 'Advertisement', value: 'Advertisement' },
    { label: 'Cover', value: 'Cover' },
    { label: 'FX', value: 'Fx' },
    { label: 'Product Placement', value: 'ProductPlacement' },
]

export const ContentLink = t
    .model('content_link', {
        id: t.identifierNumber,
        comment: t.optional(t.string, ''),
        content: t.maybe(t.reference(t.late((): any => Content))),
    })

export type ContentLink = Instance<typeof ContentLink>

export const Content = t
    .model('content', {
        id: t.identifierNumber,
        name: t.string,
        type: t.string,
        comment: t.optional(t.string, ''),
        boNumber: t.maybe(t.number),
        performances: t.optional(t.array(t.reference(Performance)), []),
        clients: t.optional(t.array(t.reference(Client)), []),
        contentRecords: t.optional(t.array(t.reference(ContentRecord)), []),
        createdAt: t.optional(t.Date, new Date()),
        updatedAt: t.optional(t.Date, new Date()),
        releaseDate: t.optional(t.string, ''),
        contentLinks: t.optional(t.array(t.reference(ContentLink)), []),
        dynamicAttributes: t.optional(t.map(GenericValue), {}),
    })

export type Content = Instance<typeof Content>

export const emptyContent = {
    id: 0,
    name: '',
    type: '',
    comment: '',
    boNumber: undefined,
    performances: [],
    clients: [],
    contentRecords: [],
    createdAt: new Date(),
    udpatedAt: new Date(),
    contentLinks: [],
    dynamicAttributes: new Map<string, GenericValue>(),
}

export const contentConstraints = {
    name: {
        presence: { allowEmpty: false },
        format: {
            pattern: /^\S*$/,
            message: 'must not contain spaces'
        }
    },
    type: { presence: { allowEmpty: false } },
    // demoUrl: { presence: { allowEmpty: false, message: "^File can't be blank" } },
    performances: {
        array: performanceConstraints
    },
    contentRecords: {
        array: contentRecordConstraints
    }
}

const clientPresence = {
    clients: { presence: { allowEmpty: false } },
}

export const contentWithClientConstraints = { ...contentConstraints, ...clientPresence }

export const ContentSchema = new schema.Entity('contents', {
    performances: [PerformanceSchema],
    clients: [ClientSchema],
    contentRecords: [ContentRecordSchema]
})

export function storeContent(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    let contents = norm.entities.contents
    if (!!contents && Object.keys(contents).length > 0) {
        storePerformance(dataContext, norm)
        storeClient(dataContext, norm)
        storeContentRecord(dataContext, norm)
        FixDatesFromBackend(contents, ['createdAt', 'updatedAt'])
        FixNullsToUndefinedFromBackend(contents)
        Object.values(contents).forEach((c: any) => {
            if (!!c.contentRecords && Array.isArray(c.contentRecords) && c.contentRecords.length > 1) {
                c.contentRecords = c.contentRecords.sort()
            }
            if (!!c.performances && Array.isArray(c.performances) && c.performances.length > 1) {
                c.performances = c.performances.sort()
            }
        })
        dataContext.contents.all.merge(contents)
        norm.entities.contents = undefined
    }
}