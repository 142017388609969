import React from 'react'
import { observable, computed, toJS } from 'mobx'
import { observer } from 'mobx-react'
import {
    Dialog, Grid, DialogContent,
    DialogActions, DialogTitle,
    Fab, IconButton, Tooltip
} from '@material-ui/core'

import { Add, Edit } from '@material-ui/icons'

import {
    DynamicAttribute, emptyDynamicAttribute,
    DynamicAttributeTypes, DynamicAttributeFields,
    DynamicAttributeParams, PageService,
    PageContext, DynamicFormState
} from '@shared/common'
import TextFieldWV from '../TextFieldWithValidation'
import DynamicAttributeSelectOptions from './DynamicAttributeSelectOptions'
import snakeCase from 'snake-case'
import CyrillicToTranslit from 'cyrillic-to-translit-js'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import DialogButtons from '../DialogButtons'

interface IProps<A> extends WithSnackbarProps {
    srv: PageService<A>
    ctx: PageContext
    attribute?: DynamicAttribute
    relatedModel?: string
}

const translit = new CyrillicToTranslit

@observer class DynamicAttributeForm<A> extends React.Component<IProps<A>> {
    @observable dynamicAttribute: DynamicAttribute = emptyDynamicAttribute as any
    @observable formState: DynamicFormState = this.props.srv.createDynamicFormState()

    @computed get isEditMode() {
        return !!this.props.attribute
    }

    handleFillFields = () => {
        if (this.isEditMode) {
            this.formState.clearErrors()
            this.dynamicAttribute = toJS(this.props.attribute!)
            this.dynamicAttribute.params = undefined
        }
    }

    openForm = () => {
        this.handleFillFields()
        this.formState.openForm()
    }

    closeForm = () => {
        this.formState.closeForm()
    }

    handleChangeOptions = (options: string[]) => {
        if (options.length === 0 || (options.length === 1 && options[0] === '')) {
            this.dynamicAttribute.params = undefined
        } else {
            this.dynamicAttribute.params = { options: options } as DynamicAttributeParams
        }
    }

    handleClear = () => {
        this.formState.clearErrors()
        this.dynamicAttribute = emptyDynamicAttribute as any
    }

    handleSubmit = () => {
        if (this.isEditMode && !!this.props.attribute!.params) {
            this.dynamicAttribute.params = {
                options:
                    this.props.attribute!.params.options.concat(this.dynamicAttribute.params ? this.dynamicAttribute.params.options : [])
            } as DynamicAttributeParams
        }
        this.formState.save(this.dynamicAttribute, this.props.relatedModel)
            .then(res => {
                if (res.isOk) {
                    this.handleClear()
                    this.closeForm()
                    this.props.srv.dynamicAttributes.fetchDynamicAttributes()
                } else {
                    this.props.enqueueSnackbar('You have filled one or more fields incorrectly.', { variant: 'error' })
                }
            })
    }

    render() {
        return (
            <React.Fragment>
                {this.isEditMode ?
                    <IconButton
                        aria-label="Edit-dynamic"
                        onClick={this.openForm}
                    >
                        <Edit />
                    </IconButton>
                    :
                    <Fab
                        style={{
                            zIndex: 2,
                        }}
                        variant="round"
                        color="primary"
                        aria-label="Add-dynamic"
                        onClick={this.openForm}
                        disabled={this.props.ctx.isLoading}
                    >
                        <Add />
                    </Fab>
                }
                <Dialog
                    open={this.formState.formOpen}
                    onClose={this.closeForm}
                    aria-labelledby="dyn-form-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="dyn-form-dialog-title">{this.isEditMode ? 'Edit Dynamic Attribute' : 'Add Dynamic Attribute'}</DialogTitle>
                    <DialogContent>
                        <Grid container direction="column">
                            <Grid item >
                                <TextFieldWV
                                    required
                                    label="Name"
                                    errors={!!this.formState.getErrorsForField('name') ? this.formState.getErrorsForField('name') : this.formState.getErrorsForField('label')}
                                    value={this.dynamicAttribute.label}
                                    onChange={value => {
                                        if (!this.isEditMode) {
                                            this.dynamicAttribute.name = snakeCase(translit.transform(value).replace(new RegExp('[^a-zA-Z0-9]', 'g'), ''))
                                        }
                                        this.dynamicAttribute.label = value
                                        this.formState.clearErrorsForField('name')
                                        this.formState.clearErrorsForField('label')
                                    }}
                                />
                            </Grid>
                            <Grid item container direction="column">
                                <Grid container direction="row">
                                    <Grid item style={{ flex: '1 1 auto' }}>
                                        <TextFieldWV
                                            readonly={this.isEditMode}
                                            required
                                            label="Select Field Type"
                                            selectOptions={DynamicAttributeFields}
                                            errors={this.formState.getErrorsForField('fieldType')}
                                            //When using as select - send id as value!
                                            value={!!this.dynamicAttribute.fieldType ? this.dynamicAttribute.fieldType : ''}
                                            onChange={value => {
                                                this.dynamicAttribute.fieldType = value as DynamicAttributeTypes
                                                this.formState.clearErrorsForField('fieldType')
                                            }}
                                        />
                                    </Grid>
                                    <div style={{ width: '24px' }} />
                                    <Tooltip
                                        title={
                                            <React.Fragment>
                                                Float number, may be negative.<br />
                                                The lower position - the closer to the top of the list.<br />
                                                Default value - maximum existing position value + 1
                                            </React.Fragment>
                                        }
                                        placement="top"
                                    >
                                        <Grid item style={{ width: '80px' }} >
                                            <TextFieldWV
                                                type="number"
                                                label="Position"
                                                value={this.dynamicAttribute.priority}
                                                onChange={value => {
                                                    this.dynamicAttribute.priority = parseFloat(value)
                                                    if (isNaN(this.dynamicAttribute.priority)) {
                                                        this.dynamicAttribute.priority = undefined
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                                {this.dynamicAttribute.fieldType === 'select' || this.dynamicAttribute.fieldType === 'multiple_select' ?
                                    <DynamicAttributeSelectOptions
                                        onChange={this.handleChangeOptions}
                                        options={
                                            this.isEditMode && this.props.attribute!.params ?
                                                this.props.attribute!.params.options : undefined}
                                    /> : []
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogButtons
                            onConfirm={this.handleSubmit}
                            confirmText="Save"
                            onCancel={this.closeForm}
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment >
        )
    }
}

export default withSnackbar(DynamicAttributeForm)
