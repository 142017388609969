import React from 'react'
import { computed, IReactionDisposer, reaction, toJS, comparer } from 'mobx'
import { observer } from 'mobx-react'
import { injectable } from 'inversify'
import StoreForm from './StoreForm'
import StoreAttributes from './StoreAttributes'
import ClientsPage from '../clients/ClientsPage'
import LocationsPage from '../locations/LocationsPage'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import StoreInfo from './StoreInfo'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import {
  DataContext, IFilterPreset,
  ISelectType, RouteParams,
  ISelectConfig, DiC,
  PageService, Store, entities,
  PageContext, emptyStore,
  presetClient
} from '@shared/common'
import {
  IFilters, dynamicAttrFilters,
  InfoPage, DataTable,
  InfoModal, DeleteEntity
} from '@shared/ui'

export const storesFilters = (dataCtx: DataContext, preset?: IFilterPreset): IFilters => ({
  name: { label: 'Name', type: 'string' },
  clientId: {
    label: 'Client',
    type: 'dialog',
    dialogParams: {
      children: (selectType?: ISelectType) => {
        return <ClientsPage
          selectConfig={
            selectType ? {
              type: selectType,
              usage: 'filter'
            } : undefined
          }
          presetFilters={preset}
        />
      },
      selectByIds: (ids: string[]) => {
        dataCtx.clients.filterSelected.clear()
        ids.forEach(id => {
          if (dataCtx.clients.all.has(id)) {
            dataCtx.clients.filterSelected.push(dataCtx.clients.all.get(id)!)
          }
        })
      },
      onSelected: () => {
        if (!!dataCtx.clients.filterSelected && dataCtx.clients.filterSelected.length > 0) {
          return dataCtx.clients.filterSelected.map(v => ({ value: v.id.toString(), label: v.name }))
        }
        return undefined
      },
      onClear: () => {
        dataCtx.clients.filterSelected.clear()
      },
      multiple: true
    }
  },
  openTime: { label: 'Open Time', type: 'time', isRange: true },
  closeTime: { label: 'CloseTime', type: 'time', isRange: true },
  openDate: { label: 'Open Date', type: 'date', isRange: true },
  locationId: {
    label: 'Location',
    type: 'dialog',
    dialogParams: {
      children: (selectType?: ISelectType) => {
        return <LocationsPage
          selectConfig={
            selectType ? {
              type: selectType,
              usage: 'filter'
            } : undefined
          }
          presetFilters={preset}
        />
      },
      selectByIds: (ids: string[]) => {
        dataCtx.locations.filterSelected.clear()
        ids.forEach(id => {
          if (dataCtx.locations.all.has(id)) {
            dataCtx.locations.filterSelected.push(dataCtx.locations.all.get(id)!)
          }
        })
      },
      onSelected: () => {
        if (!!dataCtx.locations.filterSelected && dataCtx.locations.filterSelected.length > 0) {
          return dataCtx.locations.filterSelected.map(v => ({ value: v.id.toString(), label: v.name }))
        }
        return undefined
      },
      onClear: () => {
        dataCtx.locations.filterSelected.clear()
      },
      multiple: true
    }
  },
})

interface IProps extends RouteComponentProps<RouteParams>, WithSnackbarProps {
  selectConfig?: ISelectConfig
  presetFilters?: IFilterPreset
}

@injectable()
@observer
class StoresPage extends React.Component<IProps> {
  reactionDisposers: IReactionDisposer[] = []

  get pageService() {
    return DiC.get<PageService<Store>>(entities.StoreSrv)
  }

  get ctx() {
    return DiC.get<PageContext>(entities.StoreCtx)
  }

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  @computed get dynamicAttributes() {
    return this.dataCtx.dynamicAttributes.ofEntity('store')
  }

  @computed get allColumns() {
    return StoreAttributes(this.dataCtx)
  }

  @computed get filterFields() {
    let dynamic = this.dynamicAttributes ? dynamicAttrFilters(this.dynamicAttributes) : {}
    return { ...storesFilters(this.dataCtx, this.props.presetFilters), ...dynamic }
  }

  @computed get id() {
    return this.props.match.path === '/stores/:id?' && !!this.props.match.params.id ?
      parseInt(this.props.match.params.id) : undefined
  }

  @computed get store() {
    return !!this.id && this.dataCtx.stores.all.has(this.id.toString()) ?
      this.dataCtx.stores.all.get(this.id.toString())! :
      emptyStore as any
  }

  @computed get presetFilters() {
    let preset: IFilterPreset = !!this.props.presetFilters ? this.props.presetFilters : {}
    if (this.dataCtx.global.hasSelectedClient) {
      presetClient(preset, this.dataCtx.global.selectedClient!.id)
    }
    return preset.store
  }

  @computed get canRead() {
    return this.pageService.getAbility('read')
  }

  @computed get canDelete() {
    return this.pageService.getAbility('delete')
  }

  handleInitInfo = () => {
    this.pageService.filtersState.handlePresetFilters(this.presetFilters)
    if (this.id) {
      this.pageService.init(this.id)
        .catch(_ => this.props.enqueueSnackbar('Could not find requested store', { variant: 'error' }))
    } else {
      this.pageService.init()
    }
  }

  componentDidMount() {
    this.pageService.dynamicAttributesService.fetch('address')
    this.pageService.dynamicAttributesService.fetch('location')
    this.pageService.dynamicAttributesService.fetch('client')
    this.handleInitInfo()
    this.reactionDisposers.push(reaction(
      () => toJS(this.presetFilters),
      () => {
        if (!!!this.id) {
          this.pageService.filtersState.handlePresetFilters(this.presetFilters)
          this.pageService.filtersState.applyFilters()
        }
      },
      {
        equals: comparer.structural
      }
    ))
    window.addEventListener('popstate', this.handleInitInfo)
  }

  componentWillUnmount() {
    this.reactionDisposers.forEach(d => d())
    window.removeEventListener('popstate', this.handleInitInfo)
  }

  render() {
    if (!this.canRead) {
      return (<React.Fragment />)
    }
    return (
      <React.Fragment>
        {!!this.id ?
          <InfoPage wide>
            <StoreInfo
              entity={this.store}
              attributes={this.allColumns}
            />
          </InfoPage> :
          <React.Fragment>
            <StoreForm />
            <DataTable
              repository={this.dataCtx.stores}
              attributes={this.allColumns}
              dynamic={this.dynamicAttributes}
              ctx={this.ctx}
              service={this.pageService}
              selectConfig={this.props.selectConfig}
              filterFields={this.filterFields}
              actions={(s: Store) => [
                <InfoModal
                  wide
                  key={`${s.id}-info`}
                  id={s.id}
                >
                  <StoreInfo
                    entity={s}
                    attributes={this.allColumns}
                  />
                </InfoModal>,
                <StoreForm key={s.id} store={s} />,
                <DeleteEntity key={`${s.id}-delete`} can={this.canDelete} listProvider={this.pageService.paginatedList} id={s.id} />
              ]}
            />
          </React.Fragment>
        }
      </React.Fragment >
    )
  }
}

export default withRouter(withSnackbar(StoresPage))