import { types as t, Instance, getMembers } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { GenericValue } from './generic'
import { DataContext } from '../data-context'
import { FixDatesFromBackend, FixNullsToUndefinedFromBackend } from '../services/utils'

export const Client = t
    .model('client', {
        id: t.identifierNumber,
        name: t.string,
        phone: t.optional(t.string, ''),
        corpEmail: t.optional(t.string, ''),
        logoUrl: t.maybe(t.string),
        address: t.optional(t.string, ''),
        domainName: t.optional(t.string, ''),
        createdAt: t.optional(t.Date, new Date()),
        updatedAt: t.optional(t.Date, new Date()),
        dynamicAttributes: t.optional(t.map(GenericValue), {}),
    }).volatile(() => ({
        logo: undefined
    })).actions(self => ({
        setFile(value?: File) {
            self.logo = value ? value as any : undefined
        },
        getFile(): any {
            return self.logo as any
        }
    }))

export type Client = Instance<typeof Client>

export const emptyClient = {
    id: 0,
    name: '',
    phone: '',
    corpEmail: '',
    logoUrl: undefined,
    address: '',
    domainName: '',
    createdAt: new Date(),
    udpatedAt: new Date(),
    dynamicAttributes: new Map<string, GenericValue>(),
    logo: undefined,
}

export const clientConstraints = {
    name: { presence: { allowEmpty: false } },
    phone: { presence: { allowEmpty: false } },
    corpEmail: { presence: { allowEmpty: false }, email: true },
    address: { presence: { allowEmpty: false } },
    domainName: { presence: { allowEmpty: false } },
}

export const ClientSchema = new schema.Entity('clients')

export function storeClient(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const clients = norm.entities.clients
    if (!!clients && Object.keys(clients).length > 0) {
        FixDatesFromBackend(clients, ['createdAt', 'updatedAt'])
        FixNullsToUndefinedFromBackend(clients)
        dataContext.clients.all.merge(clients)
        norm.entities.clients = undefined
    }
}