import { types as t, Instance } from 'mobx-state-tree'
import { PerformerRole } from '../models'

export const PerformerRolesRepository = t.model({
    all: t.map(PerformerRole),
    ordered: t.array(t.reference(PerformerRole)),
    selected: t.array(t.reference(PerformerRole)),
    filterSelected: t.array(t.reference(PerformerRole)),
})

export type PerformerRolesRepository = Instance<typeof PerformerRolesRepository>
