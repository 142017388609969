import React from 'react'

import {
    Address, DynamicAttribute,
    IAttribute, generateDynAttrs,
    DataContext
} from '@shared/common'
import moment from 'moment'

const AddressAttributes = (dataCtx: DataContext) => {
    let attributes: IAttribute<Address>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            display: a => a.id || '',
            value: a => a.id
        },
        {
            name: 'country',
            label: 'Country',
            show: true,
            display: a => (a.country ? a.country : ''),
            value: a => (a.country ? a.country : null)
        },
        {
            name: 'city',
            label: 'City',
            show: true,
            display: a => (a.city ? a.city : ''),
            value: a => (a.city ? a.city : null)
        },
        {
            name: 'region',
            label: 'Region',
            show: true,
            display: a => (a.region ? a.region : ''),
            value: a => (a.region ? a.region : null)
        },
        {
            name: 'street',
            label: 'Street',
            show: true,
            display: a => (a.street ? a.street : ''),
            value: a => (a.street ? a.street : null)
        },
        {
            name: 'home',
            label: 'Home',
            show: true,
            display: a => (a.home ? a.home : ''),
            value: a => (a.home ? a.home : null)
        },
        {
            name: 'zipCode',
            label: 'Zip code',
            show: true,
            display: a => (a.zipCode ? a.zipCode : ''),
            value: a => (a.zipCode ? a.zipCode : null)
        },
        {
            name: 'timeZone',
            label: 'Time zone',
            show: true,
            display: a => (a.timeZone ? a.timeZone : ''),
            value: a => (a.timeZone ? a.timeZone : null)
        },
        {
            name: 'latitude',
            label: 'Latitude',
            show: false,
            display: a => (a.latitude ? a.latitude : ''),
            value: a => (a.latitude ? a.latitude : null)
        },
        {
            name: 'longitude',
            label: 'Longitude',
            show: false,
            display: a => (a.longitude ? a.longitude : ''),
            value: a => (a.longitude ? a.longitude : null)
        },
        {
            name: 'created_at',
            label: 'Created',
            show: true,
            display: d => d.createdAt ? moment(d.createdAt).format('DD.MM.YYYY') : '',
            value: d => d.createdAt,
        },
        {
            name: 'updated_at',
            label: 'Updated',
            show: true,
            display: d => d.updatedAt ? moment(d.updatedAt).format('DD.MM.YYYY') : '',
            value: d => d.updatedAt,
        },
    ]

    let addressDynamic: DynamicAttribute[] = dataCtx.dynamicAttributes.ofEntity('address')
    const dyn: IAttribute<Address>[] = generateDynAttrs(addressDynamic)

    return attributes.concat(dyn)
}

export default AddressAttributes
