import { MediaplansPageContext } from './mediaplans-page-context'
import { IMediaplanOptions } from '../../api-client'
import { entities, DiC } from '../../ioc'
import { injectable } from 'inversify'

export interface IMediaplanDomainService {
    fetch(options?: IMediaplanOptions): Promise<any>
}

@injectable()
export class MediaplansPageService {
    get domainService() {
        return DiC.get<IMediaplanDomainService>(entities.Mediaplan)
    }

    get ctx() {
        return DiC.get<MediaplansPageContext>(entities.MediaplanCtx)
    }

    async fetch(options: IMediaplanOptions) {
        this.ctx.isLoading = true
        try {
            this.ctx.options = options as any
            await this.domainService.fetch(this.ctx.options)
        }
        finally {
            this.ctx.isLoading = false
        }
    }
}