import { types as t, Instance } from 'mobx-state-tree'

const MediaplanOptions = t.model('mediaplanOptions', {
    storeIds: t.optional(t.array(t.number), []),
    deviceIds: t.optional(t.array(t.number), []),
    committed: t.maybe(t.boolean),
    from: t.optional(t.Date, new Date()),
    to: t.optional(t.Date, new Date()),
})

type MediaplanOptions = Instance<typeof MediaplanOptions>

export const MediaplansPageContext =
    t.model({
        options: t.optional(MediaplanOptions, {}),
        isLoading: t.optional(t.boolean, false),
    })

export type MediaplansPageContext = Instance<typeof MediaplansPageContext>
