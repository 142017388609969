import React from 'react'
import moment from 'moment'
import ClientAttributes from '../clients/ClientAttributes'
import ClientInfo from '../clients/ClientInfo'
import LocationInfo from '../locations/LocationInfo'
import LocationAttributes from '../locations/LocationAttrbiutes'
import {
    DataContext, Store,
    DynamicAttribute,
    IAttribute, generateDynAttrs
} from '@shared/common'

const StoreAttributes = (dataCtx: DataContext) => {
    let attributes: IAttribute<Store>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            display: a => a.id || '',
            value: a => a.id
        },
        {
            name: 'photoUrl',
            label: 'Photo',
            show: true,
            display: a => { return (<img style={{ maxHeight: '50px', maxWidth: '200px' }} src={a.photoUrl} />) },
            value: a => a.photoUrl
        },
        {
            name: 'name',
            label: 'Name',
            show: true,
            display: a => (a.name ? a.name : ''),
            value: a => (a.name ? a.name : null)
        },
        {
            name: 'client',
            label: 'Client',
            show: true,
            nested: (s: Store) => (
                s.client ?
                    <ClientInfo
                        attributes={ClientAttributes(dataCtx)}
                        entity={s.client}
                    /> : undefined
            ),
            display: a => (a.client ? a.client.name : ''),
            value: a => (a.client ? a.client.name : null)
        },
        {
            name: 'openTime',
            label: 'Open Time',
            show: true,
            display: a => (a.openTime ? a.openTime : ''),
            value: a => (a.openTime ? a.openTime : null)
        },
        {
            name: 'closeTime',
            label: 'Close Time',
            show: true,
            display: a => (a.closeTime ? a.closeTime : ''),
            value: a => (a.closeTime ? a.closeTime : null)
        },
        {
            name: 'location',
            label: 'Location',
            show: true,
            nested: (s: Store) => (
                s.location ?
                    <LocationInfo
                        attributes={LocationAttributes(dataCtx)}
                        entity={s.location}
                    /> : undefined
            ),
            display: a => (a.location ? a.location.name : ''),
            value: a => (a.location ? a.location.name : null)
        },
        {
            name: 'address',
            label: 'Address',
            show: true,
            display: a => (a.location && a.location.address ? a.location.address.name : ''),
            value: a => (a.location && a.location.address ? a.location.address : null)
        },
        // {
        //     name: 'groupStore',
        //     label: 'Store Group',
        //     show: true,
        //     display: a => (a.groupStore ? a.groupStore.name : ''),
        //     value: a => (a.groupStore ? a.groupStore.name : null)
        // },
        {
            name: 'square',
            label: 'Square',
            show: false,
            display: a => (a.square ? a.square : ''),
            value: a => (a.square ? a.square : null)
        },
        {
            name: 'storeFormat',
            label: 'Store Format',
            show: false,
            display: a => (a.storeFormat ? a.storeFormat : ''),
            value: a => (a.storeFormat ? a.storeFormat : null)
        },
        {
            name: 'openDate',
            label: 'Open Date',
            show: true,
            display: a => (a.openDate ? moment(a.openDate).format('DD.MM.YYYY') : ''),
            value: a => (a.openDate ? a.openDate : null)
        },
        {
            name: 'created_at',
            label: 'Created',
            show: true,
            display: d => d.createdAt ? moment(d.createdAt).format('DD.MM.YYYY') : '',
            value: d => d.createdAt,
        },
        {
            name: 'updated_at',
            label: 'Updated',
            show: true,
            display: d => d.updatedAt ? moment(d.updatedAt).format('DD.MM.YYYY') : '',
            value: d => d.updatedAt,
        },
    ]

    let storeDynamic: DynamicAttribute[] = dataCtx.dynamicAttributes.ofEntity('store')
    const dyn: IAttribute<Store>[] = generateDynAttrs(storeDynamic)

    return attributes.concat(dyn)
}

export default StoreAttributes