import React from 'react'
import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'

interface IProps {
    onConfirm: () => void
    confirmText?: string
    onCancel: () => void
    cancelText?: string
    onReset?: () => void
    resetText?: string
    additionalActions?: React.ReactNode
    alignCenter?: boolean
}

const DialogButtons: React.FunctionComponent<IProps> = observer(({ onConfirm, onCancel, onReset, confirmText, cancelText, resetText, additionalActions, alignCenter }) => {
    let confirmLabel = !!confirmText ? confirmText : 'Ok'
    let cancelLabel = !!cancelText ? cancelText : 'Cancel'
    let resetLabel = !!resetText ? resetText : 'Reset'
    //let center = alignCenter === undefined ? !!!onReset && !!!additionalActions : alignCenter
    return (
        <React.Fragment>
            {!!alignCenter && <div style={{ flex: '1 1 auto' }} />}
            {!!onReset &&
                <Button onClick={onReset} color="primary" style={!!!alignCenter ? { marginLeft: '16px' } : {}} variant="outlined">
                    {resetLabel}
                </Button>
            }
            {!!additionalActions && additionalActions}
            {!!!alignCenter && <div style={{ flex: '1 1 auto' }} />}
            <Button onClick={onCancel} color="primary" variant="outlined">
                {cancelLabel}
            </Button>
            <Button onClick={onConfirm} color="primary" style={!!!alignCenter ? { marginRight: '16px' } : {}} variant="contained">
                {confirmLabel}
            </Button>
            {!!alignCenter && <div style={{ flex: '1 1 auto' }} />}
        </React.Fragment>
    )
})

export default DialogButtons