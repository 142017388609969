import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, IMediaplanOptions } from '../api-client'
import { FixDateTimesFromBackend } from './utils'
import { MediaplanSchema, storeMediaplan } from '../models/mediaplan'

@injectable()
export class MediaplansService {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)
        storeMediaplan(this.dataContext, norm)
        return norm.result
    }

    async fetch(options: IMediaplanOptions): Promise<any> {
        const data = await this.apiClient.getMediplan(options)
        this.storeContext(data, new schema.Array(MediaplanSchema))
    }
}
