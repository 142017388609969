import React from 'react'
import './program_creator.css'
import { observer } from 'mobx-react'
import ProgramCreatorPlaylistSelector from './ProgramCreatorPlaylistSelector'
import { Grid } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { ProgramCommandPlaylist } from '@shared/common'

interface IProp {
  data: ProgramCommandPlaylist[],
  errors?: { [field: string]: string[] }[]
}

@observer
class ProgramCreatorPlaylistList extends React.Component<IProp> {
  handleAddPlaylist = () => {
    if (this.props.data.length > 2) {
      return
    }

    let maxIndex = Math.max(...this.props.data.map(v => v.index))
    if (!maxIndex) {
      maxIndex = -1
    }
    let playlist = {
      index: maxIndex + 1,
      repetitionCount: 1
    } as ProgramCommandPlaylist
    //unprotect(playlist);
    this.props.data.push(playlist)
  }

  render() {
    return (
      <React.Fragment>
        {
          this.props.data.map((value, index) => {
            return <ProgramCreatorPlaylistSelector
              key={index}
              data={value}
              showRemoveCross={this.props.data.length !== 1}
              errors={this.props.errors ? this.props.errors[index] : undefined}
              onRemove={() => {
                if (this.props.data.length === 1) {
                  return
                }

                this.props.data.splice(index, 1)
              }}
            />
          })
        }
        <Grid item>
          {
            this.props.data.length < 3 ?
              <Add onClick={this.handleAddPlaylist} />
              : null
          }
        </Grid>
      </React.Fragment>
    )
  }
}

export default ProgramCreatorPlaylistList