import React from 'react'
import { Badge, withStyles, Theme } from '@material-ui/core'

const StyledBadge = withStyles((theme: Theme) => ({
    badge: {
        top: '8px',
        right: '8px',
    },
}))(Badge)

export default StyledBadge