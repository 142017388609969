import { types as t, Instance } from 'mobx-state-tree'
import { PlaylistEntry } from '../models'

export const PlaylistEntriesRepository = t.model({
    all: t.map(PlaylistEntry),
    ordered: t.array(t.reference(PlaylistEntry)),
    selected: t.array(t.reference(PlaylistEntry)),
    filterSelected: t.array(t.reference(PlaylistEntry)),
})

export type PlaylistEntriesRepository = Instance<typeof PlaylistEntriesRepository>
