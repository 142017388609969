import snakeCase from 'snake-case'

export function mapToObject<T>(map: Map<string, T>): { [field: string]: T } {
    return Array.from(map).reduce((obj, [key, value]) => (
        Object.assign(obj, { [key]: value })
    ), {})
}

export function mapDeepMerge<Tkey>(original: Map<Tkey, any>, newM: Map<Tkey, any>): Map<Tkey, any> {
    newM.forEach((v, key) => {
        if (newM.get(key) instanceof Map && original.get(key) instanceof Map) {
            let originalChild = original.get(key)
            let newChild = newM.get(key)
            original.set(key, mapDeepMerge(originalChild, newChild))
        } else {
            let newChild = newM.get(key)
            original.set(key, newChild)
        }
    })
    return original
}

export function dottedMapToDeepMap(map: Map<string, any>): Map<string, any> {
    var recursive = new Map<string, any>()
    map.forEach((value, key) => {
        mapDeepMerge(recursive, createDeepMap(key.split('.'), value))
    })
    return recursive
}

export function dottedMapFlattenByKey(entityKey: string, map: Map<string, any>): Map<string, any> {
    var result = new Map<string, any>()
    map.forEach((value, key) => {
        let splitted = key.split('.')
        let k = splitted.shift()
        if (splitted.length > 0 && k === entityKey) {
            result.set(splitted.join('.'), value)
        }
    })
    return result
}

export function createDeepMap<Tkey>(keys: Tkey[], value: any): Map<Tkey, any> {
    if (keys.length !== 0) {
        if (keys.length > 1) {
            const key = keys.shift()
            return new Map([[key!, createDeepMap(keys, value)]])
        } else {
            return new Map([[keys[0], value]])
        }
    }
    return new Map<Tkey, any>()
}

export function deepMapToObject(map: Map<string, any>): { [field: string]: any } {
    const obj: any = {}
    for (let [key, value] of map) {
        if (value instanceof Map) {
            obj[key] = deepMapToObject(value)
        } else {
            obj[key] = value
        }
    }
    return obj
}

export function snakeCaseWithDots(str: string): string {
    return str.split('.').map(pref => snakeCase(pref)).join('.')
}

export function snakecaseKeysWithDots(obj: any): any {

    return ['string', 'number', 'boolean'].includes(typeof obj) ? obj : Object.keys(obj)
        .filter(k => obj[k] !== null && obj[k] !== undefined)  // Remove undef. and null.
        .reduce((newObj, k) => {
            switch (typeof obj[k]) {
                case 'object':
                    let objMod = undefined
                    if (Array.isArray(obj[k])) {
                        objMod = { [snakeCaseWithDots(k)]: obj[k].map((item: any) => snakecaseKeysWithDots(item)) }
                    } else if (obj[k] instanceof File || obj[k] instanceof String) {
                        objMod = { [snakeCaseWithDots(k)]: obj[k] }
                    } else {
                        objMod = { [snakeCaseWithDots(k)]: snakecaseKeysWithDots(obj[k]) }
                    }  // Recurse.
                    return Object.assign(newObj, objMod)
                default:
                    return Object.assign(newObj, { [snakeCaseWithDots(k)]: obj[k] })
            }
        }
            ,  // Copy value.
            {},

        )
}
