import React from 'react'
import { observer } from 'mobx-react'
import { injectable } from 'inversify'
import { computed, toJS, reaction, comparer, IReactionDisposer } from 'mobx'
import DeviceForm from './DeviceForm'
import DeviceInfo from './DeviceInfo'
import DeviceAttributes from './DeviceAttrbiutes'
import { deviceFilters } from './DeviceFilters'
import { Grid } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import {
  RouteParams, ISelectConfig,
  IFilterPreset, DiC,
  PageService, Device,
  entities, PageContext,
  DataContext, emptyDevice,
  presetClient
} from '@shared/common'
import {
  dynamicAttrFilters, InfoPage,
  DataTable, InfoModal,
  DeleteEntity
} from '@shared/ui'

interface IProps extends RouteComponentProps<RouteParams>, WithSnackbarProps {
  selectConfig?: ISelectConfig
  presetFilters?: IFilterPreset
}

@injectable()
@observer
class DevicesPage extends React.Component<IProps> {
  reactionDisposers: IReactionDisposer[] = []

  get pageService() {
    return DiC.get<PageService<Device>>(entities.DeviceSrv)
  }

  get ctx() {
    return DiC.get<PageContext>(entities.DeviceCtx)
  }

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  @computed get dynamicAttributes() {
    return this.dataCtx.dynamicAttributes.ofEntity('device')
  }

  @computed get allColumns() {
    return DeviceAttributes(this.dataCtx)
  }

  @computed get id() {
    return this.props.match.path === '/devices/:id?' && !!this.props.match.params.id ?
      parseInt(this.props.match.params.id) : undefined
  }

  @computed get device() {
    return !!this.id && this.dataCtx.devices.all.has(this.id.toString()) ?
      this.dataCtx.devices.all.get(this.id.toString())! :
      emptyDevice as any
  }

  @computed get filterFields() {
    let dynamic = this.dynamicAttributes ? dynamicAttrFilters(this.dynamicAttributes) : {}
    return { ...deviceFilters(this.dataCtx, this.props.presetFilters), ...dynamic }
  }

  @computed get presetFilters() {
    let preset: IFilterPreset = !!this.props.presetFilters ? this.props.presetFilters : {}
    if (this.dataCtx.global.hasSelectedClient) {
      presetClient(preset, this.dataCtx.global.selectedClient!.id)
    }
    return preset.device
  }

  @computed get canRead() {
    return this.pageService.getAbility('read')
  }

  @computed get canDelete() {
    return this.pageService.getAbility('delete')
  }

  handleInitInfo = () => {
    this.pageService.filtersState.handlePresetFilters(this.presetFilters)
    if (this.id) {
      this.pageService.init(this.id)
        .catch(_ => this.props.enqueueSnackbar('Could not find requested device', { variant: 'error' }))
    } else {
      this.pageService.init()
    }
  }

  componentDidMount() {
    this.pageService.dynamicAttributesService.fetch('address')
    this.pageService.dynamicAttributesService.fetch('location')
    this.pageService.dynamicAttributesService.fetch('client')
    this.pageService.dynamicAttributesService.fetch('store')
    this.handleInitInfo()
    this.reactionDisposers.push(reaction(
      () => toJS(this.presetFilters),
      () => {
        if (!!!this.id) {
          this.pageService.filtersState.handlePresetFilters(this.presetFilters)
          this.pageService.filtersState.applyFilters()
        }
      },
      {
        equals: comparer.structural
      }
    ))
    window.addEventListener('popstate', this.handleInitInfo)
  }

  componentWillUnmount() {
    this.reactionDisposers.forEach(d => d())
    window.removeEventListener('popstate', this.handleInitInfo)
  }

  render() {
    if (!this.canRead) {
      return (<React.Fragment />)
    }
    return (
      <React.Fragment>
        {!!this.id ?
          <InfoPage wide>
            <DeviceInfo
              entity={this.device}
              attributes={this.allColumns}
            />
          </InfoPage> :
          <React.Fragment>
            <DeviceForm />
            <DataTable
              repository={this.dataCtx.devices}
              attributes={this.allColumns}
              dynamic={this.dynamicAttributes}
              ctx={this.ctx}
              service={this.pageService}
              selectConfig={this.props.selectConfig}
              filterFields={this.filterFields}
              actions={(d: Device) => [
                <Grid item key={`${d.id}-info`}>
                  <InfoModal
                    wide
                    key={`${d.id}`}
                    id={d.id}
                  >
                    <DeviceInfo
                      entity={d}
                      attributes={this.allColumns}
                    />
                  </InfoModal>
                </Grid>,
                <DeviceForm key={`${d.id}-edit`} device={d} />,
                <Grid item key={`${d.id}-delete`}>
                  <DeleteEntity can={this.canDelete} listProvider={this.pageService.paginatedList} id={d.id} />
                </Grid>
              ]}
            />
          </React.Fragment >
        }
      </React.Fragment >
    )
  }
}

export default withRouter(withSnackbar(DevicesPage))
