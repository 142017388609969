import React from 'react'

import './program_creator.css'
import { observer } from 'mobx-react'
import { FormControl, FormLabel, Checkbox, Grid, Box } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import ContentsPage from '../contents/ContentsPage'
import { StyledContainer } from './ProgramCreatorShared'
import {
  PlayFileCommand, Client,
  DiC, DataContext, entities,
  Content, playableContentRecord
} from '@shared/common'
import { DateRangePickerWV, TimeFieldWV, SelectViaDialog } from '@shared/ui'

interface IProps {
  color: string
  data: PlayFileCommand
  onRemove(): void
  errors?: { [field: string]: string[] }
  client?: Client
}

@observer
class ProgramCreatorFileForm extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  handelActivationDatesChange = (startDate: Date, endDate: Date) => {
    this.props.data.startDate = startDate
    this.props.data.endDate = endDate
  }

  handleActivationDatesReset = () => {
    this.props.data.startDate = undefined
    this.props.data.endDate = undefined
  }

  handleFillContentSelect = () => {
    this.dataCtx.contents.selected.clear()
    if (!!this.props.data.content && this.props.data.content.id > 0 && this.dataCtx.contents.all.has(this.props.data.content.id.toString())) {
      this.dataCtx.contents.selected[0] = this.dataCtx.contents.all.get(this.props.data.content.id.toString())!
    }
  }

  handleContentSelect = () => {
    if (!!this.dataCtx.contents.selected && this.dataCtx.contents.selected.length > 0) {
      this.props.data.content = this.dataCtx.contents.selected[0]
    }
    this.ClearErrorsForField('content')
  }

  handleClearContentSelect = () => {
    this.props.data.content = undefined
    this.dataCtx.contents.selected.clear()
    this.ClearErrorsForField('content')
  }

  render() {
    return (
      <React.Fragment>
        <StyledContainer>
          <Grid container>
            <Grid item xs container>
              <DateRangePickerWV
                label="Activation dates"
                errors={this.GetErrorsForField('dateRange')}
                startDate={this.props.data.startDate}
                endDate={this.props.data.endDate}
                onChange={this.handelActivationDatesChange}
                onDatesReset={this.handleActivationDatesReset}
              />
              <TimeFieldWV
                label="Start time"
                value={this.props.data.startTime}
                errors={this.GetErrorsForField('startTime')}
                onChange={value => this.props.data.startTime = value}
              />
              <Box>
                <FormControl>
                  <FormLabel>Pref</FormLabel>
                  <Checkbox
                    checked={this.props.data.pref}
                    onChange={(_, d) => this.props.data.pref = d}
                    value="on"
                  />
                </FormControl>
              </Box>
              <Box>
                <SelectViaDialog
                  label="File"
                  placeholder="Select File"
                  errors={this.GetErrorsForField('content')}
                  onOpen={(openModal) => {
                    this.handleFillContentSelect()
                    openModal()
                  }}
                  onSelected={this.handleContentSelect}
                  value={this.props.data.content ? [{ value: this.props.data.content.id.toString(), label: this.props.data.content.name }] : undefined}
                  onClear={this.handleClearContentSelect}
                >
                  <ContentsPage
                    selectConfig={{
                      type: 'single',
                      usage: 'create-or-update',
                      disableItem: (content: Content) => {
                        return !!!playableContentRecord(content)
                      }
                    }}
                    presetFilters={!!this.props.client ? {
                      content: new Map([['clientId', [this.props.client!.id]]])
                    } : undefined}
                  />
                </SelectViaDialog>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Clear onClick={this.props.onRemove} />
              </Box>
            </Grid>
          </Grid>
        </StyledContainer>
      </React.Fragment>
    )
  }

  private GetErrorsForField = (key: string) => {
    return this.props.errors && this.props.errors[key] ? this.props.errors[key] : undefined
  }

  private ClearErrorsForField = (key: string) => {
    if (!!this.props.errors && !!this.props.errors[key]) {
      delete this.props.errors[key]
    }
  }
}

export default ProgramCreatorFileForm