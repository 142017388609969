import { types as t, Instance } from 'mobx-state-tree'
import { GroupStore } from '../models'

export const GroupStoresRepository = t.model({
    all: t.map(GroupStore),
    ordered: t.array(t.reference(GroupStore)),
    selected: t.array(t.reference(GroupStore)),
    filterSelected: t.array(t.reference(GroupStore)),
})

export type GroupStoresRepository = Instance<typeof GroupStoresRepository>