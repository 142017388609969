import { types as t, Instance } from 'mobx-state-tree'

export const PlayerState = t.model({
    state: t.optional(t.enumeration('state', ['played', 'stopped', 'paused']), 'stopped'),
    muted: t.optional(t.boolean, false),
    volume: t.optional(t.number, 16),
    duration: t.optional(t.number, 0),
    currentTime: t.optional(t.number, 0),
    playerOpen: t.optional(t.boolean, false),
    timerIds: t.optional(t.array(t.number), [])
})
export type PlayerState = Instance<typeof PlayerState>

export const AudioMetaInfo = t.model({
    genre: t.maybe(t.string),
    bitrate: t.maybe(t.number),
    sampleRate: t.maybe(t.number),
    durationMs: t.maybe(t.number),
})
export type AudioMetaInfo = Instance<typeof AudioMetaInfo>

export const PlayerAudio = t.model({
    name: t.string,
    fileUrl: t.optional(t.string, ''),
    meta: t.maybe(AudioMetaInfo),
})
export type PlayerAudio = Instance<typeof PlayerAudio>

export const PlayerRepository = t.model({
    played: t.maybe(PlayerAudio),
    state: t.optional(PlayerState, {}),
})

export type PlayerRepository = Instance<typeof PlayerRepository>
