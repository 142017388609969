import React from 'react'
import 'react-dates/initialize'
import { observer } from 'mobx-react'
import DevicesPage from './components/devices/DevicesPage'
import ClientsPage from './components/clients/ClientsPage'
import ProgramCreatorPage from './components/program_creator/ProgramCreatorPage'
import LocationsPage from './components/locations/LocationsPage'
import AddressesPage from './components/addresses/AddressPage'
import ProgramsPage from './components/programs/ProgramsPage'
import StorePage from './components/stores/StorePage'
import MainMenu from './components/shared/main_menu/MainMenu'
import Axios from 'axios'
import { Container } from '@material-ui/core'
import PlaylistsPage from './components/playlists/PlaylistsPage'
import ProgramAssignPage from './components/programs/ProgramAssignPage'
import { observable } from 'mobx'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import PerformersPage from './components/performers/PerformersPage'
import ZLoginPage from './components/z_user/ZLoginPage'
import ZMainMenu from './components/z_user/ZMainMenu'
import ZStoresPage from './components/z_user/ZStoresPage'
import ZRequestsPage from './components/z_user/ZRequestsPage'
import { Route, withRouter, RouteComponentProps } from 'react-router-dom'
import PlaylistDrawer from './components/playlists/PlaylistDrawer'
import ContentsPage from './components/contents/ContentsPage'
import {
  DataContext, UIState, RestClient,
  DiC, entities, IApiClient,
  IDynamicService, DynamicAttributesService,
  IDomainService, AddressesService,
  PageContext, PageService, LocationsService,
  Client, ClientsService, Device,
  DevicesService, Content,
  ContentsService, PlaylistType,
  PlaylistTypesService, Playlist,
  PlaylistsService, GroupStore,
  GroupStoresService, Store, StoresService,
  Program, ProgramService, MediaplansService,
  MediaplansPageContext, MediaplansPageService,
  Performer, PerformersService, Address,
  Gender, Relevance, PerformerRole,
  AuthService, LoginPageService,
  Location, IMediaplanDomainService,
  GendersService, RelevancesService,
  PerformerRolesService
} from '@shared/common'
import { PrivateRoute } from '@shared/ui'
import LoginPage from './components/a_user/LoginPage'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const dataContext = DataContext.create()
const uiState = UIState.create()
const client = new RestClient()

const audio = new Audio()

DiC.bind<HTMLAudioElement>(entities.Audio).toConstantValue(audio)

DiC.bind<IApiClient>(entities.IApiClient).to(RestClient)
DiC.bind<DataContext>(entities.DataContext).toConstantValue(dataContext)
DiC.bind<IDynamicService>(entities.DynamicAttribute).to(DynamicAttributesService)

DiC.bind<IDomainService<Address>>(entities.Address).to(AddressesService)
DiC.bind<PageContext>(entities.AddressCtx).toConstantValue(uiState.ctx.addressesPage)
const addressesPageService = new PageService<Address>(entities.Address)
DiC.bind<PageService<Address>>(entities.AddressSrv).toConstantValue(addressesPageService)

DiC.bind<IDomainService<Location>>(entities.Location).to(LocationsService)
DiC.bind<PageContext>(entities.LocationCtx).toConstantValue(uiState.ctx.locationsPage)
const locationsPageService = new PageService<Location>(entities.Location)
DiC.bind<PageService<Location>>(entities.LocationSrv).toConstantValue(locationsPageService)

DiC.bind<IDomainService<Client>>(entities.Client).to(ClientsService)
DiC.bind<PageContext>(entities.ClientCtx).toConstantValue(uiState.ctx.clientsPage)
const clientsPageService = new PageService<Client>(entities.Client)
DiC.bind<PageService<Client>>(entities.ClientSrv).toConstantValue(clientsPageService)

DiC.bind<IDomainService<Device>>(entities.Device).to(DevicesService)
DiC.bind<PageContext>(entities.DeviceCtx).toConstantValue(uiState.ctx.devicesPage)
const devicesPageService = new PageService<Device>(entities.Device)
DiC.bind<PageService<Device>>(entities.DeviceSrv).toConstantValue(devicesPageService)

DiC.bind<IDomainService<Content>>(entities.Content).to(ContentsService)
DiC.bind<PageContext>(entities.ContentCtx).toConstantValue(uiState.ctx.contentsPage)
const contentsPageService = new PageService<Content>(entities.Content)
DiC.bind<PageService<Content>>(entities.ContentSrv).toConstantValue(contentsPageService)

DiC.bind<IDomainService<PlaylistType>>(entities.PlaylistType).to(PlaylistTypesService)
DiC.bind<PageContext>(entities.PlaylistTypeCtx).toConstantValue(uiState.ctx.playlistTypesPage)
const playlistTypesPageService = new PageService<PlaylistType>(entities.PlaylistType)
DiC.bind<PageService<PlaylistType>>(entities.PlaylistTypeSrv).toConstantValue(playlistTypesPageService)

DiC.bind<IDomainService<Playlist>>(entities.Playlist).to(PlaylistsService)
DiC.bind<PageContext>(entities.PlaylistCtx).toConstantValue(uiState.ctx.playlistsPage)
const playlistsPageService = new PageService<Playlist>(entities.Playlist)
DiC.bind<PageService<Playlist>>(entities.PlaylistSrv).toConstantValue(playlistsPageService)

DiC.bind<IDomainService<GroupStore>>(entities.GroupStore).to(GroupStoresService)
DiC.bind<PageContext>(entities.GroupStoreCtx).toConstantValue(uiState.ctx.groupStoresPage)
const groupStoresPageService = new PageService<GroupStore>(entities.GroupStore)
DiC.bind<PageService<GroupStore>>(entities.GroupStoreSrv).toConstantValue(groupStoresPageService)

DiC.bind<IDomainService<Store>>(entities.Store).to(StoresService)
DiC.bind<PageContext>(entities.StoreCtx).toConstantValue(uiState.ctx.storesPage)
const storesPageService = new PageService<Store>(entities.Store)
DiC.bind<PageService<Store>>(entities.StoreSrv).toConstantValue(storesPageService)

DiC.bind<IDomainService<Program>>(entities.Program).to(ProgramService)
DiC.bind<PageContext>(entities.ProgramCtx).toConstantValue(uiState.ctx.programsPage)
DiC.bind<PageService<Program>>(entities.ProgramSrv).toConstantValue(new PageService<Program>(entities.Program))

DiC.bind<IMediaplanDomainService>(entities.Mediaplan).to(MediaplansService)
DiC.bind<MediaplansPageContext>(entities.MediaplanCtx).toConstantValue(uiState.ctx.mediaplansPage)
const mediaplansPageService = new MediaplansPageService()
DiC.bind<MediaplansPageService>(entities.MediaplanSrv).toConstantValue(mediaplansPageService)

DiC.bind<IDomainService<Performer>>(entities.Performer).to(PerformersService)
DiC.bind<PageContext>(entities.PerformerCtx).toConstantValue(uiState.ctx.programsPage)
DiC.bind<PageService<Performer>>(entities.PerformerSrv).toConstantValue(new PageService<Performer>(entities.Performer))

DiC.bind<IDomainService<Gender>>(entities.Gender).to(GendersService)
DiC.bind<PageContext>(entities.GenderCtx).toConstantValue(uiState.ctx.relevancesPage)
DiC.bind<PageService<Gender>>(entities.GenderSrv).toConstantValue(new PageService<Gender>(entities.Gender))

DiC.bind<IDomainService<Relevance>>(entities.Relevance).to(RelevancesService)
DiC.bind<PageContext>(entities.RelevanceCtx).toConstantValue(uiState.ctx.relevancesPage)
DiC.bind<PageService<Relevance>>(entities.RelevanceSrv).toConstantValue(new PageService<Relevance>(entities.Relevance))

DiC.bind<IDomainService<PerformerRole>>(entities.PerformerRole).to(PerformerRolesService)
DiC.bind<PageContext>(entities.PerformerRoleCtx).toConstantValue(uiState.ctx.performerRolesPage)
DiC.bind<PageService<PerformerRole>>(entities.PerformerRoleSrv).toConstantValue(new PageService<PerformerRole>(entities.PerformerRole))

const authService = new AuthService(client)
const loginPageService = new LoginPageService(authService, uiState.ctx.loginPage)

interface IProps extends WithSnackbarProps, RouteComponentProps { }

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4054a1'
    },
  },
})

@observer
class AppRoot extends React.Component<IProps> {
  @observable currentPath: string = ''
  componentDidMount() {
    this.props.history.listen((location) => {
      this.currentPath = location.pathname
    })

    Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status >= 500) {
          this.props.enqueueSnackbar('Server failed to process the request', { variant: 'error' })
        } else if (error.response.status === 401) {
          this.onLogOut()
        } else if (error.response.status === 403) {
          let message = 'You not authorized to this action.'
          if (!!error.response.data && !!error.response.data.message) {
            message = `You ${error.response.data.message.toLowerCase()}`
          }
          this.props.enqueueSnackbar(message, { variant: 'error' })
        }
        return Promise.reject(error)
      }
    )

    authService.getCurrentUser().then(res => {
      if (res) {
        dataContext.global.loggedInUser = res[0] as any
        dataContext.global.version = res[1]
      }
    })
  }

  onLogIn = () => {
    if (this.currentPath !== '/login') {
      this.props.history.push('/login')
    }
  }

  onLogOut = () => {
    localStorage.removeItem('jwt-token')
    dataContext.global.loggedInUser = null as any
    this.onLogIn()
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        {!this.currentPath.startsWith('/z') && <MainMenu onLogIn={this.onLogIn} onLogOut={this.onLogOut} />}
        {this.currentPath.startsWith('/z') && <ZMainMenu />}
        <Container
          maxWidth={false}
          style={{
            height: '100vh',
            padding: '112px 24px 24px 24px',
            backgroundColor: '#ebebeb',
            overflow: 'hidden'
          }}
        >
          <Route exact path="/z/lk/login" render={_ => <ZLoginPage />} />
          <Route exact path="/z/lk/stores" render={_ => <ZStoresPage />} />
          <Route exact path="/z/lk/requests" render={_ => <ZRequestsPage />} />

          <PrivateRoute exact path="/contents" uiState={uiState}>
            <PlaylistDrawer />
          </PrivateRoute>
          <PrivateRoute exact path="/contents/:id" uiState={uiState}>
            <ContentsPage />
          </PrivateRoute>
          <PrivateRoute path="/devices/:id?" uiState={uiState}>
            <DevicesPage
            // presetFilters={new Map<string, any>([
            //   ['name', 'lol'],         // simple set value by filters field name
            //   ['clientId', [79, 78]],  // can set ids for dialog (sic!)[must be in array always!]
            //   ['store.openTime', { from: "7:30", to: "12:30" }], // linked instances set via dotted string
            //   ['store.openDate', { from: "2019-05-15", to: "2020-10-25" }], // and date
            //   ['store.location.address.timeZone', 'vaaalue'], //can set label fo SelectWV 
            //   ['store.location.dynamicAttributes.twoWords', 'presetted'], //and dynamic attributes too!
            // ])}
            />
          </PrivateRoute>
          {/* <PrivateRoute exact path="/devices/:id" uiState={uiState}>
              <DeviceInfo />
            </PrivateRoute> */}
          <PrivateRoute path="/locations/:id?" uiState={uiState}>
            <LocationsPage />
          </PrivateRoute>
          <PrivateRoute path="/clients/:id?" uiState={uiState}>
            <ClientsPage />
          </PrivateRoute>
          <PrivateRoute path="/addresses/:id?" uiState={uiState}>
            <AddressesPage />
          </PrivateRoute>
          <PrivateRoute path="/programs" exact uiState={uiState}>
            <ProgramsPage />
          </PrivateRoute>
          <PrivateRoute path="/program/new" exact uiState={uiState}>
            <ProgramCreatorPage createAsAdvertisment={false} />
          </PrivateRoute>
          <PrivateRoute path="/stores/:id?" uiState={uiState}>
            <StorePage />
          </PrivateRoute>
          <PrivateRoute exact path="/programs/new/ads" uiState={uiState}>
            <ProgramCreatorPage createAsAdvertisment />
          </PrivateRoute>
          <PrivateRoute exact path="/programs/:id" uiState={uiState}>
            <ProgramCreatorPage createAsAdvertisment={false} />
          </PrivateRoute>
          <PrivateRoute exact path="/programs/assign/:id" uiState={uiState}>
            <ProgramAssignPage />
          </PrivateRoute>
          <PrivateRoute exact path="/playlists/:id" uiState={uiState}>
            <PlaylistDrawer />
          </PrivateRoute>
          <PrivateRoute path="/playlists" exact uiState={uiState}>
            <PlaylistsPage />
          </PrivateRoute>
          <PrivateRoute path="/performers/:id?" uiState={uiState}>
            <PerformersPage />
          </PrivateRoute>
          <Route
            path="/login"
            render={_ => (
              <LoginPage pageService={loginPageService} pageContext={uiState.ctx.loginPage} />
            )}
          />
        </Container>
      </ThemeProvider>
    )
  }
}

export default withRouter(withSnackbar(AppRoot))
