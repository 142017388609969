import { types as t, Instance } from 'mobx-state-tree'
import { schema } from 'normalizr'

export const Genre = t
    .model('genre', {
        id: t.identifierNumber,
        name: t.string,
    })

export type Genre = Instance<typeof Genre>

export const emptyGenre = {
    id: 0,
    name: '',
}

export const genreConstraints = {
    name: { presence: { allowEmpty: false } },
}

export const GenreSchema = new schema.Entity('genres')