import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

import {
    Grid, IconButton,
    Typography
} from '@material-ui/core'
import { Delete, Add } from '@material-ui/icons'
import TextFieldWV from '../TextFieldWithValidation'

interface IProps {
    onChange: (options: string[]) => void
    options?: string[]
}

@observer
class DynamicAttributeSelectOptions extends React.Component<IProps> {
    @observable options: string[] = ['']
    indexOffset: number = this.props.options ? this.props.options.length : 0

    handleAddItem = () => {
        this.options.push('')
        this.props.onChange(this.options)
    }

    handleRemoveItem = (idx: number) => {
        if (this.options.length > 1) {
            this.options.splice(idx, 1)
        } else {
            this.options = ['']
        }
        this.props.onChange(this.options)
    }

    render() {
        return (
            <React.Fragment>
                <Grid item container direction="column" wrap="nowrap" >
                    <Grid
                        item
                        style={{ paddingTop: '8px' }}
                    >
                        <Typography
                            gutterBottom
                            variant="body1"
                        >
                            Options:
                        </Typography>
                    </Grid>
                    <Grid item container direction="row" wrap="nowrap" >
                        <Grid
                            item
                            style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                width: 'auto'
                            }}
                        >
                            <IconButton
                                color="primary"
                                disabled={this.options.filter(o => o.length === 0).length > 0}
                                onClick={this.handleAddItem}
                            >
                                <Add />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            style={{ paddingLeft: '8px' }}
                        >
                            {!!this.props.options ? this.props.options.map((o, idx) => (
                                <Grid
                                    item
                                    key={`dyn-opts-base-${idx}`}
                                    style={{ flex: '1 1 0' }}
                                >
                                    <TextFieldWV
                                        readonly
                                        label={`Option ${(idx + 1)}`}
                                        value={o}
                                        onChange={value => {
                                            this.options[idx] = value
                                            this.props.onChange(this.options)
                                        }}
                                    />
                                </Grid>
                            )) : []
                            }
                            {this.options.map((o, idx) => (
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    key={`dyn-opts-${idx}`}
                                >
                                    <Grid
                                        item
                                        style={{ flex: '1 1 0' }}
                                    >
                                        <TextFieldWV
                                            label={`Option ${(idx + this.indexOffset + 1)}`}
                                            value={o}
                                            onChange={value => {
                                                this.options[idx] = value
                                                this.props.onChange(this.options)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            onClick={() => this.handleRemoveItem(idx)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default DynamicAttributeSelectOptions
