import React from 'react'
import AppRoot from './app-root'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter } from 'react-router-dom'

const AppRootWithSnacks = () => (
  <SnackbarProvider
    maxSnack={3}
    autoHideDuration={2000}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
    <BrowserRouter>
      <AppRoot />
    </BrowserRouter>

  </SnackbarProvider>
)

export default AppRootWithSnacks