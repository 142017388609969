import React from 'react'
import { observable, computed, toJS, reaction } from 'mobx'
import { observer } from 'mobx-react'
import {
    Dialog, Grid,
    DialogContent, DialogActions,
    DialogTitle, IconButton
} from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import StoresPage from '../stores/StorePage'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {
    Device, emptyDevice,
    Undoer, UndoerState,
    emptyUndoerState, DiC,
    PageService, entities,
    PageContext, DataContext,
    deviceConstraints,
    FormState
} from '@shared/common'
import {
    FAB, TextFieldWV,
    SelectViaDialog,
    ConfirmationDialog,
    DynamicAttributeAdornment,
    UndoButtons, DialogButtons
} from '@shared/ui'
import { closeUnsavedForm } from '../shared/UnsavedDialog'

interface IProps extends WithSnackbarProps {
    device?: Device
}

@observer class DeviceForm extends React.Component<IProps> {
    @observable device: Device = emptyDevice as any
    @observable formState: FormState<Device> = this.pageService.createFormState()

    undoer: Undoer<Device> = new Undoer<Device>()
    debounceUndoer: () => void
    @observable undoerState: UndoerState = emptyUndoerState

    componentDidMount() {
        this.debounceUndoer = AwesomeDebouncePromise(() => { this.undoer.Push(toJS(this.device), this.undoerState) }, 500)
        reaction(
            () => toJS(this.device),
            () => {
                if (!this.undoerState.dontReact) {
                    this.undoerState.waiting = true
                    this.debounceUndoer()
                }
            },
        )
    }

    get pageService() {
        return DiC.get<PageService<Device>>(entities.DeviceSrv)
    }

    get ctx() {
        return DiC.get<PageContext>(entities.DeviceCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    @computed get isEditMode() {
        return !!this.props.device
    }

    @computed get dynamicAttributes() {
        return this.dataCtx.dynamicAttributes.ofEntity('device')
    }

    @computed get canCreate() {
        return this.pageService.getAbility('create')
    }

    @computed get canUpdate() {
        return this.pageService.getAbility('update')
    }

    handleClear = () => {
        this.formState.clearErrors()
        if (this.isEditMode) {
            this.handleFillDevice(toJS(this.props.device!))
        } else {
            this.handleFillDevice(emptyDevice as any)
        }
    }

    handleFillDevice = (newDevice: Device) => {
        this.device = newDevice
        this.device.dynamicAttributes = new Map(Object.entries(newDevice.dynamicAttributes)) as any
    }

    openForm = () => {
        if (this.isEditMode) {
            this.handleFillDevice(toJS(this.props.device!))
        } else {
            this.handleFillDevice(toJS(this.device))
        }
        this.formState.openForm()
    }

    closeForm = () => {
        closeUnsavedForm(this.isEditMode, () => this.formState.closeForm(), toJS(!!this.props.device ? this.props.device : emptyDevice), toJS(this.device))
    }

    handleFillSelect = () => {
        this.dataCtx.stores.selected.clear()
        if (!!this.device.store && this.device.store.id > 0 && this.dataCtx.stores.all.has(this.device.store.id.toString())) {
            this.dataCtx.stores.selected[0] = this.dataCtx.stores.all.get(this.device.store.id.toString())!
        }
    }

    handleSelect = () => {
        if (this.dataCtx.stores.selected && this.dataCtx.stores.selected.length > 0) {
            this.device.store = this.dataCtx.stores.selected[0]
        }
        this.formState.clearErrorsForField('store')
    }

    handleClearSelect = () => {
        this.device.store = null
        this.dataCtx.stores.selected.clear()
        this.formState.clearErrorsForField('store')
    }

    handleSubmit = () => {
        this.formState.save(this.device, deviceConstraints)
            .then(res => {
                if (res.isOk) {
                    this.formState.closeForm()
                    this.undoerState.dontReact = true
                    this.device = emptyDevice as any
                    this.undoerState.dontReact = false
                    this.undoer = new Undoer<Device>()
                    this.pageService.paginatedList.refresh()
                } else {
                    this.props.enqueueSnackbar('You have filled one or more fields incorrectly.', { variant: 'error' })
                }
            })
    }

    render() {
        if ((!this.isEditMode || !this.canUpdate) && (!this.canCreate || this.isEditMode)) {
            return (<React.Fragment />)
        }
        return (
            <React.Fragment>
                {this.isEditMode ?
                    <IconButton
                        aria-label="Edit"
                        onClick={this.openForm}
                    >
                        <Edit />
                    </IconButton>
                    :
                    <FAB
                        ariaLabel="Add"
                        onClick={this.openForm}
                        disabled={this.ctx.isLoading}
                    >
                        <Add />
                        Add Device
                    </FAB>
                }
                <Dialog
                    open={this.formState.formOpen}
                    onClose={this.closeForm}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title">
                        {this.isEditMode ? 'Edit' : 'Add'} Device
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="column">
                            <Grid item style={{ width: '100%' }} >
                                <TextFieldWV
                                    required
                                    label="Name"
                                    errors={this.formState.getErrorsForField('name')}
                                    value={this.device.name}
                                    onChange={value => {
                                        this.device.name = value
                                        this.formState.clearErrorsForField('name')
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ width: '100%' }}>
                                <TextFieldWV
                                    required
                                    label="MAC address"
                                    errors={this.formState.getErrorsForField('mac')}
                                    value={this.device.mac}
                                    onChange={value => {
                                        this.device.mac = value
                                        this.formState.clearErrorsForField('mac')
                                    }}
                                />
                            </Grid>
                            {/* <Grid item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="Login"
                                    errors={this.formState.getErrorsForField("login")}
                                    value={this.device.login}
                                    onChange={value => {
                                        this.device.login = value
                                        this.formState.clearErrorsForField("login")
                                    }}
                                />
                            </Grid>
                            <Grid item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="Password"
                                    errors={this.formState.getErrorsForField("password")}
                                    value={this.device.password}
                                    onChange={value => {
                                        this.device.password = value
                                        this.formState.clearErrorsForField("password")
                                    }}
                                />
                            </Grid>
                            <Grid item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    label="IP address"
                                    errors={this.formState.getErrorsForField("ip")}
                                    value={this.device.ip}
                                    onChange={value => {
                                        this.device.ip = value
                                        this.formState.clearErrorsForField("ip")
                                    }}
                                />
                            </Grid>
                            <Grid item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    label="Internal IP"
                                    errors={this.formState.getErrorsForField("internalIp")}
                                    value={this.device.internalIp}
                                    onChange={value => {
                                        this.device.internalIp = value
                                        this.formState.clearErrorsForField("internalIp")
                                    }}
                                />
                            </Grid>
                            <Grid item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    label="Gateway"
                                    errors={this.formState.getErrorsForField("gateway")}
                                    value={this.device.gateway}
                                    onChange={value => {
                                        this.device.gateway = value
                                        this.formState.clearErrorsForField("gateway")
                                    }}
                                />
                            </Grid>
                            <Grid item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    label="Current State"
                                    errors={this.formState.getErrorsForField("currentState")}
                                    value={this.device.currentState}
                                    onChange={value => {
                                        this.device.currentState = value
                                        this.formState.clearErrorsForField("currentState")
                                    }}
                                />
                            </Grid> */}
                            <Grid item style={{ width: '100%' }}>
                                <SelectViaDialog
                                    errors={this.formState.getErrorsForField('store')}
                                    label="Store"
                                    placeholder="Select Store"
                                    onOpen={this.isEditMode ? (openModal) => ConfirmationDialog('Do you really want change store?',
                                        '', () => {
                                            this.handleFillSelect()
                                            openModal()
                                        }) : (openModal) => {
                                            this.handleFillSelect()
                                            openModal()
                                        }}
                                    value={!!this.device.store ? [{ value: this.device.store.id.toString(), label: this.device.store.name }] : undefined}
                                    onSelected={this.handleSelect}
                                    onClear={this.isEditMode ? () => ConfirmationDialog('Do you really want clear store?',
                                        '', this.handleClearSelect) : this.handleClearSelect}
                                >
                                    <StoresPage
                                        selectConfig={{
                                            type: 'single',
                                            usage: 'create-or-update'
                                        }}
                                    />
                                </SelectViaDialog>
                            </Grid>
                            {this.dynamicAttributes ?
                                <DynamicAttributeAdornment
                                    attributes={this.dynamicAttributes}
                                    values={this.device.dynamicAttributes}
                                    errors={this.formState.errors}
                                />
                                : <React.Fragment />
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogButtons
                            onConfirm={this.handleSubmit}
                            confirmText="Save"
                            onCancel={this.closeForm}
                            onReset={this.handleClear}
                            resetText={`${this.isEditMode ? 'Reset' : 'Clear'} Fields`}
                            additionalActions={
                                <UndoButtons
                                    onUndo={() => {
                                        this.undoer.Undo(this.handleFillDevice, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    onRedo={() => {
                                        this.undoer.Redo(this.handleFillDevice, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    state={this.undoerState}
                                />
                            }
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withSnackbar(DeviceForm)
