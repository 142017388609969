import { types as t, Instance } from 'mobx-state-tree'
import { Playlist } from '../models'

export const PlaylistsRepository = t.model({
    all: t.map(Playlist),
    ordered: t.array(t.reference(Playlist)),
    selected: t.array(t.reference(Playlist)),
    filterSelected: t.array(t.reference(Playlist)),
})

export type PlaylistsRepository = Instance<typeof PlaylistsRepository>
