import { types as t, Instance } from 'mobx-state-tree'
import { ContentRecord } from '../models'

export const ContentRecordsRepository = t.model({
    all: t.map(ContentRecord),
    ordered: t.array(t.reference(ContentRecord)),
    selected: t.array(t.reference(ContentRecord)),
    filterSelected: t.array(t.reference(ContentRecord)),
})

export type ContentRecordsRepository = Instance<typeof ContentRecordsRepository>
