import React from 'react'
import { Route, Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { UIState } from '@shared/common'

type TParams = { id?: string | undefined; }
interface IProps extends RouteComponentProps<TParams> {
    path: string,
    uiState: UIState,
    children: React.ReactNode,
    exact?: boolean
}

const PrivateRoute: React.SFC<IProps> = ({ path, uiState, children, exact }) => (
    <Route
        path={path}
        exact={exact}
        render={() => {
            return uiState.authDataPresent() ? (children) : <Redirect to="/login" />
        }}
    />
)

export default withRouter(PrivateRoute)