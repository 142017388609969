import React from 'react'
import {
  Button, Dialog, Grid,
  DialogContent, DialogActions,
  DialogTitle, Fab
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { ISelectOption } from '@shared/common'
import { TextFieldWV, SelectWV, DateRangePickerWV } from '@shared/ui'

interface IProps {

}

@observer
class ZRequestForm extends React.Component<IProps> {
  @observable openForm: boolean = false
  @observable theme: string = ''
  @observable description: string = ''
  @observable freq: string = ''
  @observable stores: ISelectOption[] = []
  @observable contentType: ISelectOption[] = []
  @observable broadcast: ISelectOption[] = []
  @observable start?: Date
  @observable end?: Date

  get storesOptions() {
    return [
      { label: 'Москва, ул Чаянова, 14 (Азбука Вкуса)', value: '1' },
      { label: 'Москва, ул 2-ая Тверская-Ямская, 54 (Азбука Вкуса)', value: '2' },
      { label: 'Москва, ул Большая садовая, 5, стр. 1 (АВ Daily)', value: '3' },
    ]
  }

  get contentTypeOptions() {
    return [
      { label: 'Аудиоролик с музыкой (звучит между музыкальными треками)', value: '1' },
      { label: 'Cover аудиоролик (сообщение звучит поверх основной музыки)', value: '2' },
      { label: 'Видеоролик, анимация без звука', value: '3' },
      { label: 'Видеоролик, анимация со звуком', value: '4' },
      { label: 'Видеоролик с "живым" видео (люди в кадре, съемка, монтаж)', value: '5' },
    ]
  }

  get broadcastOptions() {
    return [
      { label: 'Торговый зал', value: '1' },
      { label: 'Улица или входная группа', value: '2' },
    ]
  }

  render() {
    return (
      <React.Fragment>
        <Fab
          color="primary"
          variant="extended"
          onClick={() => this.openForm = true}
          disabled={false}
        >
          <Add />
          Создать запрос
        </Fab>
        <Dialog
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth="sm"
          open={this.openForm}
          onClose={() => this.openForm = false}
        >
          <DialogTitle id="form-dialog-title">
            Новый запрос
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column">
              <Grid item >
                <TextFieldWV
                  required
                  label="Тема"
                  errors={undefined}
                  value={this.theme}
                  onChange={(v) => { this.theme = v }}
                />
              </Grid>
              <Grid item >
                <SelectWV
                  required
                  label="Магазины"
                  multiple
                  selectOptions={this.storesOptions}
                  errors={undefined}
                  value={this.stores}
                  onChange={(v) => { this.stores = v }}
                />
              </Grid>
              <Grid item >
                <TextFieldWV
                  required
                  label="Описание запроса"
                  multiline
                  rowsMax={4}
                  errors={undefined}
                  value={this.description}
                  onChange={(v) => { this.description = v }}
                />
              </Grid>
              <Grid item >
                <SelectWV
                  required
                  label="Тип ролика"
                  selectOptions={this.contentTypeOptions}
                  errors={undefined}
                  value={this.contentType}
                  onChange={(v) => { this.contentType = v }}
                />
              </Grid>
              <Grid item >
                <SelectWV
                  required
                  label="Где будет транслироваться ролик"
                  selectOptions={this.broadcastOptions}
                  errors={undefined}
                  value={this.broadcast}
                  onChange={(v) => { this.broadcast = v }}
                />
              </Grid>
              <Grid item>
                <DateRangePickerWV
                  label="Даты трансляции"
                  startDate={this.start}
                  endDate={this.end}
                  onChange={(start, end) => { this.start = start; this.end = end }}
                  onDatesReset={() => { }}
                />
              </Grid>
            </Grid>
            <Grid item >
              <TextFieldWV
                required
                label="Сколько раз в час транслируется ролик"
                errors={undefined}
                value={this.freq}
                onChange={(v) => { this.freq = v }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <div style={{ flex: '1 1 0' }} />
            <Button
              onClick={() => { this.openForm = false }}
              color="primary"
            >
              Отмена
            </Button>
            <Button
              onClick={() => { this.openForm = false }}
              color="primary"
            >
              Создать
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default ZRequestForm