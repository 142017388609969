import React from 'react'
import {
  CardHeader,
  CardContent, CardActions,
} from '@material-ui/core'
import { observer } from 'mobx-react'

interface IProps {
  header?: React.ReactNode
  subHeader?: React.ReactNode
  media?: React.ReactNode
  content: React.ReactNode
  actionsHeader?: JSX.Element[]
  actionsFooter?: JSX.Element[]
}

@observer class InfoCardContent extends React.Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <CardHeader
          title={this.props.header}
          subheader={this.props.subHeader}
          action={this.props.actionsHeader}
        />
        {this.props.media}
        <CardContent>
          {this.props.content}
        </CardContent>
        {!!this.props.actionsFooter ?
          <CardActions disableSpacing>
            {this.props.actionsFooter}
          </CardActions> : []
        }
      </React.Fragment>
    )
  }
}
export default InfoCardContent