import React from 'react'
import { observer } from 'mobx-react'
import {
    DynamicAttribute, PageService,
    PageContext
} from '@shared/common'
import {
    List, ListItem, ListItemText,
    IconButton, Dialog, DialogTitle,
    DialogContent, Grid, DialogActions,
    Button, Paper, ListItemSecondaryAction
} from '@material-ui/core'
import { Settings, Delete } from '@material-ui/icons'
import DynamicAttributeForm from './DynamicAttributeForm'
import ConfirmationDialod from '../ConfirmationDialog'
import { computed } from 'mobx'

interface IProps<A> {
    srv: PageService<A>
    ctx: PageContext
    dynamic?: DynamicAttribute[]
    relatedModel?: string
}

@observer class DynamicAttributeList<A> extends React.Component<IProps<A>> {
    @computed get canCreate() {
        return this.props.srv.getAbility('create', true)
    }

    @computed get canUpdate() {
        return this.props.srv.getAbility('update', true)
    }

    @computed get canDelete() {
        return this.props.srv.getAbility('delete', true)
    }

    openList = () => {
        this.props.srv.dynamicAttributes.openDynList()
    }

    closeList = () => {
        this.props.srv.dynamicAttributes.closeDynList()
    }

    handleDelete = (attribute: DynamicAttribute) => {
        this.props.srv.dynamicAttributes.deleteDynamicAttribute(attribute.id)
            .then(() => {
                this.props.srv.dynamicAttributes.fetchDynamicAttributes()
            })
    }

    render() {
        return (
            <React.Fragment>
                <IconButton
                    disabled={this.props.ctx.isLoading}
                    onClick={this.openList}
                >
                    <Settings />
                </IconButton>
                <Dialog
                    open={this.props.ctx.dynListOpen}
                    onClose={this.closeList}
                    aria-labelledby="dyn-list-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="dyn-list-dialog-title">Dynamic Attributes List</DialogTitle>
                    <DialogContent
                        style={{ backgroundColor: '#ebebeb' }}
                    >
                        <Grid
                            container
                            direction="column"
                            style={{ height: '100%' }}
                            alignItems="stretch"
                            wrap="nowrap"
                        >
                            <Paper>
                                <List
                                    style={{
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: '100%'
                                    }}
                                >
                                    {!!this.props.dynamic && this.props.dynamic.length > 0 ?
                                        this.props.dynamic.map(a => (
                                            <ListItem
                                                key={a.id}
                                            >
                                                <ListItemText
                                                    style={{ marginLeft: '8px' }}
                                                    primary={a.label !== '' ? a.label : a.name}
                                                    secondary={`${a.fieldTypeLabel()}, position: ${a.priority}`}
                                                />
                                                {(this.canDelete || this.canUpdate) && <ListItemSecondaryAction>
                                                    {this.canUpdate && <DynamicAttributeForm
                                                        srv={this.props.srv}
                                                        ctx={this.props.ctx}
                                                        attribute={a}
                                                        relatedModel={this.props.relatedModel}
                                                    />}
                                                    {this.canDelete && <IconButton
                                                        edge="end"
                                                        aria-label="Delete-dynamic"
                                                        onClick={() => ConfirmationDialod(`Delete "${a.label !== '' ? a.label : a.name}"?`,
                                                            'Attribute will be permanently deleted',
                                                            () => this.handleDelete(a))
                                                        }
                                                    >
                                                        <Delete />
                                                    </IconButton>}
                                                </ListItemSecondaryAction>}
                                            </ListItem>

                                        )) :
                                        <ListItem
                                            key="no-attrs"
                                        >
                                            <ListItemText
                                                style={{ marginLeft: '8px' }}
                                                primary="No Dynamic Attributes"
                                                secondary={this.canCreate ? 'You can add them by clicking on the button below' : undefined}
                                            />
                                        </ListItem>
                                    }
                                </List>
                            </Paper>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row">
                            <div style={{ flex: '1 1 0' }} />
                            {this.canCreate &&
                                <Grid
                                    item
                                    style={{ marginTop: '-40px' }}
                                >
                                    <DynamicAttributeForm
                                        srv={this.props.srv}
                                        ctx={this.props.ctx}
                                        relatedModel={this.props.relatedModel}
                                    />
                                </Grid>}
                            <Grid
                                item
                                container
                                direction="row"
                                wrap="nowrap"
                                style={{ flex: '1 1 0' }}
                            >
                                <div style={{ flex: '1 1 0' }} />
                                <Grid item >
                                    <Button onClick={this.closeList} color="primary" variant="outlined" style={{ marginRight: '16px' }}>
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default DynamicAttributeList
