import React from 'react'
import { observable, computed, toJS, reaction } from 'mobx'
import { observer } from 'mobx-react'
import {
    Dialog, Grid,
    DialogContent, DialogActions,
    DialogTitle,
    IconButton,
    Typography,
    Tabs, Tab, Badge
} from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import PerformanceItem from './PerformanceItem'
import ClientsPage from '../clients/ClientsPage'
import ContentRecordItem from './ContentRecordItem'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {
    Content, emptyContent,
    FormState, Undoer,
    UndoerState, emptyUndoerState,
    DiC, PageService, entities,
    PageContext, DataContext,
    contentConstraints,
    contentWithClientConstraints,
    emptyPerformance, ContentRecord,
    emptyContentRecord, ContentTypes,
    Performance
} from '@shared/common'
import {
    FAB, UndoButtons, MultilineAdd,
    TextFieldWV, ConfirmationDialog,
    SelectViaDialog,
    DynamicAttributeAdornment,
    DialogButtons, ProgressBar
} from '@shared/ui'
import { closeUnsavedForm } from '../shared/UnsavedDialog'

interface IProps extends WithSnackbarProps {
    content?: Content
    relatedModel: string
}

@observer class ContentForm extends React.Component<IProps> {
    @observable content: Content = emptyContent as any
    @observable formState: FormState<Content> = this.pageService.createFormState()
    @observable selectedTab = 0

    undoer: Undoer<Content> = new Undoer<Content>()
    debounceUndoer: () => void
    @observable undoerState: UndoerState = emptyUndoerState

    componentDidMount() {
        this.debounceUndoer = AwesomeDebouncePromise(() => { this.undoer.Push(toJS(this.content), this.undoerState) }, 500)
        reaction(
            () => toJS(this.content),
            () => {
                if (!this.undoerState.dontReact) {
                    this.undoerState.waiting = true
                    this.debounceUndoer()
                }
            },
        )
    }

    get pageService() {
        return DiC.get<PageService<Content>>(entities.ContentSrv)
    }

    get ctx() {
        return DiC.get<PageContext>(entities.ContentCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    @computed get dynamicAttributes() {
        return this.dataCtx.dynamicAttributes.ofEntity(!!this.content.type ? this.content.type : this.props.relatedModel)
    }

    @computed get isEditMode() {
        return !!this.props.content
    }

    @computed get hasBaseErrors() {
        let errCount = this.formState.errors.size
        this.hasPerformancesErrors && (errCount -= 1)
        this.hasRecordsErrors && (errCount -= 1)
        this.hasLinksErrors && (errCount -= 1)
        return errCount > 0
    }

    @computed get hasPerformancesErrors() {
        return this.formState.errors.has('performances')
    }

    @computed get hasRecordsErrors() {
        return this.formState.errors.has('contentRecords')
    }

    @computed get hasLinksErrors() {
        return this.formState.errors.has('ContentLink')
    }

    @computed get canCreate() {
        return this.pageService.getAbility('create')
    }

    @computed get canUpdate() {
        return this.pageService.getAbility('update')
    }

    handleClear = () => {
        this.formState.clearErrors()
        if (this.isEditMode) {
            this.handleFillContent(toJS(this.props.content!))
        } else {
            this.handleFillContent(emptyContent as any)
        }
    }

    handleFillContent = (newContent: Content) => {
        this.content = newContent
        this.content.dynamicAttributes = new Map(Object.entries(newContent.dynamicAttributes)) as any
        this.content.performances.clear()
        newContent.performances.forEach(p => {
            if (!!p.performer && p.performer.id > 0 && this.dataCtx.performers.all.has(p.performer.id.toString())) {
                p.performer = this.dataCtx.performers.all.get(p.performer.id.toString())
            }
            this.content.performances.push(p)
        })
    }

    openForm = () => {
        this.content.type = this.props.relatedModel
        if (this.isEditMode) {
            this.handleFillContent(toJS(this.props.content!))
        } else {
            this.handleFillContent(toJS(this.content))
        }
        this.formState.openForm()
    }

    closeForm = () => {
        closeUnsavedForm(this.isEditMode, () => this.formState.closeForm(), toJS(!!this.props.content ? this.props.content : emptyContent), toJS(this.content))
    }

    handleFillSelect = () => {
        this.dataCtx.clients.selected.clear()
        this.content.clients.forEach(client => {
            if (!!client && client.id > 0 && this.dataCtx.clients.all.has(client.id.toString())) {
                this.dataCtx.clients.selected.push(this.dataCtx.clients.all.get(client.id.toString())!)
            }
        })
    }

    handleSelect = () => {
        if (this.dataCtx.clients.selected && this.dataCtx.clients.selected.length > 0) {
            this.content.clients = toJS(this.dataCtx.clients.selected)
        }
        this.formState.clearErrorsForField('clients')
    }

    handleClearSelect = () => {
        this.content.clients.clear()
        this.dataCtx.clients.selected.clear()
        this.formState.clearErrorsForField('clients')
    }

    handleSubmit = () => {
        let constraints = contentConstraints
        if (this.content.type === 'Advertisement') {
            constraints = contentWithClientConstraints
        }
        this.formState.save(this.content, constraints)
            .then(res => {
                if (res.isOk) {
                    this.formState.closeForm()
                    this.selectedTab = 0
                    this.undoerState.dontReact = true
                    this.content = emptyContent as any
                    this.undoerState.dontReact = false
                    this.undoer = new Undoer<Content>()
                    this.pageService.paginatedList.refresh()
                } else {
                    this.props.enqueueSnackbar('You have filled one or more fields incorrectly.', { variant: 'error' })
                }
            })
    }

    handleTabChange = (e: object, value: number) => {
        this.selectedTab = value
    }

    handeAddPerformance = () => {
        let perfomance: Performance = emptyPerformance
        this.content.performances.push(perfomance)
    }

    handeRemovePerformance = (idx: number) => {
        this.formState.clearErrorsForField('performances')
        this.content.performances.splice(idx, 1)
    }

    handeAddRecord = () => {
        let record: ContentRecord = emptyContentRecord as any
        this.content.contentRecords.push(record)
    }

    handeSetForPlayFalse = () => {
        this.content.contentRecords.forEach(cr => cr.forPlay = false)
    }

    handeRemoveRecord = (idx: number) => {
        this.formState.clearErrorsForField('contentRecords')
        this.content.contentRecords.splice(idx, 1)
    }

    render() {
        if ((!this.isEditMode || !this.canUpdate) && (!this.canCreate || this.isEditMode)) {
            return (<React.Fragment />)
        }
        return (
            <React.Fragment>
                {this.isEditMode ?
                    <IconButton
                        aria-label="Edit"
                        onClick={this.openForm}
                    >
                        <Edit />
                    </IconButton>
                    :
                    <FAB
                        ariaLabel="Add"
                        onClick={this.openForm}
                        disabled={this.ctx.isLoading}
                    >
                        <Add />
                        Add Content
                    </FAB>
                }
                <Dialog
                    open={this.formState.formOpen}
                    onClose={this.closeForm}
                    PaperProps={{
                        style: {
                            height: 'calc(100% - 96px)'
                        }
                    }}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        {this.isEditMode ? 'Edit' : 'Add'} Content
                        <Tabs
                            value={this.selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            onChange={this.handleTabChange}
                        >
                            <Tab
                                label={
                                    <Badge
                                        style={{
                                            padding: '0 8px'
                                        }}
                                        color="error"
                                        variant="dot"
                                        invisible={!this.hasBaseErrors}
                                    >
                                        Base info
                                    </Badge>
                                }
                            />
                            <Tab
                                label={
                                    <Badge
                                        style={{
                                            padding: '0 8px'
                                        }}
                                        color="error"
                                        variant="dot"
                                        invisible={!this.hasPerformancesErrors}
                                    >
                                        Performances
                                    </Badge>
                                }
                            />
                            <Tab
                                label={
                                    <Badge
                                        style={{
                                            padding: '0 8px'
                                        }}
                                        color="error"
                                        variant="dot"
                                        invisible={!this.hasRecordsErrors}
                                    >
                                        Records
                                    </Badge>
                                }
                            />
                            {/* <Tab
                                label={
                                    <Badge
                                        style={{
                                            padding: '0 8px'
                                        }}
                                        color="error"
                                        variant="dot"
                                        invisible={!this.hasLinksErrors}
                                    >
                                        Links
                                    </Badge>
                                }
                            /> */}
                        </Tabs>
                    </DialogTitle>
                    <DialogContent>
                        {this.selectedTab === 0 && this.renderBaseFields()}
                        {this.selectedTab === 1 && this.renderPerformanceFields()}
                        {this.selectedTab === 2 && this.renderRecordsFields()}
                        {/* {this.selectedTab == 3 && this.renderLinksFields()} */}
                    </DialogContent>
                    <ProgressBar loading={this.ctx.isLoading} percent={this.formState.uploadingPercent} waitOnZero />
                    <DialogActions>
                        <DialogButtons
                            onConfirm={this.handleSubmit}
                            confirmText="Save"
                            onCancel={this.closeForm}
                            onReset={this.handleClear}
                            resetText={`${this.isEditMode ? 'Reset' : 'Clear'} Fields`}
                            additionalActions={
                                <UndoButtons
                                    onUndo={() => {
                                        this.undoer.Undo(this.handleFillContent, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    onRedo={() => {
                                        this.undoer.Redo(this.handleFillContent, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    state={this.undoerState}
                                />
                            }
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }

    renderPerformanceFields() {
        let performancesError = this.formState.getErrorsForField('performances')
        return (
            <MultilineAdd<Performance>
                placeholder="No performances. You can add them by clicking the button on the left."
                collection={this.content.performances}
                renderItem={(entity, idx) => {
                    return (
                        <PerformanceItem
                            key={idx}
                            performance={entity}
                            errors={!!performancesError && !!performancesError[0] ? performancesError[0][idx] : undefined}
                        />
                    )
                }}
                onAdd={this.handeAddPerformance}
                onRemove={(idx) => ConfirmationDialog('Delete confirmation',
                    'Do you really want to delete performance?', () => { this.handeRemovePerformance(idx) })}
            />
        )
    }

    renderRecordsFields() {
        let recordError = this.formState.getErrorsForField('contentRecords')
        return (
            <Grid container direction="column">
                {this.content.contentRecords.length > 0 &&
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="subtitle1"
                            style={{
                                marginBottom: '-16px',
                                paddingLeft: '52px'
                            }}
                        >
                            For play
                        </Typography>
                    </Grid>
                }
                <MultilineAdd<ContentRecord>
                    placeholder="No Records. You can add them by clicking the button on the left."
                    collection={this.content.contentRecords}
                    renderItem={(entity, idx) => {
                        return (
                            <ContentRecordItem
                                key={idx}
                                record={entity}
                                errors={!!recordError && !!recordError[0] ? recordError[0][idx] : undefined}
                                onPlayChange={this.handeSetForPlayFalse}
                            />
                        )
                    }}
                    onAdd={this.handeAddRecord}
                    onRemove={(idx) => ConfirmationDialog('Delete confirmation',
                        'Do you really want to delete content record?', () => { this.handeRemoveRecord(idx) })}
                />
            </Grid>
        )
    }

    renderBaseFields() {
        return <Grid container direction="column">
            <Grid
                item
                style={{
                    flex: '1 1 auto',
                    width: '100%'
                }}
            >
                <TextFieldWV
                    required
                    label="Name"
                    errors={this.formState.getErrorsForField('name')}
                    value={this.content.name}
                    onChange={value => {
                        this.content.name = value
                        this.formState.clearErrorsForField('name')
                    }}
                />
            </Grid>
            <Grid
                item
                style={{
                    flex: '1 1 auto',
                    width: '100%'
                }}
            >
                <TextFieldWV
                    required
                    label="Select Type"
                    selectOptions={ContentTypes}
                    errors={this.formState.getErrorsForField('type')}
                    onOpen={(openModal) => ConfirmationDialog('Do you really want change content type?',
                        'All fields will be CLEARED', openModal)}
                    //When using as select - send id as value!
                    value={!!this.content.type ? this.content.type : ''}
                    onChange={(value: string) => {
                        if (this.content.type !== value) {
                            this.content.dynamicAttributes.forEach((v, k, m) => m.set(k, ''))
                        }
                        this.content.type = value
                        this.formState.clearErrorsForField('type')
                    }}
                />
            </Grid>
            <Grid
                item
                style={{
                    flex: '1 1 auto',
                    width: '100%'
                }}
            >
                <TextFieldWV
                    label="БО№"
                    type="number"
                    errors={this.formState.getErrorsForField('boNumber')}
                    value={this.content.boNumber || ''}
                    onChange={value => {
                        this.content.boNumber = parseFloat(value)
                        if (isNaN(this.content.boNumber)) {
                            this.content.boNumber = undefined
                        }
                        this.formState.clearErrorsForField('boNumber')
                    }}
                />
            </Grid>
            <Grid
                item
                style={{
                    flex: '1 1 auto',
                    width: '100%'
                }}
            >
                <SelectViaDialog
                    required={this.content.type === 'Advertisement'}
                    errors={this.formState.getErrorsForField('clients')}
                    label="Clients"
                    placeholder="Select Clients"
                    onOpen={this.content.type !== 'Advertisement' ? (openModal) => ConfirmationDialog('Do you really want change clients?',
                        'Сontent will ONLY be available to selected clients!', () => {
                            this.handleFillSelect()
                            openModal()
                        }) : (openModal) => {
                            this.handleFillSelect()
                            openModal()
                        }}
                    value={!!this.content.clients ? this.content.clients.map(c => {
                        return {
                            value: c.id.toString(),
                            label: c.name
                        }
                    }) : undefined}
                    onSelected={this.handleSelect}
                    onClear={this.content.type !== 'Advertisement' ? () => ConfirmationDialog('Do you really want clear clients?',
                        'Сontent will be available to ALL clients!', this.handleClearSelect) : this.handleClearSelect}
                    multiple
                >
                    <ClientsPage
                        selectConfig={{
                            type: 'multiple',
                            usage: 'create-or-update'
                        }}
                    />
                </SelectViaDialog>
            </Grid>
            {/* <Grid item>
                <FileDialogWV
                    fileUrl={this.content.demoUrl}
                    fileName={!!this.demoFile ? this.demoFile.name : undefined}
                    errors={this.formState.getErrorsForField("demoUrl")}
                    onChange={file => {
                        this.demoFile = file
                        this.content.demoUrl = !!this.demoFile ? URL.createObjectURL(this.demoFile) : ''
                        this.formState.clearErrorsForField("demoUrl")
                    }}
                    label="Demo File"
                />
            </Grid> */}
            <Grid
                item
                style={{
                    flex: '1 1 auto',
                    width: '100%'
                }}
            >
                <TextFieldWV
                    label="Comment"
                    errors={this.formState.getErrorsForField('comment')}
                    value={this.content.comment}
                    onChange={value => {
                        this.content.comment = value
                        this.formState.clearErrorsForField('comment')
                    }}
                />
            </Grid>
            {this.dynamicAttributes ?
                <DynamicAttributeAdornment
                    attributes={this.dynamicAttributes}
                    values={this.content.dynamicAttributes}
                    errors={this.formState.errors}
                />
                : <React.Fragment />
            }
        </Grid >
    }
}

export default withSnackbar(ContentForm)
