import { types as t, Instance } from 'mobx-state-tree'
import { Client } from '../models'

export const ClientsRepository = t.model({
    all: t.map(Client),
    ordered: t.array(t.reference(Client)),
    selected: t.array(t.reference(Client)),
    filterSelected: t.array(t.reference(Client)),
})

export type ClientsRepository = Instance<typeof ClientsRepository>
