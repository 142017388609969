import React from 'react'

import {
    Client, DynamicAttribute,
    IAttribute, generateDynAttrs,
    DataContext
} from '@shared/common'
import moment from 'moment'

const ClientAttributes = (dataCtx: DataContext) => {
    let attributes: IAttribute<Client>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            value: (d: Client) => d.id,
            display: (d: Client) => d.id || '',
        },
        {
            name: 'logoUrl',
            label: 'Logo',
            show: true,
            display: (d: Client) => { return (<img style={{ maxHeight: '50px', maxWidth: '200px' }} src={d.logoUrl} />) },
            value: (d: Client) => d.logoUrl,
        },
        {
            name: 'name',
            label: 'Name',
            show: true,
            display: (d: Client) => d.name || '',
            value: (d: Client) => d.name,
        },
        {
            name: 'phone',
            label: 'Phone',
            show: true,
            display: (d: Client) => d.phone || '',
            value: (d: Client) => d.phone,
        },
        {
            name: 'corpEmail',
            label: 'EMail',
            show: true,
            display: (d: Client) => d.corpEmail || '',
            value: (d: Client) => d.corpEmail,
        },
        {
            name: 'address',
            label: 'Address',
            show: true,
            display: (d: Client) => d.address || '',
            value: (d: Client) => d.address,
        },
        {
            name: 'domainName',
            label: 'Domain Name',
            show: true,
            display: (d: Client) => d.domainName || '',
            value: (d: Client) => d.domainName,
        },
        {
            name: 'created_at',
            label: 'Created',
            show: true,
            display: (d: Client) => d.createdAt ? moment(d.createdAt).format('DD.MM.YYYY') : '',
            value: (d: Client) => d.createdAt,
        },
        {
            name: 'updated_at',
            label: 'Updated',
            show: true,
            display: (d: Client) => d.updatedAt ? moment(d.updatedAt).format('DD.MM.YYYY') : '',
            value: (d: Client) => d.updatedAt,
        },
    ]

    let clientDynamic: DynamicAttribute[] = dataCtx.dynamicAttributes.ofEntity('client')
    const dyn: IAttribute<Client>[] = generateDynAttrs(clientDynamic)

    return attributes.concat(dyn)
}

export default ClientAttributes