import { types as t, Instance } from 'mobx-state-tree'
import { Client } from '.'

export type IActionType = 'update' | 'create' | 'read' | 'delete'

export const Ability = t.model('ability', {
    model: t.string,
    create: t.optional(t.boolean, false),
    update: t.optional(t.boolean, false),
    read: t.optional(t.boolean, false),
    delete: t.optional(t.boolean, false),
})

export type Ability = Instance<typeof Ability>

export function getAbilityByUser(aUser: AUser, entity: string, action: IActionType, dynamic?: boolean): boolean {
    let modelName = dynamic ? `dynamic_attribute.${entity}` : entity
    for (let i = 0; i < aUser.abilities.length; i++) {
        const ability = aUser.abilities[i]
        if (ability.model === 'all' || ability.model === modelName) {
            switch (action) {
                case 'create':
                    return ability.create
                case 'read':
                    return ability.read
                case 'update':
                    return ability.update
                case 'delete':
                    return ability.delete
                default: return false
            }
        }
    }
    return false
}

export const AUser = t.model('a_user', {
    id: t.identifierNumber,
    email: t.string,
    abilities: t.optional(t.array(Ability), []),
    selectedClient: t.maybe(t.reference(Client))
})

export const emptyAUser = {
    id: 0,
    email: '',
    abilities: [],
}

export type AUser = Instance<typeof AUser>