
import moment from 'moment'
import { IAttribute, Playlist } from '@shared/common'

const PlaylistAttributes = () => {
    let attributes: IAttribute<Playlist>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            value: (l: Playlist) => l.id,
            display: (l: Playlist) => l.id || '',
        },
        {
            name: 'name',
            label: 'Name',
            show: true,
            value: (l: Playlist) => l.name,
            display: (l: Playlist) => l.name || '',
        },
        {
            name: 'playlistType',
            label: 'Type',
            show: true,
            value: (l: Playlist) => l.playlistType ? l.playlistType.name : '',
            display: (l: Playlist) => l.playlistType ? l.playlistType.name : '',
        },
        {
            name: 'created_at',
            label: 'Created',
            show: true,
            display: (d: Playlist) => d.createdAt ? moment(d.createdAt).format('DD.MM.YYYY') : '',
            value: (d: Playlist) => d.createdAt,
        },
        {
            name: 'updated_at',
            label: 'Updated',
            show: true,
            display: (d: Playlist) => d.updatedAt ? moment(d.updatedAt).format('DD.MM.YYYY') : '',
            value: (d: Playlist) => d.updatedAt,
        },
    ]

    return attributes
}

export default PlaylistAttributes