import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend } from '../services/utils'

export const PlaylistType = t
    .model('playlist_type', {
        id: t.identifierNumber,
        name: t.optional(t.string, ''),
    })

export type PlaylistType = Instance<typeof PlaylistType>

export const emptyPlaylistType = {
    id: 0,
    name: '',
}

export const playlistTypeConstraints = {
    name: { presence: { allowEmpty: false } },
}

export const PlaylistTypeSchema = new schema.Entity('playlist_types')

export function storePlaylistType(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const playlistTypes = norm.entities.playlist_types
    if (!!playlistTypes && Object.keys(playlistTypes).length > 0) {
        FixNullsToUndefinedFromBackend(playlistTypes)
        dataContext.playlistTypes.all.merge(playlistTypes)
        norm.entities.playlist_types = undefined
    }
}