import React from 'react'
import { TextField, MenuItem, InputAdornment, Tooltip } from '@material-ui/core'
import { ISelectOption } from '@shared/common'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { amber } from '@material-ui/core/colors'
import { Save } from '@material-ui/icons'

const warningColor = amber[600]

interface IProps {
    value?: string | number
    originalValue?: string | number
    errors?: string[]
    readonly?: boolean
    onChange(value: string): void
    onOpen?: (handleOpen: () => void) => void
    label: string
    type?: string
    required?: boolean
    selectOptions?: ISelectOption[]
    multiline?: boolean
    rowsMax?: number
}

@observer
class TextFieldWV extends React.Component<IProps> {
    @observable isOpen: boolean = false

    handleOpen = () => {
        if (!!this.props.onOpen) {
            this.props.onOpen(() => { this.isOpen = true })
        } else {
            this.isOpen = true
        }
    }

    handleClose = () => {
        this.isOpen = false
    }

    render() {
        let warning = typeof this.props.originalValue !== 'undefined' && typeof this.props.value !== 'undefined' && this.props.originalValue !== this.props.value
        return (
            <TextField
                rowsMax={this.props.rowsMax}
                multiline={this.props.multiline}
                disabled={!!this.props.readonly ? this.props.readonly : false}
                select={!!this.props.selectOptions ? true : undefined}
                SelectProps={!!this.props.onOpen ? {
                    open: this.isOpen,
                    onOpen: this.handleOpen,
                    onClose: this.handleClose
                } : {}}
                InputProps={warning ? {
                    startAdornment: (
                        <InputAdornment position="start">
                            <Tooltip
                                title={`Field changed and not saved.\nOriginal value: ${!!this.props.originalValue ? this.props.originalValue : 'empty'}`}
                            >
                                <Save style={{ color: warningColor }} />
                            </Tooltip>
                        </InputAdornment>
                    ),
                } : {}}
                type={this.props.type}
                error={!!this.props.errors ? true : undefined}
                required={this.props.required}
                label={this.props.label}
                value={this.props.value !== undefined ? this.props.value : ''}
                fullWidth
                onChange={e => this.props.onChange(e.target.value)}
                helperText={!!this.props.errors ? this.props.errors.join(', ') : undefined}
            >
                {!!this.props.selectOptions ? this.props.selectOptions.length === 0 ? <MenuItem value="" disabled>
                    <em>No options</em>
                </MenuItem> :
                    this.props.selectOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    )) : []}
            </TextField>
        )
    }
}

export default TextFieldWV