import React from 'react'
import { IAttribute, Performer } from '@shared/common'
import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { InfoCardContent, AttributesList } from '@shared/ui'

interface IProps {
    attributes: IAttribute<Performer>[]
    entity: Performer
}

const PerformerInfo: React.FunctionComponent<IProps> = ({ entity, attributes }) => (
    <React.Fragment>
        <InfoCardContent
            header="Performer"
            //subHeader={`ID = ${entity.id}`}
            subHeader={entity.id > 0 ?
                <Link component={RouterLink} to={`/performers/${entity.id}`} target="_blank">
                    {`${window.location.origin}/performers/${entity.id}`}
                </Link> : undefined
            }
            content={
                <AttributesList
                    entity={entity}
                    attrs={attributes.filter(a => a.show)}
                />
            }
        />
    </React.Fragment>

)

export default PerformerInfo
