import React from 'react'
import { observable, computed, toJS, reaction } from 'mobx'
import { observer } from 'mobx-react'
import {
    Dialog, Grid,
    DialogContent, DialogActions,
    DialogTitle, IconButton
} from '@material-ui/core'

import { Add, Edit } from '@material-ui/icons'

import AddressesPage from '../addresses/AddressPage'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {
    emptyLocation, FormState,
    Undoer, UndoerState,
    emptyUndoerState, DiC,
    PageService, entities,
    PageContext, DataContext,
    locationConstraints, Location
} from '@shared/common'
import {
    FAB, TextFieldWV,
    SelectViaDialog,
    DynamicAttributeAdornment,
    UndoButtons, DialogButtons
} from '@shared/ui'
import { closeUnsavedForm } from '../shared/UnsavedDialog'

interface IProps extends WithSnackbarProps {
    location?: Location
}

@observer class LocationForm extends React.Component<IProps> {
    @observable location: Location = emptyLocation as any
    @observable formState: FormState<Location> = this.pageService.createFormState()

    undoer: Undoer<Location> = new Undoer<Location>()
    debounceUndoer: () => void
    @observable undoerState: UndoerState = emptyUndoerState

    componentDidMount() {
        this.debounceUndoer = AwesomeDebouncePromise(() => { this.undoer.Push(toJS(this.location), this.undoerState) }, 500)
        reaction(
            () => toJS(this.location),
            () => {
                if (!this.undoerState.dontReact) {
                    this.undoerState.waiting = true
                    this.debounceUndoer()
                }
            },
        )
    }

    get pageService() {
        return DiC.get<PageService<Location>>(entities.LocationSrv)
    }

    get ctx() {
        return DiC.get<PageContext>(entities.LocationCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    @computed get dynamicAttributes() {
        return this.dataCtx.dynamicAttributes.ofEntity('location')
    }

    @computed get isEditMode() {
        return !!this.props.location
    }

    @computed get canCreate() {
        return this.pageService.getAbility('create')
    }

    @computed get canUpdate() {
        return this.pageService.getAbility('update')
    }

    handleClear = () => {
        this.formState.clearErrors()
        if (this.isEditMode) {
            this.handleFillLocation(toJS(this.props.location!))
        } else {
            this.handleFillLocation(emptyLocation as any)
        }
    }

    handleFillLocation = (newLocation: Location) => {
        this.location = newLocation
        this.location.dynamicAttributes = new Map(Object.entries(newLocation.dynamicAttributes)) as any
    }

    openForm = () => {
        if (this.isEditMode) {
            this.handleFillLocation(toJS(this.props.location!))
        } else {
            this.handleFillLocation(toJS(this.location))
        }
        this.formState.openForm()
    }

    closeForm = () => {
        closeUnsavedForm(this.isEditMode, () => this.formState.closeForm(), toJS(!!this.props.location ? this.props.location : emptyLocation), toJS(this.location))
    }

    handleFillSelect = () => {
        this.dataCtx.addresses.selected.clear()
        if (!!this.location.address && this.location.address.id > 0 && this.dataCtx.addresses.all.has(this.location.address.id.toString())) {
            this.dataCtx.addresses.selected[0] = this.dataCtx.addresses.all.get(this.location.address.id.toString())!
        }
    }

    handleSelect = () => {
        if (this.dataCtx.addresses.selected && this.dataCtx.addresses.selected.length > 0) {
            this.location.address = this.dataCtx.addresses.selected[0]
        }
        this.formState.clearErrorsForField('address')
    }

    handleClearSelect = () => {
        this.location.address = undefined
        this.dataCtx.addresses.selected.clear()
        this.formState.clearErrorsForField('address')
    }

    handleSubmit = () => {
        this.formState.save(this.location, locationConstraints)
            .then(res => {
                if (res.isOk) {
                    this.formState.closeForm()
                    this.undoerState.dontReact = true
                    this.location = emptyLocation as any
                    this.undoerState.dontReact = false
                    this.undoer = new Undoer<Location>()
                    this.pageService.paginatedList.refresh()
                } else {
                    this.props.enqueueSnackbar('You have filled one or more fields incorrectly.', { variant: 'error' })
                }
            })
    }

    render() {
        if ((!this.isEditMode || !this.canUpdate) && (!this.canCreate || this.isEditMode)) {
            return (<React.Fragment />)
        }
        return (
            <React.Fragment>
                {this.isEditMode ?
                    <IconButton
                        aria-label="Edit"
                        onClick={this.openForm}
                    >
                        <Edit />
                    </IconButton>
                    :
                    <FAB
                        ariaLabel="Add"
                        onClick={this.openForm}
                        disabled={this.ctx.isLoading}
                    >
                        <Add />
                        Add Location
                    </FAB>
                }
                <Dialog
                    open={this.formState.formOpen}
                    onClose={this.closeForm}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title">
                        {this.isEditMode ? 'Edit' : 'Add'} Location
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="column">
                            <Grid item style={{ width: '100%' }}>
                                <TextFieldWV
                                    required
                                    label="Name"
                                    errors={this.formState.getErrorsForField('name')}
                                    value={this.location.name}
                                    onChange={value => {
                                        this.location.name = value
                                        this.formState.clearErrorsForField('name')
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ width: '100%' }}>
                                <TextFieldWV
                                    required
                                    label="Room"
                                    errors={this.formState.getErrorsForField('room')}
                                    value={this.location.room}
                                    onChange={value => {
                                        this.location.room = value
                                        this.formState.clearErrorsForField('room')
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ width: '100%' }}>
                                <SelectViaDialog
                                    errors={this.formState.getErrorsForField('address')}
                                    required
                                    label="Address"
                                    placeholder="Select Address"
                                    onOpen={(openModal) => {
                                        this.handleFillSelect()
                                        openModal()
                                    }}
                                    value={!!this.location.address ? [{ value: this.location.address.id.toString(), label: this.location.address.name }] : undefined}
                                    onSelected={this.handleSelect}
                                    onClear={this.handleClearSelect}
                                >
                                    <AddressesPage
                                        selectConfig={{
                                            type: 'single',
                                            usage: 'create-or-update'
                                        }}
                                    />
                                </SelectViaDialog>
                            </Grid>
                            {this.dynamicAttributes ?
                                <DynamicAttributeAdornment
                                    attributes={this.dynamicAttributes}
                                    values={this.location.dynamicAttributes}
                                    errors={this.formState.errors}
                                />
                                : <React.Fragment />
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogButtons
                            onConfirm={this.handleSubmit}
                            confirmText="Save"
                            onCancel={this.closeForm}
                            onReset={this.handleClear}
                            resetText={`${this.isEditMode ? 'Reset' : 'Clear'} Fields`}
                            additionalActions={
                                <UndoButtons
                                    onUndo={() => {
                                        this.undoer.Undo(this.handleFillLocation, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    onRedo={() => {
                                        this.undoer.Redo(this.handleFillLocation, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    state={this.undoerState}
                                />
                            }
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment >
        )
    }
}

export default withSnackbar(LocationForm)
