import { types as t, Instance } from 'mobx-state-tree'
import { Performance } from '../models'

export const PerformancesRepository = t.model({
    all: t.map(Performance),
    ordered: t.array(t.reference(Performance)),
    selected: t.array(t.reference(Performance)),
    filterSelected: t.array(t.reference(Performance)),
})

export type PerformancesRepository = Instance<typeof PerformancesRepository>
