import React from 'react'
import { observer } from 'mobx-react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { PlaylistAdd, Add, Clear, Delete } from '@material-ui/icons'

interface IProps<A> {
    collection: A[]
    renderItem: (entity: A, idx: number) => React.ReactNode
    onAdd: () => void
    onRemove: (idx: number) => void
    secondary?: boolean
    divideItems?: boolean
    placeholder?: string
}
@observer class MultilineAdd<A> extends React.Component<IProps<A>>  {
    render() {
        return (
            <Grid item container direction="row" wrap="nowrap" >
                <Grid
                    item
                    style={Object.assign(
                        {
                            display: 'flex',
                            alignItems: 'flex-end',
                            width: 'auto',
                            marginTop: '16px',
                        },
                        this.props.secondary && {
                            backgroundColor: 'rgba(63, 81, 181, 0.08)',
                            borderRadius: '24px',
                        }
                    )}

                >
                    <IconButton
                        color="primary"
                        onClick={this.props.onAdd}
                    >
                        {this.props.secondary ? <PlaylistAdd /> : <Add />}
                    </IconButton>
                </Grid>
                <Grid item container direction="column" style={{ paddingLeft: '8px' }} >
                    {(!!this.props.placeholder && this.props.collection.length === 0) &&
                        <Grid
                            item
                            style={{
                                paddingTop: '26px'
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                color="textSecondary"
                            >
                                {this.props.placeholder}
                            </Typography>

                        </Grid>
                    }
                    {this.props.collection.map((entity, idx) => {
                        return (
                            <Grid
                                item
                                container
                                direction="row"
                                key={`${idx}`}
                                style={this.props.divideItems ? { marginTop: '24px' } : undefined}
                            >
                                <Grid
                                    item
                                    style={{
                                        flex: '1 1 auto',
                                        paddingTop: '8px'
                                    }}
                                >
                                    {this.props.renderItem(entity, idx)}
                                </Grid>
                                <Grid
                                    item
                                    style={{ padding: '16px 0 0 8px' }}
                                >
                                    <IconButton
                                        onClick={() => this.props.onRemove(idx)}
                                    >
                                        {this.props.secondary ? <Clear /> : <Delete />}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid >
        )
    }
}
export default MultilineAdd