import React from 'react'
import styled from 'styled-components'
import './program_creator.css'
import { observer } from 'mobx-react'
import { FormLabel, Checkbox, FormControl, Grid, Box } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import PlaylistsPage from '../playlists/PlaylistsPage'
import { SmallInputBox } from './ProgramCreatorShared'
import { observable, computed, autorun } from 'mobx'
import {
  ProgramCommandPlaylist, DiC,
  DataContext, entities
} from '@shared/common'
import { SelectViaDialog, TextFieldWV } from '@shared/ui'

interface IProp {
  data: ProgramCommandPlaylist,
  onRemove(): void,
  showRemoveCross: boolean
  errors?: { [field: string]: string[] }
}

const StyledBox = styled(({ ...otherProps }) => <Box {...otherProps} />)`
  display: inline-flex;
  >div {
    padding-right: 8px;
  }
`

@observer
class ProgramCreatorPlaylistSelector extends React.Component<IProp> {
  @observable isMouseOver: boolean = false

  componentDidMount() {
    autorun(() => {
      if (this.props.showRemoveCross) {
        this.isMouseOver = false
      }
    })
  }

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  handleFillPlaylistSelect = () => {
    this.dataCtx.playlists.selected.clear()
    if (!!this.props.data.playlist && this.props.data.playlist.id > 0 && this.dataCtx.playlists.all.has(this.props.data.playlist.id.toString())) {
      this.dataCtx.playlists.selected[0] = this.dataCtx.playlists.all.get(this.props.data.playlist.id.toString())!
    }
  }

  handlePlaylistSelect = () => {
    if (this.dataCtx.playlists.selected && this.dataCtx.playlists.selected.length > 0) {
      this.props.data.playlist = this.dataCtx.playlists.selected[0]
    }
    this.ClearErrorsForField('playlist')
  }

  handleClearPlaylistSelect = () => {
    this.props.data.playlist = undefined
    this.dataCtx.clients.selected.clear()
    this.ClearErrorsForField('playlist')
  }

  handleMouseEnter = () => {
    this.isMouseOver = true
  }

  handleMouseLeave = () => {
    this.isMouseOver = false
  }

  @computed get isHighlighted() {
    return this.isMouseOver && this.props.showRemoveCross
  }

  render() {
    return (
      <StyledBox style={{ backgroundColor: (this.isHighlighted ? '#eee' : undefined) }}>
        <Box>
          <FormControl>
            <FormLabel style={{ textAlign: 'center' }}>S</FormLabel>
            <Checkbox
              checked={!!this.props.data.shuffle}
              onChange={(_, d) => this.props.data.shuffle = d}
              value="on"
            />
          </FormControl>
        </Box>
        <Box>
          <SelectViaDialog
            label="Playlist"
            placeholder="Select Playlist"
            errors={this.GetErrorsForField('playlist')}
            onOpen={(openModal) => {
              this.handleFillPlaylistSelect()
              openModal()
            }}
            onSelected={this.handlePlaylistSelect}
            value={this.props.data.playlist ? [{ value: this.props.data.playlist.id.toString(), label: this.props.data.playlist.name }] : undefined}
            onClear={this.handleClearPlaylistSelect}
          >
            <PlaylistsPage
              selectConfig={{
                type: 'single',
                usage: 'create-or-update'
              }}
            />
          </SelectViaDialog>
        </Box>
        <SmallInputBox>
          <TextFieldWV
            label="RC"
            type="number"
            value={this.props.data.repetitionCount.toString()}
            onChange={value => this.props.data.repetitionCount = parseInt(value)}
            errors={this.GetErrorsForField('repetitionCount')}
          />
        </SmallInputBox>
        {this.props.showRemoveCross && <Grid item>
          <Delete
            onClick={this.props.onRemove}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          />
        </Grid>}
      </StyledBox>
    )
  }

  private GetErrorsForField = (key: string) => {
    return this.props.errors && this.props.errors[key] ? this.props.errors[key] : undefined
  }

  private ClearErrorsForField = (key: string) => {
    if (!!this.props.errors && !!this.props.errors[key]) {
      delete this.props.errors[key]
    }
  }
}

export default ProgramCreatorPlaylistSelector