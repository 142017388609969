import { ICollectionMeta, ISortOptions, ICollectionOptions } from '../../../api-client'
import { toJS, computed } from 'mobx'
import { Instance, types as t } from 'mobx-state-tree'
import { IFilterValues } from '../../../models'

export interface IUIContext {
    currentPage: number
    perPage: number
    totalPages: number
    itemsCount: number
    filters: IFilterValues
    filtersPreset: IFilterValues
    searchQuery: string
    applyedFiltersCount: number
    sort?: ISortOptions
    isLoading: boolean
}

export const PaginatedListUIContext = t.model({
    currentPage: t.optional(t.number, 1),
    perPage: t.optional(t.number, 10),
    totalPages: t.optional(t.number, 1),
    itemsCount: t.optional(t.number, 0),
    sort: t.maybe(t.model({ field: t.string, dir: t.enumeration(['asc', 'desc']) })),
    isLoading: t.optional(t.boolean, false),
})

export type PaginatedListUIContext = Instance<typeof PaginatedListUIContext>

interface IDataService<A> {
    fetch(options?: ICollectionOptions): Promise<ICollectionMeta>
    get(id: number): Promise<A>
    delete(id: number): Promise<void>
    clear(): void
}

export interface IPaginatedListStateManager {
    goToPage(page: number): Promise<void>
    sort(sort: ISortOptions): Promise<void>
    initPaginatedList(): Promise<void>
    refresh(): Promise<void>
    delete(id: number): Promise<void>
}

export class PaginatedListState<A> implements IPaginatedListStateManager {
    constructor(
        readonly uiContext: IUIContext,
        readonly dataService: IDataService<A>,
    ) { }

    @computed get mixedFilters() {
        let filters = new Map(Object.entries(toJS(this.uiContext.filters)))
        this.uiContext.filtersPreset.forEach((v, k) => {
            filters.set(k, toJS(v))
        })
        return filters
    }

    @computed get filtersCount() {
        return this.mixedFilters.size
    }

    async goToPage(page: number) {
        this.uiContext.isLoading = true
        this.uiContext.currentPage = page
        try {
            const meta = await this.dataService.fetch({
                page: this.uiContext.currentPage,
                perPage: this.uiContext.perPage,
                sort: this.uiContext.sort,
                filters: this.mixedFilters,
                searchQuery: this.uiContext.searchQuery
            })
            this.uiContext.currentPage = meta.page
            this.uiContext.totalPages = meta.totalPages
            this.uiContext.itemsCount = meta.itemsCount
        }
        finally {
            this.uiContext.applyedFiltersCount = this.filtersCount
            this.uiContext.isLoading = false
        }
    }

    async getAll() {
        this.uiContext.isLoading = true
        try {
            await this.dataService.fetch({
                sort: this.uiContext.sort,
                filters: this.mixedFilters,
                searchQuery: this.uiContext.searchQuery
            })
        }
        finally {
            this.uiContext.applyedFiltersCount = this.filtersCount
            this.uiContext.isLoading = false
        }
    }

    async getEntity(id: number): Promise<A> {
        this.uiContext.isLoading = true
        try {
            const entityResponse: A = await this.dataService.get(id)
            return Promise.resolve(entityResponse as A)
        } catch (e) {
            console.error(e)
            return Promise.reject()
        }
        finally {
            this.uiContext.isLoading = false
        }
    }

    async sort(sort: ISortOptions) {
        this.uiContext.sort = sort
        await this.goToPage(1)
    }

    async initPaginatedList() {
        await this.goToPage(1)
    }

    async refresh() {
        await this.goToPage(this.uiContext.currentPage)
    }

    async delete(id: number) {
        await this.dataService.delete(id)
        await this.refresh()
    }
}
