import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend } from '../services/utils'

export const Relevance = t
    .model('relevance', {
        id: t.identifierNumber,
        name: t.string,
    })

export type Relevance = Instance<typeof Relevance>

export const emptyRelevance = {
    id: 0,
    name: '',
}

export const relevanceConstraints = {
    name: { presence: { allowEmpty: false } },
}

export const RelevanceSchema = new schema.Entity('relevances')

export function storeRelevance(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const relevances = norm.entities.relevances
    if (!!relevances && Object.keys(relevances).length > 0) {
        FixNullsToUndefinedFromBackend(relevances)
        dataContext.relevances.all.merge(relevances)
        norm.entities.relevances = undefined
    }
}