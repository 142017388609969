import React from 'react'
import { observer } from 'mobx-react'

import { DynamicAttribute, GenericValue } from '@shared/common'
import DynamicAttributeItem from './DynamicAttributeItem'

interface IProps {
    attributes: DynamicAttribute[],
    values: Map<string, GenericValue>,
    errors?: Map<string, string[]>,
}

@observer
class DynamicAttributeAdornment extends React.Component<IProps> {
    handleChange = (name: string, value: GenericValue) => {
        this.props.values.set(name, value)
    }

    render() {
        return (
            <React.Fragment>
                {this.props.attributes.map((a, idx) => (
                    <DynamicAttributeItem
                        key={`dyn-attr-item-adornment-${idx}`}
                        attribute={a}
                        value={this.props.values.get(a.name)}
                        onChange={this.handleChange}
                        errors={this.props.errors ? this.props.errors.get(a.name) : undefined}
                    />
                ))
                }
            </React.Fragment>
        )
    }
}

export default DynamicAttributeAdornment
