import React from 'react'
import { observer } from 'mobx-react'
import { computed, IReactionDisposer, reaction, toJS, comparer } from 'mobx'
import ProgramAttributes from './ProgramAttributes'
import { programFilters } from './ProgramFilterForm'
import { Add, Edit, Input } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  ISelectConfig, IFilterPreset, DiC,
  PageService, Program, entities,
  PageContext, DataContext,
  IFilterValues, presetClient
} from '@shared/common'
import { dynamicAttrFilters, FAB, DataTable } from '@shared/ui'

interface IProps extends RouteComponentProps {
  selectConfig?: ISelectConfig
  presetFilters?: IFilterPreset
}

@observer
class ProgramsPage extends React.Component<IProps> {
  reactionDisposers: IReactionDisposer[] = []

  get pageService() {
    return DiC.get<PageService<Program>>(entities.ProgramSrv)
  }

  get ctx() {
    return DiC.get<PageContext>(entities.ProgramCtx)
  }

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  @computed get dynamicAttributes() {
    return this.dataCtx.dynamicAttributes.ofEntity('program')
  }

  @computed get allColumns() {
    return ProgramAttributes(this.dataCtx)
  }

  @computed get filterFields() {
    let dynamic = this.dynamicAttributes ? dynamicAttrFilters(this.dynamicAttributes) : {}
    return { ...programFilters(this.dataCtx, this.props.presetFilters), ...dynamic }
  }

  @computed get presetFilters(): IFilterValues | undefined {
    let preset: IFilterPreset = !!this.props.presetFilters ? this.props.presetFilters : {}
    if (this.dataCtx.global.hasSelectedClient) {
      presetClient(preset, this.dataCtx.global.selectedClient!.id)
    }
    return preset.program
  }

  @computed get canRead() {
    return this.pageService.getAbility('read')
  }

  actions(p: Program) {
    let res: JSX.Element[] = []

    p.isCreated && res.push(<IconButton key={`${p.id}_edit`} onClick={() => this.props.history.push(`/programs/${p.id}`)}><Edit /></IconButton>)
    p.isApproved && res.push(<IconButton key={`${p.id}_assign`} onClick={() => this.props.history.push(`/programs/assign/${p.id}`)}><Input /></IconButton>)

    return res
  }

  handleInitInfo = () => {
    this.pageService.filtersState.handlePresetFilters(this.presetFilters)
    // if (this.id) {
    //   this.pageService.init(this.id)
    //     .catch(_ => this.props.enqueueSnackbar("Could not find requested client", { variant: 'error' }));
    // } else {
    this.pageService.init()
    // }
  }

  componentDidMount() {
    this.handleInitInfo()
    this.reactionDisposers.push(reaction(
      () => toJS(this.presetFilters),
      () => {
        // if (!!!this.id) {
        this.pageService.filtersState.handlePresetFilters(this.presetFilters)
        this.pageService.filtersState.applyFilters()
        // }
      },
      {
        equals: comparer.structural
      }
    ))
    window.addEventListener('popstate', this.handleInitInfo)
  }

  componentWillUnmount() {
    this.reactionDisposers.forEach(d => d())
    window.removeEventListener('popstate', this.handleInitInfo)
  }

  render() {
    if (!this.canRead) {
      return (<React.Fragment />)
    }
    return (
      <React.Fragment>
        <FAB
          ariaLabel="Add Music Program"
          onClick={() => this.props.history.push('/programs/new')}
          disabled={this.ctx.isLoading}
        >
          <Add />
          Add Music Program
        </FAB>
        <FAB
          marginLeft="250px"
          ariaLabel="Add Ads Program"
          onClick={() => this.props.history.push('/programs/new/ads')}
          disabled={this.ctx.isLoading}
        >
          <Add />
          Add Ads Program
        </FAB>
        <DataTable
          repository={this.dataCtx.programs}
          attributes={this.allColumns}
          ctx={this.ctx}
          service={this.pageService}
          selectConfig={this.props.selectConfig}
          filterFields={this.filterFields}
          actions={(p: Program) => [...this.actions(p)]}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(ProgramsPage)