
import React, { ReactNode } from 'react'
import {
    Dialog, DialogTitle, DialogContent,
    DialogActions, Button, FormControl,
    InputLabel, Select, FormHelperText,
    Input, Chip, InputAdornment,
    IconButton, Box
} from '@material-ui/core'
import {
    Clear, Cancel
} from '@material-ui/icons'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { ISelectOption } from '@shared/common'
import DialogButtons from './DialogButtons'
import styled from 'styled-components'

type IProps = {
    errors?: string[]
    readonly?: boolean
    label: string
    placeholder: string
    onSelected: () => void
    onOpen?: (handleOpen: () => void) => void
    onRemoveItem?: (item: ISelectOption) => void
    onClear: () => void
    value?: ISelectOption[]
    children: ReactNode
    required?: boolean
    multiple?: boolean
}

const ContentBox = styled(({ size, ...otherProps }) => <Box {...otherProps} />)`
    height: 100%;
    height: -moz-available;
    height: -webkit-fill-available; 
    height: fill-available;
`

@observer
class SelectViaDialog extends React.Component<IProps> {
    @observable isOpen: boolean = false

    openModal = () => {
        if (!!this.props.onOpen) {
            this.props.onOpen(() => { this.isOpen = true })
        } else {
            this.isOpen = true
        }
    }

    closeModal = () => {
        this.isOpen = false
    }

    handleSelect = () => {
        this.props.onSelected()
        this.closeModal()
    }

    handleKeyPress = (e: React.KeyboardEvent<any>) => {
        switch (e.key) {
            case 'Enter':
                e.stopPropagation()
                this.handleSelect()
                break
            default: return
        }
    }

    render() {
        return (
            <React.Fragment>
                <FormControl
                    error={!!this.props.errors ? true : undefined}
                    fullWidth
                    required={this.props.required}
                    disabled={this.props.readonly}
                >
                    <InputLabel
                        htmlFor={`select-dialog-${this.props.label}`}
                    >
                        {!!this.props.value && this.props.value.length > 0 ? this.props.label : this.props.label}
                    </InputLabel>

                    <Select
                        multiple={this.props.multiple}
                        open={false}
                        value={!!this.props.value ? this.props.value : []}
                        onOpen={this.openModal}
                        renderValue={!!this.props.multiple ? (selected) => (
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flex: 1,
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    backgroundColor: 'rgba(255, 255, 255, 0)'
                                }}
                            >
                                {(selected as ISelectOption[]).map(value => (
                                    <Chip
                                        key={`chip-${value.value}`}
                                        tabIndex={-1}
                                        label={value.label}
                                        style={{ margin: '2px 1px' }} //theme.spacing(0.5, 0.25),
                                        onDelete={!!this.props.onRemoveItem ? () => this.props.onRemoveItem!(value) : undefined}
                                        deleteIcon={!!this.props.onRemoveItem ? <Cancel /> : undefined}
                                    />
                                ))}
                            </div>
                        ) : (selected) => (
                            (selected as ISelectOption[]).map(value => (
                                value.label
                            ))
                        )}
                        input={
                            <Input
                                id={`select-dialog-${this.props.label}`}
                                endAdornment={
                                    <InputAdornment
                                        style={{
                                            marginLeft: '0',
                                            marginRight: '24px'
                                        }}
                                        position="end"
                                    >
                                        <IconButton
                                            disabled={this.props.readonly}
                                            aria-label="clear-select"
                                            onClick={this.props.onClear}
                                            style={{ padding: '0' }}
                                        >
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        }
                    />
                    {!!this.props.errors ?
                        <FormHelperText>
                            {this.props.errors!.join(', ')}
                        </FormHelperText> : []
                    }
                </FormControl>
                <Dialog
                    open={this.isOpen}
                    onClose={this.closeModal}
                    aria-labelledby="modal-dialog-title"
                    onKeyPress={this.handleKeyPress}
                    fullWidth
                    maxWidth="xl"
                >
                    <DialogTitle id="modal-dialog-title">{'Select ' + this.props.label}</DialogTitle>
                    <DialogContent
                        style={{ backgroundColor: '#eeeeee' }}
                    >
                        <ContentBox>
                            {this.props.children}
                        </ContentBox>
                    </DialogContent>
                    <DialogActions>
                        <DialogButtons
                            onConfirm={this.handleSelect}
                            onCancel={this.closeModal}
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default SelectViaDialog