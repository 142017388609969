import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { DemoRecording, DemoRecordingSchema, demoRecordingConstraints, storeDemoRecording } from './demo_recording'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend, FixDatesFromBackend } from '../services/utils'

export const Demo = t
    .model('demo', {
        id: t.identifierNumber,
        comment: t.optional(t.string, ''),
        recordingDate: t.optional(t.Date, new Date()),
        demoRecordings: t.optional(t.array(t.reference(DemoRecording)), []),
    })

export type Demo = Instance<typeof Demo>

export const emptyDemo = {
    id: 0,
    comment: '',
    recordingDate: new Date(),
    demoRecordings: []
}

export const demoConstraints = {
    recordingDate: { presence: { allowEmpty: false } },
    demoRecordings: {
        array: demoRecordingConstraints,
    }

}

export const DemoSchema = new schema.Entity('demos', {
    demoRecordings: [DemoRecordingSchema],
})

export function storeDemo(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const demos = norm.entities.demos
    if (!!demos && Object.keys(demos).length > 0) {
        storeDemoRecording(dataContext, norm)
        FixNullsToUndefinedFromBackend(demos)
        FixDatesFromBackend(demos, ['recordingDate'])
        dataContext.demos.all.merge(demos)
        norm.entities.demos = undefined
    }
}