import React from 'react'
import { Location, IAttribute } from '@shared/common'
import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { InfoCardContent, AttributesList } from '@shared/ui'

interface IProps {
    attributes: IAttribute<Location>[]
    entity: Location
}

const LocationInfo: React.FunctionComponent<IProps> = ({ entity, attributes }) => (
    <React.Fragment>
        <InfoCardContent
            header="Location"
            //subHeader={`ID = ${entity.id}`}
            subHeader={entity.id > 0 ?
                <Link component={RouterLink} to={`/locations/${entity.id}`} target="_blank">
                    {`${window.location.origin}/locations/${entity.id}`}
                </Link> : undefined
            }
            content={
                <AttributesList
                    entity={entity}
                    attrs={attributes.filter(a => a.show)}
                />
            }
        />
    </React.Fragment>

)

export default LocationInfo
