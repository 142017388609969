import React, { ReactNode } from 'react'
import { computed, observable } from 'mobx'
import { observer } from 'mobx-react'

import TimeFieldWV from './TimeFieldWithValidation'
import {
    GenericRangeValue, GenericSimpleValue, GenericValue,
    DynamicAttribute, DynamicAttributeTypes, ISelectOption,
    ISelectType, FilterTypes, IFilterValues, FormatToBackendDate
} from '@shared/common'
import { Grid } from '@material-ui/core'
import SelectWV from './SelectWithValidation'
import SelectViaDialog from './SelectViaDialog'
import TextFieldWV from './TextFieldWithValidation'
import DateRangePickerWV from './DateRangePickerWithValidation'
import moment from 'moment'

export interface IFilter {
    label: string,
    type: FilterTypes,
    readonly?: boolean,
    hidden?: boolean,
    // for `numeric` types: step
    numberStep?: number,
    // only for 'numeric' | 'time' | 'date' types
    isRange?: boolean,
    // Required for `select` type
    selectParams?: {
        options: ISelectOption[] // Format [[label: string, value: string]]
        multiple: boolean // Can choose more than one option
    },
    dialogParams?: {
        children: (selectType: ISelectType) => ReactNode
        onSelected: () => ISelectOption[] | undefined
        onClear: () => void
        selectByIds: (ids: string[]) => void
        multiple?: boolean
    }
    // Required for `search` type
    searchParams?: {
        query: (query: string) => Promise<ISelectOption>, // query function that returns list of options found
    },
}

export type IFilters = { [field: string]: IFilter }

const mapDynAttrFieldTypes = (t: DynamicAttributeTypes): FilterTypes => {
    switch (t) {
        case 'multiple_select':
            return 'select'
        default:
            return t
    }
}

export const dynamicAttrFilters = (attrs: DynamicAttribute[]): IFilters => (
    attrs.map(a => (
        {
            [`dynamicAttributes.${a.name}`]: ({
                type: mapDynAttrFieldTypes(a.fieldType!),
                label: a.label,
                selectParams: a.fieldType === 'select' || a.fieldType === 'multiple_select' ? {
                    options: [...a.params!.options.map(o => ({ value: o, label: o }))],
                    multiple: a.fieldType === 'multiple_select',
                } : undefined,
            })
        }))
        .reduce((a, c) => ({ ...a, ...c }), {})
)

interface IFilterProps {
    filterParams: IFilters
    filterValues: IFilterValues
    presetFiltersValues?: IFilterValues
    onChange: (name: string, value: GenericValue) => void
}

interface IFilterFieldProps {
    label: string
    name: string
    params: IFilter,
    value: GenericValue,
    onChange: (name: string, value: GenericValue) => void
}

const Filters: React.FunctionComponent<IFilterProps> = observer(({ filterParams, filterValues, onChange, presetFiltersValues }) => {
    return (
        <Grid container direction="column">
            {
                Object.keys(filterParams).map(name => {
                    let hidden = !!filterParams[name].hidden ? filterParams[name].hidden : false
                    if (hidden) {
                        return <React.Fragment key={name} />
                    }
                    let type = filterParams[name].type
                    let field = <FieldSelector
                        key={name}
                        type={type}
                        fieldProps={{
                            label: filterParams[name].label,
                            name: name,
                            params: {
                                ...filterParams[name],
                                readonly: !!filterParams[name].readonly ?
                                    filterParams[name].readonly :
                                    (!!presetFiltersValues ? presetFiltersValues.has(name) : undefined)
                            },
                            value: !!presetFiltersValues && presetFiltersValues.has(name) ?
                                presetFiltersValues.get(name) : filterValues.get(name),
                            onChange: onChange
                        }}
                    />
                    return field
                })
            }
        </Grid>
    )
})

export default Filters

const FieldSelector: React.FunctionComponent<{ type: FilterTypes, fieldProps: IFilterFieldProps }> = observer(({ type, fieldProps }) => {
    if (fieldProps.params.isRange) {
        return <RangeFilter type={type} fieldProps={{ ...fieldProps, params: { ...fieldProps.params, isRange: false } }} />
    }

    const field = (
        type === 'numeric' ? <NumericFilterField {...fieldProps} /> :
            type === 'string' ? <StringFilterField {...fieldProps} /> :
                type === 'time' ? <TimeFilter {...fieldProps} /> :
                    type === 'duration' ? <DurationFilter {...fieldProps} /> :
                        type === 'select' ? <SelectFilter {...fieldProps} /> :
                            type === 'date' ? <DateFilter {...fieldProps} /> :
                                type === 'boolean' ? <BooleanFilter {...fieldProps} /> :
                                    type === 'dialog' ? <DialogFilter {...fieldProps} /> :
                                        undefined
    )

    if (!field) {
        console.warn('no filter field implemented for type', type)
    }

    return field || <React.Fragment />
})

@observer
class RangeFilter extends React.Component<{ type: FilterTypes, fieldProps: IFilterFieldProps }> {
    constructor(props: { type: FilterTypes, fieldProps: IFilterFieldProps }) {
        super(props)

        const { type } = props
        if (type !== 'numeric' && type !== 'date' && type !== 'time' && type !== 'duration') {
            console.error(`filter range of type ${type} is not allowed`)
        }
    }

    @computed get val() {
        return this.props.fieldProps.value as GenericRangeValue
    }

    @computed get from() {
        return this.val ? this.val.from : undefined
    }

    @computed get to() {
        return this.val ? this.val.to : undefined
    }

    onFromChange = (name: string, val: GenericValue) => {
        this.props.fieldProps.onChange(name, {
            from: val as GenericSimpleValue,
            to: this.to,
        })
    }

    onToChange = (name: string, val: GenericValue) => {
        this.props.fieldProps.onChange(name, {
            from: this.from,
            to: val as GenericSimpleValue
        })
    }

    render() {
        const { type, fieldProps } = this.props

        return (
            <Grid container direction="row" wrap="nowrap">
                <FieldSelector type={type} fieldProps={{ ...fieldProps, value: this.from, onChange: this.onFromChange, params: { ...fieldProps.params, label: fieldProps.params.label + ' from' } }} />
                <Grid item style={{ width: '24px' }} />
                <FieldSelector type={type} fieldProps={{ ...fieldProps, value: this.to, onChange: this.onToChange, params: { ...fieldProps.params, label: fieldProps.params.label + ' to' } }} />
            </Grid>
        )
    }
}

const NumericFilterField: React.FunctionComponent<IFilterFieldProps> = observer(({ name, params, value, onChange }) => (
    <Grid
        item
        style={{
            flex: '1 1 auto',
            width: '100%'
        }}
    >
        <TextFieldWV
            readonly={params.readonly}
            type="number"
            label={params.label}
            value={value ? (value as GenericSimpleValue).toString() : undefined}
            onChange={(v: string) => onChange(name, v)}
        />
    </Grid>
))

const StringFilterField: React.FunctionComponent<IFilterFieldProps> = observer(({ name, params, value, onChange }) => (
    <Grid
        item
        style={{
            flex: '1 1 auto',
            width: '100%'
        }}
    >
        <TextFieldWV
            readonly={params.readonly}
            label={params.label}
            value={value ? (value as GenericSimpleValue).toString() : undefined}
            onChange={(v: string) => onChange(name, v)}
        />
    </Grid>
))

const TimeFilter: React.FunctionComponent<IFilterFieldProps> = observer(({ name, params, value, onChange }) => (
    <Grid
        item
        style={{
            flex: '1 1 auto',
            width: '100%'
        }}
    >
        <TimeFieldWV
            readonly={params.readonly}
            label={params.label}
            value={value ? (value as string).toString() : undefined}
            onChange={(v) => onChange(name, v)}
        />
    </Grid>
))

const DurationFilter: React.FunctionComponent<IFilterFieldProps> = observer(({ name, params, value, onChange }) => (
    <Grid
        item
        style={{
            flex: '1 1 auto',
            width: '100%'
        }}
    >
        <TimeFieldWV
            readonly={params.readonly}
            duration
            label={params.label}
            value={value as string}
            onChange={(v) => onChange(name, v)}
        />
    </Grid>
))

const SelectFilter: React.FunctionComponent<IFilterFieldProps> = observer(({ name, params, value, onChange }) => {
    return (
        <Grid container direction="row" >
            <Grid
                item
                style={{
                    flex: '1 1 auto',
                    width: '100%'
                }}
            >
                <SelectWV
                    readonly={params.readonly}
                    label={params.label}
                    selectOptions={params.selectParams!.options}
                    multiple={params.selectParams!.multiple}
                    value={
                        !!value ?
                            Array.isArray(value) ?
                                value.map(v => ({
                                    value: v.toString(), label: (() => {
                                        let opt = params.selectParams!.options.find(o => (o.value.toString() === v.toString()))
                                        return opt ? opt.label : v.toString()
                                    })()
                                })) :
                                [{
                                    value: value.toString(), label: (() => {
                                        let opt = params.selectParams!.options.find(o => (o.value.toString() === value.toString()))
                                        return opt ? opt.label : value.toString()
                                    })()
                                }] :
                            undefined
                    }
                    onChange={value => {
                        params.selectParams!.multiple ?
                            onChange(name, !!value && value.length > 0 ? value.map(v => v.value) as GenericSimpleValue[] : []) :
                            onChange(name, !!value && value.length > 0 ? value[0].value as GenericSimpleValue : '')
                    }}
                />
            </Grid>
        </Grid>
    )
})

const DateFilter: React.FunctionComponent<IFilterFieldProps> = observer(({ name, params, value, onChange }) => {
    return (
        <Grid
            item
            style={{
                flex: '1 1 auto',
                width: '100%'
            }}
        >
            <DateRangePickerWV
                readonly={params.readonly}
                label={params.label}
                startDate={value ? moment((value as GenericSimpleValue).toString(), ['DD.MM.YYYY', 'YYYY-MM-DD']).toDate() : undefined}
                onChange={(start, end) => {
                    onChange(name, FormatToBackendDate(start))
                }}
                onDatesReset={() => {
                    value = undefined
                    onChange(name, '')
                }}
                mode="single"
            />
        </Grid>
    )
})

const BooleanFilter: React.FunctionComponent<IFilterFieldProps> = observer(({ name, params, value, onChange }) => {
    return (
        <Grid container direction="row" >
            <Grid
                item
                style={{
                    flex: '1 1 auto',
                    width: '100%'
                }}
            >
                <SelectWV
                    readonly={params.readonly}
                    label={params.label}
                    selectOptions={[{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]}
                    value={
                        !!value ?
                            Array.isArray(value) ?
                                value.map(v => ({ value: v.toString(), label: v.toString() })) :
                                [{ value: value.toString(), label: value.toString() }] :
                            undefined
                    }
                    onChange={value => {
                        onChange(name, !!value && value.length > 0 ? value[0].value as GenericSimpleValue : '')
                    }}
                />
            </Grid>
        </Grid>
    )
})

@observer
class DialogFilter extends React.Component<IFilterFieldProps> {
    @observable selfValue: ISelectOption[] | undefined = undefined

    componentDidMount = () => {
        this.selfValue = this.props.params.dialogParams!.onSelected()
    }

    handleClear = () => {
        this.selfValue = undefined
        this.props.onChange(this.props.name, '')
        this.props.params.dialogParams!.onClear()
    }

    render() {
        return (
            <Grid container direction="row" >
                <Grid
                    item
                    style={{
                        flex: '1 1 auto',
                        width: '100%'
                    }}
                >
                    <SelectViaDialog
                        readonly={this.props.params.readonly}
                        multiple={this.props.params.dialogParams!.multiple}
                        label={this.props.params.label}
                        placeholder={'Select ' + this.props.params.label}
                        value={!!this.props.value && !!this.selfValue ?
                            Array.isArray(this.props.value) ?
                                this.props.value.map(v => ({
                                    value: v.toString(), label: (() => {
                                        let opt = this.selfValue!.find(o => (o.value.toString() === v.toString()))
                                        return opt ? opt.label : v.toString()
                                    })()
                                })) :
                                [{
                                    value: this.props.value.toString(), label: (() => {
                                        let opt = this.selfValue!.find(o => (o.value.toString() === this.props.value.toString()))
                                        return opt ? opt.label : this.props.value.toString()
                                    })()
                                }] :
                            undefined}
                        onSelected={() => {
                            this.selfValue = this.props.params.dialogParams!.onSelected()
                            this.props.params.dialogParams!.multiple ?
                                this.props.onChange(this.props.name,
                                    !!this.selfValue && this.selfValue.length > 0 ?
                                        this.selfValue.map(v => v.value) as GenericSimpleValue[] : []) :
                                this.props.onChange(this.props.name,
                                    !!this.selfValue && this.selfValue.length > 0 ?
                                        this.selfValue[0].value as GenericSimpleValue : '')
                        }}
                        onClear={this.handleClear}
                    >
                        {!!this.props.params.dialogParams!.multiple ?
                            this.props.params.dialogParams!.children('multiple') :
                            this.props.params.dialogParams!.children('single')
                        }
                    </SelectViaDialog>
                </Grid>
            </Grid>
        )
    }

}