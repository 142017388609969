import React from 'react'
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'
import { FormControl, FormLabel, FormHelperText, Box } from '@material-ui/core'
import moment from 'moment'
import styled from 'styled-components'

interface IProps {
  value?: string
  errors?: string[]
  readonly?: boolean
  duration?: boolean
  onChange(value: string): void
  label: string
  required?: boolean
}

// remove max-width: 64px;
const TimeBox = styled(({ color, ...otherProps }) => <Box {...otherProps} />)`
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  input.flatpickr-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    box-shadow: inset 0 -1px 0 0 #959595;
    height: 1.1875em;
    margin: 0;
    padding: 6px 0 7px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    &:hover {
      transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.87);
    }
  }
  .flatpickr-calendar {
    width: 120px;
  }
`

const TimeFieldWV: React.SFC<IProps> = ({ errors, value, label, onChange, required, readonly, duration }) => {
  return (
    <TimeBox>
      <FormControl
        required={required}
        error={errors ? true : undefined}
        style={{ width: '100%' }}
        disabled={readonly}
      >
        <FormLabel>{label}</FormLabel>
        <Flatpickr
          value={value}
          options={Object.assign({
            static: true,
            enableTime: true,
            noCalendar: true,
            dateFormat: 'H:i',
            minuteIncrement: 1,
            time_24hr: true,
            allowInput: true,
          },
            (!!readonly && readonly) && {
              allowInput: false,
              clickOpens: false
            },
            (!!duration && duration) && {
              enableSeconds: true,
              dateFormat: 'H:i:S',
            }
          )}
          onOpen={_ => {
            (!!duration && duration) ?
              !value && onChange('00:00:00') :
              !value && onChange('12:00')
          }}
          onChange={date => {
            (!!duration && duration) ?
              onChange(moment(date[0]).format('HH:mm:ss')) :
              onChange(moment(date[0]).format('HH:mm'))
          }
          }
        />
        {!!errors &&
          <FormHelperText error={errors ? true : undefined}>
            {errors && errors.join(', ')}
          </FormHelperText>
        }
      </FormControl>
    </TimeBox>
  )
}

export default TimeFieldWV