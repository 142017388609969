import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ICollectionOptions } from '../api-client'
import { GroupStore, GroupStoreSchema } from '../models'
import { IDomainService } from '.'
import { FixTimesFromBackend, FixDatesFromBackend } from './utils'

const entityName: string = 'groupStore'

@injectable()
export class GroupStoresService implements IDomainService<GroupStore> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)

        const groupStores = norm.entities.group_stores
        const clients = norm.entities.clients

        FixDatesFromBackend(clients, ['createdAt'])
        FixTimesFromBackend(groupStores, ['openTime', 'closeTime'])

        if (!!clients && Object.keys(clients).length > 0) {
            this.dataContext.clients.all.merge(clients)
        }
        if (!!groupStores && Object.keys(groupStores).length > 0) {
            this.dataContext.groupStores.all.merge(groupStores)
        }

        return norm.result
    }

    async fetch(options?: ICollectionOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(GroupStoreSchema))
        if (!!order && order.length > 0) {
            this.dataContext.groupStores.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async get(id: number): Promise<GroupStore> {
        let result: any
        if (id > 0) {
            result = await this.apiClient.getObject(id, entityName)
        } else {
            return Promise.reject()
        }
        this.storeContext(result, GroupStoreSchema)
        return this.dataContext.groupStores.all.get(result.id)!
    }

    async createOrUpdate(groupStore: GroupStore): Promise<GroupStore> {
        const clone = {
            ...groupStore,

            clientId: groupStore.client!.id,
            client: undefined
        }
        let result: any
        if (groupStore.id > 0) {
            result = await this.apiClient.patchObject(clone, entityName)
        } else {
            result = await this.apiClient.postObject(clone, entityName)
        }
        this.storeContext(result, GroupStoreSchema)
        return this.dataContext.groupStores.all.get(result.id)!
    }

    clear() {
        this.dataContext.groupStores.ordered.clear()
    }
}
