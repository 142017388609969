import { types as t, Instance } from 'mobx-state-tree'
import { Location } from '../models'

export const LocationsRepository = t.model({
    all: t.map(Location),
    ordered: t.array(t.reference(Location)),
    selected: t.array(t.reference(Location)),
    filterSelected: t.array(t.reference(Location)),
})

export type LocationsRepository = Instance<typeof LocationsRepository>
