import { types as t, Instance } from 'mobx-state-tree'
import { schema } from 'normalizr'
import { Client, ClientSchema } from './client'

export const GroupStore = t.model('group_store', {
    id: t.identifierNumber,
    name: t.string,
    color: t.optional(t.string, ''),
    openTime: t.maybe(t.string),
    closeTime: t.maybe(t.string),

    client: t.maybe(t.reference(Client)),
})

export type GroupStore = Instance<typeof GroupStore>

export const emptyGroupStore = {
    id: 0,
    name: '',
    color: '',
    openTime: '',
    closeTime: '',
    client: undefined,
}

export const GroupStoreConstraints = {
    name: { presence: { allowEmpty: false } },
    color: { presence: { allowEmpty: true } },
    openTime: { presence: { allowEmpty: false } },
    closeTime: { presence: { allowEmpty: false } },
    client: { presence: { allowEmpty: false } },
}

export const GroupStoreSchema = new schema.Entity('group_stores', {
    client: ClientSchema,
})