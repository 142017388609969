import React from 'react'
import { observable, computed, toJS, reaction } from 'mobx'
import { observer } from 'mobx-react'

import {
    Client, emptyClient,
    clientConstraints,
    PageService, PageContext,
    entities, DiC, DataContext,
    FormState, Undoer,
    UndoerState, emptyUndoerState
} from '@shared/common'
import {
    DynamicAttributeAdornment,
    TextFieldWV, FAB, FileDialogWV,
    UndoButtons, DialogButtons
} from '@shared/ui'
import {
    Dialog, Grid,
    DialogContent, DialogActions,
    DialogTitle, IconButton
} from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { closeUnsavedForm } from '../shared/UnsavedDialog'

interface IProps extends WithSnackbarProps {
    client?: Client
}

@observer class ClientForm extends React.Component<IProps> {
    @observable client: Client = emptyClient as any
    @observable formState: FormState<Client> = this.pageService.createFormState()

    undoer: Undoer<Client> = new Undoer<Client>()
    debounceUndoer: () => void
    @observable undoerState: UndoerState = emptyUndoerState

    componentDidMount() {
        this.debounceUndoer = AwesomeDebouncePromise(() => { this.undoer.Push(toJS(this.client), this.undoerState) }, 500)
        reaction(
            () => toJS(this.client),
            () => {
                if (!this.undoerState.dontReact) {
                    this.undoerState.waiting = true
                    this.debounceUndoer()
                }
            },
        )
    }

    get pageService() {
        return DiC.get<PageService<Client>>(entities.ClientSrv)
    }

    get ctx() {
        return DiC.get<PageContext>(entities.ClientCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    @computed get isEditMode() {
        return !!this.props.client
    }

    @computed get dynamicAttributes() {
        return this.dataCtx.dynamicAttributes.ofEntity('client')
    }

    @computed get canCreate() {
        return this.pageService.getAbility('create')
    }

    @computed get canUpdate() {
        return this.pageService.getAbility('update')
    }

    handleClear = () => {
        this.formState.clearErrors()
        if (this.isEditMode) {
            this.handleFillClient(toJS(this.props.client!))
        } else {
            this.handleFillClient(emptyClient as any)
        }
    }

    handleFillClient = (newClient: Client) => {
        this.client = newClient
        this.client.dynamicAttributes = new Map(Object.entries(newClient.dynamicAttributes)) as any
    }

    openForm = () => {
        if (this.isEditMode) {
            this.handleFillClient(toJS(this.props.client!))
        } else {
            this.handleFillClient(toJS(this.client))
        }
        this.formState.openForm()
    }

    closeForm = () => {
        closeUnsavedForm(this.isEditMode, () => this.formState.closeForm(), toJS(!!this.props.client ? this.props.client : emptyClient), toJS(this.client))
    }

    handleSubmit = () => {
        this.formState.save(this.client, clientConstraints, this.client.logo as any)
            .then(res => {
                if (res.isOk) {
                    this.formState.closeForm()
                    this.undoerState.dontReact = true
                    this.client = emptyClient as any
                    this.undoerState.dontReact = false
                    this.undoer = new Undoer<Client>()
                    this.pageService.paginatedList.refresh()
                } else {
                    this.props.enqueueSnackbar('You have filled one or more fields incorrectly.', { variant: 'error' })
                }
            })
    }

    render() {
        if ((!this.isEditMode || !this.canUpdate) && (!this.canCreate || this.isEditMode)) {
            return (<React.Fragment />)
        }
        return (
            <React.Fragment>
                {this.isEditMode ?
                    <IconButton
                        aria-label="Edit"
                        onClick={this.openForm}
                    >
                        <Edit />
                    </IconButton>
                    :
                    <FAB
                        ariaLabel="Add"
                        onClick={this.openForm}
                        disabled={this.ctx.isLoading}
                    >
                        <Add />
                        Add Client
                    </FAB>
                }

                <Dialog
                    open={this.formState.formOpen}
                    onClose={this.closeForm}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title">
                        {this.isEditMode ? 'Edit' : 'Add'} Client
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="column">
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="Name"
                                    errors={this.formState.getErrorsForField('name')}
                                    value={this.client.name}
                                    onChange={value => {
                                        this.client.name = value
                                        this.formState.clearErrorsForField('name')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="Phone"
                                    errors={this.formState.getErrorsForField('phone')}
                                    value={this.client.phone}
                                    onChange={value => {
                                        this.client.phone = value
                                        this.formState.clearErrorsForField('phone')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="E-Mail"
                                    errors={this.formState.getErrorsForField('corpEmail')}
                                    value={this.client.corpEmail}
                                    onChange={value => {
                                        this.client.corpEmail = value
                                        this.formState.clearErrorsForField('corpEmail')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <FileDialogWV
                                    fileName={!!this.client.logo ? (this.client.logo as any).name : undefined}
                                    errors={this.formState.getErrorsForField('logoUrl')}
                                    type="image"
                                    fileUrl={this.client.logoUrl}
                                    onClear={() => {
                                        this.client.logo = undefined as any
                                        this.client.logoUrl = undefined
                                        this.formState.clearErrorsForField('logoUrl')
                                    }}
                                    onChange={file => {
                                        if (!!file) {
                                            this.client.logo = file as any
                                            this.client.logoUrl = URL.createObjectURL(file)
                                        }
                                        this.formState.clearErrorsForField('logoUrl')
                                    }}
                                    label="Logo File"
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="Address"
                                    errors={this.formState.getErrorsForField('address')}
                                    value={this.client.address}
                                    onChange={value => {
                                        this.client.address = value
                                        this.formState.clearErrorsForField('address')
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                style={{ width: '100%' }}
                            >
                                <TextFieldWV
                                    required
                                    label="Domain Name"
                                    errors={this.formState.getErrorsForField('domainName')}
                                    value={this.client.domainName}
                                    onChange={value => {
                                        this.client.domainName = value
                                        this.formState.clearErrorsForField('domainName')
                                    }}
                                />
                            </Grid>
                            {this.dynamicAttributes ?
                                <DynamicAttributeAdornment
                                    attributes={this.dynamicAttributes}
                                    values={this.client.dynamicAttributes}
                                    errors={this.formState.errors}
                                />
                                : <React.Fragment />
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogButtons
                            onConfirm={this.handleSubmit}
                            confirmText="Save"
                            onCancel={this.closeForm}
                            onReset={this.handleClear}
                            resetText={`${this.isEditMode ? 'Reset' : 'Clear'} Fields`}
                            additionalActions={
                                <UndoButtons
                                    onUndo={() => {
                                        this.undoer.Undo(this.handleFillClient, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    onRedo={() => {
                                        this.undoer.Redo(this.handleFillClient, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    state={this.undoerState}
                                />
                            }
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withSnackbar(ClientForm)
