import React from 'react'
import { LinearProgress } from '@material-ui/core'
import { observer } from 'mobx-react'
import { observable, reaction, toJS, IReactionDisposer } from 'mobx'
import { withStyles, createStyles, Theme, WithStyles } from '@material-ui/core/styles'

interface IProps {
    loading?: boolean
    topRounded?: boolean
    bottomRounded?: boolean
    color?: 'primary' | 'secondary'
    height?: string
    percent?: number
    waitOnZero?: boolean
}

const styles = (theme: Theme) => createStyles({
    topRounded: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius
    },
    bottomRounded: {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius
    }
})

@observer
class ProgressBar extends React.Component<IProps & WithStyles<typeof styles>> {
    @observable isLoading: boolean = false
    @observable percent?: number = !!this.props.waitOnZero ? 0 : undefined
    reactionDisposers: IReactionDisposer[] = []
    timerId?: number
    intervalId?: number

    componentDidMount() {
        this.reactionDisposers.push(reaction(
            () => toJS(this.props.loading),
            () => {
                if (this.props.loading) {
                    if (!this.isLoading) {
                        this.isLoading = true
                    }
                    if (this.timerId) {
                        window.clearTimeout(this.timerId)
                    }
                    this.timerId = undefined
                } else {
                    this.timerId = window.setTimeout(() => {
                        if (this.isLoading) {
                            this.isLoading = false
                        }
                    }, 800)
                }
            }
        ))
        this.reactionDisposers.push(reaction(
            () => toJS(this.props.percent),
            () => {
                if (this.props.percent && (this.props.percent !== 100 || !!this.percent)) { //if super-speed uploding - intermediate
                    if (!!!this.percent) {
                        this.percent = 0
                    }
                    if (!!!this.intervalId) {
                        this.intervalId = window.setInterval(() => {
                            if (this.percent >= this.props.percent || this.percent >= 100) {
                                window.clearInterval(this.intervalId)
                                if (this.percent >= 100) { //when 100% - intermediate
                                    this.percent = undefined
                                }
                            } else {
                                // if big interval - speed-up progress bar
                                this.props.percent - this.percent > 25 ? this.percent += 2 : this.percent += 1
                            }
                        }, 20)
                    }
                } else {
                    if (this.intervalId) {
                        window.clearInterval(this.intervalId)
                    }
                    if (!!this.percent) {
                        this.percent = undefined
                    }
                }
            }
        ))
    }

    componentWillUnmount() {
        this.reactionDisposers.forEach(d => d())
        if (this.timerId) {
            window.clearTimeout(this.timerId)
        }
        if (this.intervalId) {
            window.clearInterval(this.intervalId)
        }
    }

    render() {
        const classes = this.props.classes
        const heightStyles = Object.assign(
            {
                zIndex: 1,
                minHeight: '4px',
                height: '4px'
            },
            !!this.props.height && {
                height: this.props.height,
                minHeight: this.props.height
            }
        )
        return (
            <React.Fragment>
                {this.isLoading ?
                    <LinearProgress
                        variant={this.percent !== undefined ? 'determinate' : 'indeterminate'}
                        value={this.percent}
                        color={this.props.color}
                        className={`
                            ${!!this.props.topRounded ? classes.topRounded : ''} 
                            ${!!this.props.bottomRounded ? classes.bottomRounded : ''}`
                        }
                        style={heightStyles}
                    /> :
                    <div
                        style={heightStyles}
                    />
                }
            </React.Fragment>
        )
    }

}

export default withStyles(styles)(ProgressBar)