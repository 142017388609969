import React from 'react'
import { observer } from 'mobx-react'

import {
    GenericSimpleValue, GenericValue,
    FormatToBackendDate, DynamicAttribute,
    DynamicAttributeTypes, ISelectOption
} from '@shared/common'
import { Grid } from '@material-ui/core'
import SelectWV from '../SelectWithValidation'
import TextFieldWV from '../TextFieldWithValidation'
import DateRangePickerWV from '../DateRangePickerWithValidation'
import TimeFieldWV from '../TimeFieldWithValidation'
import moment from 'moment'

interface IDynamicProps {
    attribute: DynamicAttribute
    value: GenericValue
    errors?: string[]
    onChange: (name: string, value: GenericValue) => void
}

interface IDynamicFieldProps {
    label: string
    name: string
    errors?: string[]
    selectParams?: {
        options: ISelectOption[] // Format [{label: string, value: string}]
        multiple: boolean // Can choose more than one option
    }
    value: GenericValue,
    onChange: (name: string, value: GenericValue) => void
}

const DynamicAttributeItem: React.FunctionComponent<IDynamicProps> = observer(({ attribute, value, onChange }) => {
    return (
        !!attribute.fieldType ?
            <FieldSelector
                key={attribute.id}
                type={attribute.fieldType === 'multiple_select' ? 'select' : attribute.fieldType}
                fieldProps={{
                    label: attribute.label,
                    name: attribute.name,
                    selectParams: attribute.fieldType === 'select' || attribute.fieldType === 'multiple_select' ? {
                        options: [...attribute.params!.options.map(o => ({ value: o, label: o }))],
                        multiple: attribute.fieldType === 'multiple_select',
                    } : undefined,
                    value: value,
                    onChange: onChange
                }}
            />
            : <React.Fragment />
    )
})

export default DynamicAttributeItem

const FieldSelector: React.FunctionComponent<{ type: DynamicAttributeTypes, fieldProps: IDynamicFieldProps }> = observer(({ type, fieldProps }) => {
    const field = (
        type === 'numeric' ? <NumericDynamicField {...fieldProps} /> :
            type === 'string' ? <StringDynamicField {...fieldProps} /> :
                type === 'time' ? <TimeDynamicField {...fieldProps} /> :
                    type === 'duration' ? <DurationDynamicField {...fieldProps} /> :
                        type === 'select' ? <SelectDynamicField {...fieldProps} /> :
                            type === 'date' ? <DateDynamicField {...fieldProps} /> :
                                type === 'boolean' ? <BooleanDynamicField {...fieldProps} /> :
                                    undefined
    )

    if (!field) {
        console.warn('no dynamic field implemented for type', type)
    }

    return field || <React.Fragment />
})

const NumericDynamicField: React.FunctionComponent<IDynamicFieldProps> = observer(({ name, label, value, onChange, errors }) => (
    <Grid
        item
        style={{
            flex: '1 1 auto',
            width: '100%'
        }}
    >
        <TextFieldWV
            type="number"
            label={label}
            value={value ? (value as GenericSimpleValue).toString() : undefined}
            errors={errors}
            onChange={(v: string) => onChange(name, v)}
        />
    </Grid>
))

const StringDynamicField: React.FunctionComponent<IDynamicFieldProps> = observer(({ name, label, value, onChange, errors }) => (
    <Grid
        item
        style={{
            flex: '1 1 auto',
            width: '100%'
        }}
    >
        <TextFieldWV
            label={label}
            value={value ? (value as GenericSimpleValue).toString() : undefined}
            errors={errors}
            onChange={(v: string) => onChange(name, v)}
        />
    </Grid>
))

const TimeDynamicField: React.FunctionComponent<IDynamicFieldProps> = observer(({ name, label, value, onChange, errors }) => (
    <Grid
        item
        style={{
            flex: '1 1 auto',
            width: '100%'
        }}
    >
        <TimeFieldWV
            errors={errors}
            label={label}
            value={value ? (value as GenericSimpleValue).toString() : undefined}
            onChange={(v) => onChange(name, v)}
        />
    </Grid>
))

const DurationDynamicField: React.FunctionComponent<IDynamicFieldProps> = observer(({ name, label, value, onChange, errors }) => (
    <Grid
        item
        style={{
            flex: '1 1 auto',
            width: '100%'
        }}
    >
        <TimeFieldWV
            duration
            errors={errors}
            label={label}
            value={value ? (value as GenericSimpleValue).toString() : undefined}
            onChange={(v) => onChange(name, v)}
        />
    </Grid>
))

const SelectDynamicField: React.FunctionComponent<IDynamicFieldProps> = observer(({ name, label, selectParams, value, onChange, errors }) => {
    return (
        <Grid
            item
            style={{
                flex: '1 1 auto',
                width: '100%'
            }}
        >
            <SelectWV
                errors={errors}
                label={label}
                selectOptions={selectParams!.options}
                multiple={selectParams!.multiple}
                value={
                    !!value ?
                        Array.isArray(value) ?
                            value.filter(v => v !== '').map(v => ({
                                value: v.toString(), label:
                                    !!selectParams!.options.find(o => (o.value.toString() === v)) ?
                                        selectParams!.options.find(o => (o.value.toString() === v)).label :
                                        v.toString()
                            })) :
                            [{
                                value: value.toString(), label:
                                    !!selectParams!.options.find(o => (o.value.toString() === value)) ?
                                        selectParams!.options.find(o => (o.value.toString() === value)).label :
                                        value.toString()
                            }] : undefined
                }
                onChange={value => {
                    selectParams!.multiple ?
                        onChange(name, !!value && value.length > 0 ? value.map(v => v.value) as GenericSimpleValue[] : []) :
                        onChange(name, !!value && value.length > 0 ? value[0].value as GenericSimpleValue : '')
                }}
            />
        </Grid>
    )
})

const DateDynamicField: React.FunctionComponent<IDynamicFieldProps> = observer(({ name, label, value, onChange, errors }) => {
    return (
        <Grid
            item
            style={{
                flex: '1 1 auto',
                width: '100%'
            }}
        >
            <DateRangePickerWV
                label={label}
                errors={errors}
                startDate={value ? moment((value as GenericSimpleValue).toString(), ['DD.MM.YYYY', 'YYYY-MM-DD']).toDate() : undefined}
                onChange={(start, end) => {
                    onChange(name, FormatToBackendDate(start))
                }}
                onDatesReset={() => {
                    value = undefined
                    onChange(name, '')
                }}
                mode="single"
            />
        </Grid>
    )
})

const BooleanDynamicField: React.FunctionComponent<IDynamicFieldProps> = observer(({ name, label, value, onChange, errors }) => {
    return (
        <Grid
            item
            style={{
                flex: '1 1 auto',
                width: '100%'
            }}
        >
            <SelectWV
                errors={errors}
                label={label}
                selectOptions={[{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]}
                value={
                    !!value ?
                        Array.isArray(value) ?
                            value.map(v => ({ value: v.toString(), label: v.toString() })) :
                            [{ value: value.toString(), label: value.toString() }] :
                        undefined
                }
                onChange={value => {
                    onChange(name, !!value && value.length > 0 ? value[0].value as GenericSimpleValue : '')
                }}
            />
        </Grid>
    )
})
