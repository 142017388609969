import { types as t, Instance } from 'mobx-state-tree'
import { Content } from '../models'

export const ContentsRepository = t.model({
    all: t.map(Content),
    ordered: t.array(t.reference(Content)),
    selected: t.array(t.reference(Content)),
    filterSelected: t.array(t.reference(Content)),
})

export type ContentsRepository = Instance<typeof ContentsRepository>
