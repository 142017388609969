import React from 'react'

import './program_creator.css'
import { Grid, Fab, Container, IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import styled from 'styled-components'

interface IProps {
  children: any
  name?: string
  color: string
  commandName: string
  onAddNew(): void
}

const StyledContainer = styled(({ color, ...otherProps }) => <Container {...otherProps} />)`
  font-family: 'Roboto Mono', monospace;
  background-color: ${props => props.color};
  height: 100%;
  border-radius: 4px;
  color: white;
  width: 27px !important;
  margin: 0 !important;
  text-align: center;
  font-size: 12px;
  min-height: 100px;
  padding: 7px 10px !important;
`

class ProgramCreateCommandList extends React.Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <Grid container style={{ borderTop: '2px solid ' + this.props.color, paddingTop: '16px' }}>
          <Grid item xs="auto" zeroMinWidth style={{ minHeight: '100px' }}>
            <Grid container direction="column" style={{ height: '100%', alignItems: 'center' }} >
              <Grid item xs><StyledContainer color={this.props.color}>{this.props.name}</StyledContainer></Grid>
              <Grid item xs="auto" style={{ marginTop: '4px' }}>
                {React.Children.count(this.props.children) !== 0 ?
                  (
                    <IconButton aria-label="Add" onClick={this.props.onAddNew} size="small">
                      <Add fontSize="inherit" />
                    </IconButton>
                  ) : undefined}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs style={{ minHeight: '100px', margin: '0' }}>
            {React.Children.count(this.props.children) === 0 ?
              (
                <Grid container justify="center" style={{ minHeight: '100px', alignItems: 'center' }}>
                  <Grid item>
                    <Fab variant="extended" onClick={this.props.onAddNew} style={{ color: 'white', backgroundColor: this.props.color }}>
                      <Add /> {this.props.commandName}
                    </Fab>
                  </Grid>
                </Grid>
              ) : this.props.children}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default ProgramCreateCommandList