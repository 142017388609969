import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { Address, AddressSchema, storeAddress } from './address'
import { GenericValue } from './generic'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend, FixDatesFromBackend } from '../services/utils'

export const Location = t.model('location', {
    id: t.identifierNumber,
    name: t.string,
    room: t.string,
    address: t.maybe(t.reference(Address)),
    createdAt: t.optional(t.Date, new Date()),
    updatedAt: t.optional(t.Date, new Date()),
    dynamicAttributes: t.optional(t.map(GenericValue), {}),
})

export type Location = Instance<typeof Location>

export const emptyLocation = {
    id: 0,
    name: '',
    room: '',
    address: undefined,
    createdAt: new Date(),
    udpatedAt: new Date(),
    dynamicAttributes: new Map<string, GenericValue>(),
}

export const locationConstraints = {
    name: { presence: { allowEmpty: false } },
    room: { presence: { allowEmpty: false } },
    address: { presence: { allowEmpty: false } },
}

export const LocationSchema = new schema.Entity('locations', {
    address: AddressSchema,
})

export function storeLocation(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const locations = norm.entities.locations
    if (!!locations && Object.keys(locations).length > 0) {
        storeAddress(dataContext, norm)
        FixNullsToUndefinedFromBackend(locations)
        FixDatesFromBackend(locations, ['createdAt', 'updatedAt'])
        dataContext.locations.all.merge(locations)
        norm.entities.locations = undefined
    }
}