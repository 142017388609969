import React from 'react'
import StoresPage from '../stores/StorePage'
import ClientsPage from '../clients/ClientsPage'
import { DataContext, IFilterPreset, ISelectType } from '@shared/common'
import { IFilters } from '@shared/ui'

export const deviceFilters = (dataCtx: DataContext, preset?: IFilterPreset): IFilters => ({
  name: { label: 'Name', type: 'string' },
  mac: { label: 'Mac', type: 'string' },
  isUpToDate: { label: 'Is Up To Date', type: 'boolean' },
  storeId: {
    label: 'Store',
    type: 'dialog',
    dialogParams: {
      children: (selectType?: ISelectType) => {
        return <StoresPage
          selectConfig={
            selectType ? {
              type: selectType,
              usage: 'filter'
            } : undefined
          }
          presetFilters={preset}
        />
      },
      selectByIds: (ids: string[]) => {
        dataCtx.stores.filterSelected.clear()
        ids.forEach(id => {
          if (dataCtx.stores.all.has(id)) {
            dataCtx.stores.filterSelected.push(dataCtx.stores.all.get(id)!)
          }
        })
      },
      onSelected: () => {
        if (!!dataCtx.stores.filterSelected && dataCtx.stores.filterSelected.length > 0) {
          return dataCtx.stores.filterSelected.map(v => ({ value: v.id.toString(), label: v.name }))
        }
        return undefined
      },
      onClear: () => {
        dataCtx.stores.filterSelected.clear()
      },
      multiple: true
    }
  },
  clientId: {
    label: 'Client',
    type: 'dialog',
    dialogParams: {
      children: (selectType?: ISelectType) => {
        return <ClientsPage
          selectConfig={
            selectType ? {
              type: selectType,
              usage: 'filter'
            } : undefined
          }
          presetFilters={preset}
        />
      },
      selectByIds: (ids: string[]) => {
        dataCtx.clients.filterSelected.clear()
        ids.forEach(id => {
          if (dataCtx.clients.all.has(id)) {
            dataCtx.clients.filterSelected.push(dataCtx.clients.all.get(id)!)
          }
        })
      },
      onSelected: () => {
        if (!!dataCtx.clients.filterSelected && dataCtx.clients.filterSelected.length > 0) {
          return dataCtx.clients.filterSelected.map(v => ({ value: v.id.toString(), label: v.name }))
        }
        return undefined
      },
      onClear: () => {
        dataCtx.clients.filterSelected.clear()
      },
      multiple: true
    }
  },
})
