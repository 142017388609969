import React from 'react'
import {
    Table, TableHead, TableRow,
    TableCell, TableBody, Grid, Box
} from '@material-ui/core'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import { IAttribute, IRepository } from '@shared/common'
import ScrollBox from './ScrollBox'

interface IProps<A> {
    values: Array<A>
    attributes: IAttribute<A>[]
    actions?: (entity: A) => JSX.Element[]
}

@observer class RawDataTable<A> extends React.Component<IProps<A>> {

    @computed get visibleColumnsNames() {
        return this.props.attributes.filter(a => a.show).map(a => a.name)
    }

    render() {
        return (
            <ScrollBox
                direction="horizontal"
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* {!!this.props.selectType ? <TableCell padding="checkbox"></TableCell> : []}*/}
                            {!!this.props.actions ? <TableCell>Actions</TableCell> : []}
                            {this.props.attributes.filter(attribute => this.visibleColumnsNames.indexOf(attribute.name) >= 0).map((attribute, idx) => (
                                <TableCell
                                    key={`thcell-${attribute.name}-${idx}`}
                                // sortDirection={this.sort.field == attribute.name ? this.sort.dir : false}
                                >
                                    {attribute.label}
                                    {/* <Tooltip
                                    title="Sort"
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={this.sort.field == attribute.name}
                                        direction={this.sort.field == attribute.name ? this.sort.dir : undefined}
                                        onClick={() => this.handleSort(attribute.name)}
                                    >
                                        {attribute.label}
                                    </TableSortLabel>
                                </Tooltip> */}
                                </TableCell>
                            ))
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {this.props.values.map(value => (
                            <TableRow key={(value as any).id}>
                                { /*     hover={!!this.props.selectType}
                            onClick={() => this.handleChangeSelection(value)}
                            role={!!this.props.selectType ? "checkbox" : undefined}
                            aria-checked={!!this.props.selectType ? this.props.repository.selected.indexOf(value) >= 0 : undefined}
                            tabIndex={!!this.props.selectType ? -1 : undefined}
                            selected={!!this.props.selectType ? this.props.repository.selected.indexOf(value) >= 0 : undefined}
                            key={(value as any).id}
                        >
                            {!!this.props.selectType ?
                                <TableCell padding="checkbox">
                                    <Checkbox checked={this.props.repository.selected.indexOf(value) >= 0} />
                                </TableCell> : []
                            }
                            */}
                                {!!this.props.actions ?
                                    <TableCell>
                                        <Grid container direction="row" wrap="nowrap" >
                                            {this.props.actions(value)}
                                        </Grid>
                                    </TableCell>
                                    : []
                                }
                                {this.props.attributes.filter(attribute => this.visibleColumnsNames.indexOf(attribute.name) >= 0).map((attribute, idx) => (
                                    <TableCell
                                        key={`tccell-${attribute.name}-${idx}`}
                                    >
                                        {attribute.display(value)}
                                    </TableCell>
                                ))
                                }
                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
            </ScrollBox>
        )
    }
}

export default RawDataTable