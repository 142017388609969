import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ICollectionOptions } from '../api-client'
import { Device, Program, DeviceSchema, storeDevice } from '../models'
import { IDomainService } from '.'
import { toJS } from 'mobx'

const entityName = 'device'

@injectable()
export class DevicesService implements IDomainService<Device> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)
        storeDevice(this.dataContext, norm)
        return norm.result
    }

    async fetch(options?: ICollectionOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(DeviceSchema))
        if (!!order && order.length > 0) {
            this.dataContext.devices.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async get(id: number): Promise<Device> {
        let result: any
        if (id > 0) {
            result = await this.apiClient.getObject(id, entityName)
        } else {
            return Promise.reject()
        }
        this.storeContext(result, DeviceSchema)
        return this.dataContext.devices.all.get(result.id)!
    }

    async createOrUpdate(device: Device): Promise<Device> {
        const clone = {
            ...device,
            storeId: device.store ? device.store.id : null,
            store: null,
            dynamicAttributes: toJS(device.dynamicAttributes),
        }
        let result: any
        if (device.id > 0) {
            result = await this.apiClient.patchObject(clone, entityName)
        } else {
            result = await this.apiClient.postObject(clone, entityName)
        }
        this.storeContext(result, DeviceSchema)
        return this.dataContext.devices.all.get(result.id)!
    }

    async clearCurrentProgs(dev: Device): Promise<any> {
        return await this.apiClient.deleteByUrl(`devices/${dev.id}/programs/all`)
    }

    async removeProgram(dev: Device, prog: Program): Promise<any> {
        return await this.apiClient.deleteByUrl(`devices/${dev.id}/programs/${prog.id}`)
    }

    clear() {
        this.dataContext.devices.ordered.clear()
    }
}
