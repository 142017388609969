import React from 'react'
import { Tooltip, Box, SvgIcon } from '@material-ui/core'
import { green, red, grey, amber } from '@material-ui/core/colors'
import { observer } from 'mobx-react'

const errorColor = red[400]
const successColor = green[400]
const warningColor = amber[600]
const undefinedColor = grey[500]

type IColor = 'error' | 'warning' | 'success' | 'undefined' | string

interface IBooleanDisplay {
    color?: IColor
    message?: string
    icon?: React.ReactNode
}

interface IProps {
    value?: boolean
    onTrue?: IBooleanDisplay
    onFalse?: IBooleanDisplay
    onUndefined?: IBooleanDisplay
    children?: React.ReactNode
}

const colorMap = (color: IColor): string => {
    switch (color) {
        case 'error':
            return errorColor
        case 'success':
            return successColor
        case 'warning':
            return warningColor
        case 'undefined':
            return undefinedColor
        default:
            return color
    }
}

const BooleanIndicator: React.SFC<IProps> = observer(({ value, onTrue, onFalse, onUndefined, children }) => {
    let displayState = value === undefined ? onUndefined : (value ? onTrue : onFalse)
    const defaultColor = value === undefined ? undefinedColor : (value ? successColor : errorColor)
    const color = !!displayState && !!displayState.color ? colorMap(displayState.color) : defaultColor
    const defaultMessage = value === undefined ? 'Undefined' : (value ? 'Yes' : 'No')
    const message = !!displayState && !!displayState.message ? displayState.message : defaultMessage
    const defaultNode = !!children ? children :
        <SvgIcon>
            <circle cx="12" cy="12" r="10" />
        </SvgIcon>
    const childrenNode: React.ReactNode = !!displayState && !!displayState.icon ? displayState.icon : defaultNode
    return (
        <Box
            style={{
                color: color,
                flex: '1 1 auto'
            }}
        >
            <Tooltip
                title={message}
            >
                {childrenNode as any}
            </Tooltip>
        </Box>
    )
})

export default BooleanIndicator