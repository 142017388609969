import React from 'react'
import humanizeDuration from 'humanize-duration'

import StoreInfo from '../stores/StoreInfo'
import StoreAttributes from '../stores/StoreAttributes'
import moment from 'moment'
import { Warning } from '@material-ui/icons'
import {
    DataContext, IAttribute,
    Device, DynamicAttribute,
    generateDynAttrs
} from '@shared/common'
import { BooleanIndicator } from '@shared/ui'

const DeviceAttributes = (dataCtx: DataContext) => {
    let attributes: IAttribute<Device>[] = [
        {
            name: 'id',
            label: 'Id',
            show: true,
            value: (d: Device) => d.id,
            display: (d: Device) => d.id || '',
        },
        {
            name: 'name',
            label: 'Name',
            show: true,
            display: (d: Device) => d.name || '',
            value: (d: Device) => d.name,
        },
        {
            name: 'mac',
            label: 'MAC-address',
            show: true,
            display: (d: Device) => d.mac || '',
            value: (d: Device) => d.mac,
        },
        {
            name: 'store',
            label: 'Store',
            show: true,
            nested: (d: Device) => (
                d.store ?
                    <StoreInfo
                        attributes={StoreAttributes(dataCtx)}
                        entity={d.store}
                    /> : undefined
            ),
            display: (d: Device) => d.store ? d.store.name : '',
            value: (d: Device) => d.store,
        },
        {
            name: 'clientName',
            label: 'Client name',
            show: true,
            notSortable: true,
            display: (d: Device) => d.store && d.store.client ? d.store.client.name : '',
            value: (d: Device) => d.store && d.store.client ? d.store.client : null,
        },
        {
            name: 'location',
            label: 'Location',
            show: true,
            notSortable: true,
            display: (d: Device) => d.store && d.store.location ? d.store.location.name : '',
            value: (d: Device) => d.store && d.store.location ? d.store.location : null,
        },
        {
            name: 'address',
            label: 'Address',
            show: true,
            notSortable: true,
            display: (d: Device) => d.store && d.store.location && d.store.location.address ? d.store.location.address.name : '',
            value: (d: Device) => d.store && d.store.location && d.store.location.address ? d.store.location.address : null,
        },
        {
            name: 'lastActivity',
            label: 'Last Activity',
            show: true,
            display: (d: Device) => d.lastActivityDeltaSeconds ?
                humanizeDuration(d.lastActivityDeltaSeconds * 1000, { round: true }) + ' ago' :
                'Never',
            value: (d: Device) => d.lastActivityDeltaSeconds
        },
        {
            name: 'isUpToDate',
            label: 'Up To Date?',
            show: true,
            display: (d: Device) => <BooleanIndicator value={d.isUpToDate} onFalse={{ color: 'warning', icon: <Warning /> }} />, //d.isUpToDate ? 'Yes' : 'No',
            value: (d: Device) => d.isUpToDate
        },
        {
            name: 'isEmptyMss',
            label: 'MSS empty?',
            show: true,
            notSortable: true,
            display: (d: Device) => <BooleanIndicator value={d.mssAssigned === ''} onTrue={{ color: 'warning', icon: <Warning />, message: 'Assigned MSS is empty' }} onFalse={{ color: 'success' }} />,
            value: (d: Device) => undefined
        },
        {
            name: 'created_at',
            label: 'Created',
            show: true,
            display: (d: Device) => d.createdAt ? moment(d.createdAt).format('DD.MM.YYYY') : '',
            value: (d: Device) => d.createdAt,
        },
        {
            name: 'updated_at',
            label: 'Updated',
            show: true,
            display: (d: Device) => d.updatedAt ? moment(d.updatedAt).format('DD.MM.YYYY') : '',
            value: (d: Device) => d.updatedAt,
        },

    ]

    let deviceDynamic: DynamicAttribute[] = dataCtx.dynamicAttributes.ofEntity('device')
    const dyn: IAttribute<Device>[] = generateDynAttrs(deviceDynamic)

    return attributes.concat(dyn)
}

export default DeviceAttributes