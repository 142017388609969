import Axios, { AxiosRequestConfig } from 'axios'
import camelCase from 'camelcase'
import pluralize from 'pluralize'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import snakeCase from 'snake-case'
import objectToFormData from 'object-to-formdata'
import { injectable } from 'inversify'
import { GenericValue } from '../models/generic'
import { IFilterValues } from '../models'
import { FormatToBackendDate } from '../services/utils'
import { snakeCaseWithDots, snakecaseKeysWithDots } from '../utils/map-to-object'

export type RouteParams = {
    id?: string
}

export const resources = {
    prefix: '/api/a/v1',
    getEndpoint: (entity: string) => `${resources.prefix}/${pluralize(snakeCase(entity))}`
}

export type ISortOptions = {
    field: string,
    dir: 'asc' | 'desc'
}

export interface ICollectionOptions {
    page?: number
    perPage?: number
    filters?: IFilterValues
    sort?: ISortOptions
    searchQuery?: string
    [x: string]: any
}

export interface ICollectionMeta {
    page: number,
    totalPages: number,
    itemsCount: number,
}

export interface ICollectionResponse {
    meta?: ICollectionMeta,
    values: any,
}

export interface IMediaplanOptions {
    storeIds?: number[]
    deviceIds?: number[]
    committed?: boolean
    from: Date
    to: Date
}

export type IFiltersRecursive = Map<string, GenericValue | ITFiltersRecursive>
interface ITFiltersRecursive extends IFiltersRecursive { }

export const removeEmpty = (obj: any): any =>
    Object.keys(obj)
        .filter(k => obj[k] !== null && obj[k] !== undefined)  // Remove undef. and null.
        .reduce((newObj, k) =>
            typeof obj[k] === 'object' && !(obj[k].constructor === Array/* && obj[k].length == 0*/) ?
                Object.assign(newObj, { [k]: removeEmpty(obj[k]) }) :  // Recurse.
                Object.assign(newObj, { [k]: obj[k] }),  // Copy value.
            {})

export interface IApiClient {
    postLogin(loginData: any): Promise<any>
    getCurrentUser(): Promise<any>

    getDynamicAttributesByEntity(entityName: string): Promise<any>

    getObject(id: number, endpoint: string): Promise<any>
    getCollection(entity: string, options?: ICollectionOptions): Promise<ICollectionResponse>
    postByResource(data: any, resource: string): Promise<any>
    postObject(data: any, entity: string): Promise<any>
    postObjectWithAttachments(data: any, entityName: string, attachments?: { [key: string]: File }, uploadCallback?: (progressEvent: any) => void): Promise<any>
    patchObject(data: any, entity: string): Promise<any>
    patchObjectWithAttachments(data: any, entityName: string, attachments?: { [key: string]: File }, uploadCallback?: (progressEvent: any) => void): Promise<any>
    postForm(form: FormData, enity: string, uploadCallback?: (progressEvent: any) => void): Promise<any>
    putByUrl(data: any, url: string): Promise<any>
    getByUrl(url: string): Promise<any>

    getMediplan(options: IMediaplanOptions): Promise<any>
    deleteByUrl(url: string): Promise<any>
    deleteObject(id: number, entityName: string): Promise<any>
}

@injectable()
export class RestClient implements IApiClient {
    async postLogin(loginData: any): Promise<any> {
        return await Axios.post('/api/a/sign_in', loginData)
    }

    async getCurrentUser(): Promise<any> {
        return await Axios.get('/api/a/current_user', this.axiosConfig())
    }

    async postObjectWithAttachments(data: any, entityName: string, files?: { [key: string]: File }, uploadCallback?: (progressEvent: any) => void): Promise<any> {
        const entity = snakeCase(entityName)
        const tr = snakecaseKeysWithDots(data) //snakecaseKeys(data, { deep: true })
        let formData = objectToFormData(tr, { indices: false, nullsAsUndefineds: false }, undefined, entity)

        !!files && Object.entries(files).forEach(([key, file]) => {
            formData.append(`${entity}[${key}]`, file, file.name)
        })
        return await this.postForm(formData, pluralize(entity), uploadCallback)
    }

    async patchObjectWithAttachments(data: any, entityName: string, files?: { [key: string]: File }, uploadCallback?: (progressEvent: any) => void): Promise<any> {
        const entity = snakeCase(entityName)
        const tr = snakecaseKeysWithDots(data)//snakecaseKeys(data, { deep: true })
        let formData = objectToFormData(tr, { indices: false, nullsAsUndefineds: false }, undefined, entity)

        !!files && Object.entries(files).forEach(([key, file]) => {
            formData.append(`${entity}[${key}]`, file, file.name)
        })
        return await this.patchForm(data.id, formData, pluralize(entity), uploadCallback)
    }

    async getDynamicAttributesByEntity(entityName: string): Promise<any> {
        const resp = await Axios.get(`${resources.getEndpoint('dynamicAttributes')}?entity=${snakeCase(entityName)}`, this.axiosConfig())
        return camelcaseKeys(resp.data.dynamic_attributes, { deep: true })
    }

    async deleteObject(id: number, entityName: string): Promise<void> {
        const endpoint = resources.getEndpoint(entityName)
        await Axios.delete(`${endpoint}/${id}`, this.axiosConfig())
    }

    async getObject(id: number, entity: string): Promise<any> {
        const endpoint = resources.getEndpoint(entity)
        const resp = await Axios.get(`${endpoint}/${id}`, this.axiosConfig())
        return camelcaseKeys(resp.data, { deep: true })
    }

    async getCollection(entity: string, options?: ICollectionOptions): Promise<ICollectionResponse> {
        const collectionName = camelCase(pluralize(entity))
        const endpoint = resources.getEndpoint(entity)
        let q = serializeQueryParams({
            page: !!options && !!options.page ? options.page : undefined,
            per_page: !!options && !!options.perPage ? options.perPage : undefined,
            sort: !!options && !!options.sort ? this.transformSort(options.sort, entity) : undefined,
            filters: snakecaseKeysWithDots(!!options && !!options.filters ? this.transformFilters(options.filters, entity) : {}),
            search: !!options && !!options.searchQuery && options.searchQuery.length > 0 ? options.searchQuery : undefined,
        }, '')

        const response = await Axios.get(q !== '' ? `${endpoint}?${q}` : `${endpoint}`, this.axiosConfig())

        const data: any = camelcaseKeys(response.data, { deep: true })

        const m = data.meta!

        const meta = {
            page: parseInt(m.page),
            totalPages: parseInt(m.totalPages),
            itemsCount: parseInt(m.itemsCount)
        }

        const values = data[collectionName].map((d: any) => removeEmpty(d))

        return { meta, values }
    }

    async getMediplan(options: IMediaplanOptions): Promise<any> {
        const entity = 'mediaplan'
        const collectionName = camelCase(pluralize(entity))
        const endpoint = resources.getEndpoint(entity)
        let q = serializeQueryParams({
            store_ids: !!options.storeIds && options.storeIds.length > 0 ? options.storeIds : undefined,
            device_ids: !!options.deviceIds && options.deviceIds.length > 0 ? options.deviceIds : undefined,
            committed: !!options.committed ? options.committed : false,
            start_time: FormatToBackendDate(options.from),
            end_time: FormatToBackendDate(options.to),
        }, '')

        const response = await Axios.get(q !== '' ? `${endpoint}?${q}` : `${endpoint}`, this.axiosConfig())

        const data: any = camelcaseKeys(response.data, { deep: true })

        const values = data[collectionName].map((d: any) => removeEmpty(d))

        return values
    }

    async postByResource(data: any, resource: string): Promise<any> {
        const endpoint = `${resources.prefix}/${snakeCase(resource)}`
        const tr = snakecaseKeys(data, { deep: true })
        const resp = await Axios.post(endpoint, tr, this.axiosConfig())
        return camelcaseKeys(resp.data, { deep: true })
    }

    async putByUrl(data: any, url: string): Promise<any> {
        const endpoint = `${resources.prefix}/${url}`
        const tr = snakecaseKeys(data, { deep: true })
        const resp = await Axios.put(endpoint, tr, this.axiosConfig())
        return camelcaseKeys(resp.data, { deep: true })
    }

    async getByUrl(url: string): Promise<any> {
        const endpoint = `${resources.prefix}/${url}`
        const resp = await Axios.get(endpoint, this.axiosConfig())
        return camelcaseKeys(resp.data, { deep: true })
    }

    async postObject(data: any, entity: string): Promise<any> {
        const endpoint = resources.getEndpoint(entity)
        let json = {} as any
        json[entity] = data
        const tr = snakecaseKeys(json, { deep: true })
        const resp = await Axios.post(endpoint, tr, this.axiosConfig())
        return camelcaseKeys(resp.data, { deep: true })
    }

    async patchObject(data: any, entity: string): Promise<any> {
        const endpoint = resources.getEndpoint(entity)
        let json = {} as any
        json[entity] = data
        const tr = snakecaseKeys(json, { deep: true })
        const resp = await Axios.patch(`${endpoint}/${data.id}`, tr, this.axiosConfig())
        return camelcaseKeys(resp.data, { deep: true })
    }

    async postForm(form: FormData, entity: string, uploadCallback?: (progressEvent: any) => void): Promise<any> {
        const endpoint = resources.getEndpoint(entity)
        const resp = await Axios.post(endpoint, form, this.axiosConfig(uploadCallback))
        return camelcaseKeys(resp.data, { deep: true })
    }

    async patchForm(id: string, form: FormData, entity: string, uploadCallback?: (progressEvent: any) => void): Promise<any> {
        const endpoint = resources.getEndpoint(entity)
        const resp = await Axios.patch(`${endpoint}/${id}`, form, this.axiosConfig(uploadCallback))
        return camelcaseKeys(resp.data, { deep: true })
    }

    async deleteByUrl(url: string): Promise<any> {
        const endpoint = `${resources.prefix}/${url}`
        const resp = await Axios.delete(endpoint, this.axiosConfig())
        return camelcaseKeys(resp.data, { deep: true })
    }

    private transformSort(s: ISortOptions, entityName: string): any {
        return {
            field: entityName + '.' + snakeCaseWithDots(s.field),
            direction: s.dir,
        }
    }

    private transformFilters(filters: IFilterValues, entityName: string): any {
        let obj: any = {}
        filters.forEach((v, k) => {
            obj[snakeCaseWithDots(k)] = v
        })
        return obj
    }

    private axiosConfig(uploadCallback?: (progressEvent: any) => void): AxiosRequestConfig {
        const config: AxiosRequestConfig = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('jwt-token'),
            },
            onUploadProgress: progressEvent => {
                if (!!uploadCallback) {
                    uploadCallback(progressEvent)
                }
            },
        }
        return config
    }
}

const serializeQueryParams = (obj: any, prefix: string): string => {
    let str = []
    for (let p in obj) {
        if (obj.hasOwnProperty(p) && obj[p]) {
            let propName = Array.isArray(obj) ? '' : p
            let k = prefix ? prefix + '[' + propName + ']' : p,
                v = obj[p]
            if (v !== null) {
                str.push(
                    (typeof v === 'object')
                        ? serializeQueryParams(v, k)
                        : encodeURIComponent(k) + '=' + encodeURIComponent(v)
                )
            }
        }
    }
    str = str.filter(s => s !== '')

    return str.join('&')
}
