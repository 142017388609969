import { types as t, Instance } from 'mobx-state-tree'
import { PlaylistType } from '../models'

export const PlaylistTypesRepository = t.model({
    all: t.map(PlaylistType),
    ordered: t.array(t.reference(PlaylistType)),
    selected: t.array(t.reference(PlaylistType)),
    filterSelected: t.array(t.reference(PlaylistType)),
})

export type PlaylistTypesRepository = Instance<typeof PlaylistTypesRepository>
