import React, { ReactNode } from 'react'
import { Fab } from '@material-ui/core'

interface IProps {
    onClick(): void
    disabled: boolean
    children: ReactNode
    ariaLabel: string
    marginLeft?: string
}

const FAB: React.SFC<IProps> = ({ onClick, disabled, children, ariaLabel, marginLeft }) => {
    return (
        <Fab
            style={Object.assign({
                position: 'fixed',
                zIndex: 1000,
                marginLeft: marginLeft || '24px',
                marginTop: '-24px',
                //width: 'auto',
            })}
            color="primary"
            variant="extended"
            aria-label={ariaLabel}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </Fab>
    )
}

export default FAB