import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { GenericValue } from './generic'
import { ISelectOption } from '.'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend } from '../services/utils'

export const PerformerRoleTypes: ISelectOption[] = [
    { label: 'Arranger', value: 'Arranger' },
    { label: 'Composer', value: 'Composer' },
    { label: 'Copywriter', value: 'CopyWriter' },
    { label: 'Decryptor', value: 'Decryptor' },
    { label: 'Instrumentalist', value: 'Instrumentalist' },
    { label: 'Poet', value: 'Poet' },
    { label: 'Song Writer', value: 'SongWriter' },
    { label: 'Sound Designer', value: 'SoundDesigner' },
    { label: 'Sound Engineer', value: 'SoundEngineer' },
    { label: 'Speaker', value: 'Speaker' },
    { label: 'Translator', value: 'Translator' },
    { label: 'Video Animator', value: 'VideoAnimator' },
    { label: 'Video Engineer', value: 'VideoEngineer' },
    { label: 'Vocalist', value: 'Vocalist' }]

export const PerformerRole = t.model('performer_role', {
    id: t.identifierNumber,
    type: t.string,
    dynamicAttributes: t.optional(t.map(GenericValue), {}),
})

export type PerformerRole = Instance<typeof PerformerRole>

export const emptyPerformerRole = {
    id: 0,
    type: '',
    dynamicAttributes: new Map<string, GenericValue>(),
}

export const performerRoleConstraints = {
    name: { presence: { allowEmpty: false } },
    room: { presence: { allowEmpty: false } },
    address: { presence: { allowEmpty: false } },
}

export const PerformerRoleSchema = new schema.Entity('performer_roles')

export function storePerformerRole(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const performerRoles = norm.entities.performer_roles
    if (!!performerRoles && Object.keys(performerRoles).length > 0) {
        FixNullsToUndefinedFromBackend(performerRoles)
        dataContext.performerRoles.all.merge(performerRoles)
        norm.entities.performer_roles = undefined
    }
}