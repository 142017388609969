import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ICollectionOptions } from '../api-client'
import { Performer, PerformerSchema, storePerformer } from '../models'
import { IDomainService } from '.'
import { toJS } from 'mobx'
import { FixDatesFromBackend, FixNullsToUndefinedFromBackend } from './utils'

const entityName = 'performer'

@injectable()
export class PerformersService implements IDomainService<Performer> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)
        storePerformer(this.dataContext, norm)
        return norm.result
    }

    async fetch(options?: ICollectionOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(PerformerSchema))
        if (!!order && order.length > 0) {
            this.dataContext.performers.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async get(id: number): Promise<Performer> {
        let result: any
        if (id > 0) {
            result = await this.apiClient.getObject(id, entityName)
        } else {
            return Promise.reject()
        }
        this.storeContext(result, PerformerSchema)
        return this.dataContext.performers.all.get(result.id)!
    }

    async createOrUpdate(performer: Performer): Promise<Performer> {
        const roles = performer.performerRoles.map(pr => {
            return { ...toJS(pr), id: (pr.id > 0 ? pr.id : undefined), dynamicAttributes: toJS(pr.dynamicAttributes) }
        })
        const demos = performer.demos.map(d => {
            return {
                ...d, id: d.id > 0 ? d.id : undefined,
                recordingDate: d.recordingDate.toDateString(),
                demoRecordings: undefined,
                demo_recordings_attributes: d.demoRecordings.map(dr => {
                    const clonedDr = toJS(dr)
                    return { ...clonedDr, id: dr.id > 0 ? dr.id : undefined }
                })
            }
        })

        const clone = {
            ...performer,
            id: performer.id > 0 ? performer.id : undefined,
            relevance_id: performer.relevance ? performer.relevance.id : undefined,
            gender_id: performer.gender ? performer.gender.id : undefined,
            birthDate: performer.birthDate ? performer.birthDate.toDateString() : undefined,
            relevance: undefined,
            gender: undefined,
            performerRoles: undefined,
            demos: undefined,
            performer_roles_attributes: roles,
            demos_attributes: demos,
            dynamicAttributes: toJS(performer.dynamicAttributes),
        }
        let result: any
        if (performer.id > 0) {
            result = await this.apiClient.patchObjectWithAttachments(clone, entityName)
        } else {
            result = await this.apiClient.postObjectWithAttachments(clone, entityName)
        }
        this.storeContext(result, PerformerSchema)
        // clean all files to avoid upload if the same record will be edit one more time
        performer.demos.forEach(d => d.demoRecordings.forEach(dr => dr.file = null))
        return this.dataContext.performers.all.get(result.id)!
    }

    clear() {
        this.dataContext.performers.ordered.clear()
    }
}

export default PerformersService