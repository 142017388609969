import { types as t, Instance } from 'mobx-state-tree'
import { PaginatedListUIContext } from '../generic/list-state'
import { DynamicAttributesUIContext } from '../generic/dynamic-attributes'
import { FiltersUIContext } from '../generic/filters-state'

export const PageContext =
    t.compose(
        FiltersUIContext,
        PaginatedListUIContext,
        DynamicAttributesUIContext,
    )

export type PageContext = Instance<typeof PageContext>
