import { types as t, Instance, unprotect } from 'mobx-state-tree'

import { PageContext } from './generic-page'
import { LoginPageContext } from './login-page'

import { ProgramsPageContext } from './programs-page'
import { programsPageDefaults } from './programs-page/programs-page-context'
import { MediaplansPageContext } from './mediaplans-page'

export const UIStateContext = t.model({
    loginPage: t.optional(LoginPageContext, {}),

    devicesPage: t.optional(PageContext, {}),
    clientsPage: t.optional(PageContext, {}),
    addressesPage: t.optional(PageContext, {}),
    locationsPage: t.optional(PageContext, {}),
    contentsPage: t.optional(PageContext, {}),
    contentTypesPage: t.optional(PageContext, {}),
    contentCategoriesPage: t.optional(PageContext, {}),
    genresPage: t.optional(PageContext, {}),
    playlistsPage: t.optional(PageContext, {}),
    playlistTypesPage: t.optional(PageContext, {}),
    groupStoresPage: t.optional(PageContext, {}),
    programsPage: t.optional(PageContext, {}),
    storesPage: t.optional(PageContext, {}),
    relevancesPage: t.optional(PageContext, {}),
    performerRolesPage: t.optional(PageContext, {}),
    mediaplansPage: t.optional(MediaplansPageContext, {})
})

export type UIStateContext = Instance<typeof UIStateContext>

export class UIState {
    static create() {
        const m = UIStateContext.create({
            programsPage: programsPageDefaults(),
        })
        unprotect(m)
        return new UIState(m)
    }

    constructor(readonly ctx: UIStateContext) { }

    authDataPresent(): boolean {
        let token = localStorage.getItem('jwt-token')
        return !!token && (token.length > 0)
    }
}

export * from './generic'
export * from './generic-page'
export * from './login-page'
export * from './mediaplans-page'
export * from './programs-page'
