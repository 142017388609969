import isEqual from 'react-fast-compare'
import { ConfirmationDialog } from '@shared/ui'

export function closeUnsavedForm<A>(isEditMode: boolean, onClose: () => void, savedValue: A, newValue: A) {
    if (/*isEditMode && */!isEqual(savedValue, newValue)) {
        ConfirmationDialog('You have unsaved changes! Do you want close form?',
            isEditMode ? 'You can restore your changes when you open the edit form again by clicking the \"UNDO\" button' : 'Your changes are restored when you open the creation form again',
            onClose)
    } else {
        onClose()
    }
}