import React from 'react'
import { CircularProgress, Grid, Tooltip } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import { green, red, grey, amber } from '@material-ui/core/colors'
import { observer } from 'mobx-react'
import { IAutosaveState } from '@shared/common'

const errorColor = red[400]
const successColor = green[400]
const warningColor = amber[600]
const loadingColor = grey[500]

interface IProps {
    state: IAutosaveState
}

const AutosaveIndicator: React.SFC<IProps> = observer(({ state }) => (
    <React.Fragment>
        <Grid item>
            <Tooltip
                style={{ position: 'relative' }}
                title={state.loading ? 'Loading...' :
                    (() => {
                        switch (state.status) {
                            case 'error': return 'Not saved yet'
                            case 'warning': return 'Was saved previously, but not saved now'
                            case 'success': return 'Saved successfully'
                            default: return 'Loading...'
                        }
                    })()
                }
                placement="left"
            >
                <span>
                    <Save
                        style={Object.assign(
                            {
                                color: (() => {
                                    switch (state.status) {
                                        case 'error': return errorColor
                                        case 'warning': return warningColor
                                        case 'success': return successColor
                                        default: return loadingColor
                                    }
                                })(),
                                position: 'absolute' as any,
                                marginLeft: '6px',
                                marginTop: '6px'
                            },
                            state.loading && { color: loadingColor }
                        )}
                    />
                    <CircularProgress
                        size={36}
                        variant={state.loading ? 'indeterminate' : 'determinate'}
                        style={Object.assign(
                            {
                                color: (() => {
                                    switch (state.status) {
                                        case 'error': return errorColor
                                        case 'warning': return warningColor
                                        case 'success': return successColor
                                        default: return loadingColor
                                    }
                                })()
                            },
                            state.loading && { color: loadingColor }
                        )}
                    />
                </span>
            </Tooltip>
        </Grid>
    </React.Fragment >
))

export default AutosaveIndicator