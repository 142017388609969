export * from './address'
export * from './timezones'
export * from './location'
export * from './device'
export * from './client'
export * from './content'
export * from './content-record'
export * from './genre'
export * from './content-type'
export * from './content-category'
export * from './program'
export * from './a_user'
export * from './dynamic-attribute'
export * from './playlist'
export * from './playlist-type'
export * from './playlist-entry'
export * from './group-store'
export * from './store'
export * from './generic'
export * from './filter'
export * from './mediaplan'
export * from './performer'
export * from './gender'
export * from './relevance'
export * from './performer_role'
export * from './performance'
export * from './demo'
export * from './demo_recording'
export * from './zuser'

export type ISelectType = 'single' | 'multiple' | 'multiple-repeat'
export type ISelectUsage = 'create-or-update' | 'filter'

export type ISelectConfig = {
    type: ISelectType
    usage: ISelectUsage
    disableItem?: (entity: any) => boolean
    onSelect?: () => void
}

export type ISelectOption = {
    label: string
    value: string
}

export type IAutosaveState = {
    dontReact: boolean
    needSave: boolean
    loading: boolean
    status: 'success' | 'error' | 'warning'
}

export const emptyAutosaveState: IAutosaveState = {
    dontReact: false,
    needSave: false,
    loading: false,
    status: 'error'
}

export interface IAttribute<A> {
    name: string
    label: string
    show: boolean
    dynamic?: boolean
    nested?: (a: A) => React.ReactNode
    selectable?: ISelectType
    notSortable?: boolean
    value: (a: A) => any
    display: (a: A) => React.ReactNode
}

export function displayMore<A>(values: A[] | undefined, displayFn: (a: A) => string, bound?: number): string[] {
    let boundCount = bound ? bound : 3
    if (!!values && values.length > 0) {
        if (values.length > boundCount) {
            let firstElems = values.slice(0, boundCount).map(displayFn)
            firstElems.push(`and ${values.length - boundCount} more...`)
            return firstElems
        } else {
            return values.map(displayFn)
        }
    }
    return ['']
}