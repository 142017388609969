import { types as t, Instance, getMembers } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { Store, StoreSchema, storeStore } from './store'
import { GenericValue } from './generic'
import { FixNullsToUndefinedFromBackend, FixDatesFromBackend } from '../services/utils'
import { DataContext } from '../data-context'

export const Device = t
    .model('device', {
        id: t.identifierNumber,
        name: t.string,
        mac: t.string,
        musicServerId: t.maybe(t.number),
        ip: t.optional(t.string, ''),
        internalIp: t.optional(t.string, ''),
        gateway: t.optional(t.string, ''),
        login: t.optional(t.string, ''),
        password: t.optional(t.string, ''),
        currentState: t.optional(t.string, ''),
        mssAssigned: t.optional(t.string, ''),
        mssCommitted: t.optional(t.string, ''),
        store: t.maybeNull(t.reference(Store)),
        lastActivityDeltaSeconds: t.maybeNull(t.number),
        isUpToDate: t.optional(t.boolean, true),
        createdAt: t.optional(t.Date, new Date()),
        updatedAt: t.optional(t.Date, new Date()),
        dynamicAttributes: t.optional(t.map(GenericValue), {}),
    })

export type Device = Instance<typeof Device>

export const emptyDevice = {
    id: 0,
    name: '',
    mac: '',
    musicServerId: undefined,
    ip: '',
    internalIp: '',
    gateway: '',
    login: '',
    password: '',
    currentState: '',
    mssAssigned: '',
    mssCommitted: '',
    store: null,
    lastActivityDeltaSeconds: null,
    createdAt: new Date(),
    udpatedAt: new Date(),
    dynamicAttributes: new Map<string, GenericValue>(),
}

export const deviceConstraints = {
    name: { presence: { allowEmpty: false } },
    mac: { presence: { allowEmpty: false } },
    // ip: { presence: true },
    // internalIp: { presence: true },
    // gateway: { presence: true },
    // login: { presence: { allowEmpty: false } },
    // password: { presence: { allowEmpty: false } },
    // currentState: { presence: true }
}

export const DeviceSchema = new schema.Entity('devices', {
    store: StoreSchema
})

export function storeDevice(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const devices = norm.entities.devices
    if (!!devices && Object.keys(devices).length > 0) {
        storeStore(dataContext, norm)
        FixDatesFromBackend(devices, ['createdAt', 'updatedAt'])
        FixNullsToUndefinedFromBackend(devices)
        dataContext.devices.all.merge(devices)
        norm.entities.devices = undefined
    }
}
