import React from 'react'
import { observer } from 'mobx-react'
import {
    Grid, Drawer, IconButton, Tooltip,
    Typography,
    Slider
} from '@material-ui/core'
import {
    Stop, PlaylistAdd, PlayArrow, ExpandMore, Close,
    Pause, VolumeOff, VolumeMute, VolumeDown, VolumeUp
} from '@material-ui/icons'
import moment from 'moment'

import { DataContext, DiC, entities } from '@shared/common'
import { toJS } from 'mobx'

@observer class Player extends React.Component {

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    get audio() {
        return DiC.get<HTMLAudioElement>(entities.Audio)
    }

    handleAudioError = () => {
        this.handleStop()
    }

    handleCanPlay = (e: Event) => {
        this.audio.addEventListener('timeupdate', this.handleTimeUpdate, false)
        this.audio.addEventListener('ended', this.handleStop, false)
        this.dataCtx.player.state.duration = this.audio.duration
    }

    handleTimeUpdate = (e: Event) => {
        this.dataCtx.player.state.currentTime = this.audio.currentTime
    }

    componentWillUpdate = () => {
        let elemUrl = undefined
        let fileUrl = undefined
        if (!!this.dataCtx.player.played) {
            fileUrl = new URL(this.dataCtx.player.played.fileUrl).pathname
        }
        if (this.audio.src !== '') {
            elemUrl = new URL(this.audio.src).pathname
        }
        if (!!this.dataCtx.player.played && elemUrl !== fileUrl) {
            this.handleEmptify()
            this.audio.src = this.dataCtx.player.played.fileUrl
            this.audio.addEventListener('error', this.handleAudioError, false)
            this.audio.addEventListener('canplay', this.handleCanPlay, false)
            this.audio.play()
            this.dataCtx.player.state.state = 'played'
        }
    }

    componentWillUnmount = () => {
        this.handleEmptify()
        this.dataCtx.player.played = undefined
        this.audio.src = ''
    }

    handleEmptify = () => {
        if (this.audio.src !== '') {
            this.handleStop()
            this.audio.removeEventListener('timeupdate', this.handleTimeUpdate, false)
            this.audio.removeEventListener('ended', this.handleStop, false)
            this.audio.removeEventListener('canplay', this.handleCanPlay, false)
            this.audio.removeEventListener('error', this.handleAudioError, false)
        }
    }

    handlePlayPause = () => {
        this.dataCtx.player.state.playerOpen = true
        if (!!this.audio.error) {
            this.handleStop()
        } else {
            if (this.dataCtx.player.state.state === 'played') {
                this.dataCtx.player.state.state = 'paused'
                this.audio.pause()
            } else {
                this.dataCtx.player.state.state = 'played'
                this.audio.play()
            }
        }
    }

    handleStop = () => {
        this.dataCtx.player.state.state = 'stopped'
        this.audio.pause()
        this.audio.currentTime = 0
        this.dataCtx.player.state.currentTime = 0
    }

    handleCurrentTimeSeek = (currentTime: number) => {
        this.audio.currentTime = currentTime
    }

    handleVolumeSeek = (volume: number) => {
        this.dataCtx.player.state.volume = volume
        this.audio.volume = volume * volume / 256
    }

    handleMuteUnmute = () => {
        this.audio.muted = !this.audio.muted
        this.dataCtx.player.state.muted = this.audio.muted
    }

    handleOpenPlayer = () => {
        this.dataCtx.player.state.playerOpen = true
    }

    handleClosePlayer = () => {
        this.handleEmptify()
        this.dataCtx.player.state.playerOpen = false
        this.dataCtx.player.played = undefined
        this.audio.src = ''
    }

    handleHidePlayer = () => {
        this.dataCtx.player.state.playerOpen = false
    }

    render() {
        return (
            <React.Fragment>
                {!!this.dataCtx.player.played ?
                    <React.Fragment>
                        <Drawer
                            variant="persistent"
                            anchor="bottom"
                            open={this.dataCtx.player.state.playerOpen}
                            onClose={this.handleClosePlayer}
                            onMouseLeave={() => {
                                this.dataCtx.player.state.timerIds.push(window.setTimeout(() => {
                                    this.dataCtx.player.state.playerOpen = false
                                    this.dataCtx.player.state.timerIds.clear()
                                }, 3000))
                            }}
                            onMouseEnter={() => {
                                if (this.dataCtx.player.state.timerIds.length > 0) {
                                    this.dataCtx.player.state.timerIds.forEach(tid => {
                                        window.clearTimeout(tid)
                                    })
                                    this.dataCtx.player.state.timerIds.clear()
                                }
                                this.dataCtx.player.state.playerOpen = true
                            }}
                        >
                            <Grid
                                container
                                direction="column"
                                style={{ width: '100%', minHeight: '88px', padding: '8px' }}
                                wrap="nowrap"
                            >
                                <Grid item container direction="row" wrap="nowrap">
                                    <Grid item >
                                        <Typography
                                            color="textSecondary"
                                        >
                                            {moment.utc(this.dataCtx.player.state.currentTime * 1000).format('HH:mm:ss')}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ flex: '1 1 0', padding: '0 16px' }} >
                                        <Slider
                                            min={0}
                                            max={this.dataCtx.player.state.duration}
                                            value={this.dataCtx.player.state.currentTime}
                                            onChange={(e, v) => (this.handleCurrentTimeSeek(v as number))}
                                        />
                                    </Grid>
                                    <Grid item >
                                        <Typography
                                            color="textSecondary"
                                        >
                                            {moment.utc(this.dataCtx.player.state.duration * 1000).format('HH:mm:ss')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container direction="row" wrap="nowrap">
                                    <Grid item container direction="row" wrap="nowrap" style={{ flex: '1 1 0' }}>
                                        <Grid item container direction="column" style={{ paddingLeft: '24px' }}>
                                            <Grid item >
                                                <Typography
                                                    color="textPrimary"
                                                    style={{ fontSize: 'larger' }}
                                                >
                                                    {this.dataCtx.player.played.name}
                                                </Typography>
                                            </Grid>
                                            {this.dataCtx.player.played.meta ?
                                                <Grid item container direction="row" spacing={1}>
                                                    <React.Fragment>
                                                        {this.dataCtx.player.played.meta.genre ?
                                                            <Grid item>
                                                                <Typography
                                                                    color="textPrimary"
                                                                >
                                                                    {this.dataCtx.player.played.meta.genre}
                                                                </Typography>
                                                            </Grid> : []
                                                        }
                                                        {this.dataCtx.player.played.meta.sampleRate ?
                                                            <Grid item>
                                                                <Typography
                                                                    color="textSecondary"
                                                                >
                                                                    {`${this.dataCtx.player.played.meta.sampleRate}Hz `}
                                                                </Typography>
                                                            </Grid> : []
                                                        }
                                                        {this.dataCtx.player.played.meta.bitrate ?
                                                            <Grid item>
                                                                <Typography
                                                                    color="textSecondary"
                                                                >
                                                                    {`${this.dataCtx.player.played.meta.bitrate}kbps`}
                                                                </Typography>
                                                            </Grid> : []
                                                        }
                                                    </React.Fragment>
                                                </Grid> : []
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ width: '64px' }}>
                                        <Tooltip title={this.dataCtx.player.state.state === 'played' ? 'Pause' : 'Play'} placement="top">
                                            <IconButton
                                                aria-label={this.dataCtx.player.state.state === 'played' ? 'Pause' : 'Play'}
                                                color="primary"
                                                onClick={this.handlePlayPause}
                                            >
                                                {this.dataCtx.player.state.state === 'played' ?
                                                    <Pause
                                                        style={{ height: '40px', width: '40px' }}
                                                    /> :
                                                    <PlayArrow
                                                        style={{ height: '40px', width: '40px' }}
                                                    />
                                                }
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        wrap="nowrap"
                                        style={{ flex: '1 1 0' }}
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Tooltip title="Stop" placement="top">
                                                <IconButton
                                                    aria-label="Stop"
                                                    onClick={this.handleStop}
                                                >
                                                    <Stop />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item >
                                            <Tooltip title={this.dataCtx.player.state.muted ? 'Unmute' : 'Mute'} placement="top">
                                                <IconButton
                                                    aria-label={this.dataCtx.player.state.muted ? 'Unmute' : 'Mute'}
                                                    color={this.dataCtx.player.state.muted ? 'primary' : 'default'}
                                                    onClick={this.handleMuteUnmute}
                                                >
                                                    {this.dataCtx.player.state.muted ?
                                                        <VolumeOff /> :
                                                        this.dataCtx.player.state.volume === 0 ?
                                                            <VolumeMute /> :
                                                            this.dataCtx.player.state.volume <= 8 ?
                                                                <VolumeDown /> :
                                                                <VolumeUp />
                                                    }
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item style={{ minWidth: '128px', padding: '0 24px' }} >
                                            <Slider
                                                style={{ padding: '32px 0' }}
                                                min={0}
                                                max={16}
                                                step={1}
                                                color="primary"
                                                value={this.dataCtx.player.state.volume}
                                                onChange={(e, v) => (this.handleVolumeSeek(v as number))}
                                            />
                                        </Grid>
                                        <div style={{ flex: '1 1 0' }} />
                                        <Grid item >
                                            <Tooltip title="Hide Player" placement="top">
                                                <IconButton
                                                    aria-label="Hide"
                                                    onClick={this.handleHidePlayer}
                                                >
                                                    <ExpandMore />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item >
                                            <Tooltip title="Close Player" placement="top">
                                                <IconButton
                                                    aria-label="Close"
                                                    onClick={this.handleClosePlayer}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Drawer>
                        <div
                            style={{
                                position: 'fixed',
                                height: '16px',
                                width: '100vw',
                                bottom: 0,
                                left: 0
                            }}
                            onMouseEnter={() => {
                                if (this.dataCtx.player.state.timerIds.length > 0) {
                                    this.dataCtx.player.state.timerIds.forEach(tid => {
                                        window.clearTimeout(tid)
                                    })
                                    this.dataCtx.player.state.timerIds.clear()
                                }
                                this.dataCtx.player.state.playerOpen = true
                            }}
                        />
                    </React.Fragment > : []
                }
            </React.Fragment >
        )
    }
}

export default Player
