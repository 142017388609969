import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ICollectionOptions } from '../api-client'
import { Gender, GenderSchema, storeGender } from '../models'
import { IDomainService } from '.'

const entityName = 'gender'

@injectable()
export class GendersService implements IDomainService<Gender> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)
        storeGender(this.dataContext, norm)
        return norm.result
    }

    async fetch(options?: ICollectionOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(GenderSchema))
        if (!!order && order.length > 0) {
            this.dataContext.genders.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async get(id: number): Promise<Gender> {
        let result: any
        if (id > 0) {
            result = await this.apiClient.getObject(id, entityName)
        } else {
            return Promise.reject()
        }
        this.storeContext(result, GenderSchema)
        return this.dataContext.genders.all.get(result.id)!
    }

    async createOrUpdate(gender: Gender): Promise<Gender> {
        let result: any
        if (gender.id > 0) {
            result = await this.apiClient.patchObject(gender, entityName)
        } else {
            result = await this.apiClient.postObject(gender, entityName)
        }
        this.storeContext(result, GenderSchema)
        return this.dataContext.genders.all.get(result.id)!
    }

    clear() {
        this.dataContext.genders.ordered.clear()
    }
}
