import { PageContext } from './page-context'

import { IDynamicService } from '../../services/dynamic-attributes-service'

import { PaginatedListState } from '../generic/list-state'
import { DynamicState, DynamicFormState } from '../generic/dynamic-attributes'
import { FormState } from '../generic/form-state'
import { FiltersState } from '../generic/filters-state'
import { injectable } from 'inversify'
import { DiC, entities } from '../../ioc'
import { IDomainService } from '../../services'
import { getAbilityByUser, IActionType } from '../../models'
import { DataContext } from '../../data-context'

@injectable()
export class PageService<A> {
    get domainService() {
        return DiC.get<IDomainService<A>>(this.entity)
    }

    get ctx() {
        return DiC.get<PageContext>(this.entity + 'Ctx')
    }

    get dynamicAttributesService() {
        return DiC.get<IDynamicService>(entities.DynamicAttribute)
    }

    get globalCtx() {
        return DiC.get<DataContext>(entities.DataContext).global
    }

    getAbility(action: IActionType, dynamic?: boolean): boolean {
        if (!!this.globalCtx.loggedInUser) {
            return getAbilityByUser(this.globalCtx.loggedInUser, this.entity.toLowerCase(), action, dynamic)
        }
        return false
    }

    readonly paginatedList = new PaginatedListState(this.ctx, this.domainService)
    readonly dynamicAttributes = new DynamicState(this.entity, this.dynamicAttributesService, this.ctx)
    readonly filtersState = new FiltersState(this.ctx, this.paginatedList)

    createFormState() {
        return new FormState(this.domainService, this.ctx)
    }

    createDynamicFormState() {
        return new DynamicFormState(this.entity, this.dynamicAttributesService, this.ctx)
    }

    constructor(
        readonly entity: string,
    ) { }

    async init(id?: number): Promise<A | void> {
        await this.dynamicAttributes.initDynamicAttributes()
        return id ? await this.paginatedList.getEntity(id) : await this.paginatedList.initPaginatedList()
    }
}
