import React from 'react'
import { Container, Typography, TextField, Button } from '@material-ui/core'
import { observable } from 'mobx'
import validate from 'validate.js'
import {
  ILoginData, LoginPageService,
  LoginPageContext, DataContext,
  entities, DiC
} from '@shared/common'
import { observer } from 'mobx-react'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import { RouteComponentProps, withRouter } from 'react-router-dom'

export const loginDataConstraints = {
  userEmail: { presence: { allowEmpty: false }, email: true },
  password: { presence: { allowEmpty: false } },
}

interface IProps extends WithSnackbarProps, RouteComponentProps {
  pageService: LoginPageService,
  pageContext: LoginPageContext,
}

@observer class LoginPage extends React.Component<IProps> {
  @observable loginData: ILoginData = { userEmail: '', password: '' }
  @observable errors?: { [field: string]: string[] } = {}

  get globalCtx() {
    return DiC.get<DataContext>(entities.DataContext).global
  }
  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const err = validate(this.loginData, loginDataConstraints)
    this.errors = err
    if (!!err) {
      console.log(err)
      //his.errors && this.errors.map(e => this.props.enqueueSnackbar(`Failed to login: ${e}`, {variant: 'error'}));
      return
    }
    this.props.pageService.doLogin(this.loginData)
      .then(res => {
        if (res) {
          this.globalCtx.loggedInUser = res[0]
          this.globalCtx.version = res[1]
          this.props.history.push('/')
          this.props.enqueueSnackbar(`Successfully logged in`, { variant: 'info' })
        }
      }).catch(e => {
        console.error('Failed to login', e)
        this.globalCtx.loggedInUser = null
        if (e && e.response && e.response.status === 401) {
          this.props.enqueueSnackbar('Invalid email or password', { variant: 'error' })
        } else {
          this.props.enqueueSnackbar('Failed to login', { variant: 'error' })
        }
      })
  }

  private GetErrorsForField = (key: string) => {
    return this.errors && this.errors[key] ? this.errors[key].join(', ') : undefined
  }

  render() {

    return (
      <React.Fragment>
        <Container maxWidth="xs">
          <Typography variant="h5">
            Sign in
          </Typography>
          <form onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              error={!!this.GetErrorsForField('userEmail')}
              helperText={this.GetErrorsForField('userEmail') || ''}
              name="email"
              autoComplete="email"
              autoFocus
              value={this.loginData.userEmail || ''}
              onChange={e => this.loginData.userEmail = e.target.value}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={this.loginData.password || ''}
              onChange={e => this.loginData.password = e.target.value}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Sign In
            </Button>
          </form>
        </Container>
      </React.Fragment>
    )
  }
}
export default withRouter(withSnackbar(LoginPage))