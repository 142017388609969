import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ICollectionOptions } from '../api-client'
import { Address, AddressSchema, storeAddress } from '../models'
import { IDomainService } from '.'
import { toJS } from 'mobx'

const entityName = 'address'

@injectable()
export class AddressesService implements IDomainService<Address> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)
        storeAddress(this.dataContext, norm)
        return norm.result
    }

    async fetch(options?: ICollectionOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(AddressSchema))
        if (!!order && order.length > 0) {
            this.dataContext.addresses.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async get(id: number): Promise<Address> {
        let result: any
        if (id > 0) {
            result = await this.apiClient.getObject(id, entityName)
        } else {
            return Promise.reject()
        }
        this.storeContext(result, AddressSchema)
        return this.dataContext.addresses.all.get(result.id)!
    }

    async createOrUpdate(address: Address): Promise<Address> {
        const clone = {
            ...address,
            dynamicAttributes: toJS(address.dynamicAttributes),
        }
        let result: any
        if (address.id > 0) {
            result = await this.apiClient.patchObject(clone, entityName)
        } else {
            result = await this.apiClient.postObject(clone, entityName)
        }
        this.storeContext(result, AddressSchema)
        return this.dataContext.addresses.all.get(result.id)!
    }

    clear() {
        this.dataContext.addresses.ordered.clear()
    }
}
