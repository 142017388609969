import React from 'react'
import { Grid } from '@material-ui/core'
import { observer } from 'mobx-react'
import { computed } from 'mobx'
import { DemoRecording } from '@shared/common'
import { FileDialogWV, TextFieldWV } from '@shared/ui'

interface IProps {
    demoRecording: DemoRecording
    errors?: any
    idx?: number
}
@observer
class FileUploadForm extends React.Component<IProps> {

    @computed get currentFile() {
        return (this.getFile() as any)
    }

    setFile = (value: File) => {
        this.props.demoRecording.file = value as any
    }
    getFile = () => {
        return this.props.demoRecording.file as any
    }

    render() {
        return (
            <Grid item container direction="row" spacing={2}>
                <Grid item xs>
                    <FileDialogWV
                        key={`demo-file-${this.props.idx}`}
                        fileUrl={this.props.demoRecording.fileUrl}
                        fileName={this.currentFile ? this.currentFile.name : ''}
                        errors={this.props.errors ? this.props.errors.fileUrl : undefined}
                        type="audio"
                        onChange={file => {
                            if (!!file) {
                                this.setFile(file)
                                this.props.demoRecording.fileUrl = URL.createObjectURL(file)
                            }
                            this.props.errors && (this.props.errors.fileUrl = '')
                        }}
                        label="Audio File"
                        required
                    />
                </Grid>
                <Grid item xs>
                    <TextFieldWV
                        label="Comment"
                        //errors={this.props.errors.get('comment')}
                        value={this.props.demoRecording.comment}
                        onChange={value => {
                            this.props.demoRecording.comment = value
                        }}
                    />
                </Grid>
            </Grid>

        )
    }
}

export default FileUploadForm