import React from 'react'
import { observer } from 'mobx-react'
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { IAttribute } from '@shared/common'

interface IProps<A> {
    attributes: IAttribute<A>[]
    visibleColumnsNames: string[]
}
@observer
class ColumnEditor<A> extends React.Component<IProps<A>>  {
    handleChangeVisibility = (a: IAttribute<A>) => {
        let idx = this.props.visibleColumnsNames.indexOf(a.name)
        if (idx >= 0) {
            this.props.visibleColumnsNames.splice(idx, 1)
        } else {
            this.props.visibleColumnsNames.push(a.name)
        }
    }

    render() {
        return (
            <FormControl>
                {/* <FormLabel>Select visible columns</FormLabel> */}
                <FormGroup>
                    {this.props.attributes.map((a) => (
                        <FormControlLabel
                            key={a.name}
                            style={{ marginLeft: 0 }}
                            control={
                                <Checkbox
                                    // <Switch
                                    checked={this.props.visibleColumnsNames.indexOf(a.name) >= 0}
                                    onChange={() => this.handleChangeVisibility(a)}
                                    value={a.label}
                                    color="primary"
                                />
                            }
                            label={a.label}
                        />
                    ))}
                </FormGroup>
                {/* <FormHelperText>Olala</FormHelperText> */}
            </FormControl>
        )
    }
}

export default ColumnEditor