import { types as t, Instance } from 'mobx-state-tree'

import { ProgramFilters, Program, ProgramFields } from '../../models'
import { GenericValue } from '../../models/generic'

export const ProgramsPageContext = t
    .model({
        formOpen: t.boolean,
        filtersOpen: t.boolean,
        currentPage: t.number,
        totalPages: t.number,
        perPage: t.optional(t.number, 5),
        filters: t.map(GenericValue),
        newProgram: Program,
        isLoading: t.boolean,
        sort: t.maybe(t.model({
            field: ProgramFields,
            dir: t.enumeration(['asc', 'desc'])
        })),
    })
    .actions(self => ({
        toggleForm() {
            self.formOpen = !self.formOpen
        },
        toggleFilters() {
            self.filtersOpen = !self.filtersOpen
        },
        setPage(page: number) {
            self.currentPage = page
        }
    }))

export type ProgramsPageContext = Instance<typeof ProgramsPageContext>

export const programsPageDefaults = () => ({
    formOpen: false,
    filtersOpen: false,
    currentPage: 1,
    totalPages: 1,
    isLoading: false,
    // newProgram: emptyProgram
})
