import React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import {
    IAttribute, ISelectConfig, DynamicAttribute,
    ISortOptions, IRepository,
    PageService, PageContext
} from '@shared/common'
import ColumnEditor from './ColumnEditor'
import {
    IconButton, Popover, Tooltip,
    Table, TableRow, TableHead, TableCell,
    TableBody, TableSortLabel, Paper, TablePagination,
    Toolbar, Checkbox, Grid, Typography,
    TextField, InputAdornment
} from '@material-ui/core'
import { Visibility, Refresh, Clear, ExpandMore, Search, BorderTop } from '@material-ui/icons'
import { withStyles, createStyles, Theme, WithStyles, lighten, fade, darken } from '@material-ui/core/styles'
import { IFilters } from './Filters'
import GenericFilterForm from './GenericFilterForm'
import DynamicAttributeList from './dynamic_attributes/DynamicAttributeList'
import RawDataTable from './RawDataTable'
import ProgressBar from './ProgressBar'
import StyledBadge from './StyledBadge'

const styles = (theme: Theme) => createStyles({
    toolbarSelect: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.type === 'light' ? lighten(theme.palette.secondary.light, 0.88) : darken(fade(theme.palette.secondary.main, 0.2), 0.68),
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius
    },
    stickyHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        lineHeight: 'normal',
        boxShadow: `${theme.palette.type === 'light' ? lighten(fade(theme.palette.divider, 1), 0.88) : darken(fade(theme.palette.divider, 1), 0.68)} 0 1px 0 0`
    },
    stickyColumn: {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'inherit',
        boxShadow: `${theme.palette.type === 'light' ? lighten(fade(theme.palette.divider, 1), 0.88) : darken(fade(theme.palette.divider, 1), 0.68)} 1px 0 0 0`,
    },
    stickyBoth: {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 3,
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        lineHeight: 'normal',
        boxShadow: `${theme.palette.type === 'light' ? lighten(fade(theme.palette.divider, 1), 0.88) : darken(fade(theme.palette.divider, 1), 0.68)} 1px 1px 0 0`
    },
})

const StyledTableRow = withStyles((theme: Theme) => createStyles({
    root: {
        backgroundColor: 'inherit',
        '&$hover': {
            '&:hover': {
                backgroundColor: 'inherit',
                backgroundImage: `linear-gradient(
                ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.07)' : 'rgba(255, 255, 255, 0.14)'}, 
                ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.07)' : 'rgba(255, 255, 255, 0.14)'})`
            },
        },
        '&$selected': {
            backgroundColor: 'inherit',
            backgroundImage: `linear-gradient(
                ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)'}, 
                ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.08)'})`
        },
    },
    hover: {},
    selected: {}
}))(TableRow)

interface IProps<A> {
    repository: IRepository<A>
    attributes: IAttribute<A>[]
    dynamic?: DynamicAttribute[]
    ctx: PageContext
    service: PageService<A>
    selectConfig?: ISelectConfig
    actions?: (entity: A) => JSX.Element[]
    filterFields?: IFilters
    tabsComponent?: React.ReactNode
    relatedModel?: string
    toolbarPaddingRight?: boolean
}

@observer
class DataTable<A> extends React.Component<IProps<A> & WithStyles<typeof styles>> {
    @observable sort: ISortOptions = { field: '', dir: 'desc' }
    @observable colunmEditorAncher?: EventTarget & HTMLButtonElement
    @observable selectedTableAncher?: EventTarget & HTMLButtonElement
    @observable cellAnchor?: EventTarget & HTMLButtonElement
    @observable openPopovers: Map<number, Map<number, boolean>> = new Map()
    // @observable copied?: {
    //     rowIdx: number
    //     colIdx: number
    // } = undefined
    // timerId?: number
    @observable visibleColumnsNames: string[] = []
    selectRef: A[] = this.props.repository.selected
    activeSearch: string = this.props.ctx.searchQuery

    @observable actionsRef = React.createRef<HTMLTableHeaderCellElement>()
    @observable checkboxRef = React.createRef<HTMLTableHeaderCellElement>()

    get actionsWidth() {
        return !!this.actionsRef && !!this.actionsRef.current ? this.actionsRef.current.offsetWidth : 0
    }

    get checkboxWidth() {
        return !!this.checkboxRef && !!this.checkboxRef.current ? this.checkboxRef.current.offsetWidth : 0
    }

    componentDidMount() {
        this.visibleColumnsNames = this.props.attributes.filter(a => a.show).map(a => a.name)
        if (!!this.props.selectConfig) {
            switch (this.props.selectConfig.usage) {
                case 'filter':
                    this.selectRef = this.props.repository.filterSelected
                    break
                case 'create-or-update':
                default:
                    this.selectRef = this.props.repository.selected

            }
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps<A>>) {
        if (this.props.attributes.length !== prevProps.attributes.length) {
            this.visibleColumnsNames = this.props.attributes.filter(a => a.show).map(a => a.name)
        }
    }

    // handleOnCopy = (rowIdx: number, colIdx: number) => {
    //     this.copied = { colIdx: colIdx, rowIdx: rowIdx }
    //     if (!!this.timerId) {
    //         window.clearTimeout(this.timerId)
    //     }
    //     this.timerId = window.setTimeout(() => {
    //         this.copied = undefined
    //     }, 3000)
    // }

    // cellCopied = (rowIdx: number, colIdx: number): boolean => {
    //     return !!this.copied && this.copied.colIdx === colIdx && this.copied.rowIdx === rowIdx
    // }

    handleNestedPopoverSwitch = (rowIdx: number, colIdx: number) => {
        let m1 = this.openPopovers.get(rowIdx)
        let m2 = m1 ? m1 : new Map<number, boolean>()
        let v = m2.get(colIdx)
        m2.set(colIdx, v ? !v : true)
        this.openPopovers.set(rowIdx, m2)
    }
    nestedPopoverOpened = (rowIdx: number, colIdx: number): boolean => {
        return this.openPopovers.get(rowIdx) ? (
            this.openPopovers.get(rowIdx)!.get(colIdx) ?
                this.openPopovers.get(rowIdx)!.get(colIdx)! : false
        ) : false
    }

    handleSort = (columnName: string) => {
        if (this.sort.field === columnName) {
            this.sort.dir = this.sort.dir === 'asc' ? 'desc' : 'asc'
        } else {
            this.sort.field = columnName
            this.sort.dir = 'desc'
        }

        this.props.service.paginatedList.sort({ field: this.sort.field, dir: this.sort.dir })
    }

    handlePageChange = async (page: number) => {
        await this.props.service.paginatedList.goToPage(page)
    }

    handleRefresh = () => {
        this.props.service.paginatedList.refresh()
    }

    handleChangeRowsPerPage = (e: any) => {
        this.props.ctx.perPage = e.target.value
        this.handlePageChange(1)
    }

    handleChangeSelection = (entity: A) => {
        if (!!this.props.selectConfig && (!!this.props.selectConfig.disableItem ? !this.props.selectConfig.disableItem(entity) : true)) {
            if (this.selectRef.length === 0) {
                this.selectedTableAncher = undefined
            }
            let idx = this.selectRef.indexOf(entity)
            if (this.props.selectConfig.type === 'single') {
                this.handleClearSelection()
                if (idx < 0) {
                    this.selectRef.push(entity)
                    if (!!this.props.selectConfig.onSelect) {
                        this.props.selectConfig.onSelect()
                    }
                }
            } else {
                if (idx >= 0) {
                    this.selectRef.splice(idx, 1)
                } else {
                    this.selectRef.push(entity)
                }
            }
        }
    }

    handleClearSelection = () => {
        if (!!this.props.selectConfig) {
            this.selectRef.length = 0
        }
    }

    handleSelectAllClick = async () => {
        if (!!this.props.selectConfig && this.props.selectConfig.type !== 'single') {
            if (this.selectRef.length < this.props.ctx.itemsCount) {
                const backup = [...this.props.repository.ordered.values()]
                await this.props.service.paginatedList.getAll()
                this.handleClearSelection()
                this.selectRef.push(...this.props.repository.ordered.values())
                this.props.repository.ordered = backup
            } else {
                this.handleClearSelection()
            }
        }
    }

    handleSearch = () => {
        if (this.props.ctx.searchQuery.length > 0) {
            this.props.service.paginatedList.initPaginatedList()
            this.activeSearch = this.props.ctx.searchQuery
        } else {
            this.handleClearSearch()
        }
    }

    handleClearSearch = () => {
        this.props.ctx.searchQuery = ''
        this.props.service.paginatedList.initPaginatedList()
        this.activeSearch = ''
    }

    render() {
        const classes = this.props.classes
        return (
            <React.Fragment>
                <Paper style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }} elevation={4}>
                    <ProgressBar loading={this.props.ctx.isLoading} topRounded color={!!this.props.selectConfig && this.selectRef.length > 0 ? 'secondary' : 'primary'} />
                    <Toolbar
                        className={!!this.props.selectConfig && this.selectRef.length > 0 ? classes.toolbarSelect : ''}
                        style={Object.assign(
                            { paddingRight: '8px', marginTop: '-4px' },
                            !!this.props.toolbarPaddingRight && { paddingRight: '76px' }
                        )}
                    >
                        {!!this.props.tabsComponent ?
                            this.props.tabsComponent : <div style={{ flex: '1 1 0' }} />
                        }
                        {!!this.props.selectConfig && this.selectRef.length > 0 ?
                            <React.Fragment>
                                <IconButton
                                    color="secondary"
                                    disabled={this.props.ctx.isLoading}
                                    onClick={(e) => {
                                        this.selectedTableAncher = e.currentTarget
                                    }}
                                >
                                    <ExpandMore
                                        style={Object.assign(
                                            {
                                                transform: 'rotate(0deg)',
                                                transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                            },
                                            !!this.selectedTableAncher && { transform: 'rotate(180deg)' }
                                        )}
                                    />
                                </IconButton>
                                <Popover
                                    anchorEl={this.selectedTableAncher}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={!!this.selectedTableAncher}
                                    onClose={() => { this.selectedTableAncher = undefined }}
                                >
                                    <RawDataTable
                                        values={this.selectRef}
                                        attributes={this.props.attributes}
                                        actions={(entity: A) => [
                                            <Tooltip
                                                key={(entity as any).id}
                                                title="Remove from selection"
                                                placement="top-end"
                                            >
                                                <IconButton
                                                    aria-label="Remove"
                                                    color="secondary"
                                                    onClick={e => this.handleChangeSelection(entity)}
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </Tooltip>
                                        ].concat(!!this.props.actions ? this.props.actions(entity) : [])}
                                    />
                                </Popover>
                                <Typography color="inherit" variant="subtitle1" style={{ whiteSpace: 'nowrap' }}>
                                    {this.selectRef.length} of {this.props.ctx.itemsCount} selected
                                </Typography>
                                <IconButton
                                    color="secondary"
                                    disabled={this.props.ctx.isLoading}
                                    onClick={this.handleClearSelection}
                                >
                                    <Clear />
                                </IconButton>
                            </React.Fragment> : []
                        }
                        <TextField
                            onKeyPress={(e) => {
                                switch (e.key) {
                                    case 'Enter':
                                        e.stopPropagation()
                                        this.handleSearch()
                                        break
                                    default: return
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            title={!!this.activeSearch ? `Active search: ${this.activeSearch}` : ''}
                                            placement="top"
                                        >
                                            <StyledBadge
                                                variant="dot"
                                                color="primary"
                                                invisible={this.activeSearch === this.props.ctx.searchQuery}
                                            >
                                                <IconButton
                                                    color={!!this.activeSearch ? 'primary' : 'default'}
                                                    onClick={this.handleSearch}
                                                >
                                                    <Search />
                                                </IconButton>
                                            </StyledBadge>
                                        </Tooltip>
                                        <IconButton
                                            onClick={this.handleClearSearch}
                                        >
                                            <Clear />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Search"
                            value={this.props.ctx.searchQuery}
                            onChange={e => {
                                this.props.ctx.searchQuery = e.target.value
                            }}
                        />
                        <IconButton
                            disabled={this.props.ctx.isLoading}
                            onClick={this.handleRefresh}
                        >
                            <Refresh />
                        </IconButton>
                        {!!this.props.filterFields ?
                            <GenericFilterForm
                                pageService={this.props.service}
                                ctx={this.props.ctx}
                                filterFields={this.props.filterFields}
                            /> : []
                        }
                        {this.props.service.getAbility('read', true) && !!this.props.dynamic ? <DynamicAttributeList
                            srv={this.props.service}
                            ctx={this.props.ctx}
                            dynamic={this.props.dynamic}
                            relatedModel={this.props.relatedModel}
                        /> : []}
                        <IconButton
                            disabled={this.props.ctx.isLoading}
                            onClick={(e) => {
                                this.colunmEditorAncher = e.currentTarget
                            }}
                        >
                            <Visibility />
                        </IconButton>
                        <Popover
                            PaperProps={{
                                style: {
                                    padding: '8px'
                                }
                            }}
                            anchorEl={this.colunmEditorAncher}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={!!this.colunmEditorAncher}
                            onClose={() => { this.colunmEditorAncher = undefined }}
                        >
                            <ColumnEditor
                                attributes={this.props.attributes}
                                visibleColumnsNames={this.visibleColumnsNames}
                            />
                        </Popover>
                    </Toolbar>
                    <div style={{ flex: '1 1 auto', overflow: 'auto' }}>
                        <Table>
                            <TableHead>
                                <StyledTableRow>
                                    {!!this.props.selectConfig ?
                                        <TableCell ref={this.checkboxRef} padding="checkbox" className={classes.stickyBoth}>
                                            {this.props.ctx.itemsCount > 0 && this.props.selectConfig.type === 'multiple' ?
                                                <Checkbox
                                                    indeterminate={
                                                        this.selectRef.length > 0 &&
                                                        this.selectRef.length < this.props.ctx.itemsCount
                                                    }
                                                    checked={
                                                        this.selectRef.length >= this.props.ctx.itemsCount
                                                    }
                                                    onChange={this.handleSelectAllClick}
                                                    inputProps={{ 'aria-label': 'Select-all-checkbox' }}
                                                /> : []
                                            }
                                        </TableCell> : []}
                                    {!!this.props.actions ? <TableCell ref={this.actionsRef} className={classes.stickyBoth} style={{ left: this.checkboxWidth }}>
                                        Actions
                                </TableCell> : []}
                                    {this.props.attributes.filter(attribute => this.visibleColumnsNames.indexOf(attribute.name) >= 0).map((attribute, idx) => (
                                        <TableCell
                                            key={`thcell-${attribute.name}-${idx}`}
                                            className={attribute.name === 'id' ? classes.stickyBoth : classes.stickyHeader}
                                            style={Object.assign({ whiteSpace: 'pre' }, attribute.name === 'id' && { left: this.checkboxWidth + this.actionsWidth })}
                                            sortDirection={!attribute.notSortable && this.sort.field === attribute.name ? this.sort.dir : false}
                                        >
                                            {attribute.notSortable ?
                                                attribute.label :
                                                <Tooltip
                                                    title="Click to sort"
                                                    enterDelay={300}
                                                >
                                                    <TableSortLabel
                                                        active={this.sort.field === attribute.name}
                                                        direction={this.sort.field === attribute.name ? this.sort.dir : undefined}
                                                        onClick={() => this.handleSort(attribute.name)}
                                                    >
                                                        {attribute.label}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            }
                                        </TableCell>
                                    ))
                                    }
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.repository.ordered.map((value, rowIdx) => {
                                    const rowDisabled = !!this.props.selectConfig && !!this.props.selectConfig.disableItem ? this.props.selectConfig.disableItem(value) : false
                                    return (
                                        <StyledTableRow
                                            hover={!!this.props.selectConfig && !rowDisabled}
                                            onClick={() => this.handleChangeSelection(value)}
                                            role={!!this.props.selectConfig ? 'checkbox' : undefined}
                                            aria-checked={!!this.props.selectConfig ? this.selectRef.indexOf(value) >= 0 : undefined}
                                            tabIndex={!!this.props.selectConfig ? -1 : undefined}
                                            selected={!!this.props.selectConfig ? this.selectRef.indexOf(value) >= 0 && !rowDisabled : undefined}
                                            key={(value as any).id}
                                        >
                                            {!!this.props.selectConfig ?
                                                <TableCell padding="checkbox" className={classes.stickyColumn}>
                                                    <Checkbox
                                                        checked={this.selectRef.indexOf(value) >= 0}
                                                        disabled={rowDisabled}
                                                    />
                                                </TableCell> : []
                                            }
                                            {!!this.props.actions ?
                                                <TableCell
                                                    className={classes.stickyColumn}
                                                    style={{ left: this.checkboxWidth }}
                                                    onClick={() => this.handleChangeSelection(value)}
                                                >
                                                    <Grid container direction="row" wrap="nowrap" >
                                                        {this.props.actions(value)}
                                                    </Grid>
                                                </TableCell>
                                                : []
                                            }
                                            {this.props.attributes.filter(attribute => this.visibleColumnsNames.indexOf(attribute.name) >= 0).map((attribute, colIdx) => {
                                                const haveNested: boolean = !!attribute.nested && !!attribute.nested(value) && !!attribute.value(value)
                                                return (
                                                    <React.Fragment
                                                        key={`tcell-${attribute.name}-${colIdx}`}
                                                    >
                                                        {haveNested ?
                                                            <React.Fragment>
                                                                <TableCell>
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        wrap="nowrap"
                                                                        alignItems="center"
                                                                    >
                                                                        <Grid item style={rowDisabled ? { color: 'rgba(0, 0, 0, 0.54)' } : undefined}>
                                                                            {attribute.display(value)}
                                                                        </Grid>
                                                                        <IconButton
                                                                            onClick={(e) => {
                                                                                this.cellAnchor = e.currentTarget
                                                                                this.handleNestedPopoverSwitch(rowIdx, colIdx)
                                                                                this.handleChangeSelection(value)
                                                                            }}
                                                                            color="primary"
                                                                            edge="end"
                                                                            style={{
                                                                                margin: '4px'
                                                                            }}
                                                                        >
                                                                            <ExpandMore
                                                                                fontSize="small"
                                                                                style={Object.assign(
                                                                                    {
                                                                                        transform: 'rotate(0deg)',
                                                                                        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                                                                    },
                                                                                    this.nestedPopoverOpened(rowIdx, colIdx) && { transform: 'rotate(180deg)' }
                                                                                )}
                                                                            />
                                                                        </IconButton>
                                                                        {/* COPY TO CLIPBOARD
                                                                    <Tooltip
                                                                        title={this.cellCopied(rowIdx, colIdx) ?
                                                                            'Copied!' : 'Copy to clipboard'
                                                                        }
                                                                    >
                                                                        <CopyToClipboard
                                                                            text={attribute.display(value).toString()}
                                                                            options={{ format: 'text/plain' }}
                                                                        >
                                                                            <IconButton
                                                                                edge="start"
                                                                                onClick={() => {
                                                                                    this.handleOnCopy(rowIdx, colIdx)
                                                                                }}
                                                                            >
                                                                                {this.cellCopied(rowIdx, colIdx) ?
                                                                                    <Check
                                                                                        style={{ color: successColor }}
                                                                                        fontSize="small"
                                                                                    /> :
                                                                                    <FileCopy
                                                                                        fontSize="small"
                                                                                    />
                                                                                }
                                                                            </IconButton>
                                                                        </CopyToClipboard>
                                                                    </Tooltip> */}
                                                                    </Grid>
                                                                </TableCell>
                                                                <Popover
                                                                    onClick={() => {
                                                                        this.handleChangeSelection(value)
                                                                    }}
                                                                    PaperProps={{
                                                                        style: {
                                                                            width: 'fit-content',
                                                                            minWidth: '320px',
                                                                            maxWidth: '640px',
                                                                            overflow: 'auto',
                                                                            padding: '8px'
                                                                        }
                                                                    }}
                                                                    anchorEl={this.cellAnchor}
                                                                    anchorOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'center',
                                                                    }}
                                                                    open={this.nestedPopoverOpened(rowIdx, colIdx)}
                                                                    onClose={() => {
                                                                        this.handleNestedPopoverSwitch(rowIdx, colIdx)
                                                                        this.cellAnchor = undefined
                                                                    }}
                                                                >
                                                                    {attribute.nested!(value)}
                                                                </Popover>
                                                            </React.Fragment> :
                                                            <TableCell
                                                                style={Object.assign(
                                                                    {},
                                                                    rowDisabled && { color: 'rgba(0, 0, 0, 0.54)' },
                                                                    attribute.name === 'id' && { left: this.checkboxWidth + this.actionsWidth }
                                                                )}
                                                                className={attribute.name === 'id' ? classes.stickyColumn : ''}
                                                            >
                                                                {attribute.display(value)}
                                                            </TableCell>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                            }
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        count={this.props.ctx.itemsCount}
                        rowsPerPage={this.props.ctx.perPage}
                        page={this.props.ctx.currentPage - 1}
                        onChangePage={(e, page) => { this.handlePageChange(page + 1) }}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper >
            </React.Fragment >
        )
    }
}

export default withStyles(styles)(DataTable)
