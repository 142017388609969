import React from 'react'
import { Tabs, Tab, Grid } from '@material-ui/core'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

interface ITabs {
    label: string
    content: React.ReactNode
    onChange?: () => void
}

interface IProps {
    tabs: ITabs[]
}

@observer class InfoTabs extends React.Component<IProps> {
    @observable selectedTab = 0

    handleTabChange = (e: object, value: number) => {
        if (value >= 0 && value < this.props.tabs.length && !!this.props.tabs[value].onChange) {
            this.props.tabs[value].onChange!()
        }
        this.selectedTab = value

    }

    handleRenderTab = (idx: number) => {
        return idx >= 0 && idx < this.props.tabs.length ? this.props.tabs[idx].content : []
    }

    render() {
        return (
            <React.Fragment>
                {this.props.tabs.length > 0 ? (this.props.tabs.length > 1 ?
                    <React.Fragment>
                        <Grid container direction="row">
                            <div style={{ flex: '1 1 auto' }} />
                            <Grid
                                item
                                style={{
                                    maxWidth: '100%'
                                }}
                            >
                                <Tabs
                                    value={this.selectedTab}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    onChange={this.handleTabChange}
                                >
                                    {this.props.tabs.map((t, idx) => {
                                        return <Tab wrapped label={t.label} key={`info-tab-${idx}`} />
                                    })}
                                </Tabs>
                            </Grid>
                            <div style={{ flex: '1 1 auto' }} />
                        </Grid>
                        {this.handleRenderTab(this.selectedTab)}
                    </React.Fragment> :
                    <React.Fragment>
                        {this.props.tabs[0].content}
                    </React.Fragment>
                ) : []
                }
            </React.Fragment>
        )
    }
}

export default InfoTabs