import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ICollectionOptions } from '../api-client'
import { Content, ContentSchema, storeContent } from '../models'
import { IDomainService } from '.'
import { toJS } from 'mobx'
import { FixNullsToUndefinedFromBackend, FixDatesFromBackend } from './utils'

const entityName = 'content'

@injectable()
export class ContentsService implements IDomainService<Content> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)
        storeContent(this.dataContext, norm)
        return norm.result
    }

    async fetch(options?: ICollectionOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(ContentSchema))
        if (!!order && order.length > 0) {
            this.dataContext.contents.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async get(id: number): Promise<Content> {
        let result: any
        if (id > 0) {
            result = await this.apiClient.getObject(id, entityName)
        } else {
            return Promise.reject()
        }
        this.storeContext(result, ContentSchema)
        return this.dataContext.contents.all.get(result.id)!
    }

    async createOrUpdate(content: Content, audioFile?: File, uploadCallback?: (progressEvent: any) => void): Promise<Content> {
        const clone = {
            ...content,
            id: content.id > 0 ? content.id : undefined,
            clientIds: content.clients.map(c => c.id),
            clients: null,
            performances_attributes: content.performances.map(p => {
                return {
                    performer_id: p.performer!!.id,
                    performer_role_id: p.performerRole!!.id,
                    comment: p.comment
                    //copyright_attributes: { agreement_number: '1' }
                }
            }),
            performances: null,
            content_records_attributes: content.contentRecords.map(cr => {
                return {
                    ...toJS(cr),
                    id: cr.id > 0 ? cr.id : undefined,
                }
            }),
            contentRecords: null,
            dynamicAttributes: toJS(content.dynamicAttributes),
        }
        const attachments = audioFile ? { 'demo': audioFile } : undefined
        let result: any
        if (content.id > 0) {
            result = await this.apiClient.patchObjectWithAttachments(clone, entityName, attachments, uploadCallback)
        } else {
            result = await this.apiClient.postObjectWithAttachments(clone, entityName, attachments, uploadCallback)
        }
        this.storeContext(result, ContentSchema)
        return this.dataContext.contents.all.get(result.id)!
    }

    clear() {
        this.dataContext.contents.ordered.clear()
    }
}
