import React from 'react'
import { observable, computed, toJS, reaction } from 'mobx'
import { observer } from 'mobx-react'
import {
    Dialog, Grid,
    DialogContent, DialogActions,
    DialogTitle, IconButton
} from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import ClientsPage from '../clients/ClientsPage'
import LocationsPage from '../locations/LocationsPage'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import {
    Store, emptyStore, FormState,
    Undoer, UndoerState,
    emptyUndoerState, DiC,
    PageService, entities,
    PageContext, DataContext,
    storeConstraints
} from '@shared/common'
import {
    FAB, TextFieldWV,
    SelectViaDialog,
    ConfirmationDialog, TimeFieldWV,
    DateRangePickerWV, FileDialogWV,
    DynamicAttributeAdornment,
    UndoButtons, DialogButtons
} from '@shared/ui'
import { closeUnsavedForm } from '../shared/UnsavedDialog'

interface IProps extends WithSnackbarProps {
    store?: Store
}

@observer class StoreForm extends React.Component<IProps> {
    @observable store: Store = emptyStore as any
    @observable formState: FormState<Store> = this.pageService.createFormState()

    undoer: Undoer<Store> = new Undoer<Store>()
    debounceUndoer: () => void
    @observable undoerState: UndoerState = emptyUndoerState

    componentDidMount() {
        this.debounceUndoer = AwesomeDebouncePromise(() => { this.undoer.Push(toJS(this.store), this.undoerState) }, 500)
        reaction(
            () => toJS(this.store),
            () => {
                if (!this.undoerState.dontReact) {
                    this.undoerState.waiting = true
                    this.debounceUndoer()
                }
            },
        )
    }

    get pageService() {
        return DiC.get<PageService<Store>>(entities.StoreSrv)
    }

    get ctx() {
        return DiC.get<PageContext>(entities.StoreCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    @computed get isEditMode() {
        return !!this.props.store
    }

    @computed get dynamicAttributes() {
        return this.dataCtx.dynamicAttributes.ofEntity('store')
    }

    @computed get canCreate() {
        return this.pageService.getAbility('create')
    }

    @computed get canUpdate() {
        return this.pageService.getAbility('update')
    }

    handleClear = () => {
        this.formState.clearErrors()
        if (this.isEditMode) {
            this.handleFillStore(toJS(this.props.store!))
        } else {
            this.handleFillStore(emptyStore as any)
        }
    }

    handleFillStore = (newStore: Store) => {
        this.store = newStore
        this.store.dynamicAttributes = new Map(Object.entries(newStore.dynamicAttributes)) as any
    }

    openForm = () => {
        if (this.isEditMode) {
            this.handleFillStore(toJS(this.props.store!))
        } else {
            this.handleFillStore(toJS(this.store))
        }
        this.formState.openForm()
    }

    closeForm = () => {
        closeUnsavedForm(this.isEditMode, () => this.formState.closeForm(), toJS(!!this.props.store ? this.props.store : emptyStore), toJS(this.store))
    }

    handleSubmit = () => {
        this.formState.save(this.store, storeConstraints, this.store.photo)
            .then(res => {
                if (res.isOk) {
                    this.formState.closeForm()
                    this.undoerState.dontReact = true
                    this.store = emptyStore as any
                    this.undoerState.dontReact = false
                    this.undoer = new Undoer<Store>()
                    this.pageService.paginatedList.refresh()
                } else {
                    this.props.enqueueSnackbar('You have filled one or more fields incorrectly.', { variant: 'error' })
                }
            })
    }

    handleFillClientSelect = () => {
        this.dataCtx.clients.selected.clear()
        if (!!this.store.client && this.store.client.id > 0 && this.dataCtx.clients.all.has(this.store.client.id.toString())) {
            this.dataCtx.clients.selected[0] = this.dataCtx.clients.all.get(this.store.client.id.toString())!
        }
    }

    handleSelectClient = () => {
        if (this.dataCtx.clients.selected && this.dataCtx.clients.selected.length > 0) {
            this.store.client = this.dataCtx.clients.selected[0]
        }
        this.formState.clearErrorsForField('client')
    }

    handleClearClientSelect = () => {
        this.store.client = undefined
        this.dataCtx.clients.selected.clear()
        this.formState.clearErrorsForField('client')
    }

    handleFillLocationSelect = () => {
        this.dataCtx.locations.selected.clear()
        if (!!this.store.location && this.store.location.id > 0 && this.dataCtx.locations.all.has(this.store.location.id.toString())) {
            this.dataCtx.locations.selected[0] = this.dataCtx.locations.all.get(this.store.location.id.toString())!
        }
    }

    handleSelectLocation = () => {
        if (this.dataCtx.locations.selected && this.dataCtx.locations.selected.length > 0) {
            this.store.location = this.dataCtx.locations.selected[0]
        }
        this.formState.clearErrorsForField('location')
    }

    handleClearLocationSelect = () => {
        this.store.location = undefined
        this.dataCtx.locations.selected.clear()
        this.formState.clearErrorsForField('location')
    }

    // handleSelectGroupStore = () => {
    //     if (this.dataCtx.groupStores.selected && this.dataCtx.groupStores.selected.length > 0) {
    //         this.store.groupStore = this.dataCtx.groupStores.selected[0]
    //     }
    //     this.pageService.formState.clearErrorsForField("groupStore")
    // }

    // handleClearGroupStoreSelect = () => {
    //     this.store.groupStore = undefined
    //     this.dataCtx.groupStores.selected.clear()
    //     this.pageService.formState.clearErrorsForField("groupStore")
    // }

    render() {
        if ((!this.isEditMode || !this.canUpdate) && (!this.canCreate || this.isEditMode)) {
            return (<React.Fragment />)
        }
        return (
            <React.Fragment>
                {this.isEditMode ?
                    <IconButton
                        aria-label="Edit"
                        onClick={this.openForm}
                    >
                        <Edit />
                    </IconButton>
                    :
                    <FAB
                        ariaLabel="Add"
                        onClick={this.openForm}
                        disabled={this.ctx.isLoading}
                    >
                        <Add />
                        Add Store
                    </FAB>
                }

                <Dialog
                    open={this.formState.formOpen}
                    onClose={this.closeForm}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle id="form-dialog-title">
                        {this.isEditMode ? 'Edit' : 'Add'} Store
                    </DialogTitle>
                    <DialogContent>
                        <Grid container direction="column">
                            <Grid item style={{ width: '100%' }}>
                                <TextFieldWV
                                    required
                                    label="Name"
                                    errors={this.formState.getErrorsForField('name')}
                                    value={this.store.name}
                                    onChange={value => {
                                        this.store.name = value
                                        this.formState.clearErrorsForField('name')
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ width: '100%' }}>
                                <SelectViaDialog
                                    errors={this.formState.getErrorsForField('client')}
                                    required
                                    label="Client"
                                    placeholder="Select Client"
                                    onOpen={this.isEditMode ? (openModal) => ConfirmationDialog('Do you really want change client?',
                                        '', () => {
                                            this.handleFillClientSelect()
                                            openModal()
                                        }) : (openModal) => {
                                            this.handleFillClientSelect()
                                            openModal()
                                        }}
                                    value={!!this.store.client ? [{ value: this.store.client.id.toString(), label: this.store.client.name }] : undefined}
                                    onSelected={this.handleSelectClient}
                                    onClear={this.isEditMode ? () => ConfirmationDialog('Do you really want clear client?',
                                        '', this.handleClearClientSelect) : this.handleClearClientSelect}
                                >
                                    <ClientsPage
                                        selectConfig={{
                                            type: 'single',
                                            usage: 'create-or-update'
                                        }}
                                    />
                                </SelectViaDialog>
                            </Grid>
                            <Grid item style={{ width: '100%' }}>
                                <SelectViaDialog
                                    errors={this.formState.getErrorsForField('location')}
                                    required
                                    label="Location"
                                    placeholder="Select Location"
                                    onOpen={(openModal) => {
                                        this.handleFillLocationSelect()
                                        openModal()
                                    }}
                                    value={!!this.store.location ? [{ value: this.store.location.id.toString(), label: this.store.location.name }] : undefined}
                                    onSelected={this.handleSelectLocation}
                                    onClear={this.handleClearLocationSelect}
                                >
                                    <LocationsPage
                                        selectConfig={{
                                            type: 'single',
                                            usage: 'create-or-update'
                                        }}
                                    />
                                </SelectViaDialog>
                            </Grid>
                            <Grid item style={{ width: '100%' }}>
                                <Grid container direction="row">
                                    <Grid item style={{ flex: '1 1 0' }}>
                                        <TimeFieldWV
                                            required
                                            label="Open time"
                                            value={this.store.openTime}
                                            errors={this.formState.getErrorsForField('openTime')}
                                            onChange={value => {
                                                this.store.openTime = value
                                                this.formState.clearErrorsForField('openTime')
                                            }}
                                        />
                                    </Grid>
                                    <Grid item style={{ width: '24px' }} />
                                    <Grid item style={{ flex: '1 1 0' }}>
                                        <TimeFieldWV
                                            required
                                            label="Close time"
                                            value={this.store.closeTime}
                                            errors={this.formState.getErrorsForField('closeTime')}
                                            onChange={value => {
                                                this.store.closeTime = value
                                                this.formState.clearErrorsForField('closeTime')
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ width: '100%' }}>
                                <DateRangePickerWV
                                    required
                                    label="Open Date"
                                    mode="single"
                                    startDate={this.store.openDate}
                                    errors={this.formState.getErrorsForField('openDate')}
                                    onChange={(begin, end) => {
                                        this.store.openDate = begin
                                        this.formState.clearErrorsForField('openDate')
                                    }}
                                    onDatesReset={() => {
                                        this.store.openDate = new Date()
                                        this.formState.clearErrorsForField('openDate')
                                    }}
                                />
                            </Grid>
                            {/* <Grid item >
                                <SelectViaDialog
                                    errors={this.formState.getErrorsForField("groupStore")}
                                    required
                                    label="Group Store"
                                    placeholder="Select Group Store"
                                    value={!!this.store.groupStore ? [{ value: this.store.groupStore.id.toString(), label: this.store.groupStore.name }] : undefined}
                                    onSelected={this.handleSelectGroupStore}
                                    onClear={this.handleClearGroupStoreSelect}
                                >
                                    <GroupStoresPage selectType="single" />
                                </SelectViaDialog>
                            </Grid> */}
                            <Grid item style={{ width: '100%' }}>
                                <TextFieldWV
                                    type="number"
                                    label="Square"
                                    errors={this.formState.getErrorsForField('square')}
                                    value={this.store.square}
                                    onChange={value => {
                                        this.store.square = parseFloat(value)
                                        this.formState.clearErrorsForField('squre')
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ width: '100%' }}>
                                <TextFieldWV
                                    label="Store Format"
                                    errors={this.formState.getErrorsForField('storeFormat')}
                                    value={this.store.storeFormat}
                                    onChange={value => {
                                        this.store.storeFormat = value
                                        this.formState.clearErrorsForField('storeFormat')
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ width: '100%' }}>
                                <FileDialogWV
                                    fileUrl={this.store.photoUrl}
                                    type="image"
                                    fileName={!!this.store.photo ? (this.store.photo as any).name : undefined}
                                    errors={this.formState.getErrorsForField('photoUrl')}
                                    onClear={() => {
                                        this.store.photo = undefined
                                        this.store.photoUrl = undefined
                                        this.formState.clearErrorsForField('photoUrl')
                                    }}
                                    onChange={file => {
                                        if (!!file) {
                                            this.store.photo = file as any
                                            this.store.photoUrl = URL.createObjectURL(file)
                                        }
                                        this.formState.clearErrorsForField('photoUrl')
                                    }}
                                    label="Photo"
                                />
                            </Grid>
                            {this.dynamicAttributes ?
                                <DynamicAttributeAdornment
                                    attributes={this.dynamicAttributes}
                                    values={this.store.dynamicAttributes}
                                    errors={this.formState.errors}
                                />
                                : <React.Fragment />
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <DialogButtons
                            onConfirm={this.handleSubmit}
                            confirmText="Save"
                            onCancel={this.closeForm}
                            onReset={this.handleClear}
                            resetText={`${this.isEditMode ? 'Reset' : 'Clear'} Fields`}
                            additionalActions={
                                <UndoButtons
                                    onUndo={() => {
                                        this.undoer.Undo(this.handleFillStore, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    onRedo={() => {
                                        this.undoer.Redo(this.handleFillStore, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    state={this.undoerState}
                                />
                            }
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withSnackbar(StoreForm)