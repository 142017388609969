import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { Content, ContentSchema, storeContent } from '.'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend } from '../services/utils'

export const PlaylistEntry = t
    .model('playlist_entry', {
        id: t.identifierNumber,
        position: t.number,
        content: t.maybe(t.reference(Content)),
    })

export type PlaylistEntry = Instance<typeof PlaylistEntry>

export const emptyPlaylistEntry = {
    id: 0,
    position: 0,
    content: undefined,
}

export const playlistEntryConstraints = {
    position: {
        presence: true, numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 0
        }
    },
    content: { presence: { allowEmpty: false } },
}

export const PlaylistEntrySchema = new schema.Entity('playlist_entries', {
    content: ContentSchema,
})

export function storePlaylistEntry(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const playlistEntries = norm.entities.playlist_entries
    if (!!playlistEntries && Object.keys(playlistEntries).length > 0) {
        storeContent(dataContext, norm)
        FixNullsToUndefinedFromBackend(playlistEntries)
        dataContext.playlistEntries.all.merge(playlistEntries)
        norm.entities.playlist_entries = undefined
    }
}