import React from 'react'
import ClientsPage from '../clients/ClientsPage'
import { DataContext, IFilterPreset, ISelectType } from '@shared/common'
import { IFilters } from '@shared/ui'

export const programFilters = (dataCtx: DataContext, preset?: IFilterPreset): IFilters => ({
    name: { label: 'Name', type: 'string' },
    isAdvertisement: {
        label: 'Type',
        type: 'select',
        selectParams: {
            multiple: true,
            options: [{ label: 'Music', value: 'false' }, { label: 'Advertisement', value: 'true' }],
        }
    },
    state: {
        label: 'State',
        type: 'select',
        selectParams: {
            multiple: true,
            options: ['approved', 'created', 'disabled'].map(t => ({ value: t, label: t })),
        }
    },
    clientId: {
        label: 'Client',
        type: 'dialog',
        dialogParams: {
            children: (selectType?: ISelectType) => {
                return <ClientsPage
                    selectConfig={
                        selectType ? {
                            type: selectType,
                            usage: 'filter'
                        } : undefined
                    }
                    presetFilters={preset}
                />
            },
            selectByIds: (ids: string[]) => {
                dataCtx.clients.filterSelected.clear()
                ids.forEach(id => {
                    if (dataCtx.clients.all.has(id)) {
                        dataCtx.clients.filterSelected.push(dataCtx.clients.all.get(id)!)
                    }
                })
            },
            onSelected: () => {
                if (!!dataCtx.clients.filterSelected && dataCtx.clients.filterSelected.length > 0) {
                    return dataCtx.clients.filterSelected.map(v => ({ value: v.id.toString(), label: v.name }))
                }
                return undefined
            },
            onClear: () => {
                dataCtx.clients.filterSelected.clear()
            },
            multiple: true
        }
    },
    startTime: { label: 'Start Time', type: 'time', isRange: true },
    stopTime: { label: 'Stop Time', type: 'time', isRange: true },
    startDate: { label: 'Start Date', type: 'date', isRange: true },
    endDate: { label: 'End Date', type: 'date', isRange: true },
})
