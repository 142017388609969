import { types as t, Instance } from 'mobx-state-tree'
import { AUser } from '../models'

export const GlobalRepository = t.model({
    loggedInUser: t.maybeNull(AUser),
    version: t.optional(t.string, '')
}).views(self => ({
    get hasSelectedClient() {
        return !!self.loggedInUser && !!self.loggedInUser.selectedClient
    },
    get selectedClient() {
        return this.hasSelectedClient ? self.loggedInUser!.selectedClient : undefined
    }
}))

export type GlobalRepository = Instance<typeof GlobalRepository>
