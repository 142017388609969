import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { Content } from './content'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend } from '../services/utils'

export const ContentRecord = t
    .model('content_record', {
        id: t.identifierNumber,
        forPlay: t.boolean,
        comment: t.maybe(t.string),
        fileUrl: t.optional(t.string, ''),
        fileName: t.optional(t.string, ''),
        bitrate: t.optional(t.number, 0),
        sampleRate: t.optional(t.number, 0),
        durationMs: t.optional(t.number, 0),
    }).volatile(() => ({
        file: null
    }))
    .actions(self => ({
        setFile(value: File) {
            self.file = value as any
        },
        getFile(): any {
            return self.file as any
        }
    }))

export type ContentRecord = Instance<typeof ContentRecord>

export function playableContentRecord(content?: Content): ContentRecord | undefined {
    if (!!content && content.contentRecords.length > 0) {
        let forPlay = content.contentRecords.filter(cr => cr.forPlay)
        return forPlay.length > 0 ? forPlay[0] : undefined//content.contentRecords[0]
    }
    return undefined
}

export const emptyContentRecord = {
    id: 0,
    forPlay: false,
    comment: '',
    fileUrl: '',
    fileName: '',
    bitrate: 0,
    sampleRate: 0,
    durationMs: 0,
    file: undefined,
}

export const contentRecordConstraints = {
    fileUrl: { presence: { allowEmpty: false, message: '^File can\'t be blank' } },
}

export const ContentRecordSchema = new schema.Entity('content_records', {})

export function storeContentRecord(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const contentRecords = norm.entities.content_records
    if (!!contentRecords && Object.keys(contentRecords).length > 0) {
        FixNullsToUndefinedFromBackend(contentRecords)
        dataContext.contentRecords.all.merge(contentRecords)
        norm.entities.content_records = undefined
    }
}
