import React from 'react'

import { Client, IAttribute } from '@shared/common'
import { CardMedia, Typography, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { InfoCardContent, AttributesList } from '@shared/ui'

interface IProps {
    attributes: IAttribute<Client>[]
    entity: Client
}

const ClientInfo: React.FunctionComponent<IProps> = ({ entity, attributes }) => (
    <React.Fragment>
        <InfoCardContent
            header="Client"
            //subHeader={`ID = ${entity.id}`}
            subHeader={entity.id > 0 ?
                <Link component={RouterLink} to={`/clients/${entity.id}`} target="_blank">
                    {`${window.location.origin}/clients/${entity.id}`}
                </Link> : undefined
            }
            content={
                <AttributesList
                    entity={entity}
                    attrs={attributes.filter(a => a.show && a.name !== 'logoUrl')}
                />
            }
            media={
                <CardMedia
                    style={{
                        objectFit: 'contain'
                    }}
                    component="img"
                    src={entity.logoUrl}
                />
            }
        />
    </React.Fragment>
)

export default ClientInfo