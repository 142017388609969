import { types as t, Instance } from 'mobx-state-tree'

export const GenericSimpleValue = t.union(
    t.number,
    t.string,
    t.boolean,
)

export type GenericSimpleValue = Instance<typeof GenericSimpleValue>

export const GenericRangeValue = t.model('generic_range_value', {
    from: t.maybe(GenericSimpleValue),
    to: t.maybe(GenericSimpleValue),
})

export type GenericRangeValue = Instance<typeof GenericRangeValue>

export const GenericValue = t.maybe(t.union(
    GenericSimpleValue,
    t.array(GenericSimpleValue),
    GenericRangeValue,
))

export type GenericValue = Instance<typeof GenericValue>
