import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ICollectionOptions } from '../api-client'
import { Client, ClientSchema, storeClient } from '../models'
import { IDomainService } from '.'
import { toJS } from 'mobx'

const entityName: string = 'client'

@injectable()
export class ClientsService implements IDomainService<Client> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)
        storeClient(this.dataContext, norm)
        return norm.result
    }

    async fetch(options?: ICollectionOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(ClientSchema))
        if (!!order && order.length > 0) {
            this.dataContext.clients.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async get(id: number): Promise<Client> {
        let result: any
        if (id > 0) {
            result = await this.apiClient.getObject(id, entityName)
        } else {
            return Promise.reject()
        }
        this.storeContext(result, ClientSchema)
        return this.dataContext.clients.all.get(result.id)!
    }

    async createOrUpdate(client: Client, logoImage?: File): Promise<Client> {
        const clone = {
            ...client,
            logo: !!client.logoUrl ? null : 'nil',
            dynamicAttributes: toJS(client.dynamicAttributes),
        }
        const attachments = logoImage ? { 'logo': logoImage } : undefined
        let result: any
        if (client.id > 0) {
            result = await this.apiClient.patchObjectWithAttachments(clone, entityName, attachments)
        } else {
            result = await this.apiClient.postObjectWithAttachments(clone, entityName, attachments)
        }
        this.storeContext(result, ClientSchema)
        return this.dataContext.clients.all.get(result.id)!
    }

    clear() {
        this.dataContext.clients.ordered.clear()
    }
}
