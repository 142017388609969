import React from 'react'
import { observer } from 'mobx-react'
import { injectable } from 'inversify'
import { computed, IReactionDisposer, reaction, toJS, comparer } from 'mobx'
import PerformerAttributes, { performerFilters } from './PerformerAttributes'
import PerformerForm from './PerformerForm'
import PerformerInfo from './PerformerInfo'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Player from '../contents/Player'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import {
  RouteParams, ISelectConfig,
  IFilterPreset, DiC, PageService,
  Performer, entities,
  PageContext, DataContext,
  Gender, Relevance,
  emptyPerformer
} from '@shared/common'
import {
  dynamicAttrFilters, InfoPage,
  DataTable, InfoModal,
  DeleteEntity
} from '@shared/ui'

interface IProps extends RouteComponentProps<RouteParams>, WithSnackbarProps {
  selectConfig?: ISelectConfig
  presetFilters?: IFilterPreset
}

@injectable()
@observer
class PerformersPage extends React.Component<IProps> {
  reactionDisposers: IReactionDisposer[] = []

  get pageService() {
    return DiC.get<PageService<Performer>>(entities.PerformerSrv)
  }

  get ctx() {
    return DiC.get<PageContext>(entities.PerformerCtx)
  }

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  get genderPageService() {
    return DiC.get<PageService<Gender>>(entities.GenderSrv)
  }

  get relevancePageService() {
    return DiC.get<PageService<Relevance>>(entities.RelevanceSrv)
  }

  @computed get dynamicAttributes() {
    return this.dataCtx.dynamicAttributes.ofEntity('performer')
  }

  @computed get allColumns() {
    return PerformerAttributes(this.dataCtx)
  }

  @computed get filterFields() {
    let dynamic = this.dynamicAttributes ? dynamicAttrFilters(this.dynamicAttributes) : {}
    return { ...performerFilters(this.dataCtx), ...dynamic }
  }

  @computed get id() {
    return this.props.match.path === '/performers/:id?' && !!this.props.match.params.id ?
      parseInt(this.props.match.params.id) : undefined
  }

  @computed get performer() {
    return !!this.id && this.dataCtx.performers.all.has(this.id.toString()) ?
      this.dataCtx.performers.all.get(this.id.toString())! :
      emptyPerformer as any
  }

  @computed get presetFilters() {
    let preset: IFilterPreset = !!this.props.presetFilters ? this.props.presetFilters : {}
    // if (this.dataCtx.global.hasSelectedClient) {
    //   presetClient(preset, this.dataCtx.global.selectedClient!.id)
    // }
    return preset.performer
  }

  @computed get canRead() {
    return this.pageService.getAbility('read')
  }

  @computed get canDelete() {
    return this.pageService.getAbility('delete')
  }

  handleInitInfo = () => {
    this.pageService.filtersState.handlePresetFilters(this.presetFilters)
    if (this.id) {
      this.pageService.init(this.id)
        .catch(_ => this.props.enqueueSnackbar('Could not find requested performer', { variant: 'error' }))
    } else {
      this.pageService.init()
    }
  }

  componentDidMount() {
    this.pageService.dynamicAttributesService.fetch('performer_role')
    Promise.all([
      this.genderPageService.domainService.fetch(),
      this.relevancePageService.domainService.fetch(),
    ])
    this.handleInitInfo()
    this.reactionDisposers.push(reaction(
      () => toJS(this.presetFilters),
      () => {
        if (!!!this.id) {
          this.pageService.filtersState.handlePresetFilters(this.presetFilters)
          this.pageService.filtersState.applyFilters()
        }
      },
      {
        equals: comparer.structural
      }
    ))
    window.addEventListener('popstate', this.handleInitInfo)
  }

  componentWillUnmount() {
    this.reactionDisposers.forEach(d => d())
    window.removeEventListener('popstate', this.handleInitInfo)
  }

  render() {
    if (!this.canRead) {
      return (<React.Fragment />)
    }
    return (
      <React.Fragment>
        <Player />
        {!!this.id ?
          <InfoPage>
            <PerformerInfo
              entity={this.performer}
              attributes={this.allColumns}
            />
          </InfoPage> :
          <React.Fragment>
            <PerformerForm />
            <DataTable
              repository={this.dataCtx.performers}
              attributes={this.allColumns}
              dynamic={this.dynamicAttributes}
              ctx={this.ctx}
              service={this.pageService}
              selectConfig={this.props.selectConfig}
              filterFields={this.filterFields}
              actions={(p: Performer) => [
                <InfoModal
                  key={`${p.id}-info`}
                  id={p.id}
                >
                  <PerformerInfo
                    entity={p}
                    attributes={this.allColumns}
                  />
                </InfoModal>,
                <PerformerForm key={p.id} performer={p} />,
                <DeleteEntity key={`${p.id}-delete`} can={this.canDelete} listProvider={this.pageService.paginatedList} id={p.id} />
              ]}
            />
          </React.Fragment >
        }
      </React.Fragment >
    )
  }
}

export default withRouter(withSnackbar(PerformersPage))
