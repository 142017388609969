import { types as t } from 'mobx-state-tree'
import { DynamicAttribute, dynamicAttributeConstraints } from '../../../models'
import { ISubmitResult, ISubmitErrorFormat } from '../form-state'
import validate from '../../../utils/extended-validate'
import camelcaseKeys from 'camelcase-keys'
import { IDynamicService } from '../../../services'
import { observable } from 'mobx'

interface IUIContext {
    isLoading: boolean
    dynListOpen: boolean
}

export const DynamicAttributesUIContext = t.model({
    dynListOpen: t.optional(t.boolean, false),
})

export class DynamicState {
    constructor(
        readonly entityName: string,
        readonly domainService: IDynamicService,
        readonly ctx: IUIContext
    ) { }

    async initDynamicAttributes() {
        await this.fetchDynamicAttributes()
    }

    async fetchDynamicAttributes() {
        this.ctx.isLoading = true
        try {
            await this.domainService.fetch(this.entityName)
        } catch (e) {
            console.error(e)
        }
        finally {
            this.ctx.isLoading = false
        }
    }

    async deleteDynamicAttribute(id: number): Promise<void> {
        this.ctx.isLoading = true
        try {
            await this.domainService.delete(id)
        } catch (e) {
            console.error(e)
        }
        finally {
            this.ctx.isLoading = false
            return Promise.resolve()
        }
    }

    openDynList() {
        this.ctx.dynListOpen = true
    }

    closeDynList() {
        this.ctx.dynListOpen = false
    }
}

export class DynamicFormState {
    constructor(
        readonly entityName: string,
        readonly domainService: IDynamicService,
        readonly ctx: IUIContext
    ) { }

    @observable formOpen: boolean = false
    @observable errors: Map<string, any> = new Map<string, any>()

    openForm() {
        this.formOpen = true
    }

    closeForm() {
        this.formOpen = false
    }

    getErrorsForField(key: string) {
        return this.errors && this.errors.get(key) ? this.errors.get(key) : undefined
    }

    setErrors(errors: Map<string, any>) {
        this.clearErrors()
        errors.forEach((v, k) => {
            this.errors.set(k, v)
        })
    }

    clearErrorsForField(field: string) {
        if (this.errors && this.errors.get(field)) {
            this.errors.delete(field)
        }
    }

    clearErrors() {
        if (this.errors) {
            this.errors.clear()
        }
    }

    async save(attribute: DynamicAttribute, entityName?: string): Promise<ISubmitResult<DynamicAttribute>> {
        this.clearErrors()
        const err = validate(attribute, dynamicAttributeConstraints)
        if (!!err) {
            this.setErrors(new Map(Object.entries(err)))
            return Promise.resolve({
                isOk: false
            })
        } else {
            this.ctx.isLoading = true
            try {
                const dyn: DynamicAttribute = await this.domainService.createOrUpdate(attribute, entityName ? entityName : this.entityName)
                return Promise.resolve({ isOk: true, response: dyn as DynamicAttribute })
            } catch (e) {
                console.error(e)
                if (!!e.response && e.response.status === 400) {
                    let response = camelcaseKeys(e.response.data, { deep: true }) as any
                    if (!!response) {
                        this.setErrors(new Map(Object.entries((response as ISubmitErrorFormat).errors)))
                    }
                    return Promise.resolve({
                        isOk: false,
                        response: response
                    })
                }

                return Promise.reject()
            }
            finally {
                this.ctx.isLoading = false
            }
        }
    }
}
