import React from 'react'
import { Tabs, Tab, Grid } from '@material-ui/core'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

interface IProps {
    labels: string[]
    selected?: number
    primaryColor?: boolean
    onTabChange: (value: number) => void
}

@observer
class DataTabs extends React.Component<IProps> {
    @observable selectedTab = 0

    componentDidMount() {
        if (!!this.props.selected && this.props.selected >= 1 && this.props.selected < this.props.labels.length) {
            this.selectedTab = this.props.selected
            this.props.onTabChange(this.selectedTab)
        }
    }

    handleTabChange = (e: object, value: number) => {
        this.selectedTab = value
        this.props.onTabChange(value)
    }

    render() {
        return (
            <React.Fragment>
                {this.props.labels.length && this.props.labels.length > 1 ?
                    <Grid container direction="row">
                        <div style={{ flex: '1 1 0' }} />
                        <Grid
                            item
                            style={{
                                maxWidth: '100%'
                            }}
                        >
                            <Tabs
                                value={this.selectedTab}
                                indicatorColor={this.props.primaryColor ? 'primary' : 'secondary'}
                                textColor={this.props.primaryColor ? 'primary' : 'secondary'}
                                variant="scrollable"
                                scrollButtons="auto"
                                onChange={this.handleTabChange}
                            >
                                {this.props.labels.map((tl, idx) => {
                                    return <Tab wrapped label={tl} key={`data-tab-${idx}`} />
                                })}
                            </Tabs>
                        </Grid>
                        <div style={{ flex: '1 1 0' }} />
                    </Grid> : []
                }
            </React.Fragment>
        )
    }
}

export default DataTabs