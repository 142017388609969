import { types as t, Instance } from 'mobx-state-tree'
import { Store } from '../models'

export const StoresRepository = t.model({
    all: t.map(Store),
    ordered: t.array(t.reference(Store)),
    selected: t.array(t.reference(Store)),
    filterSelected: t.array(t.reference(Store)),
})

export type StoresRepository = Instance<typeof StoresRepository>