import { ILoginData, IAuthService } from '../../services/auth-service'
import { LoginPageContext } from './login-page-context'

export class LoginPageService {
    constructor(
        readonly authService: IAuthService,
        readonly ctx: LoginPageContext
    ) { }

    async doLogin(loginData: ILoginData) {
        return await this.authService.login(loginData)
    }
}