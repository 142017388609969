import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import ProgramAttributes from './ProgramAttributes'
import {
  Fab, Typography, Grid, ExpansionPanel,
  ExpansionPanelSummary, ExpansionPanelDetails,
  Paper, Dialog, DialogTitle, DialogContent,
  DialogActions, Button, Container, IconButton
} from '@material-ui/core'
import { Add, ExpandMore, Remove } from '@material-ui/icons'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import DevicesPage from '../devices/DevicesPage'
import DeviceAttributes from '../devices/DeviceAttrbiutes'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import {
  Program, emptyMusicProgram,
  DiC, PageService, entities,
  PageContext, DataContext,
  ProgramService, Device
} from '@shared/common'
import {
  AttributesList, RawDataTable,
  ConfirmationDialog, DialogButtons
} from '@shared/ui'

type TParams = { id?: string | undefined; }

interface IProps extends WithSnackbarProps, RouteComponentProps<TParams> {
}

@observer
class ProgramAssignPage extends React.Component<IProps> {
  @observable program: Program = emptyMusicProgram as any
  @observable isOpen: boolean = false

  openModal = () => {
    this.isOpen = true
  }

  closeModal = () => {
    this.isOpen = false
  }

  handleSelect = () => {
    // this.props.onSelected();
    this.closeModal()
  }

  get pageService() {
    return DiC.get<PageService<Program>>(entities.ProgramSrv)
  }

  get ctx() {
    return DiC.get<PageContext>(entities.ProgramCtx)
  }

  get dataCtx() {
    return DiC.get<DataContext>(entities.DataContext)
  }

  get programsService() {
    return DiC.get<ProgramService>(entities.Program)
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params &&
      this.props.match.params.id && parseInt(this.props.match.params.id)) {
      this.programsService.get(parseInt(this.props.match.params.id)!)
        .then(res => {
          this.program = res
          this.programsService.getAssignments(res).then(devs => {
            this.dataCtx.devices.selected.replace(devs)
          })

        })
    } else {
      this.program = emptyMusicProgram as any
    }
  }

  submitAssign = () => {
    this.programsService.assignProgram(this.program, this.dataCtx.devices.selected.slice()).then(_ => {
      this.props.enqueueSnackbar('Assignment for program has been updated.', { variant: 'success' })
    })
      .catch(_ => {
        this.props.enqueueSnackbar('Failed to assign program', { variant: 'error' })
      })
  }

  get progAttributes() {
    return ProgramAttributes(this.dataCtx)
  }

  render() {
    return (
      <Paper>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {/* Side panel */}
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Assign devices to program
                      </Typography>
                </Grid>
                <Grid item>
                  <AttributesList attrs={this.progAttributes} entity={this.program} />
                </Grid>
                <Grid item>
                  <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                      <Typography>
                        MSS
                          </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <pre>{this.program.mss}</pre>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              {/* device list */}
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Fab
                    color="primary"
                    variant="extended"
                    aria-label="Add"
                    onClick={() => this.openModal()}
                  >
                    <Add />
                    Select devices
                  </Fab>
                </Grid>
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Program will be assigned to:
                      </Typography>
                </Grid>
                <Grid item>

                  <Dialog
                    open={this.isOpen}
                    onClose={this.closeModal}
                    aria-labelledby="modal-dialog-title"
                    fullWidth
                    maxWidth="xl"
                  >
                    <DialogTitle id="modal-dialog-title">Select Devices</DialogTitle>
                    <DialogContent
                      style={{ backgroundColor: '#eeeeee' }}
                    >
                      <DevicesPage
                        selectConfig={{
                          type: 'multiple',
                          usage: 'create-or-update'
                        }}

                        presetFilters={
                          !!this.program.client ? {
                            client: new Map([['id', [this.program.client!.id]]]),
                            device: new Map([['clientId', [this.program.client!.id]]]),
                            store: new Map([['clientId', [this.program.client!.id]]]),
                          } : undefined
                        }
                      />
                    </DialogContent>
                    <DialogActions>
                      <DialogButtons
                        onConfirm={this.handleSelect}
                        onCancel={this.closeModal}
                      />
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item>
                  <RawDataTable
                    values={this.dataCtx.devices.selected}
                    attributes={DeviceAttributes(this.dataCtx)}
                    actions={(p: Device) => [
                      <IconButton
                        key={p.id}
                        aria-label="Remove"
                        color="secondary"
                        size="small"
                        onClick={() => this.dataCtx.devices.selected.remove(p)}
                      >
                        <Remove />
                      </IconButton>

                    ]}
                  />
                </Grid>
                <Grid item>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => ConfirmationDialog('Update program assignment?',
                      'Program assignment will be changed',
                      this.submitAssign)
                    }
                  >
                    Save Assignment
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Container>
      </Paper>
    )
  }
}

export default withRouter(withSnackbar(ProgramAssignPage))