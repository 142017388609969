import { types as t, Instance } from 'mobx-state-tree'
import { DemoRecording } from '../models'

export const DemoRecordingsRepository = t.model({
    all: t.map(DemoRecording),
    ordered: t.array(t.reference(DemoRecording)),
    selected: t.array(t.reference(DemoRecording)),
    filterSelected: t.array(t.reference(DemoRecording)),
})

export type DemoRecordingsRepository = Instance<typeof DemoRecordingsRepository>
