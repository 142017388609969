import { types as t, Instance } from 'mobx-state-tree'
import { Genre } from '../models'

export const GenresRepository = t.model({
    all: t.map(Genre),
    ordered: t.array(t.reference(Genre)),
    selected: t.array(t.reference(Genre)),
    filterSelected: t.array(t.reference(Genre)),
})

export type GenresRepository = Instance<typeof GenresRepository>
