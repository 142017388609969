import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend } from '../services/utils'

export const DemoRecording = t
    .model('demo_recording', {
        id: t.identifierNumber,
        fileUrl: t.optional(t.string, ''),
        comment: t.optional(t.string, '')
    })
    .volatile(() => ({
        file: null
    }))
    .actions(self => ({
        setFile(value: File) {
            self.file = value as any
        },
        getFile(): any {
            return self.file as any
        }
    }))

export type DemoRecording = Instance<typeof DemoRecording>

export const emptyDemoRecording = {
    id: 0,
    fileUrl: '',
    file: undefined
}

export const demoRecordingConstraints = {
    fileUrl: { presence: { allowEmpty: false, message: '^File can\'t be blank' } },
}

export const DemoRecordingSchema = new schema.Entity('demo_recordings')

export function storeDemoRecording(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const demoRecordings = norm.entities.demo_recordings
    if (!!demoRecordings && Object.keys(demoRecordings).length > 0) {
        FixNullsToUndefinedFromBackend(demoRecordings)
        dataContext.demoRecordings.all.merge(demoRecordings)
        norm.entities.demo_recordings = undefined
    }
}