import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ICollectionOptions } from '../api-client'
import { Relevance, RelevanceSchema, storeRelevance } from '../models'
import { IDomainService } from '.'

const entityName = 'relevance'

@injectable()
export class RelevancesService implements IDomainService<Relevance> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)
        storeRelevance(this.dataContext, norm)
        return norm.result
    }

    async fetch(options?: ICollectionOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(RelevanceSchema))
        if (!!order && order.length > 0) {
            this.dataContext.relevances.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async get(id: number): Promise<Relevance> {
        let result: any
        if (id > 0) {
            result = await this.apiClient.getObject(id, entityName)
        } else {
            return Promise.reject()
        }
        this.storeContext(result, RelevanceSchema)
        return this.dataContext.relevances.all.get(result.id)!
    }

    async createOrUpdate(relevance: Relevance): Promise<Relevance> {
        let result: any
        if (relevance.id > 0) {
            result = await this.apiClient.patchObject(relevance, entityName)
        } else {
            result = await this.apiClient.postObject(relevance, entityName)
        }
        this.storeContext(result, RelevanceSchema)
        return this.dataContext.relevances.all.get(result.id)!
    }

    clear() {
        this.dataContext.relevances.ordered.clear()
    }
}
