import React from 'react'

import { CardMedia, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { IAttribute, Store } from '@shared/common'
import { InfoCardContent, AttributesList, InfoTabs, Timeline } from '@shared/ui'

interface IProps {
    attributes: IAttribute<Store>[]
    entity: Store
}

const StoreInfo: React.FunctionComponent<IProps> = ({ entity, attributes }) => (
    <React.Fragment>
        <InfoTabs
            tabs={[
                {
                    label: 'Info',
                    content:
                        <InfoCardContent
                            header="Store"
                            //subHeader={`ID  = ${entity.id}`}
                            subHeader={entity.id > 0 ?
                                <Link component={RouterLink} to={`/stores/${entity.id}`} target="_blank">
                                    {`${window.location.origin}/stores/${entity.id}`}
                                </Link> : undefined
                            }
                            content={
                                <AttributesList
                                    entity={entity}
                                    attrs={attributes.filter(a => a.show && a.name !== 'photoUrl')}
                                />
                            }
                            media={
                                <CardMedia
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '640px'
                                    }}
                                    component="img"
                                    src={entity.photoUrl}
                                />
                            }
                        />
                },
                {
                    label: 'Programs',
                    content:
                        <div style={{ padding: '8px', minWidth: '90vw' }}>
                            <Timeline storeIds={entity.id > 0 ? [entity.id] : []} />
                        </div>
                }
            ]
            }
        />
    </React.Fragment>
)

export default StoreInfo