import { normalize, schema, Schema } from 'normalizr'
import { injectable, inject } from 'inversify'
import { entities } from '../ioc'

import { DataContext } from '../data-context'
import { IApiClient, ICollectionMeta, ICollectionOptions } from '../api-client'
import { PlaylistType, PlaylistTypeSchema, storePlaylistType } from '../models'
import { IDomainService } from '.'

const entityName = 'playlistType'

@injectable()
export class PlaylistTypesService implements IDomainService<PlaylistType> {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    storeContext(data: any, entitySchema: Schema): any {
        const norm = normalize(data, entitySchema)
        storePlaylistType(this.dataContext, norm)
        return norm.result
    }

    async fetch(options?: ICollectionOptions): Promise<ICollectionMeta> {
        const data = await this.apiClient.getCollection(entityName, options)
        const order = this.storeContext(data.values, new schema.Array(PlaylistTypeSchema))
        if (!!order && order.length > 0) {
            this.dataContext.playlistTypes.ordered.replace(order)
        } else {
            this.clear()
        }
        return data.meta!
    }

    async delete(id: number): Promise<void> {
        await this.apiClient.deleteObject(id, entityName)
    }

    async get(id: number): Promise<PlaylistType> {
        let result: any
        if (id > 0) {
            result = await this.apiClient.getObject(id, entityName)
        } else {
            return Promise.reject()
        }
        this.storeContext(result, PlaylistTypeSchema)
        return this.dataContext.playlistTypes.all.get(result.id)!
    }

    async createOrUpdate(playlistType: PlaylistType): Promise<PlaylistType> {
        let result: any
        if (playlistType.id > 0) {
            result = await this.apiClient.patchObject(playlistType, entityName)
        } else {
            result = await this.apiClient.postObject(playlistType, entityName)
        }
        this.storeContext(result, PlaylistTypeSchema)
        return this.dataContext.playlistTypes.all.get(result.id)!
    }

    clear() {
        this.dataContext.playlistTypes.ordered.clear()
    }
}
