import { Instance, types as t } from 'mobx-state-tree'
import { GenericValue } from '.'
import { dottedMapFlattenByKey } from '../utils/map-to-object'

export const FilterTypes = t.enumeration([
    'numeric',
    'string',
    'time',
    'select',
    'date',
    'boolean',
    'duration',
    'search',
    'dialog',
])

export type FilterTypes = Instance<typeof FilterTypes>

export type IFilterValues = Map<string, GenericValue>

export function filterPresetFlatten(key: string, map?: IFilterValues): IFilterValues | undefined {
    if (!!map) {
        let res = dottedMapFlattenByKey(key, map)
        return res.size > 0 ? res : undefined
    }
    return undefined
}

export function presetClient(preset: IFilterPreset, clientId: number) {
    !!preset.client ? preset.client.set('id', [clientId]) : preset.client = new Map([['id', [clientId]]])
    !!preset.store ? preset.store.set('clientId', [clientId]) : preset.store = new Map([['clientId', [clientId]]])
    !!preset.device ? preset.device.set('clientId', [clientId]) : preset.device = new Map([['clientId', [clientId]]])
    !!preset.content ? preset.content.set('clientId', [clientId]) : preset.content = new Map([['clientId', [clientId]]])
    !!preset.playlist ? preset.playlist.set('clientId', [clientId]) : preset.playlist = new Map([['clientId', [clientId]]])
    !!preset.program ? preset.program.set('clientId', [clientId]) : preset.program = new Map([['clientId', [clientId]]])
}

export interface IFilterPreset {
    address?: IFilterValues,
    location?: IFilterValues,
    store?: IFilterValues,
    client?: IFilterValues,
    content?: IFilterValues,
    device?: IFilterValues,
    performer?: IFilterValues,
    playlist?: IFilterValues,
    program?: IFilterValues
}
