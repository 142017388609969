import 'reflect-metadata'
import ReactDOM from 'react-dom'
import React from 'react'
import './index.css'
import AppRootWithSnacks from './app-middleware'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(React.createElement(AppRootWithSnacks), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
