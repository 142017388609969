export const timeZones: string[] = [
    'Abu Dhabi UTC +4',
    'Adelaide UTC +9',
    'Alaska UTC -8',
    'Almaty UTC +6',
    'American Samoa UTC -11',
    'Amsterdam UTC +2',
    'Arizona UTC -7',
    'Astana UTC +6',
    'Athens UTC +3',
    'Atlantic Time (Canada) UTC -3',
    'Auckland UTC +12',
    'Azores UTC 0',
    'Baghdad UTC +3',
    'Baku UTC +4',
    'Bangkok UTC +7',
    'Beijing UTC +8',
    'Belgrade UTC +2',
    'Berlin UTC +2',
    'Bern UTC +2',
    'Bogota UTC -5',
    'Brasilia UTC -3',
    'Bratislava UTC +2',
    'Brisbane UTC +10',
    'Brussels UTC +2',
    'Bucharest UTC +3',
    'Budapest UTC +2',
    'Buenos Aires UTC -3',
    'Cairo UTC +2',
    'Canberra UTC +10',
    'Cape Verde Is. UTC -1',
    'Caracas UTC -4',
    'Casablanca UTC +1',
    'Central America UTC -6',
    'Central Time (US \u0026 Canada) UTC -5',
    'Chatham Is. UTC +12',
    'Chennai UTC +5',
    'Chihuahua UTC -6',
    'Chongqing UTC +8',
    'Copenhagen UTC +2',
    'Darwin UTC +9',
    'Dhaka UTC +6',
    'Dublin UTC +1',
    'Eastern Time (US \u0026 Canada) UTC -4',
    'Edinburgh UTC +1',
    'Ekaterinburg UTC +5',
    'Fiji UTC +12',
    'Georgetown UTC -4',
    'Greenland UTC -2',
    'Guadalajara UTC -5',
    'Guam UTC +10',
    'Hanoi UTC +7',
    'Harare UTC +2',
    'Hawaii UTC -10',
    'Helsinki UTC +3',
    'Hobart UTC +10',
    'Hong Kong UTC +8',
    'Indiana (East) UTC -4',
    'International Date Line West UTC -11',
    'Irkutsk UTC +8',
    'Islamabad UTC +5',
    'Istanbul UTC +3',
    'Jakarta UTC +7',
    'Jerusalem UTC +3',
    'Kabul UTC +4',
    'Kaliningrad UTC +2',
    'Kamchatka UTC +12',
    'Karachi UTC +5',
    'Kathmandu UTC +5',
    'Kolkata UTC +5',
    'Krasnoyarsk UTC +7',
    'Kuala Lumpur UTC +8',
    'Kuwait UTC +3',
    'Kyiv UTC +3',
    'La Paz UTC -4',
    'Lima UTC -5',
    'Lisbon UTC +1',
    'Ljubljana UTC +2',
    'London UTC +1',
    'Madrid UTC +2',
    'Magadan UTC +11',
    'Marshall Is. UTC +12',
    'Mazatlan UTC -6',
    'Melbourne UTC +10',
    'Mexico City UTC -5',
    'Mid-Atlantic UTC -2',
    'Midway Island UTC -11',
    'Minsk UTC +3',
    'Monrovia UTC 0',
    'Monterrey UTC -5',
    'Montevideo UTC -3',
    'Moscow UTC +3',
    'Mountain Time (US \u0026 Canada) UTC -6',
    'Mumbai UTC +5',
    'Muscat UTC +4',
    'Nairobi UTC +3',
    'New Caledonia UTC +11',
    'New Delhi UTC +5',
    'Newfoundland UTC -3',
    'Novosibirsk UTC +7',
    'Nuku\'alofa UTC +13',
    'Osaka UTC +9',
    'Pacific Time (US \u0026 Canada) UTC -7',
    'Paris UTC +2',
    'Perth UTC +8',
    'Port Moresby UTC +10',
    'Prague UTC +2',
    'Pretoria UTC +2',
    'Quito UTC -5',
    'Rangoon UTC +6',
    'Riga UTC +3',
    'Riyadh UTC +3',
    'Rome UTC +2',
    'Samara UTC +4',
    'Samoa UTC +13',
    'Santiago UTC -4',
    'Sapporo UTC +9',
    'Sarajevo UTC +2',
    'Saskatchewan UTC -6',
    'Seoul UTC +9',
    'Singapore UTC +8',
    'Skopje UTC +2',
    'Sofia UTC +3',
    'Solomon Is. UTC +11',
    'Srednekolymsk UTC +11',
    'Sri Jayawardenepura UTC +5',
    'St. Petersburg UTC +3',
    'Stockholm UTC +2',
    'Sydney UTC +10',
    'Taipei UTC +8',
    'Tallinn UTC +3',
    'Tashkent UTC +5',
    'Tbilisi UTC +4',
    'Tehran UTC +4',
    'Tijuana UTC -7',
    'Tokelau Is. UTC +13',
    'Tokyo UTC +9',
    'UTC UTC 0',
    'Ulaanbaatar UTC +8',
    'Urumqi UTC +6',
    'Vienna UTC +2',
    'Vilnius UTC +3',
    'Vladivostok UTC +10',
    'Volgograd UTC +4',
    'Warsaw UTC +2',
    'Wellington UTC +12',
    'West Central Africa UTC +1',
    'Yakutsk UTC +9',
    'Yerevan UTC +4',
    'Zagreb UTC +2',
    'Zurich UTC +2'
]