import React from 'react'
import { Box } from '@material-ui/core'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const OverflowVerticalBox = styled(({ size, ...otherProps }) => <Box {...otherProps} />)`
    overflow: auto;
    background:
		linear-gradient(white 20%, rgba(255,255,255,0)) 0 0,
        linear-gradient(rgba(255,255,255,0), white 80%) 0 100%,
        
        radial-gradient(farthest-side at 100% 0, rgba(0,0,0,.17), rgba(0,0,0,0)) 0 0,
        linear-gradient(rgba(0,0,0,.17), rgba(0,0,0,0)) 50% 0,
        radial-gradient(farthest-side at 0 0, rgba(0,0,0,.17), rgba(0,0,0,0)) 100% 0,
        
        radial-gradient(farthest-side at 100% 100%, rgba(0,0,0,.17), rgba(0,0,0,0)) 0 100%,
        linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.17)) 50% 100%,
        radial-gradient(farthest-side at 0% 100%, rgba(0,0,0,.17), rgba(0,0,0,0)) 100% 100%;
        
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% ${props => props.size * 2}px, 100% ${props => props.size * 2}px, 
        ${props => props.size * 4}px ${props => props.size}px, calc(100% - ${props => props.size * 8}px) ${props => props.size}px, ${props => props.size * 4}px ${props => props.size}px,
        ${props => props.size * 4}px ${props => props.size}px, calc(100% - ${props => props.size * 8}px) ${props => props.size}px, ${props => props.size * 4}px ${props => props.size}px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, 
        scroll, scroll, scroll,
        scroll, scroll, scroll;
`

const OverflowHorizontalBox = styled(({ size, ...otherProps }) => <Box {...otherProps} />)`
    overflow: auto;

    background:
		linear-gradient(to right, white 20%, rgba(255,255,255,0)) 0 0,
        linear-gradient(to right, rgba(255,255,255,0), white 80%) 100% 0,
        
        radial-gradient(farthest-side at 0 100%, rgba(0,0,0,.17), rgba(0,0,0,0)) 0 0,
        linear-gradient(to right, rgba(0,0,0,.17), rgba(0,0,0,0)) 0 50%,
        radial-gradient(farthest-side at 0 0, rgba(0,0,0,.17), rgba(0,0,0,0)) 0 100% ,
        
        radial-gradient(farthest-side at 100% 100%, rgba(0,0,0,.17), rgba(0,0,0,0)) 100% 0,
        linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,.17)) 100% 50%,
        radial-gradient(farthest-side at 100% 0, rgba(0,0,0,.17), rgba(0,0,0,0)) 100% 100%;

    background-repeat: no-repeat;
    background-color: white;
    background-size: ${props => props.size * 2}px 100%, ${props => props.size * 2}px 100%, 
    ${props => props.size}px ${props => props.size * 4}px, ${props => props.size}px calc(100% - ${props => props.size * 8}px), ${props => props.size}px ${props => props.size * 4}px,
    ${props => props.size}px ${props => props.size * 4}px, ${props => props.size}px calc(100% - ${props => props.size * 8}px), ${props => props.size}px ${props => props.size * 4}px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, 
        scroll, scroll, scroll,
        scroll, scroll, scroll;
`

type IScrollDirection = 'vertical' | 'horizontal' //| 'both' | 'none'

interface IProps {
    direction?: IScrollDirection
    children: React.ReactNode
    size?: number
}

const ScrollBox: React.FunctionComponent<IProps> = observer(({ direction, children, size }) => {
    // let vertical = !!scrollDirection && (scrollDirection == 'vertical' || scrollDirection == 'both')
    // let horizontal = !!scrollDirection && (scrollDirection == 'horizontal' || scrollDirection == 'both')
    let horizontal = !!direction && direction === 'horizontal'
    let shadowSize = !!size ? size : 16
    return (
        <React.Fragment>
            {horizontal ?
                <OverflowHorizontalBox
                    size={shadowSize}
                >
                    {children}
                </OverflowHorizontalBox> :
                <OverflowVerticalBox
                    size={shadowSize}
                >
                    {children}
                </OverflowVerticalBox>}

        </React.Fragment>
    )
})

export default ScrollBox
