import React from 'react'
import { Typography, Paper, Grid } from '@material-ui/core'
import { unprotect } from 'mobx-state-tree'
import { ZStoresRepository, ZStoreAttributes } from '@shared/common'
import { RawDataTable } from '@shared/ui'

interface IProps {

}

class ZStoresPage extends React.Component<IProps> {
  stores: ZStoresRepository = ZStoresRepository.create()

  constructor(props: IProps) {
    super(props)
    unprotect(this.stores)
    let s1 = {
      id: 1,
      address: 'Москва, ул Чаянова, 14',
      conceptName: 'Азбука Вкуса',
      dateOpen: '10.07.2019',
      timeOpen: '00:00',
      timeClose: '00:00',

    }
    let s2 = {
      id: 2,
      address: 'Москва, ул 2-ая Тверская-Ямская, 54',
      conceptName: 'Азбука Вкуса',
      dateOpen: '10.07.2019',
      timeOpen: '00:00',
      timeClose: '00:00',

    }
    let s3 = {
      id: 3,
      address: 'Москва, ул Большая садовая, 5, стр. 1',
      conceptName: 'АВ Daily',
      dateOpen: '10.07.2019',
      timeOpen: '00:00',
      timeClose: '00:00',

    }
    this.stores.all.merge({ 1: s1 as any, 2: s2, 3: s3 })

    this.stores.ordered.replace([1, 2, 3] as any)
  }

  render() {
    return <React.Fragment>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={2}>
          <img width="100%" src={process.env.PUBLIC_URL + '/img/azbuka.svg'} alt={'logo'} />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h3" gutterBottom>
            Магазины
          </Typography>
        </Grid>
      </Grid>
      <Paper>
        <RawDataTable
          values={this.stores.ordered}
          attributes={ZStoreAttributes()}
        />
      </Paper>
    </React.Fragment>
  }
}

export default ZStoresPage