import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { Client, ClientSchema, storeClient } from './client'
import { GroupStore, GroupStoreSchema } from './group-store'
import { Location, LocationSchema, storeLocation } from './location'
import { GenericValue } from './generic'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend, FixTimesFromBackend, FixDatesFromBackend } from '../services/utils'

export const Store = t.model('store', {
    id: t.identifierNumber,
    name: t.string,
    openTime: t.optional(t.string, ''),
    closeTime: t.optional(t.string, ''),
    storeFormat: t.optional(t.string, ''),
    square: t.maybe(t.number),
    openDate: t.optional(t.Date, new Date()),
    photoUrl: t.maybe(t.string),

    location: t.maybe(t.reference(Location)),
    client: t.maybe(t.reference(Client)),
    createdAt: t.optional(t.Date, new Date()),
    updatedAt: t.optional(t.Date, new Date()),
    //groupStore: t.maybe(t.reference(GroupStore))
    dynamicAttributes: t.optional(t.map(GenericValue), {}),
}).volatile(() => ({
    photo: undefined
})).actions(self => ({
    setFile(value?: File) {
        self.photo = value ? value as any : undefined
    },
    getFile(): any {
        return self.photo as any
    }
}))

export type Store = Instance<typeof Store>

export const emptyStore = {
    id: 0,
    name: '',
    openTime: '',
    closeTime: '',
    storeFormat: '',
    square: undefined,
    openDate: new Date(),
    photoUrl: undefined,
    location: undefined,
    client: undefined,
    createdAt: new Date(),
    udpatedAt: new Date(),
    //groupStore: undefined
    dynamicAttributes: new Map<string, GenericValue>(),
    photo: undefined
}

export const storeConstraints = {
    name: { presence: { allowEmpty: false } },
    openTime: { presence: { allowEmpty: false } },
    closeTime: { presence: { allowEmpty: false } },
    client: { presence: { allowEmpty: false } },
    location: { presence: { allowEmpty: false } },
    openDate: { presence: { allowEmpty: false } },
}

export const StoreSchema = new schema.Entity('stores', {
    client: ClientSchema,
    groupStore: GroupStoreSchema,
    location: LocationSchema
})

export function storeStore(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const stores = norm.entities.stores
    if (!!stores && Object.keys(stores).length > 0) {
        storeLocation(dataContext, norm)
        storeClient(dataContext, norm)
        FixNullsToUndefinedFromBackend(stores)
        FixTimesFromBackend(stores, ['openTime', 'closeTime'])
        FixDatesFromBackend(stores, ['openDate', 'createdAt', 'updatedAt'])
        dataContext.stores.all.merge(stores)
        norm.entities.stores = undefined
    }
}