import { types as t, Instance } from 'mobx-state-tree'
import { schema } from 'normalizr'

export const ContentCategory = t
    .model('content_category', {
        id: t.identifierNumber,
        name: t.string,
    })

export type ContentCategory = Instance<typeof ContentCategory>

export const emptyContentCategory = {
    id: 0,
    name: '',
}

export const contentCategoryConstraints = {
    name: { presence: { allowEmpty: false } },
}

export const ContentCategorySchema = new schema.Entity('content_categories')