import validate, { isEmpty } from 'validate.js'

validate.validators.time = (value: any, options: any) => {
    if (value && value !== '') {
        let regexp = null

        if (options.format === 'hh:mm:ss') {
            regexp = /^(?:([01]?\d|2[0-3]):([0-5]?\d):)?([0-5]?\d)$/
        } else if (options.format === 'hh:mm') {
            regexp = /^(?:[0-1]?[0-9]|2[0-3])(?::[0-5][0-9])?$/
        }

        if (!value.match(regexp)) {
            return 'is incorrect'
        }
    }
}

validate.validators.date = (value: any, options: any) => {
    if (value && value !== '') {
        let regexp = null

        if (options.format === 'yyyy-mm-dd') {
            regexp = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/
        }

        if (!value.match(regexp)) {
            return 'is incorrect'
        }
    }
}

validate.validators.lessThen = (value: any, options: any, key: any, attributes: any) => {
    if (value && value !== '') {
        if (options.hasOwnProperty('attr')) {
            let attr = options.attr
            if (attributes.hasOwnProperty(attr)) {
                let otherValue = attributes[attr]

                if (value >= otherValue) {
                    return ('expected to be less then ').concat(options.label ? options.label : attr)
                }
            }
        }
    }
}

validate.validators.greaterThen = (value: any, options: any, key: string, attributes: any) => {
    if (value && value !== '') {
        if (options.hasOwnProperty('attr')) {
            let attr = options.attr
            if (attributes.hasOwnProperty(attr)) {
                let otherValue = attributes[attr]

                if (value <= otherValue) {
                    return ('expected to be greater then ').concat(options.label ? options.label : attr)
                }
            }
        }
    }
}

validate.validators.email = (value: any) => {
    if (value && value !== '') {
        const reg = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

        if (!value.match(reg)) {
            return 'is incorrect'
        }
    }
}

validate.validators.array = (items: any, itemConstraints: any) => {
    const arrayItemErrors = items.reduce((errors: any, item: any, index: number) => {
        const error = validate(item, itemConstraints)
        if (!!error) {
            errors[index] = error as Map<string, string[]>
        }
        return errors
    }, {})
    return isEmpty(arrayItemErrors) ? null : arrayItemErrors
}

export default validate
