import React from 'react'
import { observer } from 'mobx-react'
import { injectable } from 'inversify'
import { computed, toJS, reaction, IReactionDisposer, comparer } from 'mobx'
import LocationForm from './LocationForm'
import LocationInfo from './LocationInfo'
import LocationAttributes from './LocationAttrbiutes'
import { locationFilters } from './LocationFilters'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import {
    RouteParams, ISelectConfig,
    IFilterPreset, DiC, PageService,
    entities, PageContext,
    DataContext, emptyLocation,
    Location
} from '@shared/common'
import {
    dynamicAttrFilters,
    InfoPage, DataTable,
    InfoModal, DeleteEntity
} from '@shared/ui'

interface IProps extends RouteComponentProps<RouteParams>, WithSnackbarProps {
    selectConfig?: ISelectConfig
    presetFilters?: IFilterPreset
}

@injectable()
@observer
class LocationsPage extends React.Component<IProps> {
    reactionDisposers: IReactionDisposer[] = []

    get pageService() {
        return DiC.get<PageService<Location>>(entities.LocationSrv)
    }

    get ctx() {
        return DiC.get<PageContext>(entities.LocationCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    @computed get dynamicAttributes() {
        return this.dataCtx.dynamicAttributes.ofEntity('location')
    }

    @computed get allColumns() {
        return LocationAttributes(this.dataCtx)
    }

    @computed get filterFields() {
        let dynamic = this.dynamicAttributes ? dynamicAttrFilters(this.dynamicAttributes) : {}
        return { ...locationFilters(this.dataCtx, this.props.presetFilters), ...dynamic }
    }

    @computed get id() {
        return this.props.match.path === '/locations/:id?' && !!this.props.match.params.id ?
            parseInt(this.props.match.params.id) : undefined
    }

    @computed get location() {
        return !!this.id && this.dataCtx.locations.all.has(this.id.toString()) ?
            this.dataCtx.locations.all.get(this.id.toString())! :
            emptyLocation as any
    }

    @computed get presetFilters() {
        let preset: IFilterPreset = !!this.props.presetFilters ? this.props.presetFilters : {}
        // if (this.dataCtx.global.hasSelectedClient) {
        //   presetClient(preset, this.dataCtx.global.selectedClient!.id)
        // }
        return preset.location
    }

    @computed get canRead() {
        return this.pageService.getAbility('read')
    }

    @computed get canDelete() {
        return this.pageService.getAbility('delete')
    }

    handleInitInfo = () => {
        this.pageService.filtersState.handlePresetFilters(this.presetFilters)
        if (this.id) {
            this.pageService.init(this.id)
                .catch(_ => this.props.enqueueSnackbar('Could not find requested location', { variant: 'error' }))
        } else {
            this.pageService.init()
        }
    }

    componentDidMount() {
        this.pageService.dynamicAttributesService.fetch('address')
        this.handleInitInfo()
        this.reactionDisposers.push(reaction(
            () => toJS(this.presetFilters),
            () => {
                if (!!!this.id) {
                    this.pageService.filtersState.handlePresetFilters(this.presetFilters)
                    this.pageService.filtersState.applyFilters()
                }
            },
            {
                equals: comparer.structural
            }
        ))
        window.addEventListener('popstate', this.handleInitInfo)
    }

    componentWillUnmount() {
        this.reactionDisposers.forEach(d => d())
        window.removeEventListener('popstate', this.handleInitInfo)
    }

    render() {
        if (!this.canRead) {
            return (<React.Fragment />)
        }
        return (
            <React.Fragment>
                {!!this.id ?
                    <InfoPage>
                        <LocationInfo
                            entity={this.location}
                            attributes={this.allColumns}
                        />
                    </InfoPage> :
                    <React.Fragment>
                        <LocationForm />
                        <DataTable
                            repository={this.dataCtx.locations}
                            attributes={this.allColumns}
                            dynamic={this.dynamicAttributes}
                            ctx={this.ctx}
                            service={this.pageService}
                            selectConfig={this.props.selectConfig}
                            filterFields={this.filterFields}
                            actions={(l: Location) => [
                                <InfoModal
                                    key={`${l.id}-info`}
                                    id={l.id}
                                >
                                    <LocationInfo
                                        entity={l}
                                        attributes={this.allColumns}
                                    />
                                </InfoModal>,
                                <LocationForm key={`${l.id}-edit`} location={l} />,
                                <DeleteEntity key={`${l.id}-delete`} can={this.canDelete} listProvider={this.pageService.paginatedList} id={l.id} />
                            ]}
                        />
                    </React.Fragment >
                }
            </React.Fragment >
        )
    }
}

export default withRouter(withSnackbar(LocationsPage))
