import React from 'react'
import { observer } from 'mobx-react'
import {
    red, yellow, lime,
    grey, green, orange, amber
} from '@material-ui/core/colors'
import * as d3 from 'd3'
import moment from 'moment'
import {
    entities, DiC, DataContext,
    MediaplansPageService, MediaplansPageContext,
    Mediaplan, MediaplanProgram, IMediaplanOptions
} from '@shared/common'
import { toJS, observable } from 'mobx'
import { Typography, Grid } from '@material-ui/core'

const SIDEBAR_WIDTH = 160
const TIMELINE_HEIGHT = 24
const SMALL_OFFSET = 8
const MEDIUM_OFFSET = 12
const FONT_SIZE_LABEL = 14
const FONT_SIZE_AXIS = 12
const FONT_FAMILY = 'roboto'

const DELTA_TIME = 0.3

const COLOR_DARKER_DEF = '#00000010'
const COLOR_LIGHTER_DEF = '#FFFFFF40'

const COLOR_FONT_DARK_PIMARY = grey[900]
const COLOR_FONT_DARK_SECONDARY = grey[700]
const COLOR_FONT_LIGHT_PIMARY = grey[50]
const COLOR_FONT_LIGHT_SECONDARY = grey[200]

const COLOR_MAIN_ORANGE = orange[600]
const COLOR_MAIN_AMBER = amber[600]
const COLOR_MAIN_YELLOW = yellow.A700
const COLOR_MAIN_LIME = lime[600]
const COLOR_MAIN_RED = red.A700

const COLOR_NOW_LINE = green[600]

interface IPalette {
    darker: string
    lighter: string
    main: string
    textPrimary: string
    textSecondary: string
}

interface IMediaplanInstance {
    label: string
    id: number
    color: IPalette
    timeStart: Date
    timeEnd: Date
}

interface IDeviceInfo {
    id: number
    name: string
}

interface IMediaplanDomain {
    label: string
    devices: IDeviceInfo[]
    lines: IMediaplanInstance[][]
}

interface IDrawableMedialplan {
    domains: IMediaplanDomain[]
    timeStart: Date
    timeEnd: Date
}

const paletteYellow: IPalette = {
    darker: COLOR_DARKER_DEF,
    lighter: COLOR_LIGHTER_DEF,
    main: COLOR_MAIN_YELLOW,
    textPrimary: COLOR_FONT_DARK_PIMARY,
    textSecondary: COLOR_FONT_DARK_SECONDARY
}

const paletteAmber: IPalette = {
    darker: COLOR_DARKER_DEF,
    lighter: COLOR_LIGHTER_DEF,
    main: COLOR_MAIN_AMBER,
    textPrimary: COLOR_FONT_DARK_PIMARY,
    textSecondary: COLOR_FONT_DARK_SECONDARY
}

const paletteOrange: IPalette = {
    darker: COLOR_DARKER_DEF,
    lighter: COLOR_LIGHTER_DEF,
    main: COLOR_MAIN_ORANGE,
    textPrimary: COLOR_FONT_DARK_PIMARY,
    textSecondary: COLOR_FONT_DARK_SECONDARY
}

const paletteLime: IPalette = {
    darker: COLOR_DARKER_DEF,
    lighter: COLOR_LIGHTER_DEF,
    main: COLOR_MAIN_LIME,
    textPrimary: COLOR_FONT_DARK_PIMARY,
    textSecondary: COLOR_FONT_DARK_SECONDARY
}

const paletteRed: IPalette = {
    darker: COLOR_DARKER_DEF,
    lighter: COLOR_LIGHTER_DEF,
    main: COLOR_MAIN_RED,
    textPrimary: COLOR_FONT_LIGHT_PIMARY,
    textSecondary: COLOR_FONT_LIGHT_SECONDARY
}

const emptyMedialplan: IDrawableMedialplan = {
    domains: [],
    timeStart: new Date(2018, 6, 30),
    timeEnd: new Date(2020, 6, 30)
}

interface IProps {
    storeIds?: number[]
    deviceIds?: number[]
    from?: Date
    to?: Date
    committed?: boolean
}

@observer
class Timeline extends React.Component<IProps> {
    @observable mediaplan: IDrawableMedialplan = emptyMedialplan
    mediaplanRef: any
    @observable emptyDevices: IDeviceInfo[] = []

    get pageService() {
        return DiC.get<MediaplansPageService>(entities.MediaplanSrv)
    }

    get ctx() {
        return DiC.get<MediaplansPageContext>(entities.MediaplanCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    convertMediplan = (options: IMediaplanOptions) => {
        this.emptyDevices = []
        let mediaplans: Mediaplan[] = []
        let inDomain: boolean[] = []
        let storebased: boolean = options.storeIds !== undefined
        let deviceIds = options.deviceIds !== undefined ? options.deviceIds : []
        if (storebased) {
            this.dataCtx.mediaplans.all.forEach(mp => {
                if (!!mp.storeId && options.storeIds.includes(mp.storeId)) {
                    deviceIds.push(mp.deviceId)
                }
            })
        }
        deviceIds.forEach((id) => {
            let uid = id.toString()
            if (this.dataCtx.mediaplans.all.has(uid)) {
                let mplan = toJS(this.dataCtx.mediaplans.all.get(uid)!)
                //sort by assign time
                mplan.programs.sort((p1, p2) => {
                    return p1.linkedAt.valueOf() - p2.linkedAt.valueOf()
                })
                mediaplans.push(mplan)
                inDomain.push(false)
            }
        })

        //domains creating
        let domains: IMediaplanDomain[] = []
        let domainIdx = 0
        for (let i = 0; i < mediaplans.length; i++) {
            if (inDomain[i]) {
                continue
            }
            inDomain[i] = true
            domains.push({
                label: '',
                devices: [{
                    id: mediaplans[i].deviceId,
                    name: mediaplans[i].name
                }],
                lines: generateLines(mediaplans[i].programs)
            })
            //check same
            for (let j = i + 1; j < mediaplans.length; j++) {
                if (inDomain[j]) {
                    continue
                }
                if (isMediaplanProgramsSame(mediaplans[i].programs, mediaplans[j].programs)) {
                    domains[domainIdx].devices.push({
                        id: mediaplans[j].deviceId,
                        name: mediaplans[j].name
                    })
                    inDomain[j] = true
                }
            }
            domainIdx++
        }

        //find and remove empty domain
        let emptyIdx = -1
        for (let i = 0; i < domains.length; i++) {
            if (domains[i].lines.length === 0 || domains[i].lines.length === 1 && domains[i].lines[0].length === 0) {
                this.emptyDevices = domains[i].devices
                emptyIdx = i
                break
            }
        }
        if (emptyIdx >= 0) {
            domains.splice(emptyIdx, 1)
        }

        //fill new domain names
        for (let i = 0; i < domains.length; i++) {
            if (domains[i].devices.length === 1) {
                domains[i].label = `${domains[i].devices[0].name}`
            } else {
                domains[i].label = `Domain ${i + 1}`
            }
        }

        this.mediaplan = {
            domains: domains,
            timeStart: options.from,
            timeEnd: options.to
        }

        //programs must be sorted by assign time!!
        function generateLines(programs: MediaplanProgram[]): IMediaplanInstance[][] {
            //programs
            let prog: IMediaplanInstance[] = []
            programs.forEach(p => {
                if (!p.isAds) {
                    prog.push({
                        label: p.name,
                        id: p.id,
                        color: paletteAmber,
                        timeStart: p.startTime,
                        timeEnd: p.stopTime
                    })
                }
            })
            //pallete fix
            //TODO
            //ads
            let ads: IMediaplanInstance[][] = []
            programs.forEach(p => {
                if (p.isAds) {
                    ads.push([{
                        label: p.name,
                        id: p.id,
                        color: paletteRed,
                        timeStart: p.startTime,
                        timeEnd: p.stopTime
                    }])
                }
            })
            ads.sort((p1, p2) => {
                return p1[0].timeStart.valueOf() - p2[0].timeStart.valueOf()
            })
            if (prog.length > 0) {
                ads.unshift(prog)
            }
            return ads
        }

        // function palettePicker(prog: IMediaplanInstance[]): IMediaplanInstance[] {

        // }

        //p1 and p2 must be sorted!!
        function isMediaplanProgramsSame(p1: MediaplanProgram[], p2: MediaplanProgram[]): boolean {
            if (p1.length !== p2.length) {
                return false
            }
            for (let i = 0; i < p1.length; i++) {
                if (p1[i].id !== p2[i].id) {
                    return false
                }
            }
            return true
        }
    }

    update = async (options: IMediaplanOptions) => {
        if ((!!!options.deviceIds || options.deviceIds.length === 0) && (!!!options.storeIds || options.storeIds.length === 0)) {
            return
        }
        //count delta
        let delta = moment.duration(moment(options.to).diff(moment(options.from)) * DELTA_TIME)
        await this.pageService.fetch({
            storeIds: options.storeIds,
            deviceIds: options.deviceIds,
            committed: options.committed,
            from: moment(options.from).subtract(delta).toDate(),
            to: moment(options.to).add(delta).toDate()
        })
        this.convertMediplan(options)
    }

    defineFilters = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
        let defs = svg.append('defs')

        let filterRight = defs.append('filter')
            .attr('id', 'drop-shadow-right')
            .attr('width', '200%')
            .attr('height', '200%')

        filterRight.append('feDropShadow')
            .attr('stdDeviation', '4 4')
            .attr('in', 'SourceGraphic')
            .attr('dx', 4)
            .attr('dy', SMALL_OFFSET)
            .attr('flood-color', 'black')
            .attr('flood-opacity', '0.2')
            .attr('result', 'dropShadow')

        let filterLeft = defs.append('filter')
            .attr('id', 'drop-shadow-left')
            .attr('width', '200%')
            .attr('height', '200%')

        filterLeft.append('feDropShadow')
            .attr('stdDeviation', '5 5')
            .attr('in', 'SourceGraphic')
            .attr('dx', -4)
            .attr('dy', SMALL_OFFSET)
            .attr('flood-color', 'black')
            .attr('flood-opacity', '0.2')
            .attr('result', 'dropShadow')

        let feMerge = filterLeft.append('feMerge')
        feMerge.append('feMergeNode')
        feMerge.append('feMergeNode').attr('in', 'SourceGraphic')
    }

    draw = (
        svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
        timeScale: d3.ScaleTime<number, number>,
        itemTip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
        domainTip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
    ) => {
        let h = this.computeHeight()
        let w = svg.node() ? svg.node()!.clientWidth : 200
        svg.attr('height', h)
        svg.selectAll('.diagram').remove()
        svg.selectAll('.axis').remove()
        svg.selectAll('.sidebar').remove()

        let gDiagram = svg
            .append('g')
            .attr('class', 'diagram')

        //time now lime
        let dnow = timeScale(Date.now())
        let tnow = gDiagram
            .append('g')
            .attr('class', 'timenow')

        tnow.append('line')
            .attr('x1', dnow)
            .attr('x2', dnow)
            .attr('y1', MEDIUM_OFFSET)
            .attr('y2', h)
            .attr('stroke', COLOR_NOW_LINE)
            .attr('stroke-width', 1)

        tnow.append('text')
            .attr('x', dnow)
            .attr('y', MEDIUM_OFFSET * 0.5)
            .attr('style', 'dominant-baseline: central')
            .attr('font-size', FONT_SIZE_AXIS)
            .attr('font-family', FONT_FAMILY)
            .attr('text-anchor', 'middle')
            .attr('fill', COLOR_NOW_LINE)
            .text('Now')

        //axis
        let gAxis = drawAxis(gDiagram, timeScale)

        //sidebar rect
        gDiagram.append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('height', h - 2 * SMALL_OFFSET)
            .attr('width', SIDEBAR_WIDTH)
            .attr('fill', 'white')
            .attr('style', 'filter:url(#drop-shadow-right); cursor: default')

        //get axis offset
        let y = gAxis.node() ? gAxis.node()!.getBBox().height + gAxis.node()!.getBBox().y + MEDIUM_OFFSET : 0
        //time perod
        gDiagram.append('text')
            .attr('x', SIDEBAR_WIDTH * 0.5)
            .attr('y', y * 0.5)
            .attr('style', 'dominant-baseline: central; cursor: default')
            .attr('text-anchor', 'middle')
            .attr('fill', COLOR_FONT_DARK_SECONDARY)
            .attr('class', 'text')
            .attr('font-size', FONT_SIZE_AXIS)
            .attr('font-family', FONT_FAMILY)
            .text(`${moment(timeScale.domain()[0]).format('DD.MM.YYYY')} - ${moment(timeScale.domain()[1]).format('DD.MM.YYYY')}`)
        //domains
        this.mediaplan.domains.forEach((domain) => {
            let g = gDiagram
                .append('g')
                .attr('transform', `translate(0, ${y})`)
            drawDomain(g, timeScale, domain, itemTip, domainTip, h)
            y += g.node() ? g.node()!.getBBox().height : 0
        })

        //right sidebar rect
        gDiagram.append('rect')
            .attr('x', w)
            .attr('y', 0)
            .attr('height', h - 2 * SMALL_OFFSET)
            .attr('width', SIDEBAR_WIDTH)
            .attr('fill', 'white')
            .attr('style', 'filter:url(#drop-shadow-left); cursor: default')
        // gDiagram.append('rect')
        //     .attr('x', w)
        //     .attr('y', 0)
        //     .attr('height', height)
        //     .attr('width', SIDEBAR_WIDTH)
        //     .attr('fill', 'white')
        //     .attr('style');

        return {
            axis: gAxis,
            diagram: gDiagram
        }

        //DONE
        function drawAxis(
            svg: d3.Selection<SVGGElement, unknown, null, undefined>,
            timeScale: d3.ScaleTime<number, number>
        ) {
            let g = svg.append('g')
                .attr('class', 'axis')

            let axis = d3.axisBottom(timeScale)
                .tickSizeInner(SMALL_OFFSET)
                .tickSizeOuter(0)

            g.append('g')
                .call(axis)
                .attr('transform', `translate(0, ${MEDIUM_OFFSET})`)
                .attr('font-size', FONT_SIZE_AXIS)
                .attr('font-family', FONT_FAMILY)

            return g
        }

        function drawDomain(
            gDiagram: d3.Selection<SVGGElement, unknown, null, undefined>,
            timeScale: d3.ScaleTime<number, number>,
            domain: IMediaplanDomain,
            itemTip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
            domainTip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
            svgH: number,
        ) {
            let gTimeline = gDiagram.append('g')

            gTimeline.append('line')
                .attr('x1', timeScale.range()[0])
                .attr('x2', timeScale.range()[1])
                .attr('y1', 0)
                .attr('y2', 0)
                .attr('stroke', 'black')
                .attr('opacity', 0.1)

            let y = 0
            domain.lines.forEach((line) => {
                let g = gTimeline.append('g').attr('transform', `translate(0, ${y + MEDIUM_OFFSET})`)
                drawTimeline(g, timeScale, line, itemTip, svgH)
                if (g.node() !== null) {
                    y += g.node()!.getBBox().height + SMALL_OFFSET
                }
            })

            let gSidebar = gDiagram.append('g')
            if (gTimeline.node() !== null) {
                drawSidebar(gSidebar, domain, gTimeline.node()!.getBBox().height + MEDIUM_OFFSET, domainTip, svgH)
            }

            function drawTimeline(
                svg: d3.Selection<SVGGElement, unknown, null, undefined>,
                timeScale: d3.ScaleTime<number, number>,
                line: IMediaplanInstance[],
                itemTip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
                svgH: number,
            ) {
                let tl = svg.attr('class', 'timeline')
                    .selectAll('rect')
                    .data(line)
                    .enter()
                    .append('g')

                //colored rect
                tl.append('rect')
                    .attr('x', (x: any) => {
                        return timeScale(x.timeStart) + 4
                    })
                    .attr('y', 0)
                    .attr('width', blockWidth)
                    .attr('height', TIMELINE_HEIGHT)
                    .attr('rx', 4)
                    .attr('ry', 4)
                    .attr('fill', (x: any) => {
                        return x.color.main
                    })
                    .attr('class', 'item')
                //label
                tl.append('text')
                    .attr('x', (x: any) => {
                        return blockWidth(x) + timeScale(x.timeStart) + 4 - SIDEBAR_WIDTH > (x.label.length + 1) * FONT_SIZE_LABEL * 0.6 ?
                            Math.max(timeScale(x.timeStart) + 4 + FONT_SIZE_LABEL * 0.6, FONT_SIZE_LABEL * 0.6 + SIDEBAR_WIDTH) :
                            blockWidth(x) + timeScale(x.timeStart) + 4 - (x.label.length + 1) * FONT_SIZE_LABEL * 0.6
                    })
                    .attr('y', TIMELINE_HEIGHT * 0.5)//(TIMELINE_HEIGHT + FONT_SIZE_LABEL - (FONT_SIZE_LABEL > 14 ? 3 : 2)) * 0.5 - 1)
                    .attr('style', 'dominant-baseline: central')
                    .attr('font-size', FONT_SIZE_LABEL)
                    .attr('font-family', FONT_FAMILY)
                    .attr('text-anchor', 'start')
                    .attr('fill', (x: any) => {
                        return x.color.textPrimary
                    })
                    .attr('class', 'text')
                    .text((x: any) => {
                        let rw = blockWidth(x)
                        if (rw <= (x.label.length + 1) * FONT_SIZE_LABEL * 0.6) {
                            return ''
                        }
                        return x.label
                    })

                //select and tooltip
                svgH = svgH - 160 //height of program tooltip
                tl.append('rect')
                    .attr('x', (x: any) => {
                        return timeScale(x.timeStart) + 4
                    })
                    .attr('y', 0)
                    .attr('width', blockWidth)
                    .attr('height', TIMELINE_HEIGHT)
                    .attr('rx', 4)
                    .attr('ry', 4)
                    .attr('fill', '#00000000')
                    .attr('class', 'item-ttip')
                    .attr('style', 'cursor: default')
                    .on('mouseover', function (x: any) {
                        let mouse = d3.mouse(d3.select('.d3-mediaplan').node() as any)
                        d3.select(this).attr('fill', x.color.darker)
                        itemTip.html(htmlInstanceTooltip(x))
                            .style('left', (mouse[0] + 10) + 'px')
                            .style('top', (mouse[1] > svgH ? svgH : (mouse[1] + 10)) + 'px')
                            .style('display', 'block')
                            .style('z-index', '1')
                    })
                    .on('mouseout', function (x: any) {
                        d3.select(this).attr('fill', '#00000000')
                        itemTip.style('display', 'none')
                            .style('z-index', '0')
                    })

                function blockWidth(x: IMediaplanInstance): number {
                    let w = timeScale(x.timeEnd) - timeScale(x.timeStart)
                    return w > 8 ? w - 4 : 4
                }
            }

            function drawSidebar(
                svg: d3.Selection<SVGGElement, unknown, null, undefined>,
                domain: IMediaplanDomain,
                height: number,
                domainTip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>,
                svgH: number,
            ) {
                let gt = svg.append('g')

                gt.append('rect')
                    .attr('x', 0)
                    .attr('width', SIDEBAR_WIDTH)
                    .attr('y', 0)
                    .attr('height', height)
                    .attr('class', 'sidebar')
                    .attr('style', 'cursor: default')
                    .attr('fill', 'white')

                // gt.append('rect')
                //     .attr('x', 0)
                //     .attr('y', 0)
                //     .attr('width', SIDEBAR_WIDTH)
                //     .attr('height', 1)
                //     .attr('fill', 'black')
                //     .attr('opacity', 0.1)
                //     .attr('style', 'cursor: default');
                gt.append('line')
                    .attr('x1', 0)
                    .attr('x2', SIDEBAR_WIDTH)
                    .attr('y1', 0)
                    .attr('y2', 0)
                    .attr('stroke', 'black')
                    .attr('opacity', 0.1)

                gt.append('text')
                    .attr('x', SIDEBAR_WIDTH * 0.5)
                    .attr('y', height * 0.5)//(FONT_SIZE_LABEL + height) * 0.5 - 3)
                    .attr('style', 'dominant-baseline: central')
                    .attr('text-anchor', 'middle')
                    .attr('fill', 'black')
                    .attr('class', 'text')
                    .text(domain.label)

                svgH = svgH - 90 //height of domain tooltip
                gt.append('rect')
                    .attr('x', 0)
                    .attr('width', SIDEBAR_WIDTH)
                    .attr('y', 0)
                    .attr('height', height)
                    .attr('style', 'cursor: default')
                    .attr('class', 'sidebar-ttip')
                    .attr('fill', '#00000000')
                    .on('mouseover', function () {
                        let mouse = d3.mouse(d3.select('.d3-mediaplan').node() as any)
                        d3.select(this).attr('fill', COLOR_DARKER_DEF)
                        domainTip.html(htmlDomainTooltip(domain))
                            .style('left', (mouse[0] + 10) + 'px')
                            .style('top', (mouse[1] > svgH ? svgH : (mouse[1] + 10)) + 'px')
                            .style('display', 'block')
                            .style('z-index', '1')
                    })
                    .on('mouseout', function (x: any) {
                        d3.select(this).attr('fill', '#00000000')
                        domainTip.style('display', 'none')
                            .style('z-index', '0')
                    })
            }

            function htmlDomainTooltip(x: IMediaplanDomain): string {
                return `
                <div style=" 
                    border: 1px solid #c1c1c1;
                    border-radius: 4px; 
                    color: rgba(0, 0, 0, 0.87);
                    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    background-color: #fff;
                    width: 240px; 
                    height: 92px; 
                    padding: 0; 
                    font-size: 12px; 
                    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);" 
                >
                    <ul style=" 
                        list-style-type: none; 
                        margin: 1em 0 1em 0; 
                        padding: 0;" 
                    >
                        <li style=" 
                            padding: 0em 2em 0em 1em;" 
                        >
                            <span style=" 
                                font-size: 16px; 
                                color: ${COLOR_FONT_DARK_PIMARY}; 
                                opacity: 1; 
                                margin: 0px; 
                                text-decoration: none; 
                                font-weight: bold;"
                            >
                                ${x.label}
                            </span>
                        </li>
                    </ul>
                    <div style=" 
                        margin: 0; 
                        padding: 0; 
                        height: 1px; 
                        background-color: #dddddd;"
                    />
                    <ul style=" 
                        list-style-type: none; 
                        padding: 0.5em; 
                    ">
                        <li style=" 
                            padding: 1em 2em 1em 1em;"
                        >
                            <span style=" 
                                font-size: 14px; 
                                color: ${COLOR_FONT_DARK_PIMARY}; 
                                opacity: 1; 
                                margin: 0px; 
                                text-decoration: none;
                                font-weight: bold;" 
                            >
                                Devices:
                            </span>
                            <span style=" 
                                font-size: 14px; 
                                color: ${COLOR_FONT_DARK_PIMARY}; 
                                opacity: 1; 
                                margin: 0px; 
                                text-decoration: none;"
                            >
                                ${x.devices.map(d => d.name).join(', ')}
                            </span>
                        </li>
                    </ul>
                </div>`
            }

            function htmlInstanceTooltip(x: IMediaplanInstance): string {
                return `
                <div style=" 
                    border: 1px solid #c1c1c1;
                    border-radius: 4px; 
                    color: rgba(0, 0, 0, 0.87);
                    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    background-color: #fff;
                    width: 240px; 
                    height: 160px; 
                    padding: 0; 
                    font-size: 12px; 
                    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);" 
                >
                    <ul style=" 
                        list-style-type: none; 
                        margin: 1em 0 1em 0; 
                        padding: 0;" 
                    >
                        <li style=" 
                            padding: 0em 2em 0em 1em;" 
                        >
                            <span style=" 
                                font-size: 16px; 
                                color: ${COLOR_FONT_DARK_PIMARY}; 
                                opacity: 1; 
                                margin: 0px; 
                                text-decoration: none; 
                                font-weight: bold;"
                            >
                                ${x.label}
                            </span>
                        </li>
                    </ul>
                    <div style=" 
                        margin: 0; 
                        padding: 0; 
                        height: 1px; 
                        background-color: #dddddd;"
                    />
                    <ul style=" 
                        list-style-type: none; 
                        padding: 0.5em; 
                    ">
                        <li style=" 
                            padding: 1em 2em 1em 1em;"
                        >
                            <span style=" 
                                font-size: 14px; 
                                color: ${COLOR_FONT_DARK_SECONDARY}; 
                                opacity: 1; 
                                margin: 0px; 
                                text-decoration: none;"
                            >
                                ${moment(x.timeStart).format('DD.MM.YYYY')} - ${moment(x.timeEnd).format('DD.MM.YYYY')}
                            </span>
                        </li>
                        <li style=" 
                            padding: 1em 2em 0.5em 1em;"
                        >
                            <span style=" 
                                font-size: 14px; 
                                color: ${COLOR_FONT_DARK_PIMARY}; 
                                opacity: 1; 
                                margin: 0px; 
                                text-decoration: none;
                                font-weight: bold;" 
                            >
                                Play time:   
                            </span>
                            <span style=" 
                                font-size: 14px; 
                                color: ${COLOR_FONT_DARK_SECONDARY}; 
                                opacity: 1; 
                                margin: 0px; 
                                text-decoration: none;"
                            >
                                ${moment(x.timeStart).format('HH:mm')} - ${moment(x.timeEnd).format('HH:mm')}
                            </span>
                        </li>
                        <li style=" 
                            padding: 0.5em 2em 1em 1em;"
                        >
                            <span style=" 
                                font-size: 14px; 
                                color: ${COLOR_FONT_DARK_PIMARY}; 
                                opacity: 1; 
                                margin: 0px; 
                                text-decoration: none;
                                font-weight: bold;" 
                            >
                                Duration:
                            </span>
                            <span style=" 
                                font-size: 14px; 
                                color: ${COLOR_FONT_DARK_SECONDARY}; 
                                opacity: 1; 
                                margin: 0px; 
                                text-decoration: none;"
                            >
                                ${moment(x.timeEnd).diff(moment(x.timeStart), 'days')} days
                            </span>
                        </li>
                    </ul>
                </div>`
            }
        }
    }

    onLoadStart = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
        this.ctx.isLoading = true
        svg.attr('opacity', 0.5)
        //$(this).append('<i class="huge spinner loading icon"></i>')
    }

    onLoadEnd = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
        this.ctx.isLoading = false
        svg.attr('opacity', 1.0)
        //$(this).find('i.loading.icon').remove();
    }

    buildMediaplan() {
        let domainTip = d3.select('.mediaplan-container').append('div')
            .attr('class', 'd3-item-tip')
            .style('position', 'absolute')

        let itemTip = d3.select('.mediaplan-container').append('div')
            .attr('class', 'd3-domain-tip')
            .style('position', 'absolute')

        let svg = d3.select(this.mediaplanRef)

        let w = svg.node() ? svg.node()!.parentElement.clientWidth : 200
        let h = this.computeHeight()
        svg.attr('width', w)
            .attr('height', h)
            .attr('style', 'cursor: -webkit-grab; cursor: -moz-grab; cursor: grab;')

        this.defineFilters(svg)

        let initTimeScale = d3.scaleTime()
            .domain([this.mediaplan.timeStart, this.mediaplan.timeEnd])
            .range([SIDEBAR_WIDTH, w])

        let elements = this.draw(svg, initTimeScale, itemTip, domainTip)
        h = elements.diagram.node() ? elements.diagram.node()!.getBBox().height : 1

        svg.call(d3.zoom()
            .scaleExtent([0.25, 250])
            .on('zoom', () => {
                if (!this.ctx.isLoading) {
                    let newScale = d3.event.transform.rescaleX(initTimeScale)
                    this.draw(svg, newScale, itemTip, domainTip)
                    itemTip.style('display', 'none')
                    domainTip.style('display', 'none')
                }
            })
            .on('end', () => {
                if (!this.ctx.isLoading) {
                    let newScale = d3.event.transform.rescaleX(initTimeScale) as d3.ScaleTime<number, number>
                    let newStart = newScale.domain()[0]
                    let newEnd = newScale.domain()[1]
                    if (newStart < this.mediaplan.timeStart || newEnd > this.mediaplan.timeEnd) {
                        this.onLoadStart(svg)
                        this.update({
                            storeIds: this.props.storeIds,
                            deviceIds: this.props.deviceIds,
                            committed: this.props.committed,
                            from: newStart,
                            to: newEnd
                        })
                        this.draw(svg, newScale, itemTip, domainTip)
                        this.onLoadEnd(svg)
                    }
                }
            }))
    }

    computeHeight = () => {
        let domainsCount = 0
        let linesCount = 0
        this.mediaplan.domains.forEach((d) => {
            domainsCount += 1
            linesCount += d.lines.length
        })
        return linesCount * (TIMELINE_HEIGHT + SMALL_OFFSET)
            + (2 * MEDIUM_OFFSET - SMALL_OFFSET) * domainsCount + MEDIUM_OFFSET
            + MEDIUM_OFFSET + FONT_SIZE_AXIS * 0.71 + SMALL_OFFSET + (FONT_SIZE_AXIS > 14 ? 7 : 6)
    }

    init = async () => {
        let from: Date
        let to: Date
        switch (true) {
            case (!!this.props.from && !!this.props.to):
                from = this.props.from!
                to = this.props.to!
                break
            case (!!!this.props.from && !!this.props.to):
                from = moment(this.props.to!).subtract(1, 'year').toDate()
                to = this.props.to!
                break
            case (!!this.props.from && !!!this.props.to):
                from = this.props.from!
                to = moment(this.props.from!).add(1, 'year').toDate()
                break
            default:
                from = moment(Date.now()).subtract(4, 'month').toDate()
                to = moment(Date.now()).add(8, 'month').toDate()
        }
        await this.update({
            storeIds: this.props.storeIds,
            deviceIds: this.props.deviceIds,
            committed: this.props.committed,
            from: from,
            to: to
        })
        if (this.mediaplan.domains.length > 0) {
            //TODO: Dirty, very dirty hack for rendering in full width
            window.setTimeout(() => { this.buildMediaplan() }, 250)
        }
    }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate(prevProps: IProps) {
        const prevDeviceIsArr = Array.isArray(prevProps.deviceIds)
        const currDeviceIsArr = Array.isArray(this.props.deviceIds)
        const prevStoreIsArr = Array.isArray(prevProps.storeIds)
        const currStoreIsArr = Array.isArray(this.props.storeIds)

        const deviceChanged = prevDeviceIsArr ? !currDeviceIsArr : currDeviceIsArr ||
            currDeviceIsArr && prevDeviceIsArr &&
            (prevProps.deviceIds.length !== this.props.deviceIds.length ||
                prevProps.deviceIds.every((val, idx) => val !== this.props.deviceIds[idx]))

        const storeChanged = prevStoreIsArr ? !currStoreIsArr : currStoreIsArr ||
            currStoreIsArr && prevStoreIsArr &&
            (prevProps.storeIds.length !== this.props.storeIds.length ||
                prevProps.storeIds.every((val, idx) => val !== this.props.storeIds[idx]))

        if (deviceChanged || storeChanged || prevProps.committed !== this.props.committed) {
            this.init()
        }
    }

    render() {
        return (
            <React.Fragment>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h5" gutterBottom>
                            Mediaplan:
                        </Typography>
                    </Grid>
                    {this.mediaplan.domains.length > 0 ?
                        <Grid
                            item
                            style={{ padding: '8px 0' }}
                        >
                            <div
                                className="mediaplan-container"
                                style={{
                                    position: 'relative',
                                    minWidth: '720px'
                                }}
                            >
                                <svg className="d3-mediaplan" ref={r => (this.mediaplanRef = r)} />
                            </div>
                        </Grid> : []
                    }
                    {this.emptyDevices.length > 0 || this.mediaplan.domains.length === 0?
                        <Grid item>
                            <Typography
                                style={{ margin: '8px' }}
                                color="textSecondary"
                            >
                                {this.emptyDevices.length > 1 ?
                                    `Devices without programs: ${this.emptyDevices.map(d => d.name).join(', ')}` :
                                    `${!!this.props.storeIds && !!!this.props.deviceIds ? 'Store' : 'Device'} has no ${!!this.props.committed && this.props.committed ? 'committed' : 'assigned'} programs`
                                }
                            </Typography>
                        </Grid> : []
                    }
                </Grid>
            </React.Fragment>
        )
    }
}

export default Timeline