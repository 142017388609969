import React, { Component } from 'react'
import { IconButton } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import ConfirmationDialog from './ConfirmationDialog'
import { IPaginatedListStateManager } from '@shared/common'
import { withSnackbar, WithSnackbarProps } from 'notistack'

interface IProps extends WithSnackbarProps {
    id: number,
    listProvider: IPaginatedListStateManager
    can: boolean
}

const DeleteEntity: React.SFC<IProps> = ({ id, listProvider, can, enqueueSnackbar }) => (!can ? <React.Fragment /> :
    <IconButton
        aria-label="Info"
        onClick={
            () =>
                ConfirmationDialog(
                    'Delete confirmation',
                    'Do you really want to delete this item?',
                    () => listProvider.delete(id).then(
                        () => enqueueSnackbar('Succcessfuly deleted', { variant: 'info' })
                    )
                )
        }
    >
        <Delete />
    </IconButton>
)

export default withSnackbar(DeleteEntity)
