import React from 'react'
import ZRequestForm from './ZRequestForm'
import { unprotect } from 'mobx-state-tree'
import { Typography, Paper, Grid } from '@material-ui/core'
import { ZRequestsRepository, ZRequestAttributes } from '@shared/common'
import { RawDataTable } from '@shared/ui'

interface IProps {

}

class ZRequestsPage extends React.Component<IProps> {
    requests: ZRequestsRepository = ZRequestsRepository.create()

    constructor(props: IProps) {
        super(props)
        unprotect(this.requests)
        let r1 = {
            id: 1,
            date: '07.08.2019',
            theme: 'Реклама селедки',
            stores: 'Все',
            status: 'В обработке',
        }
        let r2 = {
            id: 2,
            date: '01.07.2019',
            theme: 'Реклама кофе',
            stores: '(Азбука Вкуса, Москва, ул 2-ая Тверская-Ямская, 54), (АВ Daily, Москва, ул Большая садовая, 5, стр. 1)',
            status: 'Выполнена',
        }

        this.requests.all.merge({ 1: r1, 2: r2 })

        this.requests.ordered.replace([1, 2] as any)
    }

    render() {
        return <React.Fragment>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={2}>
                    <img width="100%" src={process.env.PUBLIC_URL + '/img/azbuka.svg'} alt={'logo'} />
                </Grid>
                <Grid item>
                    <Typography variant="h3" gutterBottom>
                        Запросы
                    </Typography>
                </Grid>
                <Grid item>
                    <ZRequestForm />
                </Grid>
            </Grid>

            <Paper>
                <RawDataTable
                    values={this.requests.ordered}
                    attributes={ZRequestAttributes()}
                />
            </Paper>
        </React.Fragment>
    }
}

export default ZRequestsPage