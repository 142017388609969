import React from 'react'
import { computed } from 'mobx'
import { observer } from 'mobx-react'
import { Grid } from '@material-ui/core'
import PerformersPage from '../performers/PerformersPage'
import {
    DiC, DataContext,
    entities, PerformerRoleTypes,
    IFilterValues, Performance
} from '@shared/common'
import {
    SelectViaDialog, TextFieldWV
} from '@shared/ui'

interface IProps {
    performance: Performance
    errors?: any
}

@observer class PerformanceItem extends React.Component<IProps> {

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    componentWillMount() {
        if (!!this.props.performance.performerRole) {
            this.props.performance.selectedRole = this.props.performance.performerRole.type
        }
    }

    handlePermitRole = () => {
        if (!!this.props.performance.performerRole && !!this.props.performance.performer
            && this.props.performance.performer.rolesTypes().indexOf(this.props.performance.performerRole.type) < 0) {
            this.props.performance.performerRole = undefined
        } else {
            this.props.performance.selectedRole = this.props.performance.performerRole ? this.props.performance.performerRole.type : undefined
        }
    }

    handleFillPerformerSelect = () => {
        this.dataCtx.performers.selected.clear()
        if (!!this.props.performance.performer) {
            this.dataCtx.performers.selected[0] = this.props.performance.performer
        }
    }

    handlePerformerSelect = () => {
        if (this.dataCtx.performers.selected && this.dataCtx.performers.selected.length > 0) {
            this.props.performance.performer = this.dataCtx.performers.selected[0]
            if (this.props.performance.selectedRole !== '') {
                let role = this.props.performance.performer.performerRoles.find(pr => pr.type === this.props.performance.selectedRole)
                if (!!role) {
                    this.onRoleChange(role.id.toString())
                }
            }
            this.handlePermitRole()
        }
        this.props.errors && (this.props.errors.performer = undefined)
    }

    handleClearPerformerSelect = () => {
        this.props.performance.performerRole = undefined
        this.props.performance.performer = undefined
        if (!!this.props.errors) {
            this.props.errors.performer = undefined
            this.props.errors.performerRole = undefined
        }
    }

    @computed get roleOptions() {
        if (this.props.performance.performer) {
            return this.props.performance.performer.performerRoles.map(pr => (
                {
                    value: pr.id.toString(),
                    label: PerformerRoleTypes.find(t => pr.type === t.value)!.label
                }
            ))
        } else {
            return PerformerRoleTypes
        }
    }

    @computed get roleValue() {
        if (this.props.performance.performer) {
            return this.props.performance.performerRole ? this.props.performance.performerRole.id : undefined
        } else {
            return this.props.performance.selectedRole
        }
    }

    onRoleChange = (value: string) => {
        if (this.props.performance.performer) {
            this.props.performance.performerRole = this.dataCtx.performerRoles.all.get(value)
            this.handlePermitRole()
        } else {
            this.props.performance.selectedRole = value
        }
    }

    @computed get performersFilters(): IFilterValues | undefined {
        if (this.props.performance.performerRole) {
            return new Map([['performer_roles', [this.props.performance.performerRole.type]]])
        } else {
            return this.props.performance.selectedRole ?
                new Map([['performer_roles', [this.props.performance.selectedRole]]])
                : undefined
        }
    }

    render() {
        return (
            <Grid container direction="row" spacing={2}>
                <Grid item xs={5}>
                    <SelectViaDialog
                        label="Performer"
                        placeholder="Select Performer"
                        errors={this.props.errors ? this.props.errors.performer : undefined}
                        onOpen={(openModal) => {
                            this.handleFillPerformerSelect()
                            openModal()
                        }}
                        onSelected={this.handlePerformerSelect}
                        value={this.props.performance.performer ? [{ value: this.props.performance.performer.id.toString(), label: this.props.performance.performer.displayName() }] : undefined}
                        onClear={this.handleClearPerformerSelect}
                    >
                        <PerformersPage
                            selectConfig={{
                                type: 'single',
                                usage: 'create-or-update'
                            }}
                            presetFilters={{
                                performer: this.performersFilters
                            }}
                        />
                    </SelectViaDialog>
                </Grid>
                <Grid item xs={3}>
                    <TextFieldWV
                        required
                        label="Select Role"
                        selectOptions={this.roleOptions}
                        errors={this.props.errors ? this.props.errors ? this.props.errors.performerRole : undefined : undefined}
                        //When using as select - send id as value!
                        value={this.roleValue}
                        onChange={value => {
                            this.onRoleChange(value)
                            this.props.errors && (this.props.errors.performerRole = '')
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextFieldWV
                        multiline
                        label="Comment"
                        value={this.props.performance.comment}
                        onChange={v => this.props.performance.comment = v}
                    />
                </Grid>
            </Grid>
        )
    }

}

export default PerformanceItem