import React from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import styled from 'styled-components'
import { FormControl, FormLabel, FormHelperText, Box } from '@material-ui/core'

const DateBox = styled(({ color, ...otherProps }) => <Box {...otherProps} />)`
  min-width: 190px;
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    box-shadow: inset 0 -1px 0 0 #959595;
    height: 1.1875em;
    margin: 0;
    padding: 6px 0 7px;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    -webkit-tap-highlight-color: transparent;
    outline: none;
  }
  input:hover {
    transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.87);
  }
`

type DatePickerMode = 'single' | 'range'

interface IProps {
  startDate?: Date
  endDate?: Date
  errors?: string[]
  readonly?: boolean
  onChange(startDate: Date, endDate?: Date): void
  onDatesReset(): void
  label: string
  required?: boolean,
  mode?: DatePickerMode
}

interface IState {
  startDate: Date | undefined,
  endDate: Date | undefined,
  errors?: string[]
}

const dateFormat: string = 'd.m.Y'

class DateRangePickerWV extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props)
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
      errors: undefined
    }
  }
  componentWillReceiveProps(nextProps: IProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.errors !== this.state.errors) {
      this.setState({ errors: nextProps.errors })
    }

    if (nextProps.startDate !== this.state.startDate) {
      this.setState({ startDate: nextProps.startDate })
    }

    if (nextProps.endDate !== this.state.endDate) {
      this.setState({ endDate: nextProps.endDate })
    }
  }

  handleChange = (dates: Date[]) => {
    if (dates.length > 0) {
      this.setState({ startDate: dates[0], endDate: undefined })
      this.props.onChange && this.props.mode === 'single' && this.props.onChange(this.state.startDate!, this.state.endDate)
    }

    if (dates.length > 1) {
      this.setState({ endDate: dates[1] })
      this.props.onChange && this.props.onChange(this.state.startDate!, this.state.endDate!)
    }
  }

  render() {
    const { required, label, onChange, mode } = this.props
    return (
      <DateBox>
        <FormControl
          required={required}
          error={this.state.errors ? true : undefined}
          style={{ width: '100%' }}
          disabled={this.props.readonly}
        >
          <FormLabel>{label}</FormLabel>
          <Flatpickr
            value={this.Dates}
            options={Object.assign({
              mode: mode ? mode : 'range',
              dateFormat: dateFormat,
              allowInput: true
            },
              (!!this.props.readonly && this.props.readonly) && {
                allowInput: false,
                clickOpens: false
              }
            )}
            onChange={this.handleChange}
          />
          {!!this.state.errors &&
            <FormHelperText error={this.state.errors ? true : undefined}>
              {this.state.errors && this.state.errors.join(', ')}
            </FormHelperText>
          }
        </FormControl>
      </DateBox>
    )
  }

  private get Dates() {
    if (!!this.state.startDate && !!this.state.endDate) {
      return [this.state.startDate, this.state.endDate]
    } else if (!!this.state.startDate) {
      return [this.state.startDate]
    } else {
      return []
    }
  }
}

export default DateRangePickerWV