import React from 'react'
import { observer } from 'mobx-react'
import { List } from '@material-ui/core'
import { PlaylistEntry, playableContentRecord } from '@shared/common'
import { ContentItem } from './ContentItem'
import { ScrollBox } from '@shared/ui'

interface IProps {
    playlistEntries: PlaylistEntry[]
    onRemoveFromPlaylist: (playlistEntry: PlaylistEntry) => void
}

@observer
export class ContentList extends React.Component<IProps> {

    render() {
        return (
            <ScrollBox
                direction="vertical"
            >
                <List>
                    {this.props.playlistEntries.map((pe, idx) => {
                        return !!pe.content && !!playableContentRecord(pe.content) ?

                            <ContentItem
                                key={`content-item-playlist-${idx}`}
                                content={pe.content}
                                contentRecord={playableContentRecord(pe.content)!}
                                onRemoveFromPlaylist={() => { this.props.onRemoveFromPlaylist(pe) }}
                                position={pe.position}
                            /> : []
                    })}
                </List>
            </ScrollBox>
        )
    }
}
