import { IApiClient } from '../api-client'
import { AUser, Ability, emptyAUser } from '../models/a_user'
export interface ILoginData {
    userEmail: string
    password: string
}

export interface IAuthService {
    login(data: ILoginData): Promise<[AUser, string] | null>
}

export class AuthService implements IAuthService {
    constructor(
        readonly apiClient: IApiClient,
    ) { }

    async getCurrentUser(): Promise<[AUser, string] | null> {
        const resp = await this.apiClient.getCurrentUser()
        const version = resp.headers['x-ctrl-api-version'] || ''
        let abilities: Ability[] = []
        !!resp.data.abilities && resp.data.abilities.forEach((ability: any) => {
            let newAbility: Ability = { model: '' } as any
            newAbility.model = ability.model
            !!ability.actions && ability.actions.forEach((action: string) => {
                switch (action) {
                    case 'manage':
                        newAbility.create = true
                        newAbility.read = true
                        newAbility.update = true
                        newAbility.delete = true
                        break
                    case 'create':
                        newAbility.create = true
                        break
                    case 'read':
                        newAbility.read = true
                        break
                    case 'update':
                        newAbility.update = true
                        break
                    case 'delete':
                        newAbility.delete = true
                        break
                    default: break
                }
            })
            abilities.push(newAbility)
        })
        return [{ id: resp.data.id, email: resp.data.email, abilities: abilities, selectedClient: undefined } as any, version]
    }

    async login(data: ILoginData): Promise<[AUser, string] | null> {
        const postData = { 'a_user': { 'email': data.userEmail, 'password': data.password } }
        const resp = await this.apiClient.postLogin(postData)
        if (resp && resp.headers.authorization) {
            let parts = resp.headers.authorization.split(' ')
            if (parts.length === 2 && parts[0] === 'Bearer' && parts[1].length > 0) {
                localStorage.setItem('jwt-token', parts[1])
                return await this.getCurrentUser()
                // return { id: resp.data.id, email: resp.data.email } as any;
            } else {
                throw 'Auth failed: no required info in response'
            }
        } else {
            throw 'Auth failed: no required info in response'
        }
    }
}