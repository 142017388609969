import { types as t, Instance } from 'mobx-state-tree'
import { ContentType } from '../models'

export const ContentTypesRepository = t.model({
    all: t.map(ContentType),
    ordered: t.array(t.reference(ContentType)),
    selected: t.array(t.reference(ContentType)),
    filterSelected: t.array(t.reference(ContentType)),
})

export type ContentTypesRepository = Instance<typeof ContentTypesRepository>
