import React from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import { Toolbar, Box, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router-dom'
interface IProps extends RouteComponentProps {
}

const StyledLink = styled(({ ...otherProps }) => <Link {...otherProps} />)`
&& {
  border: 0;
  color: #fff;
  border-radius: 4px;
  margin-right: 4px;
  text-transform: uppercase;
  padding: 5px;
  text-decoration: none !important;
  font-size: 14px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.17);
    transition: 0.5s;
  }
}
span:first-child {
  color: rgba(255, 255, 255);
}
`

const StyledCurrentLink = styled(({ ...otherProps }) => <Link {...otherProps} />)`
&& {
  border: 0;
  color: #fff;
  border-radius: 4px;
  margin-right: 4px;
  text-transform: uppercase;
  padding: 5px;
  text-decoration: none !important;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 14px;
}
span:first-child {
  color: rgba(255, 255, 255);
}
`

const MenuLogoBox = styled(({ ...otherProps }) => <Box as="" {...otherProps} />)`
  && {
    height: 24px;
  }
  && img {
    height: 100%;
    margin-right: 24px;
  }
`

const menuItems = [
  { label: 'Магазины', path: '/z/lk/stores' },
  { label: 'Запросы', path: '/z/lk/requests' },
]

const ZMainMenu: React.SFC<IProps> = ({ location }) => (
  <React.Fragment>
    <AppBar position="fixed">
      <Toolbar>
        <MenuLogoBox>
          <img src={process.env.PUBLIC_URL + '/img/a5a7_logo.png'} alt={'logo'} />
        </MenuLogoBox>
        {menuItems.map((v, i) => {
          var re = RegExp(v.path)
          const isCurrent = re.test(location.pathname)
          return isCurrent ? <StyledCurrentLink key={i} color="inherit" component={RouterLink} to={v.path}>
            {v.label}
          </StyledCurrentLink> :
            <StyledLink key={i} color="inherit" component={RouterLink} to={v.path}>
              {v.label}
            </StyledLink>
        })}
      </Toolbar>
    </AppBar>
  </React.Fragment>
)

export default withRouter(ZMainMenu)