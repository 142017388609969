import React from 'react'
import { observer } from 'mobx-react'
import { computed, toJS, IReactionDisposer, reaction, comparer } from 'mobx'

import {
    DataTable, InfoModal,
    InfoPage, dynamicAttrFilters,
    DeleteEntity
} from '@shared/ui'
import AddressForm from './AddressForm'
import AddressInfo from './AddressInfo'
import AddressAttributes from './AddressAttrbiutes'

import {
    DataContext, Address,
    ISelectConfig, IFilterPreset,
    emptyAddress, DiC, entities,
    PageService, PageContext,
    RouteParams
} from '@shared/common'
import { addressFilters } from './AddressFilters'
import { injectable } from 'inversify'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { WithSnackbarProps, withSnackbar } from 'notistack'

interface IProps extends RouteComponentProps<RouteParams>, WithSnackbarProps {
    selectConfig?: ISelectConfig
    presetFilters?: IFilterPreset
}

@injectable()
@observer
class AddressesPage extends React.Component<IProps> {
    reactionDisposers: IReactionDisposer[] = []

    get pageService() {
        return DiC.get<PageService<Address>>(entities.AddressSrv)
    }

    get ctx() {
        return DiC.get<PageContext>(entities.AddressCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    @computed get dynamicAttributes() {
        return this.dataCtx.dynamicAttributes.ofEntity('address')
    }

    @computed get allColumns() {
        return AddressAttributes(this.dataCtx)
    }

    @computed get filterFields() {
        let dynamic = this.dynamicAttributes ? dynamicAttrFilters(this.dynamicAttributes) : {}
        return { ...addressFilters(), ...dynamic }
    }

    @computed get id() {
        return this.props.match.path === '/addresses/:id?' && !!this.props.match.params.id ?
            parseInt(this.props.match.params.id) : undefined
    }

    @computed get address() {
        return !!this.id && this.dataCtx.addresses.all.has(this.id.toString()) ?
            this.dataCtx.addresses.all.get(this.id.toString())! :
            emptyAddress as any
    }

    @computed get presetFilters() {
        let preset: IFilterPreset = !!this.props.presetFilters ? this.props.presetFilters : {}
        // if (this.dataCtx.global.hasSelectedClient) {
        //   presetClient(preset, this.dataCtx.global.selectedClient!.id)
        // }
        return preset.address
    }

    @computed get canRead() {
        return this.pageService.getAbility('read')
    }

    @computed get canDelete() {
        return this.pageService.getAbility('delete')
    }

    handleInitInfo = () => {
        this.pageService.filtersState.handlePresetFilters(this.presetFilters)
        if (this.id) {
            this.pageService.init(this.id)
                .catch(_ => this.props.enqueueSnackbar('Could not find requested address', { variant: 'error' }))
        } else {
            this.pageService.init()
        }
    }

    componentDidMount() {
        this.handleInitInfo()
        this.reactionDisposers.push(reaction(
            () => toJS(this.presetFilters),
            () => {
                if (!!!this.id) {
                    this.pageService.filtersState.handlePresetFilters(this.presetFilters)
                    this.pageService.filtersState.applyFilters()
                }
            },
            {
                equals: comparer.structural
            }
        ))
        window.addEventListener('popstate', this.handleInitInfo)
    }

    componentWillUnmount() {
        this.reactionDisposers.forEach(d => d())
        window.removeEventListener('popstate', this.handleInitInfo)
    }

    render() {
        if (!this.canRead) {
            return (<React.Fragment />)
        }
        return (
            <React.Fragment>
                {!!this.id ?
                    <InfoPage>
                        <AddressInfo
                            entity={this.address}
                            attributes={this.allColumns}
                        />
                    </InfoPage> :
                    <React.Fragment>
                        <AddressForm />
                        <DataTable
                            repository={this.dataCtx.addresses}
                            attributes={this.allColumns}
                            dynamic={this.dynamicAttributes}
                            ctx={this.ctx}
                            service={this.pageService}
                            selectConfig={this.props.selectConfig}
                            filterFields={this.filterFields}
                            actions={(a: Address) => [
                                <InfoModal
                                    key={`${a.id}-info`}
                                    id={a.id}
                                >
                                    <AddressInfo
                                        entity={a}
                                        attributes={this.allColumns}
                                    />
                                </InfoModal>,
                                <AddressForm key={`${a.id}-edit`} address={a} />,
                                <DeleteEntity key={`${a.id}-delete`} can={this.canDelete} listProvider={this.pageService.paginatedList} id={a.id} />
                            ]}
                        />
                    </React.Fragment >
                }
            </React.Fragment >
        )
    }
}

export default withRouter(withSnackbar(AddressesPage))
