import { types as t, Instance } from 'mobx-state-tree'
import { schema } from 'normalizr'

export const ContentType = t
    .model('content_type', {
        id: t.identifierNumber,
        name: t.optional(t.string, ''),
        formatName: t.optional(t.string, ''),
        typeName: t.optional(t.string, ''),
    })

export type ContentType = Instance<typeof ContentType>

export const emptyContentType = {
    id: 0,
    name: '',
    formatName: '',
    typeName: '',
}

export const contentTypeConstraints = {
    formatName: { presence: { allowEmpty: false } },
    typeName: { presence: { allowEmpty: false } },
}

export const ContentTypeSchema = new schema.Entity('content_types')