import React from 'react'
import { observable, computed, action, toJS, reaction, comparer } from 'mobx'
import { observer } from 'mobx-react'

import {
    Playlist, playlistConstraints,
    PlaylistType, PlaylistEntry,
    emptyPlaylist, Content,
    emptyPlaylistEntry,
    playableContentRecord,
    IAutosaveState, emptyAutosaveState,
    RouteParams, FormState, Undoer,
    UndoerState, emptyUndoerState,
    DiC, PageService, entities,
    PageContext, DataContext
} from '@shared/common'
import {
    Grid, Drawer, IconButton,
    Typography, Fab, Paper, Button
} from '@material-ui/core'

import { ChevronRight, PlaylistPlay } from '@material-ui/icons'
import { ContentList } from '../contents/ContentList'
import moment from 'moment'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { WithSnackbarProps, withSnackbar } from 'notistack'
import ContentsPage from '../contents/ContentsPage'
import {
    TextFieldWV, AutosaveIndicator,
    UndoButtons, StyledBadge
} from '@shared/ui'

interface IProps extends RouteComponentProps<RouteParams>, WithSnackbarProps {
    //dataTable: (actions: (entity: Content) => JSX.Element[]) => JSX.Element
}

@observer class PlaylistDrawer extends React.Component<IProps> {
    @observable playlist: Playlist = emptyPlaylist as any
    @observable formState: FormState<Playlist> = this.pageService.createFormState()
    @observable tracksDuration: number = 0

    @observable autosaveState: IAutosaveState = emptyAutosaveState
    debounceAutosave: () => void

    undoer: Undoer<Playlist> = new Undoer<Playlist>()
    debounceUndoer: () => void
    @observable undoerState: UndoerState = emptyUndoerState

    get playlistTypePageService() {
        return DiC.get<PageService<PlaylistType>>(entities.PlaylistTypeSrv)
    }

    get pageService() {
        return DiC.get<PageService<Playlist>>(entities.PlaylistSrv)
    }

    get ctx() {
        return DiC.get<PageContext>(entities.PlaylistCtx)
    }

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    componentDidMount() {
        Promise.all([
            this.playlistTypePageService.domainService.fetch(),
        ])
        if (this.isEditMode) {
            this.openForm()
        }
        this.debounceAutosave = AwesomeDebouncePromise(this.handleSubmit, 2000)
        this.debounceUndoer = AwesomeDebouncePromise(() => { this.undoer.Push(toJS(this.playlist), this.undoerState) }, 500)
        reaction(
            () => toJS(this.playlist),
            () => {
                if (!this.autosaveState.dontReact) {
                    this.autosaveState.needSave = true
                    this.autosaveState.loading = true
                    this.debounceAutosave()
                }
                if (!this.undoerState.dontReact) {
                    this.undoerState.waiting = true
                    this.debounceUndoer()
                }
            },
            {
                equals: comparer.structural
            }
        )
    }

    @computed get hasErrors() {
        return this.formState.errors.size > 0
    }

    @computed get tracksCount() {
        return this.playlist.playlistEntries.length
    }

    @computed get canRead() {
        return this.pageService.getAbility('read')
    }

    @computed get canCreate() {
        return this.pageService.getAbility('create')
    }

    @computed get canUpdate() {
        return this.pageService.getAbility('update')
    }

    @computed get isEditMode() {
        return !!this.props.match.params.id
    }

    handleFillOnEdit = () => {
        this.autosaveState.status = 'success'
        this.pageService.paginatedList.getEntity(parseInt(this.props.match.params.id!))
            .then(res => {
                this.autosaveState.dontReact = true
                this.handleFillPlaylist(toJS(res) as Playlist)
                this.autosaveState.dontReact = false
            }).catch(() => {
                this.autosaveState.status = 'error'
            })

    }

    handleFillPlaylist = (newPlaylist: Playlist) => {
        this.playlist = newPlaylist
        //this.playlist.dynamicAttributes = new Map(Object.entries(newPlaylist.dynamicAttributes)) as any
    }

    @action handleAddToPlaylist = (content: Content) => {
        this.formState.clearErrorsForField('playlistEntries')
        let playlistEntry: PlaylistEntry = emptyPlaylistEntry
        playlistEntry.content = content
        const rec = playableContentRecord(content)
        this.tracksDuration += rec ? rec.durationMs : 0
        playlistEntry.position = this.playlist.playlistEntries.length
        this.playlist.playlistEntries.push(playlistEntry)
    }

    @action handleRemoveFromPlaylist = (playlistEntry: PlaylistEntry) => {
        this.formState.clearErrorsForField('playlistEntries')
        var idx: number = this.playlist.playlistEntries.length
        for (let i = 0; i < this.playlist.playlistEntries.length; i++) {
            if (this.playlist.playlistEntries[i].position === playlistEntry.position) {
                idx = i
                break
            }
        }
        if (!!playlistEntry.content) {
            const rec = playableContentRecord(playlistEntry.content)
            this.tracksDuration -= rec ? rec.durationMs : 0
        }
        this.playlist.playlistEntries.splice(idx, 1)
        for (let i = idx; i < this.playlist.playlistEntries.length; i++) {
            this.playlist.playlistEntries[i].position -= 1
        }
    }

    openForm = () => {
        if (this.isEditMode) {
            this.handleFillOnEdit()
        } else {
            this.handleFillPlaylist(toJS(this.playlist))
        }
        this.formState.openForm()
    }

    closeForm = () => {
        this.formState.closeForm()
    }

    handleSubmit = (final?: boolean) => {
        this.autosaveState.needSave = false
        let savedItem: Playlist | undefined = undefined
        this.formState.save(this.playlist, playlistConstraints).then(result => {
            if (result.isOk) {
                savedItem = toJS(result.response as Playlist)
                if (this.playlist.id !== savedItem.id) {
                    this.autosaveState.dontReact = true
                    this.playlist.id = savedItem.id
                    this.autosaveState.dontReact = false
                }
                if (final) {
                    this.closeForm()
                    this.undoer = new Undoer<Playlist>()
                    this.undoerState = emptyUndoerState
                    this.autosaveState = emptyAutosaveState
                    this.autosaveState.dontReact = true
                    this.handleFillPlaylist(emptyPlaylist as any)
                    this.autosaveState.dontReact = false
                    this.pageService.paginatedList.refresh()
                    if (this.isEditMode) {
                        this.props.history.push({ pathname: '/playlists' })
                    }
                }
            } else {
                this.props.enqueueSnackbar('You have filled one or more fields incorrectly.', { variant: 'error' })
                this.autosaveState.status = this.autosaveState.status !== 'error' ? 'warning' : 'error'
            }
        }).catch(() => {
            this.autosaveState.status = this.autosaveState.status !== 'error' ? 'warning' : 'error'
        }).finally(() => {
            if (!this.autosaveState.needSave) {
                if (!!savedItem && !final) {
                    this.undoer.ReplaceBy(savedItem)
                    this.undoerState.dontReact = true
                    this.autosaveState.dontReact = true
                    this.handleFillPlaylist(savedItem)
                    this.autosaveState.dontReact = false
                    this.undoerState.dontReact = false
                    this.autosaveState.status = 'success'
                }
                this.autosaveState.loading = false
            }
        })
    }

    render() {
        if ((!this.isEditMode || !this.canUpdate) && (!this.canCreate || this.isEditMode)) {
            return (<ContentsPage />)
        }
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    style={{
                        position: 'fixed',
                        zIndex: 2,
                        marginTop: '-28px',
                        right: '48px',
                        width: 'auto'
                    }}
                >
                    <Grid
                        item
                        style={{
                            marginRight: '-16px',
                            marginTop: '8px',
                            alignContent: 'flex-start',
                        }}
                    >
                        <Paper>
                            <Typography
                                style={{ margin: '0 20px 8px 8px' }}
                                color="textPrimary"
                            >
                                Total duration: {moment.utc(this.tracksDuration).format('HH:mm:ss')}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <StyledBadge
                            badgeContent={this.tracksCount}
                            color={this.hasErrors ? 'error' : 'primary'}
                            style={{
                                paddingLeft: '7px'
                            }}
                        >
                            <Fab
                                aria-label="Open"
                                onClick={this.openForm}
                                disabled={this.ctx.isLoading}
                            >
                                <PlaylistPlay />
                            </Fab>
                        </StyledBadge>
                    </Grid>
                </Grid>
                <Drawer
                    variant="persistent"
                    anchor="right"
                    open={this.formState.formOpen}
                    onClose={this.closeForm}
                >
                    <Grid
                        container
                        direction="column"
                        style={{ width: '20vw', height: '100%', minWidth: '480px', padding: '8px' }}
                        wrap="nowrap"
                    >
                        <Grid item container direction="row" alignItems="center" >
                            <Grid item>
                                <IconButton
                                    onClick={this.closeForm}
                                >
                                    <ChevronRight />
                                </IconButton>
                            </Grid>
                            <Grid item style={{ marginLeft: '8px' }} >
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                >
                                    {this.isEditMode ? 'Playlist Editing' : 'New Playlist'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item >
                            <TextFieldWV
                                required
                                label="Name"
                                errors={this.formState.getErrorsForField('name')}
                                value={this.playlist.name}
                                onChange={value => {
                                    this.playlist.name = value
                                    this.formState.clearErrorsForField('name')
                                }}
                            />
                        </Grid>
                        <Grid item >
                            <TextFieldWV
                                required
                                label="Select Playlist Type"
                                selectOptions={[...this.dataCtx.playlistTypes.all.values()].map(a => ({ value: a.id.toString(), label: a.name }))}
                                errors={this.formState.getErrorsForField('playlistType')}
                                //When using as select - send id as value!
                                value={!!this.playlist.playlistType ? this.playlist.playlistType.id : ''}
                                onChange={value => {
                                    this.playlist.playlistType = this.dataCtx.playlistTypes.all.get(value)
                                    this.formState.clearErrorsForField('playlistType')
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            style={{ marginTop: '24px' }}
                            wrap="nowrap"
                        >
                            <div style={{ flex: '1 1 auto' }} />
                            <Grid item container direction="row" >
                                <Grid item style={{ margin: '6px 0 0 6px' }} >
                                    <AutosaveIndicator
                                        state={this.autosaveState}
                                    />
                                </Grid>
                                <UndoButtons
                                    onUndo={() => {
                                        this.undoer.Undo(this.handleFillPlaylist, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    onRedo={() => {
                                        this.undoer.Redo(this.handleFillPlaylist, this.undoerState)
                                        this.formState.clearErrors()
                                    }}
                                    state={this.undoerState}
                                />
                                <div style={{ flex: '1 1 auto' }} />
                                <Button
                                    onClick={() => { this.handleSubmit(true) }}
                                    color="primary"
                                    disabled={this.autosaveState.status !== 'success' || this.autosaveState.loading || this.ctx.isLoading}
                                >
                                    {'Finish Editing and Submit'}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            style={{ marginTop: '24px' }}
                            wrap="nowrap"
                        >
                            <Grid
                                item
                                container
                                direction="column"
                                alignItems="flex-start"
                            >
                                <Grid item>
                                    <Typography
                                        color="textPrimary"
                                        variant="h6"
                                    >
                                        {`${this.tracksCount} Track${this.tracksCount !== 1 ? 's' : ''}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        color="error"
                                        variant="caption"
                                    >
                                        {(() => {
                                            let err = this.formState.getErrorsForField('playlistEntries')
                                            return err ? err.join(', ') : ''
                                        })()}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <div style={{ flex: '1 1 0' }} />
                            <Grid
                                item
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    color="textSecondary"
                                    noWrap
                                >
                                    Total duration: {moment.utc(this.tracksDuration).format('HH:mm:ss')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            style={{
                                overflow: 'hidden'
                            }}
                            alignItems="stretch"
                            wrap="nowrap"
                        >
                            <ContentList
                                playlistEntries={this.playlist.playlistEntries}
                                onRemoveFromPlaylist={this.handleRemoveFromPlaylist}
                            />
                        </Grid>
                    </Grid>
                </Drawer>

                <ContentsPage
                    handleAddToPlaylist={this.handleAddToPlaylist}
                />
            </React.Fragment >
        )
    }
}

export default withSnackbar(withRouter(PlaylistDrawer))
