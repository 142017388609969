import { injectable, inject } from 'inversify'
import { entities } from '../ioc'
import snakeCase from 'snake-case'
import camelCase from 'camelcase'

import { DataContext } from '../data-context'
import { IApiClient } from '../api-client'
import { DynamicAttribute, PerformerRoleTypes, ContentTypes } from '../models'

const entityName = 'dynamicAttribute'

export interface IDynamicService {
    delete(id: number): Promise<void>
    createOrUpdate(attribute: DynamicAttribute, modelName: string): Promise<DynamicAttribute>
    fetch(modelName: string): Promise<void>
}

@injectable()
export class DynamicAttributesService implements IDynamicService {
    @inject(entities.DataContext) dataContext: DataContext
    @inject(entities.IApiClient) apiClient: IApiClient

    async fetch(modelName: string): Promise<void> {
        const resp: DynamicAttribute[] = await this.apiClient.getDynamicAttributesByEntity(modelName)
        if (!!resp) {
            if (resp.length > 0) {
                resp.forEach((r, index, arr) => {
                    arr[index].name = camelCase(arr[index].name)
                }, resp)
                switch (modelName.toLowerCase()) {
                    case 'performer_role':
                        PerformerRoleTypes.forEach(prt => {
                            let roleDynamic = resp.filter(r => r.relatedModel === snakeCase(prt.value))
                            if (!!roleDynamic && roleDynamic.length > 0) {
                                this.dataContext.dynamicAttributes.set(prt.value, roleDynamic)
                            }
                        })
                        break
                    case 'content':
                        ContentTypes.forEach(ct => {
                            let roleDynamic = resp.filter(r => r.relatedModel === snakeCase(ct.value))
                            if (!!roleDynamic && roleDynamic.length > 0) {
                                this.dataContext.dynamicAttributes.set(ct.value, roleDynamic)
                            }
                        })
                        break
                    default:
                        this.dataContext.dynamicAttributes.set(modelName, resp)
                        break
                }
            } else {
                this.dataContext.dynamicAttributes.set(modelName, [])
            }
        }
    }

    async createOrUpdate(attribute: DynamicAttribute, modelName: string): Promise<DynamicAttribute> {
        const clone = {
            ...attribute,
            relatedModel: snakeCase(modelName)
        }
        let result: any
        if (attribute.id > 0) {
            result = await this.apiClient.patchObject(clone, entityName)
        } else {
            result = await this.apiClient.postObject(clone, entityName)
        }
        this.dataContext.dynamicAttributes.set(modelName, [result])
        return this.dataContext.dynamicAttributes.all.get(result.id)!
    }

    async delete(id: number): Promise<any> {
        return await this.apiClient.deleteObject(id, entityName)
    }
}