import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { FixNullsToUndefinedFromBackend } from '../services/utils'
import { DataContext } from '../data-context'

export const Gender = t
    .model('gender', {
        id: t.identifierNumber,
        name: t.string,
    })

export type Gender = Instance<typeof Gender>

export const emptyGender = {
    id: 0,
    name: '',
}

export const genderConstraints = {
    name: { presence: { allowEmpty: false } },
}

export const GenderSchema = new schema.Entity('genders')

export function storeGender(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const genders = norm.entities.genders
    if (!!genders && Object.keys(genders).length > 0) {
        FixNullsToUndefinedFromBackend(genders)
        dataContext.genders.all.merge(genders)
        norm.entities.genders = undefined
    }
}