import React from 'react'
import { Container, Typography, TextField, Button } from '@material-ui/core'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { withSnackbar, WithSnackbarProps } from 'notistack'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ILoginData } from '@shared/common'

export const loginDataConstraints = {
  userEmail: { presence: { allowEmpty: false }, email: true },
  password: { presence: { allowEmpty: false } },
}

interface IProps extends WithSnackbarProps, RouteComponentProps {
}

@observer class ZLoginPage extends React.Component<IProps> {
  @observable loginData: ILoginData = { userEmail: '', password: '' }
  @observable errors: { [field: string]: string[] } = {}

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // if(this.loginData.userEmail == 'z@ab.ru') {
    this.props.history.push('/z/lk/stores')
    // } else {
    //   this.errors['userEmail'] = ['Неверный логин и/или пароль']
    // }
  }

  private GetErrorsForField = (key: string) => {
    return this.errors && this.errors[key] ? this.errors[key].join(', ') : undefined
  }

  render() {

    return (
      <React.Fragment>
        <Container maxWidth="xs">
          <Typography variant="h5">
            Вход в систему для клиентов
          </Typography>
          <form onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              error={!!this.GetErrorsForField('userEmail')}
              helperText={this.GetErrorsForField('userEmail') || ''}
              name="email"
              autoComplete="email"
              autoFocus
              value={this.loginData.userEmail || ''}
              onChange={e => this.loginData.userEmail = e.target.value}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Пароль"
              type="password"
              id="password"
              autoComplete="current-password"
              value={this.loginData.password || ''}
              onChange={e => this.loginData.password = e.target.value}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Войти
            </Button>
          </form>
        </Container>
      </React.Fragment>
    )
  }
}
export default withRouter(withSnackbar(ZLoginPage))