import { Instance, types as t } from 'mobx-state-tree'
import { schema } from 'normalizr'
import { ISelectOption, IAttribute } from '.'
import { toJS } from 'mobx'
import moment from 'moment'

export const DynamicAttributeTypes = t.enumeration([
    'string',
    'numeric',
    'time',
    'date',
    'boolean',
    'select',
    'duration',
    'multiple_select',
])

export type DynamicAttributeTypes = Instance<typeof DynamicAttributeTypes>

export const DynamicAttributeFields: ISelectOption[] = [
    { label: 'String', value: 'string' },
    { label: 'Numeric', value: 'numeric' },
    { label: 'Boolean', value: 'boolean' },
    { label: 'Time', value: 'time' },
    { label: 'Date', value: 'date' },
    { label: 'Duration', value: 'duration' },
    { label: 'Select', value: 'select' },
    { label: 'Multiple select', value: 'multiple_select' }
]

export const DynamicAttributeParams = t.model('dynamic_attribute_params', {
    options: t.optional(t.array(t.string), [])
})

export type DynamicAttributeParams = Instance<typeof DynamicAttributeParams>

export const DynamicAttribute = t.model('dynamic_attribute', {
    id: t.identifierNumber,
    name: t.string,
    label: t.string,
    priority: t.maybe(t.number),
    relatedModel: t.optional(t.string, ''),
    fieldType: t.maybe(DynamicAttributeTypes),
    params: t.maybe(DynamicAttributeParams),
}).views(self => ({
    fieldTypeLabel(): string {
        const ft = DynamicAttributeFields.find((f) => {
            return f.value === self.fieldType
        })
        return !!ft ? ft.label : self.fieldType
    }
}))

export type DynamicAttribute = Instance<typeof DynamicAttribute>

export const emptyDynamicAttribute = {
    id: 0,
    name: '',
    relatedModel: '',
    priority: undefined,
    label: '',
    fieldType: undefined,
    params: undefined
}

export const dynamicAttributeConstraints = {
    name: { presence: { allowEmpty: false } },
    fieldType: { presence: { allowEmpty: false } },
}

export const DynamicAttributeSchema = new schema.Entity('dynamic_attributes')

export function generateDynAttrs(dynamic: DynamicAttribute[]): IAttribute<any>[] {
    return dynamic.map(d => ({
        name: `dynamic_attributes.${d.name}`,
        label: d.label,
        show: true,
        dynamic: true,
        display: (c: any) => {
            const v = c.dynamicAttributes.get(d.name)
            return v ? (d.fieldType === 'date' ? moment(v, ['DD.MM.YYYY', 'YYYY-MM-DD']).format('DD.MM.YYYY') : Array.isArray(v) ? v.join(', ') : v.toString()) : ''
        },
        value: (c: any) => {
            const v = c.dynamicAttributes.get(d.name)
            return v ? (d.fieldType === 'date' ? moment(v, ['DD.MM.YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : toJS(v)) : undefined
        }
    }))
}