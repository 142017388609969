import { UIStateContext } from '..'
import { IProgramService } from '../../services/program-service'
import { Program, ProgramFields, IFilterValues } from '../../models'
import { ProgramsPageContext } from './programs-page-context'
import { toJS } from 'mobx'

export class ProgramsPageService {
    constructor(
        readonly programService: IProgramService,
        readonly ctx: ProgramsPageContext,
    ) { }

    async init() {
        await this.goToPage(1)
        //this.ctx.newProgram = Program.create(emptyProgram);
    }

    async goToPage(page: number) {
        this.ctx.isLoading = true
        this.ctx.setPage(page)

        let filters: IFilterValues = toJS(this.ctx.filters)

        try {
            const meta = await this.programService.fetch({
                page: this.ctx.currentPage,
                perPage: this.ctx.perPage,
                sort: this.ctx.sort,
                filters: filters,
            })
            this.ctx.currentPage = meta.page
            this.ctx.totalPages = meta.totalPages
        }
        finally {
            this.ctx.isLoading = false
        }
    }

    async sort(field: ProgramFields, dir: 'asc' | 'desc') {
        this.ctx.sort = { field: field, dir }
        await this.goToPage(1)
    }

    openForm() {
        this.resetForm()
        this.ctx.formOpen = true
    }

    closeForm() {
        this.ctx.formOpen = false
    }

    toggleForm() {
        this.ctx.formOpen ? this.closeForm() : this.openForm()
        //this.ctx.toggleForm();
    }

    resetFilters() {
        this.ctx.filters.clear()
    }

    async applyFilters() {
        this.closeFilters()
        await this.goToPage(1)
    }

    openFilters() {
        this.ctx.filtersOpen = true
    }

    closeFilters() {
        this.ctx.filtersOpen = false
    }

    resetForm() {
        //this.ctx.newProgram = Program.create(emptyProgram);
    }

    async submitForm(program: Program) {
        this.ctx.isLoading = true
        try {
            let newProgram = await this.programService.create(program)
            this.ctx.newProgram = { ...newProgram }
            this.ctx.toggleForm()
        } catch (e) {
            console.error(e)
            throw 'Failed to submit program'
        }
        finally {
            this.ctx.isLoading = false
        }
    }

    async getMSS(program: Program) {
        let mss = await this.programService.getMss(program)
        return mss
    }
}
