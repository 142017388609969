import React from 'react'
import { Address, IAttribute } from '@shared/common'
import {
    InfoCardContent, InfoTable, AttributesList
} from '@shared/ui'
import { Typography, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

interface IProps {
    attributes: IAttribute<Address>[]
    entity: Address
}

const AddressInfo: React.FunctionComponent<IProps> = ({ entity, attributes }) => (
    <React.Fragment>
        <InfoCardContent
            header="Address"
            //subHeader={`ID = ${entity.id}`}
            subHeader={entity.id > 0 ?
                <Link component={RouterLink} to={`/addresses/${entity.id}`} target="_blank">
                    {`${window.location.origin}/addresses/${entity.id}`}
                </Link> : undefined
            }
            content={
                <React.Fragment>
                    <AttributesList
                        entity={entity}
                        attrs={attributes.filter(a => a.show)}
                    />
                </React.Fragment>
            }
        // actionsHeader={[
        //     <AddressForm key={`${entity.id}-card-action-edit`} address={entity} />
        // ]}
        //media={googlemap}
        />
    </React.Fragment>
)

export default AddressInfo
