import React from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'

import {
    ListItem, Typography,
    ListItemText, ListItemSecondaryAction,
    Tooltip, IconButton, Avatar, Grid
} from '@material-ui/core'

import { Clear } from '@material-ui/icons'
import { ContentRecord, Content } from '@shared/common'
import ContentInfo from './ContentInfo'
import { PlayerControl } from '@shared/ui'

interface IProps {
    content: Content
    contentRecord: ContentRecord
    onRemoveFromPlaylist: () => void
    position: number
}

@observer
export class ContentItem extends React.Component<IProps> {
    render() {
        return (
            <React.Fragment>
                <ListItem>
                    <PlayerControl
                        audio={{
                            name: this.props.content.name,
                            fileUrl: this.props.contentRecord.fileUrl,
                            meta: {
                                genre: undefined,
                                bitrate: this.props.contentRecord.bitrate,
                                sampleRate: this.props.contentRecord.sampleRate,
                                durationMs: this.props.contentRecord.durationMs,
                            }
                        }}
                    />
                    <ListItemText
                        style={{ flex: '0 0 auto' }}
                        primary={`#${this.props.position + 1}`}
                    />
                    <ListItemText
                        style={{ marginLeft: '16px' }}
                        primary={this.props.content.name}
                        secondary={
                            <React.Fragment>
                                {this.props.contentRecord.bitrate > 0 || this.props.contentRecord.sampleRate > 0 ?
                                    <Typography
                                        color="textPrimary"
                                    >
                                        {
                                            (this.props.contentRecord.bitrate > 0 ? `${this.props.contentRecord.bitrate}kbps ` : '') +
                                            (this.props.contentRecord.sampleRate > 0 ? `${this.props.contentRecord.sampleRate}Hz ` : '')
                                        }
                                    </Typography> : []
                                }
                            </React.Fragment>
                        }
                    />
                    <ListItemSecondaryAction>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <Typography
                                    style={{ marginRight: '8px' }}
                                    color="textPrimary"
                                >
                                    {moment.utc(this.props.contentRecord.durationMs).format('mm:ss')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Remove this track from playlist" placement="left">
                                    <IconButton
                                        aria-label="Remove"
                                        color="secondary"
                                        size="small"
                                        onClick={this.props.onRemoveFromPlaylist}
                                    >
                                        <Clear />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
            </React.Fragment>
        )
    }
}
