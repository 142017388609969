import isEqual from 'react-fast-compare'

export type UndoerState = {
    dontReact: boolean
    waiting: boolean
    canUndo: boolean
    canRedo: boolean
}

export const emptyUndoerState: UndoerState = {
    dontReact: false,
    waiting: false,
    canUndo: false,
    canRedo: false
}

export class Undoer<T> {
    pointerUserInput: number = 0
    pointerCtrlZ: number = 0
    buffer: Array<T> = []
    bufferSize: number = 10

    constructor(size?: number) {
        this.pointerUserInput = 0
        this.pointerCtrlZ = 0
        this.bufferSize = !!size && size > 0 ? size : 10
        this.buffer.length = this.bufferSize
    }

    private canUndo(): boolean {
        let tmpPointer = this.pointerCtrlZ - 1
        tmpPointer = tmpPointer < 0 ? this.bufferSize + tmpPointer : tmpPointer
        return this.pointerCtrlZ !== (this.pointerUserInput + 1) % this.bufferSize && !!this.buffer[tmpPointer]
    }

    private canRedo(): boolean {
        return this.pointerCtrlZ !== this.pointerUserInput && !!this.buffer[(this.pointerCtrlZ + 1) % this.bufferSize]
    }

    ReplaceBy(item: T): void {
        this.buffer[this.pointerCtrlZ] = item
    }

    Push(item: T, state: UndoerState): void {
        if (!isEqual(item, this.buffer[this.pointerCtrlZ])) {
            this.pointerUserInput = (this.pointerCtrlZ + 1) % this.bufferSize
            this.pointerCtrlZ = this.pointerUserInput
            this.buffer[this.pointerUserInput] = item
        }
        state.waiting = false
        state.canUndo = this.canUndo()
        state.canRedo = this.canRedo()
    }

    Undo(handleFillEntity: (item: T) => void, state: UndoerState): void {
        if (this.canUndo()) {
            let tmpPointer = this.pointerCtrlZ - 1
            tmpPointer = tmpPointer < 0 ? this.bufferSize + tmpPointer : tmpPointer
            if (!![this.buffer[tmpPointer]]) {
                this.pointerCtrlZ = tmpPointer
            }
            state.dontReact = true
            handleFillEntity(this.buffer[this.pointerCtrlZ])
            state.dontReact = false
        }
        state.canUndo = this.canUndo()
        state.canRedo = this.canRedo()
    }

    Redo(handleFillEntity: (entity: T) => void, state: UndoerState): void {
        if (this.canRedo()) {
            let tmpPointer = (this.pointerCtrlZ + 1) % this.bufferSize
            if (!![this.buffer[tmpPointer]]) {
                this.pointerCtrlZ = tmpPointer
            }
            state.dontReact = true
            handleFillEntity(this.buffer[this.pointerCtrlZ])
            state.dontReact = false
        }
        state.canUndo = this.canUndo()
        state.canRedo = this.canRedo()
    }
}