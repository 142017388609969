import { types as t, unprotect, Instance } from 'mobx-state-tree'

import { LocationsRepository } from './locations'
import { DeviceRepository } from './devices'
import { AddressRepository } from './addresses'
import { ClientsRepository } from './clients'
import {
    ProgramsRepository, TimeBlockRepository, PlayFileCommandRepository,
    PlayAdvCommandRepository, ProgramCommandPlaylistRepository, PlayPpCommandRepository,
    PlayCoverCommandRepository, PlayMusicCommandRepository
} from './programs'
import { ContentsRepository } from './contents'
import { ContentTypesRepository } from './content-types'
import { ContentCategoriesRepository } from './content-categories'
import { GenresRepository } from './genres'
import { DynamicAttributesRepository } from './dynamic-attributes'
import { PlaylistEntriesRepository } from './playlist-entries'
import { PlaylistTypesRepository } from './playlist-types'
import { PlaylistsRepository } from './playlists'
import { GroupStoresRepository } from './group-stores'
import { StoresRepository } from './stores'
import { PlayerRepository } from './player'
import { MediaplansRepository } from './mediaplans'
import { MediaplanProgramsRepository } from './mediaplan-programs'
import { PerformersRepository } from './performer'
import { GendersRepository } from './gender'
import { RelevancesRepository } from './relevance'
import { PerformerRolesRepository } from './performer_role'
import { PerformancesRepository } from './performance'
import { DemosRepository } from './demos'
import { DemoRecordingsRepository } from './demo-rercordings'
import { ContentRecordsRepository } from './content-records'
import { GlobalRepository } from './global'

const Repository = t
    .model({
        global: t.optional(GlobalRepository, {}),
        devices: t.optional(DeviceRepository, {}),
        locations: t.optional(LocationsRepository, {}),
        addresses: t.optional(AddressRepository, {}),
        clients: t.optional(ClientsRepository, {}),
        contents: t.optional(ContentsRepository, {}),
        contentRecords: t.optional(ContentRecordsRepository, {}),
        contentTypes: t.optional(ContentTypesRepository, {}),
        contentCategories: t.optional(ContentCategoriesRepository, {}),
        genres: t.optional(GenresRepository, {}),
        dynamicAttributes: t.optional(DynamicAttributesRepository, {}),
        playlists: t.optional(PlaylistsRepository, {}),
        playlistTypes: t.optional(PlaylistTypesRepository, {}),
        playlistEntries: t.optional(PlaylistEntriesRepository, {}),
        timeBlocks: t.optional(TimeBlockRepository, {}),
        programs: t.optional(ProgramsRepository, {}),
        groupStores: t.optional(GroupStoresRepository, {}),
        stores: t.optional(StoresRepository, {}),
        playFileCommands: t.optional(PlayFileCommandRepository, {}),
        playAdvCommands: t.optional(PlayAdvCommandRepository, {}),
        playPpCommands: t.optional(PlayPpCommandRepository, {}),
        playCoverCommands: t.optional(PlayCoverCommandRepository, {}),
        playMusicCommands: t.optional(PlayMusicCommandRepository, {}),
        programCommandPlaylists: t.optional(ProgramCommandPlaylistRepository, {}),
        player: t.optional(PlayerRepository, {}),
        mediaplans: t.optional(MediaplansRepository, {}),
        mediaplanPrograms: t.optional(MediaplanProgramsRepository, {}),
        performers: t.optional(PerformersRepository, {}),
        genders: t.optional(GendersRepository, {}),
        relevances: t.optional(RelevancesRepository, {}),
        performerRoles: t.optional(PerformerRolesRepository, {}),
        performances: t.optional(PerformancesRepository, {}),
        demos: t.optional(DemosRepository, {}),
        demoRecordings: t.optional(DemoRecordingsRepository, {}),
    })

export class DataContext {
    static create() {
        const models = Repository.create()
        unprotect(models)
        return models
    }
}

export interface IRepository<A> {
    all: Map<string, A>
    ordered: Array<A>
    selected: Array<A>
    filterSelected: Array<A>
}

export interface DataContext extends Instance<typeof Repository> { }

export * from './addresses'
export * from './clients'
export * from './content-categories'
export * from './content-records'
export * from './content-types'
export * from './contents'
export * from './demo-rercordings'
export * from './demos'
export * from './devices'
export * from './dynamic-attributes'
export * from './gender'
export * from './genres'
export * from './global'
export * from './group-stores'
export * from './locations'
export * from './mediaplan-programs'
export * from './mediaplans'
export * from './performance'
export * from './performer_role'
export * from './performer'
export * from './player'
export * from './playlist-entries'
export * from './playlist-types'
export * from './playlists'
export * from './programs'
export * from './relevance'
export * from './stores'
