import { types as t, Instance } from 'mobx-state-tree'
import { Performer } from '../models'

export const PerformersRepository = t.model({
    all: t.map(Performer),
    ordered: t.array(t.reference(Performer)),
    selected: t.array(t.reference(Performer)),
    filterSelected: t.array(t.reference(Performer)),
})

export type PerformersRepository = Instance<typeof PerformersRepository>
