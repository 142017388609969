import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { GenericValue } from './generic'
import { Gender, GenderSchema, storeGender } from './gender'
import { RelevanceSchema, Relevance, storeRelevance } from './relevance'
import { PerformerRoleSchema, PerformerRole, storePerformerRole } from './performer_role'
import { Demo, DemoSchema, demoConstraints, storeDemo } from './demo'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend, FixDatesFromBackend } from '../services/utils'

export const Performer = t
    .model('performer', {
        id: t.identifierNumber,
        firstName: t.string,
        lastName: t.string,
        patronymic: t.optional(t.string, ''),
        birthDate: t.maybeNull(t.Date),
        homePhoneNumber: t.optional(t.string, ''),
        mobilePhoneNumber: t.optional(t.string, ''),
        socialNetwork: t.optional(t.string, ''),
        email: t.optional(t.string, ''),
        skype: t.optional(t.string, ''),
        yandexMoneyWallet: t.optional(t.string, ''),
        creditCardNumber: t.optional(t.string, ''),
        bank: t.optional(t.string, ''),
        address: t.optional(t.string, ''),
        passportNumber: t.optional(t.string, ''),
        passportDateOfIssue: t.maybeNull(t.Date),
        passportUnitCode: t.optional(t.string, ''),
        registrationAddress: t.optional(t.string, ''),
        retirementCertificate: t.optional(t.string, ''),
        recommendation: t.optional(t.string, ''),
        comment: t.optional(t.string, ''),
        gender: t.maybe(t.reference(Gender)),
        relevance: t.maybe(t.reference(Relevance)),
        performerRoles: t.optional(t.array(t.reference(PerformerRole)), []),
        demos: t.optional(t.array(t.reference(Demo)), []),
        createdAt: t.optional(t.Date, new Date()),
        updatedAt: t.optional(t.Date, new Date()),
        dynamicAttributes: t.optional(t.map(GenericValue), {}),
    })
    .views(self => ({
        displayName(): string {
            return self.lastName === '' && self.lastName === '' ? `ID=${self.id}` : `${self.lastName} ${self.firstName}`
        },
        rolesTypes(): string[] {
            return self.performerRoles.map(pr => pr.type)
        }
    }))

export type Performer = Instance<typeof Performer>

export const emptyPerformer = {
    id: 0,
    firstName: '',
    lastName: '',
    birthDate: undefined,
    gender: undefined,
    relevance: undefined,
    patronymic: '',
    homePhoneNumber: '',
    mobilePhoneNumber: '',
    socialNetwork: '',
    email: '',
    skype: '',
    yandexMoneyWallet: '',
    creditCardNumber: '',
    bank: '',
    address: '',
    passportNumber: '',
    passportUnitCode: '',
    registrationAddress: '',
    retirementCertificate: '',
    recommendation: '',
    comment: '',
    performerRoles: [],
    demos: [],
    dynamicAttributes: new Map<string, GenericValue>(),
    createdAt: new Date(),
    udpatedAt: new Date(),
}

export const performerConstraints = {
    demos: {
        array: demoConstraints
    }
}

export const PerformerSchema = new schema.Entity('performers', {
    gender: GenderSchema,
    relevance: RelevanceSchema,
    performerRoles: [PerformerRoleSchema],
    demos: [DemoSchema]
})

export function storePerformer(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const performers = norm.entities.performers
    if (!!performers && Object.keys(performers).length > 0) {
        storeGender(dataContext, norm)
        storeRelevance(dataContext, norm)
        storeDemo(dataContext, norm)
        storePerformerRole(dataContext, norm)
        FixNullsToUndefinedFromBackend(performers)
        FixDatesFromBackend(performers, ['birthDate', 'passportDateOfIssue', 'createdAt', 'updatedAt'])
        dataContext.performers.all.merge(performers)
        norm.entities.performers = undefined
    }
}