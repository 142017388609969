import React from 'react'
import {
    Grid, IconButton, FormControl,
    InputLabel, Input, InputAdornment,
    FormHelperText, CardMedia
} from '@material-ui/core'
import { Attachment, Clear } from '@material-ui/icons'
import PlayerControl from './PlayerControl'

interface IProps {
    fileName?: string
    errors?: string[]
    onChange: (file?: File) => void
    onClear?: () => void
    label: string
    required?: boolean
    fileUrl?: string
    type?: 'image' | 'audio'
}

function generateUUID() {
    var d = new Date().getTime()
    if (Date.now) {
        d = Date.now() //high-precision timer
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        var r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return uuid
}

const FileDialogWV: React.SFC<IProps> = ({ errors, fileName, label, onChange, required, fileUrl, onClear, type }) => {
    const uuid = generateUUID()
    let urlName = ''
    if (fileUrl && !fileUrl.includes('blob:http://')) {
        let last = decodeURI(fileUrl).split('/').pop()
        if (!!last) {
            let ls = last.split('?')
            ls.pop()
            urlName = ls.join('')
        }
    }
    let displayName = fileName ? fileName : urlName
    return (
        <React.Fragment>
            <input
                //if display none - safari doesn't render element
                style={{
                    position: 'fixed',
                    top: '-100em'
                }}
                id={uuid}
                onChange={e => onChange(e.target.files && e.target.files.length > 0 ? e.target.files[0] : undefined)}
                type="file"
            />
            <Grid container direction="column">
                {(!!type && type === 'image' && !!fileUrl) &&
                    <Grid item>
                        <CardMedia
                            // style={{
                            //     maxWidth: '40vw',
                            //     maxHeight: '30vh',
                            //     objectFit: 'contain'
                            // }}
                            component="img"
                            src={fileUrl}
                        />
                    </Grid>
                }
                <Grid container direction="row" wrap="nowrap">
                    {(!!type && type === 'audio') &&
                        <Grid
                            item
                            style={{
                                paddingTop: '8px',
                                paddingRight: '8px'
                            }}
                        >
                            <PlayerControl
                                audio={{
                                    name: displayName,
                                    fileUrl: !!fileUrl ? fileUrl : '',
                                    meta: undefined
                                }}
                            />
                        </Grid>
                    }
                    <Grid
                        item
                        style={{
                            flex: '1 1 auto',
                            position: 'relative',
                        }}
                    >
                        <label
                            htmlFor={uuid}
                            style={{
                                display: 'block',
                                position: 'absolute',
                                zIndex: 1,
                                width: '100%',
                                height: '100%'
                            }}
                        />
                        <FormControl
                            error={!!errors ? true : undefined}
                            fullWidth
                            required={required}
                        >
                            <InputLabel
                                htmlFor={`select-file-${uuid}`}
                            >
                                {!!fileName ? label : 'Add ' + label}
                            </InputLabel>
                            <Input
                                inputProps={{
                                    style: {
                                        textOverflow: 'ellipsis'
                                    }
                                }}
                                id={`select-file-${uuid}`}
                                value={displayName}
                                placeholder={'Add ' + label}
                                readOnly
                                endAdornment={
                                    <InputAdornment
                                        position="end"
                                    >
                                        {!!onClear &&
                                            <IconButton
                                                aria-label="clear-file"
                                                onClick={onClear}
                                                style={{
                                                    padding: '0',
                                                    zIndex: 2
                                                }}
                                            >
                                                <Clear />
                                            </IconButton>
                                        }
                                        <Attachment
                                            color="primary"
                                        />
                                    </InputAdornment>
                                }
                            />
                            {!!errors ?
                                <FormHelperText>
                                    {errors!.join(', ')}
                                </FormHelperText> : []
                            }
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default FileDialogWV