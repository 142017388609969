import { types as t, Instance } from 'mobx-state-tree'
import { Demo } from '../models'

export const DemosRepository = t.model({
    all: t.map(Demo),
    ordered: t.array(t.reference(Demo)),
    selected: t.array(t.reference(Demo)),
    filterSelected: t.array(t.reference(Demo)),
})

export type DemosRepository = Instance<typeof DemosRepository>
