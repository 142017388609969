import { types as t, Instance } from 'mobx-state-tree'
import { Relevance } from '../models'

export const RelevancesRepository = t.model({
    all: t.map(Relevance),
    ordered: t.array(t.reference(Relevance)),
    selected: t.array(t.reference(Relevance)),
    filterSelected: t.array(t.reference(Relevance)),
})

export type RelevancesRepository = Instance<typeof RelevancesRepository>
