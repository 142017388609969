import { types as t, Instance } from 'mobx-state-tree'
import { ContentCategory } from '../models'

export const ContentCategoriesRepository = t.model({
    all: t.map(ContentCategory),
    ordered: t.array(t.reference(ContentCategory)),
    selected: t.array(t.reference(ContentCategory)),
    filterSelected: t.array(t.reference(ContentCategory)),
})

export type ContentCategoriesRepository = Instance<typeof ContentCategoriesRepository>
