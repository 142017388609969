import React, { HTMLAttributes } from 'react';
import Select, { components } from 'react-select';
import {
    Typography, NoSsr, TextField, Paper, Chip,
    MenuItem
} from '@material-ui/core';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import {
    Cancel
} from '@material-ui/icons';
import PropTypes from 'prop-types';

import { ValueContainerProps } from 'react-select/lib/components/containers';
import { ControlProps } from 'react-select/lib/components/Control';
import { MenuProps, NoticeProps } from 'react-select/lib/components/Menu';
import { MultiValueProps } from 'react-select/lib/components/MultiValue';
import { OptionProps } from 'react-select/lib/components/Option';
import { PlaceholderProps } from 'react-select/lib/components/Placeholder';
import { SingleValueProps } from 'react-select/lib/components/SingleValue';
import { IndicatorProps } from 'react-select/lib/components/indicators';

import { ValueType } from 'react-select/lib/types';
import { ISelectOption } from '@shared/common';


interface IProps {
    value?: ISelectOption[]
    errors?: string[]
    readonly?: boolean
    onChange: (value: ISelectOption[]) => void
    label: string
    required?: boolean
    multiple?: boolean
    selectOptions: ISelectOption[]
}

function NoOptionsMessage(props: NoticeProps<ISelectOption>) {
    return (
        <Typography
            color="textSecondary"
            style={{ padding: '8px 16px' }} //theme.spacing(1, 2),
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

NoOptionsMessage.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
} as any;

function IndicatorSeparator(props: IndicatorProps<ISelectOption>) {
    return (
        <span
            style={{ display: 'none' }}
            {...props.innerProps}
        />
    )
};

IndicatorSeparator.propTypes = {
    innerProps: PropTypes.object,
} as any;

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'nowrap',
                padding: '0',
                height: 'auto'
            }}
            ref={inputRef}
            {...props}
        />
    )
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
} as any;

function Control(props: ControlProps<ISelectOption>) {
    return (
        <TextField
            fullWidth
            style={{
                display: 'flex',
                padding: 0,
                height: 'auto',
            }}
            InputProps={{
                inputComponent,
                inputProps: {
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.TextFieldProps}
        />
    );
}

Control.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    selectProps: PropTypes.object.isRequired,
} as any;

function Option(props: OptionProps<ISelectOption>) {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.data.label}
        </MenuItem>
    );
}

Option.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
} as any;

function Placeholder(props: PlaceholderProps<ISelectOption>) {
    return (
        <Typography
            color="textSecondary"
            style={{
                position: 'absolute',
                left: 2,
                bottom: 6,
                fontSize: 16
            }}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

Placeholder.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
} as any;

function SingleValue(props: SingleValueProps<ISelectOption>) {
    return (
        <Typography
            style={{ fontSize: 16 }}
            {...props.innerProps}
        >
            {props.data.label}
        </Typography>
    );
}

SingleValue.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object.isRequired,
} as any;

function ValueContainer(props: ValueContainerProps<ISelectOption>) {
    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                flex: 1,
                alignItems: 'center',
                overflow: 'hidden'
            }}
        >
            {props.children}
        </div>
    )
}

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
} as any;

function MultiValue(props: MultiValueProps<ISelectOption>) {
    return (
        <Chip
            tabIndex={-1}
            label={props.data.label}
            style={{ margin: '4px 4px 4px 0' }} //theme.spacing(0.5, 0.25),
            onDelete={props.removeProps.onClick}
            deleteIcon={<Cancel {...props.removeProps} />}
        />
    );
}

MultiValue.propTypes = {
    children: PropTypes.node,
    isFocused: PropTypes.bool,
    removeProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
} as any;

function Menu(props: MenuProps<ISelectOption>) {
    return (
        <Paper
            style={{
                position: 'absolute',
                marginTop: '8px',   //theme.spacing(1),
                left: 0,
                right: 0,
                zIndex: 1,
            }}
            {...props.innerProps}
        >
            {props.children}
        </Paper>
    );
}

Menu.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.object,
    selectProps: PropTypes.object,
} as any;

const muiComponents = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    IndicatorSeparator,
};

const SelectWV: React.SFC<IProps> = ({ errors, value, label, onChange, required, multiple, selectOptions, readonly }) => {
    function handleChange(newValue: ValueType<ISelectOption>) {
        if (!!newValue) {
            if (Array.isArray(newValue)) {
                let multi = newValue as ISelectOption[]
                onChange(multi)
            } else {
                let single = newValue as ISelectOption
                onChange([single])
            }
        } else {
            onChange([])
        }
    }

    return (
        <NoSsr>
            <Select
                TextFieldProps={{
                    disabled: readonly,
                    required: required,
                    error: !!errors ? true : undefined,
                    label: !!value ? label : "Select " + label,
                    placeholder: "Select " + label,
                    helperText: !!errors ? errors.join(', ') : undefined,
                    InputLabelProps: {
                        shrink: !!value && value.length > 0 ? true : undefined
                    }
                }}
                isDisabled={readonly}
                options={selectOptions}
                components={muiComponents}
                value={!!value ? value : null}
                onChange={handleChange}
                placeholder=""
                isMulti={multiple}
                isClearable
            />
        </NoSsr>
    );
}

export default SelectWV;