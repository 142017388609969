import React from 'react'

import ProgramCreateCommandList from './ProgramCreatorCommandList'
import ProgramCreatorFileForm from './ProgramCreatorFileForm'
import ProgramCreatorAdvForm from './ProgramCreatorAdvForm'
import ProgramCreatorPpForm from './ProgramCreatorPpForm'
import ProgramCreatorCoverForm from './ProgramCreatorCoverForm'
import ProgramCreatorMusicForm from './ProgramCreatorMusicForm'
import { 
  TimeBlock, PlayFileCommand, 
  Command, PlayAdvCommand, 
  PlayPpCommand, PlayCoverCommand, 
  PlayMusicCommand, 
  TimeBlockErrors, 
  defaultPlayListArray, Program 
} from '@shared/common'
import { observer } from 'mobx-react'
import { Grid, Divider, Box } from '@material-ui/core'
import { computed } from 'mobx'
import { TextFieldWV, TimeFieldWV } from '@shared/ui'

interface IProps {
  data: TimeBlock,
  onRemove(): void,
  errors?: TimeBlockErrors
  program: Program
}

const cmpCmdByIdx = (cmd1: Command, cmd2: Command) => {
  return cmd1.index - cmd2.index
}
@observer
class ProgramCreateBlock extends React.Component<IProps> {
  handleAddNewPlayFileCommand = () => {
    let maxIndex = 0
    if (this.props.data.playFileCommands.length > 0) {
      maxIndex = Math.max(...this.props.data.playFileCommands.map(v => v.index))
    }

    let cmd: PlayFileCommand = {
      index: maxIndex + 1,
      type: 'PlayFileCommand',
      pref: false,
      startTime: this.props.data.startTime !== '' ? this.props.data.startTime : '10:00',
      ...this.datesRangeFromProgram
    } as any
    this.props.data.playFileCommands.push(cmd)
  }

  handleAddNewAdvertisingCommand = () => {
    let maxIndex = 0
    if (this.props.data.playAdvCommands.length > 0) {
      maxIndex = Math.max(...this.props.data.playAdvCommands.map(v => v.index))
    }

    let cmd: PlayAdvCommand = {
      index: maxIndex + 1,
      type: 'PlayAdvertisingCommand',
      repetitionPerHour: 1,
      songsCountInBlock: 1,
      programCommandPlaylists: defaultPlayListArray(),
      ...this.datesRangeFromProgram
    } as any
    this.props.data.playAdvCommands.push(cmd)
  }

  handleAddNewPpCommand = () => {
    let maxIndex = 0
    if (this.props.data.playPpCommands.length > 0) {
      maxIndex = Math.max(...this.props.data.playPpCommands.map(v => v.index))
    }

    let cmd: PlayPpCommand = {
      index: maxIndex + 1,
      type: 'PlayPpCommand',
      afterSongsCount: 1,
      playCount: 1,
      programCommandPlaylists: defaultPlayListArray(),
      ...this.datesRangeFromProgram
    } as any
    this.props.data.playPpCommands.push(cmd)
  }

  handleAddNewCoverCommand = () => {
    let maxIndex = 0
    if (this.props.data.playCoverCommands.length > 0) {
      maxIndex = Math.max(...this.props.data.playCoverCommands.map(v => v.index))
    }

    let cmd: PlayCoverCommand = {
      index: maxIndex + 1,
      type: 'PlayCoverCommand',
      repetitionPerHour: 1,
      songsCountInBlock: 1,
      programCommandPlaylists: defaultPlayListArray(),
      ...this.datesRangeFromProgram
    } as any
    this.props.data.playCoverCommands.push(cmd)
  }

  handleAddNewPlayMusicCommand = () => {
    let maxIndex = 0
    if (this.props.data.playMusicCommands.length > 0) {
      maxIndex = Math.max(...this.props.data.playMusicCommands.map(v => v.index))
    }

    let cmd: PlayMusicCommand = {
      index: maxIndex + 1,
      type: 'PlayMusicCommand',
      programCommandPlaylists: defaultPlayListArray(),
      ...this.datesRangeFromProgram
    } as any
    this.props.data.playMusicCommands.push(cmd)
  }

  @computed get isAdvertisment() {
    return this.props.program.isAdvertisement
  }

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextFieldWV 
              label="Block name"
              value={this.props.data.name}
              onChange={value => this.props.data.name = value}
              errors={this.GetErrorsForField('name')} 
            />
          </Grid>
          <Grid item xs={3}>
            <TimeFieldWV 
              label="Start time"
              value={this.props.data.startTime}
              errors={this.GetErrorsForField('startTime')}
              onChange={value => this.props.data.startTime = value} 
            />
          </Grid>
          <Grid item xs={3}>
            {/* <TimeFieldWV label="Stop time"
              value={this.props.data.stopTime}
              errors={this.GetErrorsForField("stopTime")}
              onChange={value => this.props.data.stopTime = value} /> */}
          </Grid>
          {this.GetErrorsForField('overall') && <Grid item xs={12}><Box color="error.main">{this.GetErrorsForField('overall')}</Box></Grid>}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {(!this.isAdvertisment) &&
            <Grid item xs={12}>
              <ProgramCreateCommandList 
                name="F" 
                color="orange" 
                onAddNew={this.handleAddNewPlayFileCommand}
                commandName="Add Play File"
              >
                {this.props.data.playFileCommands &&
                  this.props.data.playFileCommands.slice().sort(cmpCmdByIdx).map((value, index) => {
                    return <ProgramCreatorFileForm 
                      key={index} 
                      color="orange" 
                      client={this.props.program.client}
                      data={value} 
                      errors={this.props.errors && (this.props.errors.playFileErrors.length > 0 ? this.props.errors.playFileErrors[index] : undefined)}
                      onRemove={() => this.props.data.playFileCommands.splice(index, 1)} 
                    />
                  })
                }
              </ProgramCreateCommandList>
            </Grid>
          }
          <Grid item xs={12}>
            <ProgramCreateCommandList 
              name="A" 
              color="red" 
              onAddNew={this.handleAddNewAdvertisingCommand}
              commandName="Add Advertising"
            >
              {
                this.props.data.playAdvCommands &&
                this.props.data.playAdvCommands.slice().sort(cmpCmdByIdx).map((value, index) => {
                  return <ProgramCreatorAdvForm 
                    key={index} 
                    color="red" 
                    data={value}
                    errors={this.props.errors && (this.props.errors.playAdvErrors.length > 0 ? this.props.errors.playAdvErrors[index] : undefined)}
                    onRemove={() => this.props.data.playAdvCommands.splice(index, 1)} 
                  />
                })
              }
            </ProgramCreateCommandList>
          </Grid>
          {(!this.isAdvertisment) &&
            <Grid item xs={12}>
              <ProgramCreateCommandList 
                name="P" 
                color="green" 
                onAddNew={this.handleAddNewPpCommand}
                commandName="Add Product Placement"
              >
                {
                  this.props.data.playPpCommands &&
                  this.props.data.playPpCommands.slice().sort(cmpCmdByIdx).map((value, index) => {
                    return <ProgramCreatorPpForm 
                      key={index} 
                      color="green" 
                      data={value}
                      errors={this.props.errors && (this.props.errors.playPpErrors.length > 0 ? this.props.errors.playPpErrors[index] : undefined)}
                      onRemove={() => this.props.data.playPpCommands.splice(index, 1)} 
                    />
                  })
                }
              </ProgramCreateCommandList>
            </Grid>
          }
          {(!this.isAdvertisment) &&
            <Grid item xs={12}>
              <ProgramCreateCommandList 
                name="C" 
                color="purple" 
                onAddNew={this.handleAddNewCoverCommand}
                commandName="Add Cover"
              >
                {
                  this.props.data.playCoverCommands &&
                  this.props.data.playCoverCommands.slice().sort(cmpCmdByIdx).map((value, index) => {
                    return <ProgramCreatorCoverForm 
                      key={index} 
                      color="purple" 
                      data={value}
                      errors={this.props.errors && (this.props.errors.playCoverErrors.length > 0 ? this.props.errors.playCoverErrors[index] : undefined)}
                      onRemove={() => this.props.data.playCoverCommands.splice(index, 1)} 
                    />
                  })
                }
              </ProgramCreateCommandList>
            </Grid>
          }
          {(!this.isAdvertisment) &&
            <Grid item xs={12}>
              <ProgramCreateCommandList 
                name="M" 
                color="blue" 
                onAddNew={this.handleAddNewPlayMusicCommand}
                commandName="Add Music"
              >
                {
                  this.props.data.playMusicCommands &&
                  this.props.data.playMusicCommands.slice().sort(cmpCmdByIdx).map((value, index) => {
                    return <ProgramCreatorMusicForm 
                      key={index} 
                      color="purple" 
                      data={value}
                      errors={this.props.errors && (this.props.errors.playMusicErrors.length > 0 ? this.props.errors.playMusicErrors[index] : undefined)}
                      onRemove={() => this.props.data.playMusicCommands.splice(index, 1)} 
                    />
                  })
                }
              </ProgramCreateCommandList>
            </Grid>
          }
        </Grid>
      </React.Fragment>
    )
  }

  private GetErrorsForField = (key: string) => {
    return this.props.errors && this.props.errors.errors[key] ? this.props.errors.errors[key] : undefined
  }

  private get datesRangeFromProgram() {
    let startDate = undefined
    let endDate = undefined

    if (this.props.program.startDate && this.props.program.endDate) {
      startDate = this.props.program.startDate
      endDate = this.props.program.endDate
    }

    return { startDate: startDate, endDate: endDate }
  }
}

export default ProgramCreateBlock