export function chunkify<T>(arr: Array<T>, chunksCount: number): Array<Array<T>> {
    if (chunksCount < 2) {
        return [arr]
    }

    let balanced = new Array<Array<T>>()
    let len = arr.length

    let i = 1
    if (len % chunksCount === 0) {
        let size = Math.floor(len / chunksCount)
        while (i < len) {
            balanced.push(arr.slice(i, i += size))
        }
    } else {
        while (i < len) {
            let size = Math.ceil((len - i) / chunksCount--)
            balanced.push(arr.slice(i, i += size))
        }
    }

    return balanced
}