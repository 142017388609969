import React from 'react'
import styled from 'styled-components'

import AppBar from '@material-ui/core/AppBar'
import { Toolbar, Button, Typography, Tooltip, Grid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react'
import { AccountCircle } from '@material-ui/icons'
import VERSION from '../version'
import ClientSelector from './ClientSelector'
import { DiC, DataContext, entities, AUser, getAbilityByUser } from '@shared/common'
import logo from './logo.gif'

export const StyledButton = styled(({ ...otherProps }) => <Button {...otherProps} />)`
&& {
  color: #fff;
  &:hover {
    background-color: rgba(255, 255, 255, 0.17);
  }
}
`

const menuItems = [
  { label: 'Programs', path: '/programs', entity: 'program' },
  { label: 'Clients', path: '/clients', entity: 'client' },
  { label: 'Devices', path: '/devices', entity: 'device' },
  { label: 'Contents', path: '/contents', entity: 'content' },
  { label: 'Playlists', path: '/playlists', entity: 'playlist' },
  { label: 'Performers', path: '/performers', entity: 'performer' },
  { label: 'Stores', path: '/stores', entity: 'store' },
  { label: 'Locations', path: '/locations', entity: 'location' },
  { label: 'Addresses', path: '/addresses', entity: 'address' },
]

interface ILogInBlockProps {
  userName: string
  onLogOut(): void
}

const LogInBlock: React.SFC<ILogInBlockProps> = ({ userName, onLogOut }) => (
  <Grid item style={{ marginLeft: '24px' }}>
    <Tooltip title={userName} interactive>
      <span>
        <StyledButton
          // style={{
          //   borderColor: 'rgba(255, 255, 255, 0.5)'
          // }}
          //variant='outlined'
          variant="text"
          color="default"
          onClick={onLogOut}
          startIcon={<AccountCircle />}
        >
          Log Out
        </StyledButton>
      </span>
    </Tooltip>
  </Grid>
)

interface IProps extends RouteComponentProps {
  onLogOut(): void
  onLogIn(): void
}

const MainMenu: React.SFC<IProps> = observer(({ location, onLogOut }) => {
  const isLoggedIn = localStorage.getItem('jwt-token') !== null
  const global = DiC.get<DataContext>(entities.DataContext).global
  const auser: AUser | null = global.loggedInUser
  const version = global.version
  return (
    <React.Fragment>
      <AppBar position="fixed" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
        <Toolbar
          style={{ width: '100%' }}
        >
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item key="logo">
              <img src={logo} style={{ height: '24px', paddingTop: '4px' }} />
            </Grid>
            {(auser && isLoggedIn) && menuItems.filter(mi => getAbilityByUser(auser, mi.entity, 'read')).map((v, i) => {
              var re = RegExp(v.path)
              const isCurrent = re.test(location.pathname)
              return (
                <Grid item key={i}>
                  <StyledButton
                    style={isCurrent ? { backgroundColor: 'rgba(255, 255, 255, 0.07)' } : undefined}
                    component={RouterLink}
                    to={v.path}
                    color="default"
                  >
                    {v.label}
                  </StyledButton>
                </Grid>
              )
            })}
            <div style={{ flex: '1 1 auto' }} />
            {isLoggedIn && <ClientSelector />}
            {isLoggedIn && <LogInBlock userName={auser ? auser.email : ''} onLogOut={onLogOut} />}
          </Grid>
          <Typography
            style={{
              position: 'absolute',
              fontSize: '9px',
              bottom: 0,
              right: '33px' //24 toolbar + 4 grid + 5 button
            }}
            variant="caption"
            color="inherit"
          >
            {`${VERSION}${version !== '' ? `[${version}]` : ''}`}
          </Typography>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
})

export default withRouter(MainMenu)