import { types as t, Instance } from 'mobx-state-tree'
import { Address } from '../models'

export const AddressRepository = t.model({
    all: t.map(Address),
    ordered: t.array(t.reference(Address)),
    selected: t.array(t.reference(Address)),
    filterSelected: t.array(t.reference(Address)),
})

export type AddressRepository = Instance<typeof AddressRepository>
