import moment from 'moment'

export const FixDatesFromBackend = (objs: any, fields: string[]) => {
    !!objs && Object.values(objs).forEach((v: any) => {
        fields.forEach(f => {
            const formatted = f + 'Formatted'
            if (v[formatted]) {
                v[f] = moment.utc(v[formatted], 'DD.MM.YYYY').toDate()
            } else {
                v[f] && (v[f] = moment(v[f]).toDate())
            }
        })
    })
}

export const FixTimesFromBackend = (objs: any, fields: string[]) => {
    !!objs && Object.values(objs).forEach((v: any) => {
        fields.forEach(f => {
            const formatted = f + 'Formatted'
            if (v[formatted]) {
                v[f] = v[formatted]
            } else {
                v[f] && (v[f] = moment.utc(v[f]).format('HH:mm'))
            }
        })
    })
}

export const FixDateTimesFromBackend = (objs: any, fields: string[]) => {
    !!objs && Object.values(objs).forEach((v: any) => {
        fields.forEach(f => {
            const formatted = f + 'Formatted'
            if (v[formatted]) {
                v[f] = v[formatted]
            } else {
                v[f] && (v[f] = moment.utc(v[f]).valueOf())
            }
        })
    })
}

export const FormatToBackendDate = (date: Date) => {
    return moment(date).format('YYYY-MM-DD')
}

export const FixNullsToUndefinedFromBackend = (objs: any) => {
    !!objs && Object.values(objs).forEach((obj: any) => {
        Object.keys(obj).forEach((k: string) => {
            if (obj[k] === null) {
                obj[k] = undefined
            }
        })
    })
}