import { types as t, Instance } from 'mobx-state-tree'
import { schema, NormalizedSchema } from 'normalizr'
import { PlaylistType, PlaylistTypeSchema, storePlaylistType } from './playlist-type'
import { PlaylistEntry, PlaylistEntrySchema, storePlaylistEntry } from './playlist-entry'
import { DataContext } from '../data-context'
import { FixNullsToUndefinedFromBackend, FixDatesFromBackend } from '../services/utils'

export const Playlist = t
    .model('playlist', {
        id: t.identifierNumber,
        name: t.optional(t.string, ''),
        playlistType: t.maybe(t.reference(PlaylistType)),
        playlistEntries: t.optional(t.array(t.reference(PlaylistEntry)), []),
        createdAt: t.optional(t.Date, new Date()),
        updatedAt: t.optional(t.Date, new Date()),
    })

export type Playlist = Instance<typeof Playlist>

export const emptyPlaylist = {
    id: 0,
    name: '',
    playlistType: undefined,
    playlistEntries: [],
    createdAt: new Date(),
    udpatedAt: new Date(),
}

export const playlistConstraints = {
    name: {
        presence: { allowEmpty: false },
        format: {
            pattern: /^\S*$/,
            message: 'must not contain spaces'
        }
    },
    //playlistType: { presence: { allowEmpty: false } },
    playlistEntries: { presence: { allowEmpty: false, message: '^Select at least one track' } },
}

export const PlaylistSchema = new schema.Entity('playlists', {
    playlistType: PlaylistTypeSchema,
    playlistEntries: [PlaylistEntrySchema],
})

export function storePlaylist(dataContext: DataContext, norm: NormalizedSchema<any, any>) {
    const playlists = norm.entities.playlists
    if (!!playlists && Object.keys(playlists).length > 0) {
        storePlaylistType(dataContext, norm)
        storePlaylistEntry(dataContext, norm)
        FixDatesFromBackend(playlists, ['createdAt', 'updatedAt'])
        FixNullsToUndefinedFromBackend(playlists)
        dataContext.playlists.all.merge(playlists)
        norm.entities.playlists = undefined
    }
}