import React from 'react'
import { observer } from 'mobx-react'
import {
    GenericValue, PageService, PageContext
} from '@shared/common'
import Filters, { IFilters } from './Filters'
import {
    Dialog, DialogTitle, DialogContent,
    DialogActions, IconButton
} from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import { action } from 'mobx'
import StyledBadge from './StyledBadge'
import DialogButtons from './DialogButtons'

interface IProps<A> {
    pageService: PageService<A>,
    ctx: PageContext,
    filterFields: IFilters
}

@observer
class GenericFilterForm<A> extends React.Component<IProps<A>> {

    openFilters = () => {
        this.handleFillDialogs()
        this.props.pageService.filtersState.openFilters()
    }

    closeFilters = () => {
        this.props.pageService.filtersState.closeFilters()
    }

    handleFillDialogs = () => {
        let filters = this.props.pageService.paginatedList.mixedFilters
        for (let [k, v] of Object.entries(this.props.filterFields)) {
            if (filters.has(k)) {
                if (v.type === 'dialog' && !!v.dialogParams) {
                    let ids = filters!.get(k)
                    if (!!ids && Array.isArray(ids)) {
                        let strIds = ids.map(id => id.toString())
                        v.dialogParams!.selectByIds(strIds)
                    }
                }
            }
        }
    }

    handleKeyPress = (e: React.KeyboardEvent<any>) => {
        switch (e.key) {
            case 'Enter':
                e.stopPropagation()
                this.handleApplyFilters()
                break
            default: return
        }
    }

    handleApplyFilters = () => {
        this.props.pageService.filtersState.applyFilters()
    }

    @action handleResetFilters = () => {
        this.props.pageService.filtersState.resetFilters()
        this.handleApplyFilters()
    }

    @action handleChangeFilters = (name: string, value: GenericValue) => {
        if (Array.isArray(value) ? value.length > 0 : value !== '') {
            this.props.ctx.filters.set(name, value)
        } else {
            this.props.ctx.filters.delete(name)
        }
    }

    render() {
        return (
            <React.Fragment>
                <StyledBadge
                    badgeContent={this.props.ctx.applyedFiltersCount}
                    color="primary"
                >
                    <IconButton
                        color={this.props.ctx.applyedFiltersCount > 0 ? 'primary' : 'default'}
                        disabled={this.props.ctx.isLoading}
                        onClick={this.openFilters}
                    >
                        <FilterList />
                    </IconButton>
                </StyledBadge>
                <Dialog
                    open={this.props.ctx.filtersOpen}
                    onClose={this.closeFilters}
                    aria-labelledby="filters-dialog-title"
                    fullWidth
                    onKeyPress={this.handleKeyPress}
                    maxWidth="sm"
                >
                    <DialogTitle id="filters-dialog-title">Filters</DialogTitle>
                    <DialogContent>
                        <Filters
                            filterParams={this.props.filterFields}
                            filterValues={this.props.ctx.filters}
                            presetFiltersValues={this.props.ctx.filtersPreset}
                            onChange={this.handleChangeFilters}
                        />
                    </DialogContent>
                    <DialogActions>
                        <DialogButtons
                            onConfirm={this.handleApplyFilters}
                            confirmText="Apply"
                            onCancel={this.closeFilters}
                            onReset={this.handleResetFilters}
                        />
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default GenericFilterForm