import { types as t, Instance } from 'mobx-state-tree'
import { Gender } from '../models'

export const GendersRepository = t.model({
    all: t.map(Gender),
    ordered: t.array(t.reference(Gender)),
    selected: t.array(t.reference(Gender)),
    filterSelected: t.array(t.reference(Gender)),
})

export type GendersRepository = Instance<typeof GendersRepository>
