import React from 'react'
import { IconButton, Dialog } from '@material-ui/core'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { Info } from '@material-ui/icons'
import InfoCard from './InfoCard'

interface IProps {
    children: React.ReactNode
    id: string | number
    path?: string
    wide?: boolean
}

@observer class InfoModal extends React.Component<IProps> {
    @observable opened: boolean = false
    basePath: string = ''

    componentDidMount() {
        if (!!this.props.path) {
            this.basePath = `/${this.props.path}`
        } else {
            this.basePath = window.location.pathname
        }
    }

    handleOpen = () => {
        window.history.replaceState(null, '', `${this.basePath}/${this.props.id}`)
        this.opened = true
    }

    handleClose = () => {
        this.opened = false
        window.history.replaceState(null, '', `${this.basePath}`)
    }

    render() {
        return (
            <React.Fragment>
                <IconButton
                    aria-label="Info"
                    onClick={this.handleOpen}
                >
                    <Info />
                </IconButton>
                <Dialog
                    open={this.opened}
                    onClose={this.handleClose}
                    maxWidth={!!this.props.wide ? false : 'xl'}
                >
                    <InfoCard wide={this.props.wide}>
                        {this.props.children}
                    </InfoCard>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default InfoModal