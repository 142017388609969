import React from 'react'
import { IAttribute, Demo } from '@shared/common'
import { InfoCardContent, AttributesList } from '@shared/ui'

interface IProps {
    attributes: IAttribute<Demo>[]
    entity: Demo
}

const DemoInfo: React.FunctionComponent<IProps> = ({ entity, attributes }) => (
    <React.Fragment>
        <InfoCardContent
            header="Demo"
            subHeader={`ID = ${entity.id}`}
            content={
                <AttributesList
                    entity={entity}
                    attrs={attributes.filter(a => a.show)}
                />
            }
        />
    </React.Fragment>

)

export default DemoInfo
