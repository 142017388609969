import React from 'react'

import './program_creator.css'
import { observer } from 'mobx-react'
import ProgramCreatorPlaylistList from './ProgramCreatorPlaylistList'
import { Box, Grid } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { StyledContainer, SmallInputBox } from './ProgramCreatorShared'
import { PlayAdvCommand, CommandWithPlValidationError } from '@shared/common'
import { DateRangePickerWV, TextFieldWV } from '@shared/ui'

interface IProps {
  color: string
  data: PlayAdvCommand
  errors?: CommandWithPlValidationError
  onRemove(): void
}

@observer
class ProgramCreatorAdvForm extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  handelActivationDatesChange = (startDate: Date, endDate: Date) => {
    this.props.data.startDate = startDate
    this.props.data.endDate = endDate
  }

  handleActivationDatesReset = () => {
    this.props.data.startDate = undefined
    this.props.data.endDate = undefined
  }

  render() {
    return (
      <React.Fragment>
        <StyledContainer>
          <Grid container>
            <Grid item xs container>
              <DateRangePickerWV
                label="Activation dates"
                errors={this.GetErrorsForField('dateRange')}
                startDate={this.props.data.startDate}
                endDate={this.props.data.endDate}
                onChange={this.handelActivationDatesChange}
                onDatesReset={this.handleActivationDatesReset}
              />
              <SmallInputBox>
                <TextFieldWV
                  label="T/H"
                  type="number"
                  value={String(this.props.data.repetitionPerHour)}
                  onChange={value => this.props.data.repetitionPerHour = parseInt(value)}
                  errors={this.GetErrorsForField('repetitionPerHour')}
                />
              </SmallInputBox>
              <SmallInputBox>
                <TextFieldWV
                  label="Count"
                  type="number"
                  value={String(this.props.data.songsCountInBlock)}
                  onChange={value => this.props.data.songsCountInBlock = parseInt(value)}
                  errors={this.GetErrorsForField('songsCountInBlock')}
                />
              </SmallInputBox>
              <ProgramCreatorPlaylistList data={this.props.data.programCommandPlaylists} errors={this.props.errors && this.props.errors.playlistErrors} />
            </Grid>
            <Grid item>
              <Box>
                <Clear onClick={this.props.onRemove} />
              </Box>
            </Grid>
          </Grid>
        </StyledContainer>
      </React.Fragment >
    )
  }

  private GetErrorsForField = (key: string) => {
    return this.props.errors && this.props.errors.errors[key] ? this.props.errors.errors[key] : undefined
  }
}

export default ProgramCreatorAdvForm