import React from 'react'
import { Grid } from '@material-ui/core'
import InfoCard from './InfoCard'

interface IProps {
    children: React.ReactNode
    wide?: boolean
}

const InfoPage: React.SFC<IProps> = ({ children, wide }) => {
    return (
        <Grid container direction="row" style={{ height: '100%' }}>
            <div style={{ flex: '1 1 auto' }} />
            <InfoCard wide={wide}>
                {children}
            </InfoCard>
            <div style={{ flex: '1 1 auto' }} />
        </Grid>
    )
}

export default InfoPage