import React from 'react'
import { Grid } from '@material-ui/core'
import FileUploadForm from './FileUploadForm'
import { observer } from 'mobx-react'
import {
    Demo, DemoRecording,
    emptyDemoRecording
} from '@shared/common'
import {
    DateRangePickerWV,
    TextFieldWV, MultilineAdd
} from '@shared/ui'

interface IProps {
    demo: Demo
    errors?: any
}

@observer
class DemoForm extends React.Component<IProps> {

    handeAddRecording = () => {
        let dr: DemoRecording = emptyDemoRecording as any
        this.props.demo.demoRecordings.push(dr)
    }

    handeRemoveRecording = (idx: number) => {
        this.props.demo.demoRecordings.splice(idx, 1)
    }

    render() {
        const errorsRecs = this.props.errors ? this.props.errors.demoRecordings : undefined
        return (
            <Grid item container direction="column">
                <Grid item container direction="row" spacing={2}>
                    <Grid item xs>
                        <DateRangePickerWV
                            required
                            label="Demo recording date"
                            mode="single"
                            startDate={this.props.demo.recordingDate}
                            errors={this.props.errors ? this.props.errors.recordingDate : undefined}
                            onChange={(begin, end) => {
                                this.props.demo.recordingDate = begin
                            }}
                            onDatesReset={() => {
                                this.props.demo.recordingDate = new Date()
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextFieldWV
                            label="Comment"
                            //errors={this.props.errors.get('comment')}
                            value={this.props.demo.comment}
                            onChange={value => {
                                this.props.demo.comment = value
                            }}
                        />
                    </Grid>
                </Grid>
                <MultilineAdd<DemoRecording>
                    secondary
                    placeholder="No Demo Recordings. You can add them by clicking the button on the left."
                    collection={this.props.demo.demoRecordings}
                    renderItem={(entity, idx) => {
                        return (
                            <FileUploadForm
                                idx={idx}
                                demoRecording={entity}
                                errors={errorsRecs && errorsRecs[0] ? errorsRecs[0][idx] : undefined}
                            />
                        )
                    }}
                    onAdd={this.handeAddRecording}
                    onRemove={this.handeRemoveRecording}
                />
            </Grid>
        )
    }
}

export default DemoForm