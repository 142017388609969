import React from 'react'
import {
    Dialog, DialogTitle,
    DialogContent, DialogActions, Button,
    Grid, ButtonGroup
} from '@material-ui/core'
import ClientsPage from '../../clients/ClientsPage'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react'
import { Clear, ExpandMore } from '@material-ui/icons'
import { StyledButton } from './MainMenu'
import { DataContext, entities, DiC } from '@shared/common'
import { DialogButtons } from '@shared/ui'

@observer class ClientSelector extends React.Component {
    @observable isOpen: boolean = false

    get dataCtx() {
        return DiC.get<DataContext>(entities.DataContext)
    }

    get auser() {
        return this.dataCtx.global.loggedInUser
    }

    handleFillSelect = () => {
        this.dataCtx.clients.filterSelected.clear()
        if (!!this.auser && !!this.auser.selectedClient && this.auser.selectedClient.id > 0 && this.dataCtx.clients.all.has(this.auser.selectedClient.id.toString())) {
            this.dataCtx.clients.filterSelected[0] = this.dataCtx.clients.all.get(this.auser.selectedClient.id.toString())!
        }
    }

    @action handleSelect = () => {
        if (!!this.auser) {
            if (this.dataCtx.clients.filterSelected && this.dataCtx.clients.filterSelected.length > 0) {
                this.auser.selectedClient = this.dataCtx.clients.filterSelected[0]
            } else {
                this.auser.selectedClient = undefined
            }
        }
        this.handleClose()
    }

    handleKeyPress = (e: React.KeyboardEvent<any>) => {
        switch (e.key) {
            case 'Enter':
                e.stopPropagation()
                this.handleSelect()
                break
            default: return
        }
    }

    handleClear = () => {
        if (!!this.auser) {
            this.auser.selectedClient = undefined
            this.dataCtx.clients.filterSelected.clear()
        }
    }

    handleClose = () => {
        this.isOpen = false
    }

    openModal = () => {
        this.handleFillSelect()
        this.isOpen = true
    }

    render() {
        return (
            <Grid item key="client-selector">
                <ButtonGroup variant="outlined" color="primary">
                    <StyledButton
                        style={{
                            borderColor: 'rgba(255, 255, 255, 0.5)'
                        }}
                        variant="outlined"
                        endIcon={<ExpandMore />}
                        onClick={this.openModal}
                    >
                        {!!this.auser && !!this.auser.selectedClient ? this.auser.selectedClient.name : 'Select client'}
                    </StyledButton>
                    {(!!this.auser && !!this.auser.selectedClient) &&
                        <StyledButton
                            style={{
                                borderColor: 'rgba(255, 255, 255, 0.5)',
                                padding: '5px',
                            }}
                            variant="outlined"
                            onClick={this.handleClear}
                        >
                            <Clear fontSize="small" />
                        </StyledButton>}
                </ButtonGroup>
                <Dialog
                    open={this.isOpen}
                    onClose={this.handleClose}
                    onKeyPress={this.handleKeyPress}
                    fullWidth
                    maxWidth="xl"
                >
                    <DialogTitle>Select Client</DialogTitle>
                    <DialogContent
                        style={{ backgroundColor: '#eeeeee' }}
                    >
                        <ClientsPage
                            showAll
                            selectConfig={{
                                type: 'single',
                                usage: 'filter',
                                onSelect: this.handleSelect
                            }}
                        // presetFilters={{
                        //     client: new Map([['id', []]])
                        // }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <DialogButtons
                            onConfirm={this.handleSelect}
                            onCancel={this.handleClose}
                        />
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }

}

export default ClientSelector