import { IFilters } from '@shared/ui'
import { timeZones } from '@shared/common'

export const addressFilters = (): IFilters => ({
    country: { label: 'Country', type: 'string' },
    city: { label: 'City', type: 'string' },
    region: { label: 'Region', type: 'string' },
    street: { label: 'Street', type: 'string' },
    home: { label: 'Home', type: 'string' },
    zipCode: { label: 'Zip code', type: 'numeric', isRange: true },
    timeZone: {
        label: 'Time zone',
        type: 'select',
        selectParams: {
            multiple: true,
            options: timeZones.map(t => ({ value: t, label: t })),
        }
    },
    latitude: { label: 'Latitude', type: 'numeric', isRange: true },
    longitude: { label: 'Longitude', type: 'numeric', isRange: true }
})