import { types as t } from 'mobx-state-tree'
import camelcaseKeys from 'camelcase-keys'
import { observable, toJS } from 'mobx'
import validate from '../../../utils/extended-validate'

interface IUIContext {
    isLoading: boolean
}

interface IDomainService<A> {
    createOrUpdate(entity: A, attachment?: File, uploadCallback?: (progressEvent: any) => void): Promise<A>
}

export interface ISubmitErrorFormat {
    errors: Map<string, any>,
    message: string
}

export interface ISubmitResult<A> {
    isOk: boolean,
    response?: A | ISubmitErrorFormat
}

export class FormState<A> {
    constructor(
        readonly domainService: IDomainService<A>,
        readonly ctx: IUIContext
    ) { }

    @observable formOpen: boolean = false
    @observable errors: Map<string, any> = new Map<string, any>()
    @observable uploadingPercent?: number

    openForm() {
        this.formOpen = true
    }

    closeForm() {
        this.formOpen = false
    }

    getErrorsForField(key: string) {
        return this.errors && this.errors.get(key) ? this.errors.get(key) : undefined
    }

    setErrors(errors: Map<string, any>) {
        this.clearErrors()
        errors.forEach((v, k) => {
            this.errors.set(k, v)
        })
    }

    clearErrorsForField(field: string) {
        if (this.errors && this.errors.get(field)) {
            this.errors.delete(field)
        }
    }

    clearErrors() {
        if (this.errors) {
            this.errors.clear()
        }
    }

    uploadingPercentCounter = (progressEvent: any) => {
        !!progressEvent && !!progressEvent.loaded && !!progressEvent.total ? this.uploadingPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total) : this.uploadingPercent = undefined
    }

    async save(entity: A, constraints: any, attachment?: File): Promise<ISubmitResult<A>> {
        this.clearErrors()
        const err = validate(entity, constraints)
        if (!!err) {
            this.setErrors(new Map(Object.entries(err)))
            return Promise.resolve({
                isOk: false
            })
        } else {
            this.ctx.isLoading = true
            try {
                const entityResponse: A = await this.domainService.createOrUpdate(entity, attachment, this.uploadingPercentCounter)
                return Promise.resolve({ isOk: true, response: entityResponse as A })
            } catch (e) {
                console.error(e)
                if (!!e.response && e.response.status === 400) {
                    let response = camelcaseKeys(e.response.data, { deep: true }) as any
                    // TODO: check if e.response.data is ISubmitErrorFormat
                    if (!!response) {
                        this.setErrors(new Map(Object.entries((response as ISubmitErrorFormat).errors)))
                    }
                    return Promise.resolve({
                        isOk: false,
                        response: response
                    })
                }

                return Promise.reject()
            }
            finally {
                this.ctx.isLoading = false
                this.uploadingPercent = undefined
            }
        }
    }
}
